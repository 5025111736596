// Import required libraries
import React, { useState } from "react";
// import Button from "@material-ui/core/Button";
import { Button } from "../../uiTheme";
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import orderBy from "lodash/orderBy";
import { Popover } from "../../uiTheme";

// Import custom components
import Loader from "../../components/Loader/Loader";

// Import action creators

// Import data & utils
import { config } from "../../../js/config/config";
import { makeDefaultResponseJson, makeRunNameFromRun } from "../../utils/utils";

// Import styles
import "./AIDESupport.scss";

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: "none",
  },
  popoverTypography: {
    fontSize: 11,
    fontFamily: "Hind Siliguri",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    color: "#46596a",
    margin: 0,
    marginTop: 4,
    marginBottom: 4,
    width: "150px",
  },
  multiSelect: {
    fontSize: "12px",
    fontFamily: "Hind Siliguri",
    width: "100%",
    height: "30px",
    borderRadius: "5px",
    color: "#46596a",
    backgroundColor: "white",
    boxSizing: "border-box",
    //paddingLeft: "8px",
    display: "flex",
    alignItems: "center",
    //textAlign: "center",
    border: "1px solid #dedede",
    "&:before": {
      borderBottom: "0px",
    },
    "&:after": {
      borderBottom: "0px",
    },
    "&:hover:not(.Mui-disabled):before": {
      borderBottom: "0px",
    },
    "& .MuiOutlinedInput-input": {
      padding: "6px 6px",
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "transparent",
    },
    "& .MuiSelect-select.MuiSelect-select": {
      paddingLeft: "8px",
      // height: "25px",
    },
  },
  singleMenuItem: {
    fontSize: "13px",
    fontFamily: "Hind Siliguri",
    padding: "8px 12px",
    color: "#46596a",
    width: "auto",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    "&:hover": {
      backgroundColor: "#4EAFB3",
      color: "white",
    },
  },
  typographyStyle: {
    fontFamily: "Hind Siliguri",
    fontSize: 14,
    color: "#46596a",
    minWidth: 400,
    maxWidth: 600,
  },
  contained: {
    color: "white",
    backgroundColor: "#46596a",
    marginRight: 4,
    fontSize: 11,
    padding: 10,
    height: 23,
    minWidth: 15,
    textTransform: "capitalize",
    fontFamily: "Hind Siliguri",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#46596a",
      boxShadow: "none",
    },
  },
  outlined: {
    borderColor: "#46596a",
    backgroundColor: "transparent",
    color: "#46596a",
    marginRight: 4,
    fontSize: 11,
    padding: 10,
    height: 23,
    minWidth: 15,
    textTransform: "capitalize",
    fontFamily: "Hind Siliguri",
    "&:hover": {
      backgroundColor: "transparent",
      boxShadow: "none",
    },
  },
}));

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 150,
      width: "auto",
    },
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  variant: "menu",
  getContentAnchorEl: null,
};

const usePlaceholderStyles = makeStyles((theme) => ({
  placeholder: {
    color: "#aaa",
  },
}));

const Placeholder = ({ children }) => {
  const classes = usePlaceholderStyles();
  return <div className={classes.placeholder}>{children}</div>;
};

const AIDESupport = (props) => {
  const {
    setOpenDialog,
    user,
    activeApp,
    updateUserInfo,
    updateAlertInfo,
    allData,
  } = props;
  const [inputSelection, setInputSelection] = useState({
    app_id: activeApp?.id,
    country: "",
  });
  const prevRunData = allData?.runData?.find(
    (elem) => elem.id === config.hardCoded.runPrevRunsId
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  let modifiedPrevRunData = prevRunData?.data.map((elem) => ({
    ...elem,
    modifiedName: makeRunNameFromRun(elem),
  }));
  const [uiError, setUIError] = useState(false);
  const classes = useStyles();

  const countryBrandData = allData?.globalParams?.find(
    (data) => data.id === "country_brand_segment_hierarchy"
  );
  const countryData = countryBrandData?.data
    .filter((obj) => obj.is_geo_targeting_country !== true)
    ?.map((elem) => ({
      countryName: elem.name,
      data: elem.children,
    }));
  const brandData =
    countryData?.find((elem) => elem.countryName === inputSelection?.country)
      ?.data || [];

  const dataAvailable =
    allData.apps &&
    allData.models &&
    countryData &&
    brandData &&
    allData.runData;

  // console.log(`countryBrandData`, countryBrandData);
  // console.log(`countryData`, countryData);
  // console.log(`brandData`, brandData);
  // console.log(`inputSelection`, inputSelection);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const validateForm = () => {
    let fileSize = 0;
    inputSelection?.selectedFiles?.forEach((file) => {
      fileSize += +file.size;
    });

    if (fileSize > 10485760) {
      setUIError(true);
    } else {
      submitForm();
    }
  };

  const submitForm = () => {
    updateAlertInfo({
      open: true,
      message: "Submitting your ticket...",
      severity: "success",
    });
    handleClose();
    var formData = new FormData();
    inputSelection?.selectedFiles?.forEach((file) => {
      formData.append("selectedAttachments", file);
    });
    formData.set("app_id", activeApp?.id);
    formData.set(
      "country",
      inputSelection.country ? inputSelection.country : ""
    );
    formData.set("brand", inputSelection.brand ? inputSelection.brand : "");
    formData.set("run_id", inputSelection.run_id ? inputSelection.run_id : "");
    formData.set("model", inputSelection.model ? inputSelection.model : "");
    formData.set(
      "description",
      inputSelection.description ? inputSelection.description : ""
    );

    const url = config.api.submitFormUrl;
    let status;
    fetch(url, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        console.groupCollapsed("requesting", url);
        console.log("REPSONSE -> ", response);
        status = response.status;
        if (response.status === 200) {
          return response.clone().json();
        } else {
          let error = response.statusText;
          let errorCode = response.status;
          console.log("ERROR -> ", error);
          updateAlertInfo({
            open: true,
            message:
              "We were unable to submit your request due to some error. Please try again after sometime.",
            severity: "success",
          });
          return { ...makeDefaultResponseJson(), error, errorCode };
        }
      })
      .then((json) => {
        console.log("JSON -> ", json);
        console.groupEnd();
        if (status === 200) {
          updateAlertInfo({
            open: true,
            message:
              "Ticket submitted successfully! You should receive an email with the details.",
            severity: "success",
          });
        } else {
          updateAlertInfo({
            open: true,
            message:
              "We were unable to submit your request due to the following error. Error: " +
              json.statusMessage,
            severity: "error",
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleInputChange = (key, value) => {
    setInputSelection((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleDelete = (key, value) => {
    setInputSelection((preState) => {
      return {
        ...preState,
        [key]: value,
      };
    });
  };

  return (
    <div className="support-dialog-container">
      <p className="popup-title">Raise A Ticket</p>
      {dataAvailable ? (
        <>
          <div className="input-fields-container">
            <div className="input-field-container" style={{ margin: "0 20px" }}>
              <label>Name:</label>
              <div className="support-input-search">
                <input
                  name="name"
                  type="text"
                  value={user.name}
                  className="input-label"
                  onChange={() => handleInputChange}
                  disabled={true}
                />
              </div>
            </div>
            <div className="input-field-container">
              <label>Email:</label>
              <div className="support-input-search">
                <input
                  name="email"
                  type="text"
                  value={user.email}
                  className="input-label"
                  onChange={() => handleInputChange}
                  disabled={true}
                />
              </div>
            </div>
            <div className="input-field-row-container">
              <div className="input-field-sub-container">
                <label>App:</label>
                <div className="support-input-search">
                  <input
                    name="app_name"
                    type="text"
                    value={activeApp?.name}
                    className="input-label"
                    onChange={() => handleInputChange}
                    disabled={true}
                  />
                </div>
              </div>
              <div className="input-field-sub-container">
                <label>Model:</label>
                <div className="support-input-search">
                  <Select
                    name="model"
                    id="model"
                    value={inputSelection.model ? inputSelection.model : ""}
                    onChange={(e) => handleInputChange("model", e.target.value)}
                    className={classes.multiSelect}
                    MenuProps={MenuProps}
                    displayEmpty
                    renderValue={
                      inputSelection.model
                        ? undefined
                        : () => <Placeholder>Select an option</Placeholder>
                    }
                  >
                    {orderBy(allData?.models, ["name"], ["asc"])?.map((obj) => (
                      <MenuItem
                        value={obj.name}
                        key={obj.id}
                        className={classes.singleMenuItem}
                      >
                        {obj.name}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </div>
            </div>
            <div className="input-field-row-container">
              <div className="input-field-sub-container">
                <label>Country:</label>
                <div className="support-input-search">
                  <Select
                    name="country"
                    id="country"
                    value={inputSelection.country ? inputSelection.country : ""}
                    onChange={(e) =>
                      handleInputChange("country", e.target.value)
                    }
                    className={classes.multiSelect}
                    MenuProps={MenuProps}
                    displayEmpty
                    renderValue={
                      inputSelection.country
                        ? undefined
                        : () => <Placeholder>Select an option</Placeholder>
                    }
                  >
                    {orderBy(countryData, ["countryName"], ["asc"])?.map(
                      (obj) => (
                        <MenuItem
                          value={obj.countryName}
                          key={obj.countryName}
                          className={classes.singleMenuItem}
                        >
                          {obj.countryName}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </div>
              </div>
              <div className="input-field-sub-container">
                <label>Brand:</label>
                <div className="support-input-search">
                  <Select
                    name="brand"
                    id="brand"
                    value={inputSelection.brand ? inputSelection.brand : ""}
                    onChange={(e) => handleInputChange("brand", e.target.value)}
                    className={classes.multiSelect}
                    MenuProps={MenuProps}
                    displayEmpty
                    renderValue={
                      inputSelection.brand
                        ? undefined
                        : () => <Placeholder>Select an option</Placeholder>
                    }
                  >
                    {brandData.length ? (
                      orderBy(brandData, ["name"], ["asc"])?.map((obj) => (
                        <MenuItem
                          value={obj.name}
                          key={obj.name}
                          className={classes.singleMenuItem}
                        >
                          {obj.name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem className={classes.singleMenuItem} disabled>
                        {config.messages.noOptions}
                      </MenuItem>
                    )}
                  </Select>
                </div>
              </div>
            </div>
            <div className="input-field-container">
              <label>AIDE/RUN ID:</label>
              <div className="support-input-search">
                <input
                  name="run_id"
                  type="text"
                  value={inputSelection.run_id ? inputSelection.run_id : ""}
                  className="input-label"
                  onChange={(e) => handleInputChange("run_id", e.target.value)}
                />
              </div>
            </div>
            <div className="input-field-container">
              <label>Description:</label>
              <div className="support-input-search">
                <textarea
                  className="input-textarea"
                  value={
                    inputSelection.description ? inputSelection.description : ""
                  }
                  onChange={(e) =>
                    handleInputChange("description", e.target.value)
                  }
                ></textarea>
              </div>
            </div>
            <div className="input-field-container">
              <div className="name-info-container">
                <label>Attachment(s):</label>
                <i
                  className="material-icons-outlined info-icon"
                  aria-owns={open ? "mouse-over-popover" : undefined}
                  aria-haspopup="true"
                  onMouseEnter={handlePopoverOpen}
                  onMouseLeave={handlePopoverClose}
                >
                  info
                </i>
                <Popover
                  id="mouse-over-popover"
                  className={classes.popover}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handlePopoverClose}
                  disableRestoreFocus
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  <p className={classes.popoverTypography}>
                    {config.messages.uploadFileSizeMessage}
                  </p>
                </Popover>
              </div>
              <div className="input-file-selection">
                <div>
                  <label htmlFor="input-file" className="input-file-label">
                    <span>
                      <i className="material-icons-outlined">add</i>
                      Choose Files
                    </span>
                  </label>
                  <input
                    id="input-file"
                    name="attachment"
                    type="file"
                    accept="image/png, image/jpg, image/jpeg, .csv, text/plain, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    multiple
                    data-max-size="2048"
                    onChange={(e) => {
                      setUIError(false);
                      handleInputChange(
                        "selectedFiles",
                        Array.from(e.target.files)
                      );
                    }}
                  />
                </div>
                <div className="selected-file-container">
                  {inputSelection?.selectedFiles &&
                    inputSelection?.selectedFiles?.map((e, i, arr) => (
                      <div className="single-file-container" key={i}>
                        <span>{e.name}</span>
                        <i
                          className={`material-icons-outlined cancel-icon ${
                            inputSelection.selectedFiles ? "visible" : ""
                          }`}
                          onClick={() => {
                            const filterFiles = arr.filter(
                              (elm) => elm.name !== e.name
                            );
                            handleDelete("selectedFiles", filterFiles);
                          }}
                        >
                          clear
                        </i>
                      </div>
                    ))}
                </div>

                {uiError && (
                  <p className="error-msg">
                    {config.messages.uploadFileSizeMessage}
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="form-action-btn">
            <Button
              variant="outlined"
              // className={classes.outlined}
              onClick={() => {
                handleClose();
              }}
              style={{
                height: "23px",
                padding: "10px",
                fontSize: "11px",
                minWidth: "15px",
                boxSizing: "border-box",
                marginRight: "4px",
              }}
            >
              Close
            </Button>
            <Button
              variant="contained"
              // className={classes.contained}
              onClick={() => {
                validateForm();
              }}
              style={{
                height: "23px",
                padding: "10px",
                fontSize: "11px",
                minWidth: "15px",
                boxSizing: "border-box",
                marginRight: "4px",
              }}
            >
              Submit
            </Button>
          </div>
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default AIDESupport;

//Import required libraies
import React, { useEffect, useState } from "react";

//Import Custom Component

// Import custom hooks

// Import action creators

// Import styles

// Import utils & data
import { config } from "../../../../config/config";

function InputTextValidation(props) {
  let {
    id,
    name,
    enable,
    value,
    minValue,
    maxValue,
    onChange,
    customClassName,
    disabledForUploadAudience,
    activeModel,
    style,
    className,
    checkAudience,
  } = props;
  const [error, setError] = useState(false);

  useEffect(() => {
    if (value === "") {
      if (id === "pagePath") setError(true);
    } else {
      setError(false);
    }
  }, [value]);

  return (
    <div
      className={`input-item ${customClassName ? customClassName : ""} ${
        id === "audience_name" &&
        activeModel?.id === config.hardCoded.communityClusteringId
          ? "audience-community"
          : activeModel?.id === config.hardCoded.propensityId
          ? "audience-propensity column"
          : activeModel?.id === config.hardCoded.nlpId
          ? "audience-nlp"
          : ""
      } ${id} ${className && className}`}
    >
      <label title={name}>{name}:</label>
      <input
        type="text"
        disabled={!enable || disabledForUploadAudience}
        value={value ? value : ""}
        onChange={(e) => {
          onChange(id, e.target.value);
        }}
        id={
          !["eventLabel", "pagePath", "num_topics", "eventValue"].includes(id)
            ? "width-380"
            : ""
        }
        autoComplete="off"
        spellCheck="false"
      />
      {error && <p className="error">{config.inputTextValidationError}</p>}
    </div>
  );
}

export default InputTextValidation;

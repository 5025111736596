// Import required libraries
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  makeStyles,
  TableBody,
  TableRow,
  TableCell,
  withStyles,
  Button,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";

// Import data & utils
import useTable from "../../../utils/useTable";
import {
  flattenObject,
  formatDate,
  makeDefaultResponseJson,
} from "../../../utils/utils";
import { config } from "../../../config/config";

// Import action creators
import {
  updateMultiSelections,
  updateAlertInfo,
  updateDialogInfo,
  reloadAdminUserList,
  updateUserInfo,
} from "../../../redux/actions";

//Import styles
// import "./Table.scss";

const StyledTableCell = withStyles((theme) => ({
  body: {
    color: "#46596a",
    fontFamily: "Hind Siliguri",
    borderBottom: "0px",
  },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
  },
  listIcon: {
    display: "flex",
    alignItems: "center",
  },
  name: {
    maxWidth: "500px",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "13px !important",
    lineHeight: "23px",
  },
  tableRow: {
    backgroundColor: "red !important",
    padding: "9px !important",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "13px !important",
    lineHeight: "23px",
    "&:last-child th, &:last-child td": {
      borderBottom: 0,
    },
    "&:nth-of-type(odd)": {
      backgroundColor: "white !important",
      "&:hover": {
        backgroundColor: "#4eafb342 !important",
      },
    },
    "&:nth-of-type(even)": {
      backgroundColor: "rgba(70, 89, 106, 0.07) !important",
      "&:hover": {
        backgroundColor: "#4eafb342 !important",
      },
    },
  },
}));

const headCells = [
  { id: "name", label: "Name", width: "22.5%" },
  { id: "preferred_username", label: "Email", width: "22.5%" },
  { id: "created_by", label: "Created By", width: "15%" },
  { id: "created_on", label: "Created On", width: "10%" },
  { id: "modified_on", label: "Modified On", width: "10%" },
  { id: "modified_by", label: "Modified By", width: "15%" },
  { id: "delete", label: "", width: "5%" },
];
function TableAdminUsers(props) {
  let {
    user,
    data,
    paginationFlag,
    match,
    updateMultiSelections,
    updateDialogInfo,
    updateAlertInfo,
    updateUserInfo,
    reloadAdminUserList,
  } = props;
  const classes = useStyles();
  const [records, setRecords] = useState(data.map((row) => flattenObject(row)));
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const history = useHistory();

  useEffect(() => {
    setRecords(data.map((row) => flattenObject(row)));
  }, [data]);

  const clickedTableCellAction = (item) => {
    history.push(`/home/admin-panel/users/${item?.preferred_username}`);
  };

  const handleNo = () => {
    updateDialogInfo({ ...user.dialogInfo, open: false });
  };

  const handleUserDelete = (recordToDelete) => {
    console.groupCollapsed("DELETE REQUEST SENT...");
    if (recordToDelete) {
      let url = `${config.api.userUrl}?preferred_username=${recordToDelete}`;
      let status;
      fetch(url, {
        method: "DELETE",
      })
        .then((response) => {
          console.groupCollapsed("requesting", url);
          console.log("REPSONSE -> ", response);
          status = response.status;
          return response.clone().json();
        })
        .then((json) => {
          console.log("JSON -> ", json);
          console.groupEnd();
          if (status === 200) {
            updateAlertInfo({
              open: true,
              message: "User Deleted Successfully",
              severity: "success",
            });
            reloadAdminUserList();
          } else {
            updateAlertInfo({
              open: true,
              message: json.statusMessage,
              severity: "error",
            });
          }
        });
    }
  };

  const handleGroupDelete = (recordToDelete) => {
    updateDialogInfo({ ...user.dialogInfo, open: false });
    console.groupCollapsed("DELETE REQUEST SENT...");
    updateAlertInfo({
      open: true,
      message: "Requesting to delete User",
      severity: "info",
    });
    if (recordToDelete) {
      let url = `${config.api.groupUrl}?group_id=${
        recordToDelete + "_" + config.hardCoded.aeAppId
      }`;
      let status;
      fetch(url, {
        method: "DELETE",
      })
        .then((response) => {
          console.groupCollapsed("requesting", url);
          console.log("REPSONSE -> ", response);
          status = response.status;
          return response.clone().json();
        })
        .then((json) => {
          console.log("JSON -> ", json);
          console.groupEnd();
          if (status === 200) {
            handleUserDelete(recordToDelete);
          } else {
            updateAlertInfo({
              open: true,
              message: json.statusMessage,
              severity: "error",
            });
          }
        });
    }
  };

  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    useTable(records, headCells, filterFn, user, updateMultiSelections);

  return (
    <>
      <TblContainer>
        <TblHead />
        <TableBody>
          {recordsAfterPagingAndSorting().map((item, index) => (
            <TableRow
              key={item.preferred_username}
              className={classes.tableRow}
              hover
            >
              <StyledTableCell
                className={classes.name}
                onClick={() => {
                  clickedTableCellAction(item);
                }}
              >
                <div className="admin-user-name" title={item.name}>
                  {item.name}
                </div>
              </StyledTableCell>
              <StyledTableCell
                className={classes.name}
                onClick={() => {
                  clickedTableCellAction(item);
                }}
              >
                <div
                  className="admin-user-name"
                  title={item.preferred_username}
                >
                  {item.email}
                </div>
              </StyledTableCell>
              <StyledTableCell
                onClick={() => {
                  clickedTableCellAction(item);
                }}
              >
                <div
                  className="admin-user-created-by"
                  title={item.created_by ? item.created_by : "-"}
                >
                  {item.created_by ? item.created_by : "-"}
                </div>
              </StyledTableCell>
              <StyledTableCell
                onClick={() => {
                  clickedTableCellAction(item);
                }}
              >
                {item.created_on ? formatDate(item.created_on) : "-"}
              </StyledTableCell>
              <StyledTableCell
                onClick={() => {
                  clickedTableCellAction(item);
                }}
              >
                {item.modified_on ? formatDate(item.modified_on) : "-"}
              </StyledTableCell>
              <StyledTableCell
                onClick={() => {
                  clickedTableCellAction(item);
                }}
              >
                <div
                  className="admin-user-modified-by"
                  title={item.modified_by ? item.modified_by : "-"}
                >
                  {item.modified_by ? item.modified_by : "-"}
                </div>
              </StyledTableCell>
              <StyledTableCell>
                {user?.preferred_username !== item?.preferred_username ? (
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      setTimeout(() => {
                        updateDialogInfo({
                          message: config.messages.confirmDelete,
                          open: true,
                          handleYes: handleGroupDelete.bind(
                            this,
                            item.preferred_username
                          ),
                          handleNo: handleNo,
                        });
                      }, 100);
                    }}
                  >
                    <i className="material-icons-outlined delete_icon">
                      delete_forever
                    </i>
                  </Button>
                ) : (
                  <></>
                )}
              </StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </TblContainer>
      {paginationFlag && <TblPagination />}
    </>
  );
}

TableAdminUsers.propTypes = {
  data: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  paginationFlag: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  updateMultiSelections,
  updateAlertInfo,
  updateDialogInfo,
  reloadAdminUserList,
  updateUserInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(TableAdminUsers);

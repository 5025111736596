import breadcrumbsMapping from "../../assets/data/breadcrumbsMapping.json";
import sidebarApp from "../../assets/data/sidebarApp.json";
import datasetCategories from "../../assets/data/datasetCategories.json";
import { v4 } from "uuid";
import moment from "moment";
import filter from "lodash/filter";
import orderBy from "lodash/orderBy";
import isEqual from "lodash/isEqual";
import { config } from "../config/config";
import momentTZ from "moment-timezone";
import { isNumber } from "lodash";

// client side timezone
export const defaultTimeZone = () => momentTZ.tz.guess();

// Get current year
export const getCurrentYear = () => moment().year();

// get timezone offset
export const getTimezoneOffset = (timeZone) =>
  "UTC" + moment().tz(timeZone).format("Z") + " " + timeZone;

// get all TZ data
export const getTZData = () => momentTZ.tz.names();

export const splitStringInArray = (input) => input.split`,`.map((x) => x);

export const validAudienceName = (input) => {
  const regex = new RegExp("^[A-Za-z0-9_]+$");
  return regex.test(input);
};

export const splitUrlToBreadcrumbData = (url) => {
  let routes = url.replace(/^\//g, "").replace(/\/$/g, "").split("/");
  let data = routes.map((name, index) => ({
    name,
    label: breadcrumbsMapping[name] ? breadcrumbsMapping[name] : name,
    path: routes
      .slice(0, index + 1)
      .reduce((finalPath, route) => finalPath + route + "/", "/"),
  }));
  return data;
};

export const validCommunityList = (input) => {
  const regex = new RegExp("^[0-9]+(,[0-9]+)*$");
  return regex.test(input);
};

export const makeSidebarData = () => {
  let sidebarData = [...sidebarApp];
  sidebarData = sidebarData.map((obj) => ({ ...obj, key: v4() }));
  return sidebarData;
};

export const convertEpochToDate = (date, format = "MMM DD, YYYY") =>
  moment(+date).format(format);

export const changeDateInEpoch = (date) => {
  let arrOfDate = date.split("/");
  return Date.parse(`${arrOfDate[1]}/${arrOfDate[0]}/${arrOfDate[2]}`);
};
export const getCurrentDate = () => moment().format("YYYY-MM-DD");

export const getYearsAgoDate = () =>
  moment().subtract(1, "years").format("YYYY-MM-DD");

export const populateFiltersData = (fitlersData, data) => {
  let updFiltersData = fitlersData.map((obj) => ({
    ...obj,
    key: v4(),
    data: data[obj.name] ? data[obj.name] : null,
  }));
  return updFiltersData;
};

//filter saved_configuraions based on user selections
export const filterMyConfigs = (
  arr = [],
  searchKey,
  selections,
  configFlag
) => {
  let filteredArr,
    filtersObj,
    config = {};
  let validFilters = ["country", "brand"];

  for (let key in selections) {
    if (selections[key] !== "ALL" && validFilters.includes(key)) {
      config[key] = selections[key];
    }
  }

  if (configFlag) {
    filtersObj = {
      ...filtersObj,
      selections: {
        config,
      },
    };
  } else {
    filtersObj = {};
  }

  filteredArr = filter(arr, filtersObj);
  if (selections.segment !== "ALL") {
    filteredArr = filteredArr.filter((obj) =>
      obj.selections.config.segment.includes(selections.segment)
    );
  }
  // Tags are filtered as lodash is not supporting array filtering
  if (selections.configTags.length) {
    filteredArr = filteredArr.filter((obj) =>
      obj.metadata?.tags?.find((elem) =>
        selections.configTags.some((data) => data === elem)
      )
    );
  }

  if (selections.searchText !== "") {
    filteredArr = filteredArr.filter((obj) => {
      return obj[searchKey]
        .toLowerCase()
        .includes(selections.searchText.toLowerCase());
    });
  }
  return filteredArr;
};

export const addAllInData = (arr) => [{ id: "ALL", name: "ALL" }, ...arr];

// to get dataset id when in "<dataset_id>-<country>-<brand>" format
export const getDatasetId = (datasetId) => {
  datasetId = datasetId.split("-");
  let resString =
    datasetId.length > 3 ? datasetId[0] + "-" + datasetId[1] : datasetId[0];
  return resString;
};

//removes duplicate objects based on id
export const removeDupFromArrayOfObjects = (arr) => {
  const ids = arr.map((obj) => obj.id);
  const filteredArr = arr.filter(
    ({ id }, index) => !ids.includes(id, index + 1)
  );
  return filteredArr;
};

export const removeDupFromArray = (arr) => {
  let newArr = [];
  arr.forEach((val) => {
    newArr.indexOf(val) === -1 && newArr.push(val);
  });
  return newArr;
};

export const convertArrToLowerCase = (arr) =>
  arr.map((val) => val.toLowerCase());

export const getConfigName = (configName, brand) => {
  // this will give the index of the editable name starting point
  const indexOfBrand = configName.indexOf(brand) + brand.length + 1;
  // return configName
  //   ? brand.includes("-")
  //     ? configName.replace(/^[A-z ]+\-[A-z ]+\-[A-z ]+\-[A-z ]+\-/g, "")
  //     : configName.replace(/^[A-z ]+\-[A-z ]+\-[A-z ]+\-/g, "")
  //   : undefined;
  return configName ? configName.slice(indexOfBrand) : undefined;
};

export const checkDatesOverlapping = (
  global_start_date,
  global_end_date,
  local_start_date,
  local_end_date
) => {
  const completeOverlap =
    moment(global_start_date).isSameOrAfter(
      moment(local_start_date, "DDMMYYYY")
    ) &&
    moment(global_end_date).isSameOrBefore(moment(local_end_date, "DDMMYYYY"));

  const datasetPartialOverlap1 =
    moment(global_start_date).isBefore(moment(local_start_date, "DDMMYYYY")) &&
    moment(global_end_date).isSameOrAfter(
      moment(local_start_date, "DDMMYYYY")
    ) &&
    moment(global_end_date).isSameOrBefore(moment(local_end_date, "DDMMYYYY"));

  const datasetPartialOverlap2 =
    moment(global_end_date).isAfter(moment(local_end_date, "DDMMYYYY")) &&
    moment(global_start_date).isSameOrAfter(
      moment(local_start_date, "DDMMYYYY")
    ) &&
    moment(global_start_date).isSameOrBefore(
      moment(local_end_date, "DDMMYYYY")
    );

  const globalPartialOverlap1 =
    moment(global_start_date).isSameOrBefore(
      moment(local_start_date, "DDMMYYYY")
    ) &&
    moment(global_end_date).isSameOrAfter(moment(local_end_date, "DDMMYYYY"));

  if (!local_start_date && !local_end_date) {
    return {
      type: null,
      status: true,
    };
  } else if (completeOverlap) {
    return {
      type: "complete overlap",
      status: true,
    };
  } else if (datasetPartialOverlap1) {
    return {
      type: "partial overlap",
      status: true,
    };
  } else if (datasetPartialOverlap2) {
    return {
      type: "partial overlap",
      status: true,
    };
  } else if (globalPartialOverlap1) {
    return {
      type: "partial overlap",
      status: true,
    };
  } else {
    return {
      type: null,
      status: false,
    };
  }
};

export const manipulateDataset = ({
  dataset,
  kpi,
  singleKpiFlag,
  key,
  value,
  activeModel,
  kpiListFlag,
  kpiList,
  addAllKpi,
  activeDatasetForKpi,
  activeDatasetDates,
  kpi_variables_list,
  modelId,
}) => {
  let newDataset = {
    id: dataset?.id,
    name: dataset?.name,
    kpi_list: [],
    kpi_variables_list: kpi_variables_list,
    type: dataset?.type,
    source: dataset?.source,
    pipeline_dataset_id: dataset?.pipeline_dataset_id,
  };

  let mandatoryKpi = [],
    defaultKpi = [];
  if (activeDatasetForKpi) {
    mandatoryKpi = [...activeDatasetForKpi?.mandatory_kpi_list] || [];
    defaultKpi = [...activeDatasetForKpi?.default_value] || [];
  }

  if (config.hardCoded.radioDatasetList.includes(dataset?.type)) {
    newDataset = { ...newDataset, sku: [{ sku: "ALL" }] };
  }

  if (
    activeDatasetDates &&
    dataset?.type === config.hardCoded.datasetSalesType
  ) {
    newDataset = { ...newDataset, periodicity: activeDatasetDates.periodicity };
  } else {
    newDataset = { ...newDataset };
  }

  //Add local parameters
  dataset?.local_parameters?.forEach((obj) => {
    if (obj.id === "adstock_parameters") {
      newDataset[obj.id] = {};
      if (obj.data) {
        if (dataset.pipeline_dataset_id == config.hardCoded.tvId) {
          newDataset.enable_adstock = newDataset.enable_adstock
            ? newDataset.enable_adstock
            : true;
          newDataset.sl_limit = 2;
          newDataset.su_limit = 4;
          newDataset.limit = 1;
          newDataset.saturation_flag = "false";
        } else {
          obj.data.forEach((item) => {
            if (
              config.hardCoded.googleAdsIds.some(
                (elem) => elem.toLowerCase() === dataset.id.toLowerCase()
              )
            ) {
              newDataset.enable_adstock = newDataset.enable_adstock
                ? newDataset.enable_adstock
                : false;
              if (item.ui_element_type === "inputbox_number") {
                newDataset[item.id] = item.value ? item.value : 0;
              } else if (item.ui_element_type === "radio_button") {
                newDataset[item.id] = item.data ? item.data[0].id : null;
              }
            } else {
              newDataset.enable_adstock = newDataset.enable_adstock
                ? newDataset.enable_adstock
                : true;
              if (item.ui_element_type === "inputbox_number") {
                newDataset[item.id] = item.value ? item.value : 0;
              } else if (item.ui_element_type === "radio_button") {
                newDataset[item.id] = item.data ? item.data[0].id : null;
              }
            }
          });
        }
      }
    } else {
      newDataset[obj.id] = obj.data ? obj.data[0].id : null;
    }
  });

  //If key present, update the key/value pair, else update the kpi list
  if (key) {
    newDataset = { ...newDataset, [key]: value };
  }
  newDataset["kpi_list"] = kpi
    ? singleKpiFlag
      ? removeDupFromArray([...mandatoryKpi, kpi])
      : removeDupFromArray([...dataset.kpi_list, ...mandatoryKpi, kpi])
    : removeDupFromArray([...mandatoryKpi, ...defaultKpi]);

  if (kpiListFlag) {
    return { ...newDataset, kpi_list: kpiList };
  }

  if (addAllKpi) {
    newDataset["kpi_list"] = kpiList;
  }

  return newDataset;
};

export const addAllNodeAndSortSingleTree = (
  data,
  config,
  orderType = "asc"
) => {
  let key = data.length ? data[0].labelCat : "";

  //Add "All" node
  if (key && config[key]) {
    data = [
      { name: "ALL", labelCat: key, children: null },
      ...orderBy(data, ["name"], [orderType]),
    ];
  } else {
    data = orderBy(data, ["name"], [orderType]);
  }

  data = data.map((obj) => ({
    ...obj,
    children: obj.children
      ? obj.children.length !== 0
        ? addAllNodeAndSortSingleTree(obj.children, config)
        : null
      : null,
  }));
  return data;
};

export const addAllNodeAndSort = (props) => {
  let { data, settings, orderType } = props;
  let key = data[0].labelCat;

  if (settings[key]?.addAllNodeAndSortFlag) {
    data = [
      {
        name: "ALL",
        labelCat: key,
        children: null,
      },
      ...orderBy(
        data.filter((item) => item.name !== "ALL"),
        ["name"],
        [orderType]
      ),
    ];
  } else {
    data = orderBy(data, ["name"], [orderType]);
  }

  data = data.map((obj) => {
    //brand (airwick)
    //If children is null
    let newChildren;
    if (!settings[key]?.child) {
      //if this is sub-segment which does not have any child
      newChildren = null;
    } else if (obj.children) {
      //if we have atleat one child
      newChildren = addAllNodeAndSort({
        data: obj.children,
        settings: settings,
        orderType: "asc",
      });
    } else {
      //if we do not have any child, we add ALL as the child
      newChildren = addAllNodeAndSort({
        data: [
          {
            name: "ALL",
            labelCat: settings[key]?.child,
            children: null,
            visible: settings[settings[key]?.child].visible,
            type: settings[settings[key]?.child].type,
          },
        ],
        settings: settings,
        orderType: "asc",
      });
    }

    let newObj = {
      ...obj,
      visible: settings[key]?.visible,
      type: settings[key]?.type,
      children: newChildren,
    };
    return newObj;
  });
  return data;
};

export const flattenObject = (obj) => {
  const flattened = {};
  Object.keys(obj).forEach((key) => {
    if (typeof obj[key] === "object" && key === "metadata") {
      Object.assign(flattened, flattenObject(obj[key]));
    } else {
      flattened[key] = obj[key];
    }
  });
  return flattened;
};

export const splitConfigsByCategory = ({ category, arr }) => {
  let updArr = [{ key: v4(), title: null, data: arr }];
  switch (category.toLowerCase()) {
    case "recent":
      let intArr = arr.map((item) => ({
        ...item,
        recCategory: getConfigForCategory(item.metadata.modified_on),
      }));
      updArr = [
        {
          key: v4(),
          title: "TODAY",
          data: intArr.filter((item) => item.recCategory === "TODAY"),
        },
        {
          key: v4(),
          title: "YESTERDAY",
          data: intArr.filter((item) => item.recCategory === "YESTERDAY"),
        },
        {
          key: v4(),
          title: "THIS WEEK",
          data: intArr.filter((item) => item.recCategory === "THIS WEEK"),
        },
        {
          key: v4(),
          title: "LAST WEEK",
          data: intArr.filter((item) => item.recCategory === "LAST WEEK"),
        },
        {
          key: v4(),
          title: "LAST 30 DAYS",
          data: intArr.filter((item) => item.recCategory === "LAST 30 DAYS"),
        },
      ];
      //filter out elems with no data
      updArr = updArr.filter((item) => item.data.length);
      return updArr;
    default:
      return updArr;
  }
};

export const getConfigForCategory = (date) => {
  if (
    moment().startOf("date")._d.valueOf() < date &&
    date < moment().endOf("date")._d.valueOf()
  ) {
    return "TODAY";
  } else if (
    moment().startOf("date")._d.valueOf() - 24 * 60 * 60 * 1000 < date &&
    date < moment().endOf("date")._d.valueOf() - 24 * 60 * 60 * 1000
  ) {
    return "YESTERDAY";
  } else if (
    moment().startOf("isoWeek")._d.valueOf() < date &&
    date < moment().endOf("isoWeek")._d.valueOf()
  ) {
    return "THIS WEEK";
  } else if (
    moment().startOf("isoWeek")._d.valueOf() - 7 * 24 * 60 * 60 * 1000 < date &&
    date < moment().endOf("isoWeek")._d.valueOf() - 7 * 24 * 60 * 60 * 1000
  ) {
    return "LAST WEEK";
  } else if (
    moment().startOf("day")._d.valueOf() - 30 * 24 * 60 * 60 * 1000 < date &&
    date < moment()._d.valueOf()
  ) {
    return "LAST 30 DAYS";
  }
};

export const getWeekCount = (start_date, end_date) => {
  let weekCount =
    (moment(end_date, "DDMMYYYY")._d.getTime() -
      moment(start_date, "DDMMYYYY")._d.getTime()) /
    (1000 * 60 * 60 * 24) /
    7;
  return weekCount;
};

export const getDaysFromDates = (start_date_passed, end_date_passed) => {
  let daysDiff = moment(end_date_passed, "YYYY-MM-DD").diff(
    moment(start_date_passed, "YYYY-MM-DD"),
    "days"
  );
  daysDiff = daysDiff + 1;
  return daysDiff;
};
export const getWeeksFromDates = (start_date_passed, end_date_passed) => {
  let d1 = moment(start_date_passed, "YYYY-MM-DD");
  let d2 = moment(end_date_passed, "YYYY-MM-DD");
  let isoWeekday = 1;
  // figure out how many days to advance to get to the next
  // specified weekday (might be 0 if d1 is already the
  // specified weekday).
  var daysToAdd = (7 + isoWeekday - d1.isoWeekday()) % 7;
  var nextMonday = d1.clone().add(daysToAdd, "days");
  // if we are already passed the end date, there must not be any of that day in the given period.
  if (nextMonday.isAfter(d2)) {
    return 0;
  }
  // otherwise, just return the whole number of weeks
  // difference plus one for the day we already advanced to
  var weeksBetween = d2.diff(nextMonday, "weeks");
  if (d1.isoWeekday() === 1) {
    return weeksBetween + 1;
  }
  return weeksBetween + 2;
};
export const getMonthsFromDates = (start_date_passed, end_date_passed) => {
  let monthDiff = 0;
  let dateStart = moment(start_date_passed, "YYYY-MM-DD");
  let dateEnd = moment(end_date_passed, "YYYY-MM-DD").endOf("month");
  while (dateStart.isBefore(dateEnd)) {
    monthDiff++;
    dateStart = dateStart.add(1, "month");
  }
  return monthDiff;
};
export const getYearsFromDates = (start_date_passed, end_date_passed) => {
  let start_date_year = moment(start_date_passed, "YYYY-MM-DD").format("YYYY");
  let end_date_year = moment(end_date_passed, "YYYY-MM-DD").format("YYYY");
  return end_date_year - start_date_year + 1;
};

export const validateDatasetWeeksSync = (
  selectedModels,
  allDatasets,
  historic_week_data
) => {
  //filter models that disabled
  selectedModels = selectedModels.filter((model) => model.run);
  let selectedDatasets = selectedModels
    .map((model) => model.data_sources)
    .flat();
  let selectedDatasetsIds = selectedDatasets.map((dataset) => dataset.id);
  allDatasets = allDatasets.filter((dataset) =>
    selectedDatasetsIds.includes(dataset.id)
  );

  //calculate weeks
  allDatasets = allDatasets.map((obj) => ({
    ...obj,
    weekCount: getWeekCount(obj.start_date, obj.end_date),
  }));

  //filter datasets which are out of range
  allDatasets = allDatasets.filter(
    (dataset) => dataset.weekCount < historic_week_data
  );

  if (allDatasets.length)
    return {
      statusWeek: false,
      messageWeek: `Weeks selected is greater than weeks in ${allDatasets[0].name}. Do you want to continue?`,
    };
  return { statusWeek: true, messageWeek: "Pass" };
};

export const validateDatasetHasKpiSelected = (selectedModels, allModels) => {
  let invalidArr = [];
  //filter models that enabled
  selectedModels = selectedModels.filter((model) => model.run);

  selectedModels.forEach((model) => {
    let selectedDatasets = model.data_sources;
    selectedDatasets.forEach((dataset) => {
      if (dataset.kpi_list.length <= 0) {
        invalidArr.push({ ...dataset, modelId: model.id });
      }
    });
  });

  if (invalidArr.length) {
    return {
      status: false,
      message: ` ${invalidArr[0].id} in ${invalidArr[0].modelId} should have at least 1 KPI selected.`,
    };
  } else {
    return { status: true, message: "Pass" };
  }
};

export const validateModelsHasDatasetSelected = (
  selectedModels,
  allModels,
  configUserInputs,
  globalData
) => {
  //filter models that disabled
  selectedModels = selectedModels.filter((model) => model.run);
  const selectedModelsIds = selectedModels.map((model) => model.id);
  //filter global datasets available for a country provided by user
  let filteredDatasets = globalData.datasets.filter((dataset) =>
    dataset.countries.includes(configUserInputs.country)
  );

  selectedModels = selectedModels.filter((model) => {
    let selectedDatasetsLen = model.data_sources.length;
    let modelInfo = allModels.find((currModel) => currModel.id === model.id);
    if (model.id !== config.hardCoded.nlpId && modelInfo) {
      if (modelInfo.dataset_ids.length) {
        if (selectedDatasetsLen) {
          return false;
        } else {
          //hard coded for Propensity modeling
          if (
            model.id === config.hardCoded.propensityId &&
            (model.config.propensity_modeling_run_type ===
              config.hardCoded.targetRunId ||
              model.config.propensity_modeling_run_type ===
                config.hardCoded.uploadAudienceRunId)
          ) {
            return false;
          }
          //hard coded for community clustering
          if (
            model.id === config.hardCoded.communityClusteringId &&
            (model.config.community_clustering_run_type ===
              config.hardCoded.targetRunId ||
              model.config.community_clustering_run_type ===
                config.hardCoded.uploadAudienceRunId)
          ) {
            return false;
          }
          return true;
        }
      } else {
        return false;
      }
    }
  });
  // we don't need any validation for datasets on NLP model so here this "if" block will not run if NLP model is enabled
  // if (
  //   !filteredDatasets.length &&
  //   !selectedModelsIds.includes(config.hardCoded.nlpId)
  // ) {
  //   return {
  //     status: false,
  //     message: `No datasets available for ${configUserInputs.country}.`,
  //   };
  // } else
  if (selectedModels.length)
    return {
      status: false,
      message: `${selectedModels[0].name} should have at least 1 dataset selected.`,
    };
  else return { status: true, message: "Pass" };
};

export const validateValidDatasetSelected = (
  selectedModels,
  configUserInputs
) => {
  let validDataset,
    invalidDataset = [],
    partialDataset = [];
  let global_start_date = configUserInputs.startDate,
    global_end_date = configUserInputs.endDate;

  //filter models that disabled
  selectedModels = selectedModels.find((model) => model.run);
  let datasetsDatesArray = configUserInputs.datasetDetailsData.data.map(
    (elem) => ({ ...elem, dataset_new_id: getDatasetId(elem.dataset_id) })
  );
  let selectedDatasets = datasetsDatesArray.filter((dataset) =>
    selectedModels?.data_sources.some(
      (elem) =>
        elem.id === dataset.dataset_new_id &&
        !config.hardCoded.radioDatasetList.includes(elem?.type)
    )
  );

  if (
    configUserInputs.global_week_date_selection ===
    config.hardCoded.historicWeek
  ) {
    let result = getDatesFromWeek(configUserInputs.historic_week_data);
    global_start_date = result.min;
    global_end_date = result.max;
  }

  selectedDatasets.forEach((dataset) => {
    validDataset = checkDatesOverlapping(
      global_start_date,
      global_end_date,
      dataset.start_date,
      dataset.end_date
    );
    // console.log("validDataset", validDataset);
    const { type, status } = validDataset;
    if (!type || !status) {
      invalidDataset.push({
        name: dataset.dataset_name,
        startDate: convertDateToFormat(dataset.start_date, "DD MMMM, YYYY"),
        endDate: convertDateToFormat(dataset.end_date, "DD MMMM, YYYY"),
      });
    }
    if (type === "partial overlap") {
      partialDataset.push({
        message:
          `The global dates selected are ` +
          convertDateToFormat(moment(global_start_date), "DD MMMM, YYYY") +
          " to " +
          convertDateToFormat(moment(global_end_date), "DD MMMM, YYYY") +
          " but data is available from " +
          convertDateToFormat(dataset.start_date, "DD MMMM, YYYY") +
          " to " +
          convertDateToFormat(dataset.end_date, "DD MMMM, YYYY") +
          " for " +
          dataset.dataset_name +
          ".",
      });
    }
  });

  if (invalidDataset.length) {
    let displayMessage = "";
    invalidDataset.forEach(
      (elem, index) =>
        (displayMessage +=
          index +
          1 +
          ": " +
          `The global dates selected are ` +
          convertDateToFormat(moment(global_start_date), "DD MMMM, YYYY") +
          " to " +
          convertDateToFormat(moment(global_end_date), "DD MMMM, YYYY") +
          " but data is available from " +
          elem.startDate +
          " to " +
          elem.endDate +
          " for " +
          elem.name +
          "." +
          "\n")
    );
    return {
      status: false,
      message: displayMessage,
    };
  } else
    return { status: true, message: "Pass", partialDataset: partialDataset };
};

export const validateMinMaxNumberInputs = (
  selectedModels,
  allModels,
  checkDERunConfig
) => {
  let invalidArr = [];

  let selectedModelsId = selectedModels
    .filter((model) => model.run)
    .map((model) => model.id);
  let allModelsFiltered = allModels.filter((model) =>
    selectedModelsId.includes(model.id)
  );

  allModelsFiltered.forEach((model) => {
    model.local_parameters.forEach((lp) => {
      if (
        lp["ui_element_type"] === "inputbox_number" &&
        checkDERunConfig &&
        lp.id !== "no_of_similar_regions"
      ) {
        let maxVal =
          lp.maxValue == "null" ? 1000000.0 : parseFloat(lp.maxValue);
        let minVal =
          lp.minValue == "null" ? Number.MIN_VALUE : parseFloat(lp.minValue);
        let value = selectedModels.filter(
          (currModel) => currModel.id === model.id
        )[0].config[lp.id];
        if (
          parseFloat(value) < parseFloat(minVal) ||
          parseFloat(value) > parseFloat(maxVal) ||
          isNaN(value) ||
          value === ""
        ) {
          invalidArr.push(model);
        }
      }
    });
  });

  if (invalidArr.length)
    return {
      status: false,
      message: `Invalid input in ${invalidArr[0].name}`,
    };
  return { status: true, message: "Pass" };
};

export const validateOneModelSelected = (selectedModels) => {
  //filter models that disabled
  selectedModels = selectedModels.filter((model) => model.run);

  if (selectedModels.length) {
    return {
      status: true,
      message: "Pass",
    };
  } else {
    return {
      status: false,
      message: config.messages.oneModelSelectedError,
    };
  }
};

export const validateOneModelSelectedForRuns = (
  selectedModels,
  configUserInputs
) => {
  //filter models that disabled
  selectedModels = selectedModels.filter((model) => model.run);
  if (
    selectedModels.length > 1 &&
    configUserInputs.app_id === config.hardCoded.aeAppId
  ) {
    return {
      status: false,
      message:
        configUserInputs.is_schedule_config ||
        configUserInputs.is_fixed_schedule_config
          ? config.messages.multipleModelSelectedError
          : config.messages.multipleModelSelectedOnDemandError,
    };
  } else {
    return {
      status: true,
      message: "Pass",
    };
  }
};

export const validateDatasetWithLocal = (configUserInputs, selectedModels) => {
  selectedModels = selectedModels.filter((model) => model.run);
  let differenceInDatasets = [];
  let invalidDatasetArr = [];
  let uniqueDataDources = [];

  selectedModels.forEach((model) => {
    //find unique data_sources based on pipeline_dataset_id
    uniqueDataDources = new Set(
      model.data_sources.map((item) => item.pipeline_dataset_id)
    );
    //Datasets with duplicate pipeline_dataset_id
    differenceInDatasets = model.data_sources.filter(
      (x) => ![...uniqueDataDources].includes(x.id)
    );

    if (model.data_sources.length === [...uniqueDataDources].length) {
      invalidDatasetArr = invalidDatasetArr;
    } else {
      if (differenceInDatasets.length) {
        invalidDatasetArr.push({ model: model.name });
      }
    }
  });

  if (invalidDatasetArr.length) {
    return {
      status: false,
      message: `Can't add config with dataset local and platform selected for ${invalidDatasetArr[0].model}`,
    };
  } else {
    return {
      status: true,
      message: "Pass",
    };
  }
};

export const validatePropensityActionNamesParameteres = (configUserInputs) => {
  let audienceName = false;
  let propensityModel = configUserInputs.models.find(
    (model) => model.id === config.hardCoded.propensityId
  );
  if (propensityModel && propensityModel.run) {
    if (!propensityModel.config.audience_name) {
      return {
        status: false,
        message: `Please select Output Name in ${propensityModel.name}`,
      };
    } else if (propensityModel.config.audience_name) {
      audienceName = validAudienceName(propensityModel.config.audience_name);
      if (!audienceName && propensityModel.config.audience_name) {
        return {
          status: false,
          message: `Output Name can only have letters, numbers, underscore in ${propensityModel.name} `,
        };
      } else if (
        audienceName &&
        propensityModel.config.audience_name &&
        propensityModel.config.audience_name.length > 45
      ) {
        return {
          status: false,
          message: `Output Name can only have length upto 45 characters in ${propensityModel.name} `,
        };
      } else if (!propensityModel.config.action_names.length) {
        return {
          status: false,
          message: config.messages.propensityModelingActionNamesParameters,
        };
      } else if (!propensityModel.config.interface_name.length) {
        return {
          status: false,
          message: `Please select Activation Platform in ${propensityModel.name}`,
        };
      } else if (parseFloat(propensityModel.config.hot) <= 0) {
        return {
          status: false,
          message: `Please select hot segment threshold in ${propensityModel.name} `,
        };
      } else if (parseFloat(propensityModel.config.warm) <= 0) {
        return {
          status: false,
          message: `Please select warm segment threshhold in ${propensityModel.name} `,
        };
      } else if (parseFloat(propensityModel.config.cold) <= 0) {
        return {
          status: false,
          message: `Please select cold segment threshhold in ${propensityModel.name} `,
        };
      } else if (
        (
          parseFloat(propensityModel.config.hot) +
          parseFloat(propensityModel.config.warm) +
          parseFloat(propensityModel.config.cold)
        ).toFixed(10) !== parseFloat(1).toFixed(10)
      ) {
        return {
          status: false,
          message: `The segment threshold values should sum to 1.`,
        };
      }
      // else if (
      //   parseFloat(propensityModel.config.hot) >=
      //     parseFloat(propensityModel.config.warm) &&
      //   parseFloat(propensityModel.config.warm) !== 0
      // ) {
      //   return {
      //     status: false,
      //     message: config.messages.segmentHotWarmMessage,
      //   };
      // } else if (
      //   parseFloat(propensityModel.config.warm) >=
      //     parseFloat(propensityModel.config.cold) &&
      //   parseFloat(propensityModel.config.cold) !== 0
      // ) {
      //   return {
      //     status: false,
      //     message: config.messages.segmentWarmColdMessage,
      //   };
      // }
    }
    return {
      status: true,
      message: "Pass",
    };
  } else {
    return {
      status: true,
      message: "Pass",
    };
  }
};

export const validatePropensityActionNamesParametersForScheduleRun = (
  configUserInputs
) => {
  let audienceName = false;
  let propensityModel = configUserInputs.models.find(
    (model) => model.id === config.hardCoded.propensityId
  );
  if (propensityModel && propensityModel.run) {
    if (!propensityModel.config.audience_name) {
      return {
        status: false,
        message: `Please select output name in ${propensityModel.name}`,
      };
    } else if (propensityModel.config.audience_name) {
      audienceName = validAudienceName(propensityModel.config.audience_name);
      if (!audienceName && propensityModel.config.audience_name) {
        return {
          status: false,
          message: `Output name can only have letters, numbers, underscore in ${propensityModel.name} `,
        };
      } else if (
        audienceName &&
        propensityModel.config.audience_name &&
        propensityModel.config.audience_name.length > 45
      ) {
        return {
          status: false,
          message: `Output name can only have length upto 45 characters in ${propensityModel.name} `,
        };
      } else if (!propensityModel.config.action_names.length) {
        return {
          status: false,
          message: config.messages.propensityModelingActionNamesParameters,
        };
      }
    }

    if (!propensityModel.config.interface_name.length) {
      return {
        status: false,
        message: `Please select Activation Platform in ${propensityModel.name}`,
      };
    }

    if (parseFloat(propensityModel.config.hot) <= 0) {
      return {
        status: false,
        message: `Please select hot segment threshhold in ${propensityModel.name} `,
      };
    } else if (parseFloat(propensityModel.config.warm) <= 0) {
      return {
        status: false,
        message: `Please select warm segment threshhold in ${propensityModel.name} `,
      };
    } else if (parseFloat(propensityModel.config.cold) <= 0) {
      return {
        status: false,
        message: `Please select cold segment threshhold in ${propensityModel.name} `,
      };
    } else if (
      (
        parseFloat(propensityModel.config.hot) +
        parseFloat(propensityModel.config.warm) +
        parseFloat(propensityModel.config.cold)
      ).toFixed(10) !== parseFloat(1).toFixed(10)
    ) {
      return {
        status: false,
        message: `The segment threshold values should sum to 1.`,
      };
    }
    // else if (
    //   parseFloat(propensityModel.config.hot) >=
    //     parseFloat(propensityModel.config.warm) &&
    //   parseFloat(propensityModel.config.warm) !== 0 &&
    //   propensityModel.config.propensity_modeling_run_type ===
    //     config.hardCoded.targetRunId
    // ) {
    //   return {
    //     status: false,
    //     message: config.messages.segmentHotWarmMessage,
    //   };
    // } else if (
    //   parseFloat(propensityModel.config.warm) >=
    //     parseFloat(propensityModel.config.cold) &&
    //   parseFloat(propensityModel.config.cold) !== 0 &&
    //   propensityModel.config.propensity_modeling_run_type === "target"
    // ) {
    //   return {
    //     status: false,
    //     message: config.messages.segmentWarmColdMessage,
    //   };
    // }

    return {
      status: true,
      message: "Pass",
    };
  } else {
    return {
      status: true,
      message: "Pass",
    };
  }
};

export const validateCommunityClustering = (
  configUserInputs,
  position = "inside"
) => {
  let interfaceName = "",
    audienceName = false;
  let communityModel =
    position === "outside"
      ? configUserInputs.selections.models.find(
          (model) => model.id === config.hardCoded.communityClusteringId
        )
      : configUserInputs.models.find(
          (model) => model.id === config.hardCoded.communityClusteringId
        );
  if (communityModel && communityModel.run) {
    if (!communityModel.config.algorithm) {
      return {
        status: false,
        message:
          position === "outside"
            ? `Algorithm is not selected in ${communityModel.name} for this run `
            : `Please select algorithm in ${communityModel.name} `,
      };
    }
    if (!communityModel.data_sources.length > 0) {
      return {
        status: false,
        message:
          position === "outside"
            ? `Dataset is not selected in ${communityModel.name} for this run `
            : `Please select dataset in ${communityModel.name} `,
      };
    }
    audienceName = validAudienceName(communityModel.config.audience_name);
    interfaceName =
      position === "outside"
        ? communityModel.config.interface_details.interface_name
        : communityModel.config.interface_name;
    if (!audienceName && !communityModel.config.audience_name) {
      return {
        status: false,
        message:
          position === "outside"
            ? `Output Name is not present in ${communityModel.name} for this run `
            : `Please enter Output Name in ${communityModel.name} `,
      };
    } else if (!audienceName && communityModel.config.audience_name) {
      return {
        status: false,
        message: `Output Name can only have letters, numbers, underscore in ${communityModel.name} `,
      };
    } else if (
      audienceName &&
      communityModel.config.audience_name &&
      communityModel.config.audience_name.length > 45
    ) {
      return {
        status: false,
        message: `Output Name can only have length upto 45 characters in ${communityModel.name} `,
      };
    } else if (!interfaceName?.length) {
      return {
        status: false,
        message:
          position === "outside"
            ? `Activation Platform is not selected in ${communityModel.name} for this run `
            : `Please select Activation Platform in ${communityModel.name}`,
      };
    } else {
      return {
        status: true,
        message: "Pass",
      };
    }
  } else {
    return {
      status: true,
      message: "Pass",
    };
  }
};

export const validateAudienceNameForSchedule = (configUserInputs) => {
  let communityIdList = "",
    interfaceName = "",
    validCommunityListValue = false,
    audienceName = false,
    targetRunSelected = "";

  let communityModel = configUserInputs.models.find(
    (model) => model.id === config.hardCoded.communityClusteringId
  );
  if (communityModel && communityModel.run) {
    if (
      !communityModel.config.algorithm &&
      communityModel.config.community_clustering_run_type ===
        config.hardCoded.initialRunId
    ) {
      return {
        status: false,
        message: `Please select algorithm in ${communityModel.name} `,
      };
    }
    if (
      !communityModel.data_sources.length > 0 &&
      communityModel.config.community_clustering_run_type ===
        config.hardCoded.initialRunId
    ) {
      return {
        status: false,
        message: `Please select dataset in ${communityModel.name} `,
      };
    }

    if (
      communityModel.config.community_id_type === config.hardCoded.specificId &&
      communityModel.config.community_clustering_run_type ===
        config.hardCoded.targetRunId
    ) {
      communityIdList = communityModel.config.community_id_list;
      validCommunityListValue = validCommunityList(communityIdList);
      audienceName = validAudienceName(communityModel.config.audience_name);
      interfaceName = communityModel.config.interface_name;
      targetRunSelected = communityModel.config.run_id;
    } else if (
      communityModel.config.community_clustering_run_type ===
      config.hardCoded.targetRunId
    ) {
      communityIdList = communityModel.config.community_id_list;
      validCommunityListValue = true;
      audienceName = validAudienceName(communityModel.config.audience_name);
      interfaceName = communityModel.config.interface_name;
      targetRunSelected = communityModel.config.run_id;
    }
    // if (
    //   !communityIdList &&
    //   communityModel.config.community_clustering_run_type ===
    //     config.hardCoded.targetRunId
    // )
    //   return {
    //     status: false,
    //     message: `Please add community id list for specific type in ${communityModel.name} `,
    //   };
    // else if (
    //   !validCommunityListValue &&
    //   communityModel.config.community_clustering_run_type ===
    //     config.hardCoded.targetRunId
    // ) {
    //   return {
    //     status: false,
    //     message: `Please add comma separated numbers in Community Id in ${communityModel.name} `,
    //   };
    // } else
    if (
      !audienceName &&
      !communityModel.config.audience_name &&
      communityModel.config.community_clustering_run_type ===
        config.hardCoded.targetRunId
    ) {
      return {
        status: false,
        message: `Please enter Output Name in ${communityModel.name} `,
      };
    } else if (
      !audienceName &&
      communityModel.config.audience_name &&
      communityModel.config.community_clustering_run_type ===
        config.hardCoded.targetRunId
    ) {
      return {
        status: false,
        message: `Output Name can only have letters, numbers, underscore in ${communityModel.name} `,
      };
    } else if (
      audienceName &&
      communityModel.config.audience_name &&
      communityModel.config.audience_name.length > 45 &&
      communityModel.config.community_clustering_run_type ===
        config.hardCoded.targetRunId
    ) {
      return {
        status: false,
        message: `Output Name can only have length upto 45 characters in ${communityModel.name} `,
      };
    } else if (
      !interfaceName &&
      communityModel.config.community_clustering_run_type ===
        config.hardCoded.targetRunId
    ) {
      return {
        status: false,
        message: `Please select Activation Platform in ${communityModel.name}`,
      };
    }
    return { status: true, message: "Pass" };
  } else {
    return {
      status: true,
      message: "Pass",
    };
  }
};

export const validationForDatasetAlgorithmScheduleRun = (configUserInputs) => {
  const findModel = configUserInputs.models.find(
    (model) => model.id === config.hardCoded.communityClusteringId
  );
  if (!findModel.config.algorithm) {
    return {
      status: false,
      message: `Please select algorithm for ${findModel.name}`,
    };
  }
  return {
    status: true,
    message: "Pass",
  };
};

export const validateHistoricWeek = (value) => {
  if (value.global_week_date_selection === config.hardCoded.historicWeek) {
    if (value.historic_week_data <= 0.0) return false;
    if (value.historic_week_data % 1 != 0) return false;
    if (value.historic_week_data > 300) return false;
    if (isNaN(value.historic_week_data)) return false;
    if (value.historic_week_data === "") return false;
  }
  return true;
};

export const validateDatesSelected = (value) => {
  if (value.global_week_date_selection === config.hardCoded.dateRange) {
    if (value.startDate == "")
      return { status: false, message: config.messages.dateSelectedValidation };
    if (value.endDate == "")
      return { status: false, message: config.messages.dateSelectedValidation };
    if (!checkIsAfterDate(value.startDate, value.endDate))
      return {
        status: false,
        message:
          config.messages.invalidDateSelection +
          "\n Available Global Start Date: " +
          convertDateToFormat(moment(value.startDate), "DD MMMM, YYYY") +
          "\n Available Global End Date: " +
          convertDateToFormat(moment(value.endDate), "DD MMMM, YYYY"),
      };
  }
  return { status: true, message: "Pass" };
};

export const validateEmptyConfigName = (name) => {
  if (name === "") return false;
  return true;
};

export const validConfigName = (name) => {
  if (name.replace(/[^A-Za-z0-9_-]/gi, "") !== name) return false;
  return true;
};

export const transformKpiVariable = (kpi_list, kpi_variable_list) => {
  // console.log(`kpi_list`, kpi_list); // ["value","volume","distrubution"]
  // console.log(`kpi_variable_list`, kpi_variable_list);
  // const notAllowedKpi = kpi_list.filter(
  //   (elem) => !config.hardCoded.mandatoryTargetVariable.includes(elem)
  // );
  // console.log(`notAllowedKpi`, notAllowedKpi);
  let resultData = {};
  kpi_list.forEach((elem) => {
    let newKpiVariables = {};
    let datasetKpi = kpi_variable_list.find((value) => value.id === elem);
    datasetKpi = datasetKpi ? datasetKpi : [];
    if (datasetKpi?.kpi_parameters?.length) {
      let updatedKpiDetails = datasetKpi?.kpi_parameters.map((item) => item);
      updatedKpiDetails.forEach((elem) => {
        newKpiVariables = {
          ...newKpiVariables,
          [elem.id]:
            typeof datasetKpi?.[elem.id] !== "undefined"
              ? datasetKpi?.[elem.id]
              : datasetKpi?.kpi_parameters.find((item) => item.id === elem.id)
                  .default,
        };
      });
    } else {
      const { id, kpi_parameters, ...remaining } = datasetKpi;
      newKpiVariables = {
        ...newKpiVariables,
        ...remaining,
      };
    }
    resultData = { ...resultData, [elem]: { ...newKpiVariables } };
  });
  return resultData;
};
export const validateNLPModel = (configUserInputs, allDataModels) => {
  const NlpModels = configUserInputs.models.find(
    (model) => model.id === config.hardCoded.nlpId
  );
  const cmu_status = configUserInputs?.cmu_status?.data;
  const getValueForParam = (string) => {
    return allDataModels
      .find((model) => model.id === config.hardCoded.nlpId)
      .local_parameters.find((param) => param.id === string);
  };
  if (
    NlpModels.run &&
    (!NlpModels?.config?.num_topics ||
      (isNumber(NlpModels?.config?.num_topics) &&
        NlpModels?.config?.num_topics >
          getValueForParam("num_topics").maxValue) ||
      NlpModels?.config?.num_topics < getValueForParam("num_topics").minValue)
  ) {
    return {
      status: false,
      message: `Number Of Topics should be between ${
        getValueForParam("num_topics").minValue
      } and ${getValueForParam("num_topics").maxValue}`,
    };
  } else if (NlpModels.run && NlpModels?.config?.semrush) {
    if (!cmu_status?.semrush) {
      return {
        status: false,
        message: config.messages.semrushNA,
      };
    } else {
      return {
        status: true,
        message: "Pass",
      };
    }
  } else {
    return {
      status: true,
      message: "Pass",
    };
  }
};

export const allValidationFunc = {
  validateHistoricWeek,
  validateDatesSelected,
  validateEmptyConfigName,
  validConfigName,
  validateMinMaxNumberInputs,
  validateModelsHasDatasetSelected,
  validateValidDatasetSelected,
  validateDatasetHasKpiSelected,
  validateOneModelSelected,
  validateOneModelSelectedForRuns, // feature specific
  validatePropensityActionNamesParameteres, //model specific
  validateCommunityClustering, //Model specific
  validateDatasetWithLocal,
  validateNLPModel, //model specific
};

export const validateConfig = (configUserInputs, allData) => {
  //checking the  De mode
  const checkDERunConfig = configUserInputs.models.every(
    (e) => e.config.run_model === true
  );

  //validateEmptyConfigName
  var status = allValidationFunc.validateEmptyConfigName(
    configUserInputs.configName
  );
  if (!status)
    return { status: "fail", message: config.messages.emptyConfigName };

  //validConfigName
  var status = allValidationFunc.validConfigName(configUserInputs.configName);
  if (!status)
    return { status: "fail", message: config.messages.invalidConfigName };

  //validateHistoricWeek
  var status = allValidationFunc.validateHistoricWeek(configUserInputs);
  if (!status)
    return { status: "fail", message: config.messages.historicWeekValidation };

  //validateDatesSelected
  var { status, message } =
    allValidationFunc.validateDatesSelected(configUserInputs);
  if (!status) return { status: "fail", message: message };

  //validateMinMaxNumberInputs
  var { status, message } = allValidationFunc.validateMinMaxNumberInputs(
    configUserInputs.models,
    allData.models,
    checkDERunConfig
  );
  if (!status) return { status: "fail", message };

  //validateModelsHasDatasetSelected
  var { status, message } = allValidationFunc.validateModelsHasDatasetSelected(
    configUserInputs.models,
    allData.models,
    configUserInputs,
    allData
  );
  if (!status) return { status: "fail", message };

  //validateValidDatasetSelected
  var { status, message, partialDataset } =
    allValidationFunc.validateValidDatasetSelected(
      configUserInputs.models,
      configUserInputs
    );
  if (!status) return { status: "fail", message, partialDataset };

  //validateDatasetHasKpiSelected
  var { status, message } = allValidationFunc.validateDatasetHasKpiSelected(
    configUserInputs.models,
    allData.models
  );
  if (!status) return { status: "fail", message };

  //validateOneModelSelected
  var { status, message } = allValidationFunc.validateOneModelSelected(
    configUserInputs.models
  );
  if (!status) return { status: "fail", message };

  //validateOneModelSelectedForRuns
  var { status, message } = allValidationFunc.validateOneModelSelectedForRuns(
    configUserInputs.models,
    configUserInputs
  );
  if (!status) return { status: "fail", message };

  //validateDatasetWithLocal
  var { status, message } = allValidationFunc.validateDatasetWithLocal(
    configUserInputs,
    configUserInputs.models
  );
  if (!status) return { status: "fail", message };

  //validatePropensityActionNamesParameteres
  var { status, message } =
    allValidationFunc.validatePropensityActionNamesParameteres(
      configUserInputs
    );
  if (!status) return { status: "fail", message };

  //validateCommunityClustering
  var { status, message } =
    allValidationFunc.validateCommunityClustering(configUserInputs);
  if (!status) return { status: "fail", message };

  // validateNLPModel
  var { status, message } = allValidationFunc.validateNLPModel(
    configUserInputs,
    allData.models
  );
  if (!status) return { status: "fail", message };

  return {
    status: "pass",
    message: configUserInputs.configId
      ? config.messages.validConfigUpdate
      : config.messages.validConfigAdd,
    partialDataset,
  };
};

export const transformArrayToObj = (config) => {
  var result = {};
  for (var i = 0; i < config.length; i++) {
    result[i] = config[i];
  }
  return result;
};

export const transformArrayToObjBasedOnPosition = (array) => {
  var object = {};
  for (let i = 0; i < array.length; i++) {
    let { position, ...value } = array[i];
    let key = parseInt(position) - 1;
    object[key] = value;
  }
  return object;
};

export const transformConfigUserModels = (state, configUserInputs, allData) => {
  //Empty data_sources and transform interface_details if community clustering has target run selected
  let newState = state.map((model) => {
    if (model.id !== config.hardCoded.communityClusteringId) {
      return model;
    } else {
      let data_sources = model.data_sources;
      let {
        interface_name,
        custom_target_community_run,
        community_id_type,
        ...remaining
      } = model.config;
      let configObj = {
        ...remaining,
        algorithm: model.config.algorithm,
        audience_name: model.config.audience_name,
        // interface_details: {
        //   interface_name: model.config.interface_name,
        //   interface_info: [],
        // },
        target_run_id:
          model.config.community_clustering_run_type ===
          config.hardCoded.uploadAudienceRunId
            ? model.config.target_run_id
            : "",
      };
      // const scheduleConfig = {
      //   target: {
      //     audience_name: model.config.audience_name,
      //     community_id_list: model.config?.community_id_list,
      //     interface_details: {
      //       interface_name: model.config.interface_name,
      //       interface_info: [],
      //     },
      //   },
      //   ...(allData.appAccessDetails.feature_list[
      //     "community_clustering_audience_data"
      //   ]?.hasOwnProperty("write") && { upload_audience: {} }),
      // };
      let newModel = {
        ...model,
        data_sources,
        config: { ...configObj },
        // ...(configUserInputs?.is_schedule_config && {
        //   schedule_config: { ...scheduleConfig },
        // }),
      };
      return newModel;
    }
  });

  //Remove ID from action_names array
  newState = newState.map((model) => {
    //if model.config included action_names, remove id from all objects
    if (model.config[config.hardCoded.actionNamesId]) {
      let updActionNames = model.config.action_names.map((combinationRow) => {
        let { id, ...remaining } = combinationRow;
        return remaining;
      });
      let updModel = {
        ...model,
        config: {
          ...model.config,
          [config.hardCoded.actionNamesId]: updActionNames,
        },
      };
      return updModel;
    } else {
      return model;
    }
  });

  //remove keys from propensity model
  newState = newState.map((model) => {
    if (model.id !== config.hardCoded.propensityId) {
      return model;
    } else {
      let data_sources =
        // !configUserInputs?.is_schedule_config &&
        // !configUserInputs?.is_fixed_schedule_config &&
        // (model.config.propensity_modeling_run_type ===
        //   config.hardCoded.targetRunId ||
        //   model.config.propensity_modeling_run_type ===
        //     config.hardCoded.uploadAudienceRunId)
        //   ? []
        // :
        model.data_sources;
      let {
        interface_name,
        hot,
        cold,
        warm,
        custom_target_proppensity_run,
        community_id_type,
        segment_thresholds_limit,
        ...remaining
      } = model.config;
      let configObj = {
        ...remaining,
        // propensity_modeling_run_type: model.config.propensity_modeling_run_type,
        action_names:
          // !configUserInputs?.is_schedule_config &&
          // !configUserInputs?.is_fixed_schedule_config &&
          // model.config.propensity_modeling_run_type ===
          // config.hardCoded.targetRunId ||
          // model.config.propensity_modeling_run_type ===
          //   config.hardCoded.uploadAudienceRunId
          //   ? []
          //   :
          model.config.action_names,
        segment_thresholds: [
          {
            name: "hot",
            maxValue:
              // configUserInputs?.is_schedule_config ||
              // configUserInputs?.is_fixed_schedule_config
              //   ? 0.001
              //   :
              model.config.hot ? parseFloat(model.config.hot) : 0.34,
          },
          {
            name: "cold",
            maxValue:
              // configUserInputs?.is_schedule_config ||
              // configUserInputs?.is_fixed_schedule_config
              //   ? 0
              //   :
              model.config.cold ? parseFloat(model.config.cold) : 0.33,
          },
          {
            name: "warm",
            maxValue:
              // configUserInputs?.is_schedule_config ||
              // configUserInputs?.is_fixed_schedule_config
              //   ? 0
              //   :
              model.config.warm ? parseFloat(model.config.warm) : 0.33,
          },
        ],
        interface_details: model.config?.interface_details?.length
          ? model.config.interface_details
          : [
              {
                interface_name: "",
                upload_audience: false,
              },
            ],
      };
      const scheduleConfig = {
        target: {
          segment_thresholds: [
            {
              name: "hot",
              maxValue: model.config.hot ? parseFloat(model.config.hot) : 0.001,
            },
            {
              name: "cold",
              maxValue: model.config.cold ? parseFloat(model.config.cold) : 0,
            },
            {
              name: "warm",
              maxValue: model.config.warm ? parseFloat(model.config.warm) : 0,
            },
          ],
          interface_details: model.config.interface_details,
        },
        ...(allData.appAccessDetails.feature_list[
          "propensity_modelling_audience_data"
        ]?.hasOwnProperty("write") && { upload_audience: {} }),
      };

      let newModel = {
        ...model,
        data_sources,
        config: { ...configObj },
        ...((configUserInputs?.is_schedule_config ||
          configUserInputs?.is_fixed_schedule_config) && {
          schedule_config: { ...scheduleConfig },
        }),
      };
      return newModel;
    }
  });

  //Remove ID from combinations array
  newState = newState.map((model) => {
    //if model.config included combinations, remove id from all objects
    if (model.config[config.hardCoded.combinationsId]) {
      let updCombinations = model.config.combinations.map((combinationRow) => {
        let { id, ...remaining } = combinationRow;
        return remaining;
      });
      let updModel = {
        ...model,
        config: {
          ...model.config,
          [config.hardCoded.combinationsId]: updCombinations,
        },
      };
      return updModel;
    } else {
      return model;
    }
  });
  //config for Community clustering
  newState = newState.map((model) => {
    if (model.id === config.hardCoded.communityClusteringId) {
      let { community_id_list, target_run_id, run_id, ...configObj } =
        model.config;
      return { ...model, config: { ...configObj } };
    } else {
      return model;
    }
  });
  //config for Propensity modeling
  newState = newState.map((model) => {
    if (model.id === config.hardCoded.propensityId) {
      let {
        community_id_type,
        // ! To removed in few days
        // upload_audience,
        ...remaining
      } = model.config;
      let configObj = {
        ...remaining,
      };
      let newModel = { ...model, config: { ...configObj } };
      return newModel;
    } else {
      return model;
    }
  });
  //removing unnecessary keys
  newState = newState.map((model) => {
    if (model.id === config.hardCoded.nlpId) {
      const { data_sources, config, ...remaining } = model;

      return {
        config: { ...config, semrush: config.semrush ? true : false },
        ...remaining,
      };
    } else {
      const { upload_audience, ...remaining } = model;
      return { ...remaining };
    }
  });
  newState = newState.map((model) => {
    if (model.id !== config.hardCoded.nlpId) {
      if (
        !model.config.interface_details ||
        !model.config.interface_details.length
      )
        return {
          ...model,
          config: {
            ...model.config,
            interface_details: [{ interface_name: "", upload_audience: false }],
          },
        };
      else {
        return model;
      }
    } else {
      return model;
    }
  });

  return newState;
};

export const makeConfigTemplate = (configUserInputs, allData) => {
  let finalConfig = {
    name: `${
      configUserInputs.configNameFixed
    }${configUserInputs.configName.trim()}`,
    app_id: configUserInputs.app_id,
    app_name: configUserInputs.appName,
    metadata: {
      ...configUserInputs.metadata,
      tags: configUserInputs?.tags || [],
    },
    selections: {
      config: {
        app_id: configUserInputs.app_id,
        app_name: configUserInputs.appName,
        country: configUserInputs.country,
        brand: configUserInputs.brand,
        segment: configUserInputs.segment,
        historic_week_data:
          configUserInputs.global_week_date_selection === "date_range"
            ? 0
            : parseFloat(configUserInputs.historic_week_data),
        start_date: configUserInputs.startDate,
        end_date: configUserInputs.endDate,
        ...(configUserInputs.app_id === config.hardCoded.aeAppId &&
          configUserInputs?.is_schedule_config && {
            is_schedule_config: configUserInputs?.is_schedule_config,
          }),
        ...(configUserInputs?.is_fixed_schedule_config && {
          is_fixed_schedule_config: configUserInputs?.is_fixed_schedule_config,
        }),
        fixed_runs: configUserInputs.fixed_runs,
      },
      models: transformConfigUserModels(
        configUserInputs.models,
        configUserInputs,
        allData
      ),
    },
  };

  if (configUserInputs.configId) {
    finalConfig = { ...finalConfig, id: configUserInputs.configId };
  }
  console.log("final config--->", finalConfig);
  return finalConfig;
};

export const transformObjToArray = (config) => {
  let arrayResult = Object.values(config);
  return arrayResult;
};

//Transform testControl from object to array
export const transformTestControlFromObjectToArray = (
  data,
  key,
  local_parameters_key
) => {
  // let res = Object.entries(data).map((e) => ({ key: e[0], value: e[1] })); //test:[]
  let res = Object.entries(data).map(([currKey, currValue]) => {
    return {
      ...currValue,
      position: parseInt(currKey) + 1,
      ...(key && {
        type: key,
      }),
      id: v4(),
      ...(local_parameters_key &&
        (local_parameters_key === "campaigns"
          ? {
              [local_parameters_key]: [
                { ...currValue[local_parameters_key][0], id: v4() },
              ],
            }
          : {
              [local_parameters_key]: [
                { ...currValue[local_parameters_key], id: v4() },
              ],
            })),
    };
  });
  return res;
};

//adding values based on selesction in dataset, Currently for AutoML & autoMlBudget
const transformDatasets = (datasets) => {
  let checkFlagForComp = datasets.find(
    (dataset) => dataset.pipeline_dataset_id === config.hardCoded.neilsenCompId
  );
  let checkFlagForComp1 = datasets.find(
    (dataset) => dataset.pipeline_dataset_id === "TV_COMPETITOR"
  );
  //nielsen_comp_available
  let transformedDatasets = datasets.map((dataset) =>
    dataset.pipeline_dataset_id === config.hardCoded.neilsenNonCompId
      ? {
          ...dataset,
          nielsen_comp_available: checkFlagForComp ? true : false,
          top_n_competitor: checkFlagForComp
            ? checkFlagForComp.top_n_competitor
            : 5,
        }
      : config.hardCoded.topNCompetitor.includes(dataset.pipeline_dataset_id) //for other competitor datasets (right now only TV)
      ? {
          ...dataset,
          competitor_available: checkFlagForComp1 ? true : false,
          top_n_competitor: checkFlagForComp1
            ? checkFlagForComp1.top_n_competitor
            : 5,
        }
      : dataset
  );
  //enable_adstock
  transformedDatasets = transformedDatasets.map((dataset) =>
    dataset.type === config.hardCoded.datasetMediaType
      ? Object.keys(dataset?.adstock_parameters || {}).length
        ? {
            ...dataset,
            enable_adstock: true,
          }
        : {
            ...dataset,
            enable_adstock: false,
          }
      : dataset
  );
  //granularity
  transformedDatasets = transformedDatasets.map((dataset) =>
    dataset.type === config.hardCoded.datasetMediaType
      ? {
          ...dataset,
          group_as_others:
            dataset.channel_granularity === "channel"
              ? dataset?.group_as_others
                ? dataset.group_as_others
                : false
              : false,
          group_non_target_as_others:
            dataset.channel_granularity === "campaign"
              ? dataset?.group_non_target_as_others
                ? dataset.group_non_target_as_others
                : false
              : false,
          target_campaigns:
            dataset.channel_granularity === "campaign"
              ? dataset.target_campaigns
                ? dataset.target_campaigns
                : []
              : [],
        }
      : dataset
  );
  return transformedDatasets;
};

export const makeConfigUserInputs = (props) => {
  let {
    initialConfigData,
    match,
    disableFlag,
    activeApp,
    initialConfigDataFallback,
    allData,
    configType,
    is_scheduled_run,
    country,
    brand,
    configName,
    historic_week_data,
    is_fixed_schedule_run,
  } = props;
  let selections = {
    ...initialConfigData.selections,
    config: {
      ...initialConfigData.selections.config,
      configName: configName,
      ...(country && brand && { country: country, brand: brand }),
    },
  };
  let selectionModels = selections.models;
  let globalModels = allData.models;
  console.log("SELECTION MODELS BEFORE -> ", selectionModels);

  //Add data_sources in NLP model
  selectionModels = selectionModels.map((model) => {
    if (model.id === config.hardCoded.nlpId) {
      return {
        ...model,
        data_sources: [],
      };
    } else {
      return model;
    }
  });
  //check if configUserInputs.models has all the models that allData.models has; if not populate it from default_config_template
  //do this only if it's not a run model
  if (!disableFlag) {
    let selectionModelsIds = selectionModels.map((model) => model.id);
    let absentModels = globalModels?.filter(
      (model) => !selectionModelsIds.includes(model.id)
    );
    if (absentModels?.length) {
      absentModels.forEach((absentModel) => {
        let initialModelSelections =
          initialConfigDataFallback.selections.models.find(
            (model) => model.id === absentModel.id
          );
        if (initialModelSelections) {
          selectionModels = [
            ...selectionModels,
            { ...initialModelSelections, run: false },
          ];
        }
      });
    }
  }

  //check if configUserInputs.models.data_sources has all the latest local_parameters; if not populate it from allData.datasets
  //do this only if it's not a run model
  if (!disableFlag) {
    selectionModels = selectionModels.map((model) => {
      let data_sources = model.data_sources.map((dataSource) => {
        let filteredDataset = allData?.datasets?.find(
          (dataset) => dataset.id === dataSource.id
        );
        filteredDataset?.local_parameters.forEach((lp) => {
          if (!dataSource[lp.id]) {
            let value;
            switch (lp.ui_element_type) {
              case "radio_button":
                value = lp.data[0].id;
                break;
              case "dropdown":
                value = lp.data[0].id;
                break;
              case "inputbox_number":
                value = 1;
                break;
              case "inputbox_text":
                value = "";
                break;
              case "custom":
                value = [];
                break;
              default:
                value = null;
                break;
            }
            dataSource[lp.id] = value;
          }
        });
        return { ...dataSource };
      });

      let filteredModel = allData?.models?.find(
        (currModel) => currModel.id === model.id
      );
      let modelConfig = model.config;
      filteredModel?.local_parameters.forEach((lp) => {
        if (modelConfig[lp.id] === undefined) {
          let value;
          switch (lp.ui_element_type) {
            case "radio_button":
              value = lp.data[0].id;
              break;
            case "dropdown":
              value = lp.data[0].id;
              break;
            case "inputbox_number":
              value = lp.defaultValue;
              break;
            case "inputbox_text":
              value = "";
              break;
            case "custom":
              switch (lp.id) {
                case "algorithm":
                  value = lp.data[0].id;
                  break;
                case "community_id_type":
                  value = lp.data[0].id;
                  break;
                case "adstock_preview":
                  value = lp.data[0].value;
                  break;
                case "group_all_targets":
                  value = lp.data[0].value;
                  break;
                case "upload_audience":
                  value = lp.data[0].value;
                  break;
                case "sales_filter":
                  value = {};
                  break;
                case "retail_margin_price":
                  value = parseFloat(+lp.defaultValue.toFixed(2));
                  break;
                case "seasonality":
                  value = lp.data[0].value;
                  break;
                default:
                  value = [];
                  break;
              }
              break;
            default:
              value = null;
              break;
          }
          modelConfig[lp.id] = value;
        }
      });
      return { ...model, data_sources, config: modelConfig };
    });
  }

  selectionModels = selectionModels.map((model) => {
    let updModel = {
      ...model,
      data_sources: model.data_sources.map((elem) => {
        return config.hardCoded.radioDatasetList.includes(elem.type)
          ? !elem?.hasOwnProperty("sku")
            ? configType === "add"
              ? { ...elem, sku: [{ sku: "ALL" }] }
              : {
                  ...elem,
                  sku: elem?.sku
                    ? elem?.sku?.map((str) => ({ sku: str }))
                    : [{ sku: "ALL" }],
                }
            : {
                ...elem,
                sku: elem?.sku?.map((str) =>
                  typeof str === "object"
                    ? {
                        sku: str.sku,
                      }
                    : { sku: str }
                ),
              }
          : {
              ...elem,
            };
      }),
    };
    return updModel;
  });

  //add community id type in community clustering model
  selectionModels = selectionModels.map((model) => {
    if (model.id === config.hardCoded.communityClusteringId) {
      let updModel = {
        ...model,
        config: {
          ...model.config,
          audience_name: model.config.audience_name,
          interface_name:
            typeof model.config.upload_audience !== "undefined"
              ? [model.config.interface_details.interface_name]
              : model.config.interface_details
              ? model.config.interface_details
                  .map((item) => item.interface_name)
                  .filter((item) => item)
              : [],
          [config.hardCoded.communityIdListType]: model.config.community_id_list
            ? model.config.community_id_list.length
              ? model.config.community_id_list.toString() === ["ALL"].toString()
                ? "ALL"
                : config.hardCoded.specificId
              : "ALL"
            : "ALL",
          interface_details:
            typeof model.config.upload_audience !== "undefined"
              ? [
                  {
                    interface_name:
                      model.config.interface_details.interface_name,
                    upload_audience: model.config.upload_audience,
                  },
                ]
              : model.config.interface_details,
        },
      };
      return updModel;
    } else {
      return model;
    }
  });

  //add interface_name and segment threshhold values in template propensity model
  selectionModels = selectionModels.map((model) => {
    if (model.id === config.hardCoded.propensityId) {
      let updModel = {
        ...model,
        config: {
          ...model.config,
          segment_thresholds:
            selections.config.is_schedule_config ||
            selections.config.is_fixed_schedule_config
              ? model?.schedule_config?.target?.segment_thresholds
              : model?.config?.segment_thresholds,
          hot:
            selections.config.is_schedule_config ||
            selections.config.is_fixed_schedule_config
              ? model.schedule_config.target.segment_thresholds.find(
                  (i) => i.name === "hot"
                ).maxValue
              : model?.config?.segment_thresholds?.length
              ? model.config.segment_thresholds.find((i) => i.name === "hot")
                  .maxValue
              : 0.001,
          cold:
            selections.config.is_schedule_config ||
            selections.config.is_fixed_schedule_config
              ? model.schedule_config.target.segment_thresholds.find(
                  (i) => i.name === "cold"
                ).maxValue
              : model?.config?.segment_thresholds?.length
              ? model.config.segment_thresholds.find((i) => i.name === "cold")
                  .maxValue
              : 0,
          warm:
            selections.config.is_schedule_config ||
            selections.config.is_fixed_schedule_config
              ? model.schedule_config.target.segment_thresholds.find(
                  (i) => i.name === "warm"
                ).maxValue
              : model?.config?.segment_thresholds?.length
              ? model.config.segment_thresholds.find((i) => i.name === "warm")
                  .maxValue
              : 0,
          interface_name:
            typeof model.config.upload_audience !== "undefined"
              ? [model.config.interface_details.interface_name]
              : (selections.config.is_schedule_config ||
                  selections.config.is_fixed_schedule_config) &&
                model.id === config.hardCoded.propensityId
              ? model.schedule_config.target.interface_details?.map(
                  (el) => el.interface_name
                ) || []
              : model.config.interface_details?.map((el) => el.interface_name)
              ? model.config.interface_details?.map((el) => el.interface_name)
              : [],
          // interface_details: selections.config.is_schedule_config
          //   ? model.schedule_config.target.interface_details
          //   : model.config.interface_details,
          ...(model.run && {
            interface_details:
              typeof model.config.upload_audience !== "undefined"
                ? [
                    {
                      interface_name:
                        model.config.interface_details.interface_name,
                      upload_audience: model.config.upload_audience,
                    },
                  ]
                : selections.config.is_schedule_config ||
                  selections.config.is_fixed_schedule_config
                ? model.schedule_config.target.interface_details
                : model.config.interface_details,
          }),
          ...(!model.run &&
            model.id === config.hardCoded.propensityId && {
              interface_details:
                selections.config.is_schedule_config ||
                selections.config.is_fixed_schedule_config
                  ? []
                  : model.config.interface_details || [],
            }),
        },
      };
      return updModel;
    } else {
      return model;
    }
  });

  //Add ID in action_names array
  selectionModels = selectionModels.map((model) => {
    //if model.config includes combinations, add id for all objects
    if (model.config[config.hardCoded.actionNamesId]) {
      let updCombinations = model.config.action_names.map((combinationRow) => ({
        ...combinationRow,
        id: v4(),
      }));
      let updModel = {
        ...model,
        config: {
          ...model.config,
          [config.hardCoded.actionNamesId]: updCombinations,
        },
      };
      return updModel;
    } else {
      return model;
    }
  });

  // we should remove after we changed the default template and model parameters
  selectionModels = selectionModels.map((model) => {
    if (
      model.id === config.hardCoded.communityClusteringId &&
      configType !== "run"
    ) {
      const { community_clustering_run_type, ...remainingConfig } =
        model.config;
      let updModel = {
        ...model,
        config: { ...remainingConfig },
      };
      return updModel;
    } else if (
      model.id === config.hardCoded.propensityId &&
      configType !== "run"
    ) {
      const { propensity_modeling_run_type, ...remainingConfig } = model.config;
      let updModel = {
        ...model,
        config: { ...remainingConfig },
      };
      return updModel;
    } else {
      return model;
    }
  });
  selectionModels = selectionModels.map((model) => {
    const { interface_details, ...remaining } = model.config;
    if (
      typeof model.config.upload_audience === "undefined" &&
      model.id !== config.hardCoded.nlpId &&
      ((!selections.config.is_schedule_config &&
        !selections.config.is_fixed_schedule_config) ||
        model.id === config.hardCoded.communityClusteringId) &&
      interface_details?.find((el) => el.interface_name === "")
    ) {
      return {
        ...model,
        config: {
          ...remaining,
          interface_details: [],
          interface_name:
            model.config.interface_name.filter((item) => item) || [],
        },
      };
    } else {
      return model;
    }
  });

  console.log("SELECTION MODELS AFTER -> ", selectionModels);
  let configUserInputs = {
    app_id: initialConfigData.app_id,
    appName: match.params.configName
      ? selections.config.app_name
      : activeApp.name,
    configId: match.params.configName ? initialConfigData.id : null,
    configNameFixed: `${config.hardCoded.aeAppName}-${selections.config.country}-${selections.config.brand}-`,
    configName: configName
      ? configName
      : disableFlag
      ? getConfigName(initialConfigData.name, selections.config.brand)
      : match.params.configName
      ? getConfigName(match.params.configName, selections.config.brand)
      : "",
    configNameOriginal: disableFlag
      ? initialConfigData.name
      : match.params.configName
      ? match.params.configName
      : "",
    configType: configType,
    country: selections.config.country,
    brand: selections.config.brand,
    category: selections.config.category,
    segment: selections.config.segment,
    historic_week_data: historic_week_data
      ? historic_week_data
      : selections.config.historic_week_data,
    global_week_date_selection: selections.config.historic_week_data
      ? config.hardCoded.historicWeek
      : config.hardCoded.dateRange,
    tags: initialConfigData?.metadata?.tags || [],
    models: selectionModels,
    categoryData: -1,
    campaignsData: -1,
    metadata:
      match.params.configName || match.params.runName
        ? initialConfigData.metadata
        : { isStarred: false },
    loading: false,
    startDate: selections.config.start_date,
    endDate: selections.config.end_date,
    is_schedule_config: disableFlag
      ? is_scheduled_run && !is_fixed_schedule_run
      : selections.config.is_schedule_config || false,
    is_fixed_schedule_config: disableFlag
      ? is_fixed_schedule_run
      : selections.config.is_fixed_schedule_config || false,
    fixed_runs: selections.config.fixed_runs,
    old_pm: initialConfigData.old_pm,
  };
  return configUserInputs;
};

export const pickMinMaxDate = (date) => {
  if (date == null) {
    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1;
    let yyyy = today.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    today = yyyy + "-" + mm + "-" + dd;
    return today;
  } else {
    return date;
  }
};

export const makeDefaultResponseJson = () => ({ data: [], error: "" });

//Feb-12-2021 important
export const formatDate = (date, format = "MMM-DD-YYYY H:mm:ss") =>
  date ? moment(date).format(format) : "-";
export const formatDateForNotificationAndRuns = (
  date,
  format = "MMM-DD-YYYY H:mm:ss"
) => moment(date).format(format);
export const formatDateForMTADataRange = (date, format = "MMM DD,YYYY") =>
  moment(date).format(format);

export const formatDateToMoment = (date, format = "YYYY-MM-DD") =>
  moment(date, "DD-MM-YYYY").format(format);

//hh:mm:ss 17:39:07
export const formatTime = (date) => moment(date).format("H:mm:ss");

export const convertMinutesIntoHrMins = (time) => {
  var d = moment.duration(time, "minutes");
  let displayString = "";
  if (d._data.hours > 1) {
    displayString += d._data.hours + " hours ";
  } else if (d._data.hours > 0) {
    displayString += d._data.hours + " hour ";
  } else {
    displayString = displayString;
  }
  if (d._data.minutes > 1) {
    displayString += d._data.minutes + " minutes ";
  } else {
    displayString += d._data.minutes + " minute ";
  }

  return displayString;
};

//Calculate Duration false-->00:06 true--> 0 hr 6 mins
export const timeDiffCalc = (start_time, end_time) => {
  const diffInDate = moment.duration(moment(start_time).diff(moment(end_time)));
  return `${diffInDate._data.hours}hr ${diffInDate._data.minutes}min`;
};

export const timeDifference = (end_time) => {
  const diffInDate = moment.duration(moment().diff(moment(end_time)));
  return diffInDate?._data?.months > 1
    ? 8
    : diffInDate._data.days + diffInDate._data.months;
};

export const getDuration = (start_time, end_time) => {
  const diffInDate = moment.duration(moment(start_time).diff(moment(end_time)));
  return `${diffInDate._data.hours}hr ${diffInDate._data.minutes}min`;
};

//February 12, 2021 5:46 PM | Duration: 0 hr 6 mins
export const pilotRunTimestamp = (created_on, start_date, end_date, format) =>
  formatDate(created_on) +
  " | " +
  "Duration: " +
  getDuration(start_date, end_date);

//MROI-Australia-Vanish-Test-20-Jan-21-2021-13:08:04
export const makeRunNameFromRun = (run) =>
  run.metadata.config_name +
  "-" +
  formatDateForNotificationAndRuns(run.created_on);

export const makeRunNameFromRunNew = (run) =>
  run.run_name + "-" + formatDateForNotificationAndRuns(run.created_on);

export const getRunIdFromRunName = (runData, runName) => {
  let runId = "";
  runData.forEach((elem) => {
    elem.runs.forEach((run) => {
      if (
        (
          run.run_name +
          "-" +
          formatDateForNotificationAndRuns(run.created_on)
        ).toLowerCase() == runName.toLowerCase()
      ) {
        runId = run.id;
      }
    });
  });
  return runId;
};
export const copyValueToClipboard = (value) => {
  const el = document.createElement("input");
  el.value = value;
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
};
export const shortFormAE = (string) => string.replace("AUDIENCE ENGINE", "AE");

export const modelForUI = (array) => {
  if (array.includes(config.hardCoded.communityClusteringId)) return "CC";
  else if (array.includes(config.hardCoded.propensityId)) return "PM";
  else return "NLP";
};

export const getRunType = (configUserInputs, method) => {
  const enabledModel =
    method === "inside"
      ? configUserInputs.models.find((model) => model.run)
      : configUserInputs.selections.models.find((model) => model.run);
  const runType =
    enabledModel.id === config.hardCoded.communityClusteringId
      ? ""
      : enabledModel.id === config.hardCoded.propensityId
      ? enabledModel.config.propensity_modeling_run_type
      : "";
  return runType;
};
export const runTypeForUI = (string) => {
  if (string === config.hardCoded.initialRunId.toLowerCase()) return "Initial";
  else if (string === config.hardCoded.targetRunId.toLowerCase())
    return "Target";
  else if (!string || string === "") return "";
  else return "UA";
};

export const createRunName = (str) => {
  const arr = str.split("-");
  const finalStr = arr.filter((str, i) => i > 2 && str);
  return finalStr.join("-");
};
export const generateMessageNotification = (obj) => {
  const { config_name, completed_at, triggered_at, notification_type } = obj;
  let message = "";
  const shortConfigName = shortFormAE(config_name);
  switch (notification_type) {
    case "run-success":
      message =
        shortConfigName +
        " run successfully completed at " +
        formatTime(completed_at);
      break;
    case "run-failure":
      message = shortConfigName + " run failed at " + formatTime(completed_at);
      break;
    case "run-triggered":
      message =
        shortConfigName + " run triggered at " + formatTime(triggered_at);
      break;
    default:
      message = "No notifications";
  }

  return message;
};

export const convertDateToEpoch = (date, format = "YYYY-MM-DD") =>
  moment(date, format)._d.valueOf();

export const convertDateToFormat = (date, format = "YYYY-MM-DD") =>
  moment(date, "DDMMYYYY").format(format);

export const convertDateToFormatForCmu = (date, format = "YYYYMMDD") =>
  moment(date, "YYYY-MM-DD").format(format);

export const getWeeksForModels = (start_date, end_date) => {
  let startDate = convertDateToFormat(start_date, "DDMMYYYY");
  let endDate = convertDateToFormat(end_date, "DDMMYYYY");
  return Math.floor(getWeekCount(startDate, endDate)) > 0
    ? Math.floor(getWeekCount(startDate, endDate))
    : 0;
};

export const sortDataset = (datasets, selectedDatasetsIds, orderByArray) => {
  let checked = [],
    nonChecked = [];
  datasets.forEach((dataset) => {
    selectedDatasetsIds.includes(dataset.id)
      ? checked.push(dataset)
      : nonChecked.push(dataset);
  });
  checked = orderBy(checked, orderByArray);
  nonChecked = orderBy(nonChecked, orderByArray);
  let orederedDataset = [...checked, ...nonChecked];
  return orederedDataset;
};

export const getStartEndByDayCat = (dayCat) => {
  let startDate, endDate;
  switch (dayCat) {
    case "today":
      startDate = moment().startOf("date")._d.valueOf();
      endDate = moment()._d.valueOf();
      break;
    case "yesterday":
      startDate = moment().startOf("date")._d.valueOf() - 24 * 60 * 60 * 1000;
      endDate = moment().endOf("date")._d.valueOf() - 24 * 60 * 60 * 1000;
      break;
    case "this_week":
      startDate = moment().startOf("isoWeek")._d.valueOf();
      endDate = moment()._d.valueOf();
      break;
    case "last_week":
      startDate =
        moment().startOf("isoWeek")._d.valueOf() - 7 * 24 * 60 * 60 * 1000;
      endDate =
        moment().endOf("isoWeek")._d.valueOf() - 7 * 24 * 60 * 60 * 1000;
      break;
    case "last_30_days":
      startDate =
        moment().startOf("day")._d.valueOf() - 30 * 24 * 60 * 60 * 1000;
      endDate = moment()._d.valueOf();
      break;
    case "all":
      startDate = 0; //1970
      endDate = 4099680000000; //2100
      break;
    default:
      startDate = 0;
      endDate = moment()._d.valueOf();
  }
  return { start_time: startDate, end_time: endDate };
};

export const endAfterStart = (start, end) => {
  var startDate = new Date(start);
  var endDate = new Date(end);
  return endDate.getTime() >= startDate.getTime();
};

export const toggleDatasetReducer = (state, payload) => {
  let { flag, dataset, activeModel } = payload;

  //state is data_sources
  let newState;
  //remove all data_sources with the same type
  newState = state.filter((currDataset) => {
    return currDataset.type === dataset.type ? false : true;
  });

  //add that dataset if flag is true
  if (flag)
    newState = [...newState, manipulateDataset({ dataset, activeModel })];
  return newState;
};

export const toggleKpiReducer = (state, payload) => {
  let { flag, dataset, activeModel } = payload;

  let availableDatasetType = [
    config.hardCoded.datasetSalesType,
    config.hardCoded.datasetNeilsenType,
  ];

  //state is data_sources
  let newState;
  //remove all data_sources with the same type
  newState = state.filter((currDataset) => {
    return availableDatasetType.includes(currDataset.type) ? false : true;
  });

  //add that dataset if flag is true
  if (flag)
    newState = [...newState, manipulateDataset({ dataset, activeModel })];
  return newState;
};

export const getActiveModel = (configUserInputs) =>
  configUserInputs.models.filter((model) => model.run).length
    ? configUserInputs.models.find((model) => model.run).id
    : configUserInputs.models[0].id;

export const getActiveDataset = (configUserInputs, allData) => {
  let activeModel = configUserInputs.models.filter((model) => model.run).length
    ? configUserInputs.models.find((model) => model.run)
    : configUserInputs.models[0];
  let activeDataset;
  if (activeModel.data_sources.length) {
    activeDataset = activeModel.data_sources[0];
  } else {
    let globalActiveModel = allData?.models?.find(
      (model) => model.id === activeModel.id
    );
    activeDataset = allData?.datasets?.find((dataset) =>
      globalActiveModel.dataset_ids.includes(dataset.id)
    );
  }
  let activeDatasetId = activeDataset
    ? activeDataset.id
    : allData.datasets[0]?.id;
  return activeDatasetId;
};

// Accepts the array and key
const groupBy = (array, key) => {
  // Return the end result
  return array.reduce((result, currentValue) => {
    // If an array already present for key, push it to the array. Else create an array and push the object
    (result[currentValue[key]] = result[currentValue[key]] || []).push(
      currentValue
    );
    // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
    return result;
  }, {}); // empty object is the initial value for result object
};

export const splitDatasetsIntoTypes = (datasets) => {
  let availableDatasetTypes = [
    config.hardCoded.datasetSalesType,
    config.hardCoded.datasetNeilsenType,
    config.hardCoded.datasetMediaType,
  ];

  let newState = datasetCategories.map((datasetCat) => ({
    ...datasetCat,
    data: datasets.filter((dataset) => datasetCat.id.includes(dataset.type)),
  }));

  let notAvailableDataset = datasets.filter(
    (dataset) => !availableDatasetTypes.includes(dataset.type)
  );

  let newObj = groupBy(notAvailableDataset, "type");

  let newDatasetList = [];
  for (let key in newObj) {
    newDatasetList.push({
      id: key,
      name: key,
      ui_element_type: "checkbox",
      checkedSortFlag: false,
      data: newObj[key],
    });
  }

  newState = [...newState, ...newDatasetList];
  return newState;
};

export const modifyTestControlNameToAddLabel = (data, label) =>
  data.map((elem, index) => ({
    ...elem,
    name: `${label} ${index + 1} - Regions: ${
      elem.regions.length ? elem.regions : ["-"]
    }`,
    type: label.split(" ")[0],
  }));

export const addLabelToTestControlArr = (data, label, showPosition, type) =>
  data.map((elem) => ({
    ...elem,
    type: type,
    name: showPosition
      ? `${label} ${elem.position} - Regions: ${
          elem.regions.length ? elem.regions : ["-"]
        }`
      : `${label} - Regions: ${elem.regions.length ? elem.regions : ["-"]}`, //hard coded
  }));

export const makeDetailsLabelFromDetails = (details) => {
  details = details.filter((detail) => detail.id !== "");
  return details.reduce(
    (finalDetailsLabel, detail, index) =>
      (index === 0 ? " | " : "") +
      finalDetailsLabel +
      detail.name +
      (index !== details.length - 1 ? ", " : ""),
    ""
  );
};

export const modifyLevelsToAddDetails = (levels) =>
  levels.map((level) => ({
    ...level,
    name: `${level.name}${makeDetailsLabelFromDetails(level.details)}`,
  }));

export const addRunNameToRunData = (data) =>
  data.map((run) => ({ ...run, name: makeRunNameFromRun(run) }));

export const getSortedData = (data, orderField = "name", order = "asc") =>
  orderBy(data, [orderField], [order]);

// Works for Sunday to Saturday week
// If week is 2 and today's day is not sunday then start date, end date will be returned in Sunday to Saturday format
export const getWeekBasedOnHistoricWeek = (week) => {
  let currDay = moment().day();
  let startDate, endDate;
  if (currDay === 6) {
    startDate = moment()
      .subtract(week - 1, "weeks")
      .startOf("week")
      .format("YYYY-MM-DD");
    endDate = moment().endOf("week").format("YYYY-MM-DD");
  } else {
    startDate = moment()
      .subtract(week, "weeks")
      .startOf("week")
      .format("YYYY-MM-DD");
    endDate = moment().endOf("week").subtract(7, "days").format("YYYY-MM-DD");
  }
  return { min: startDate, max: endDate };
};

// Works to get end date as today and start date from provided week
// If week is 2 then start date will be current date - no of week's date and end date will be today's date
export const getDatesFromWeek = (week) => {
  let startDate = moment().subtract(week, "weeks").format("YYYY-MM-DD");
  let endDate = moment().format("YYYY-MM-DD");
  return { min: startDate, max: endDate };
};

//adds no. of day to current date
export const getFutureDate = (days) => {
  return moment().add(days, "days").format("YYYY-MM-DD");
};

//adds no. of days provided to provided date
export const getFutureDateFromGivenDate = (
  date,
  days,
  format = "YYYY-MM-DD"
) => {
  return moment(date).add(days, "days").format(format);
};

export const checkDatesInRange = (type, availableDate, selectedDate) => {
  let result = true;
  switch (type) {
    case "start_date":
      if (!moment(selectedDate).isSameOrAfter(availableDate)) result = false;
      break;
    case "end_date":
      if (!moment(selectedDate).isSameOrBefore(availableDate)) result = false;
      break;
    default:
      result = result;
  }
  return result;
};

export const checkDatesAfterGlobalDates = (availableDate, selectedDate) =>
  moment(selectedDate).isSameOrAfter(availableDate);

export const checkIsAfterDate = (start_date, end_date) => {
  return moment(end_date).isAfter(start_date);
};

export const getInBetweenDate = (week, date) => {
  let weekRange = getWeekBasedOnHistoricWeek(week);
  let res = moment(date).isBetween(weekRange.min, weekRange.max);
  return res;
};

export const getMandatoryKPIToTop = (kpi_list, mandatory_kpi) =>
  removeDupFromArray([...orderBy(mandatory_kpi), ...orderBy(kpi_list)]);

export const getDaysDiff = (start_date, end_date, pre_campaign_start_date) => {
  let pre_campaign_end_date = "";
  if (start_date && end_date && pre_campaign_start_date) {
    let daysDiff = moment(end_date, "YYYY-MM-DD").diff(
      moment(start_date, "YYYY-MM-DD"),
      "days"
    );
    pre_campaign_end_date = moment(pre_campaign_start_date)
      .add(daysDiff, "days")
      .format("YYYY-MM-DD");
  }
  return pre_campaign_end_date;
};

export const checkForAllData = (
  active_app,
  saved_config,
  global_params,
  models,
  datasets,
  runData,
  appAccessDetails,
  shared_configurations
) => {
  let flag = true;
  if (saved_config) {
    saved_config.forEach((config) => {
      if (config.app_id.toLowerCase() != active_app?.id.toLowerCase()) {
        flag = false;
      }
    });
  } else {
    return false;
  }
  if (shared_configurations) {
    shared_configurations.forEach((config) => {
      if (config.app_id.toLowerCase() != active_app?.id.toLowerCase()) {
        flag = false;
      }
    });
  } else {
    return false;
  }
  if (!appAccessDetails) {
    return false;
  }
  if (!global_params) {
    return false;
  }
  if (!runData) {
    return false;
  }
  if (models) {
    models.forEach((model) => {
      if (model.app_id.toLowerCase() != active_app?.id.toLowerCase()) {
        flag = false;
      }
    });
  } else {
    return false;
  }
  if (datasets) {
    datasets.forEach((dataset) => {
      if (dataset.app_id.toLowerCase() != active_app?.id.toLowerCase())
        flag = false;
    });
  } else {
    return false;
  }
  return flag;
};

export const transformTestControlDataInArray = (objTest, type) => {
  let arrTest = [];
  objTest = objTest ? objTest : {};
  Object.values(objTest).forEach((i) => {
    arrTest.push({
      id: v4(),
      type,
      data: i,
    });
  });
  return arrTest;
};

export const transformTestControlDataInObj = (arrTest, type) => {
  let objTest = {};
  arrTest.forEach((val, index) => {
    objTest = { ...objTest, [index]: val.data };
  });
  return objTest;
};

export const transformKpi = (dataset, activeDataset, activeModel) => {
  let newState = dataset
    .map((datasetCat) => {
      if (
        (datasetCat.type === config.hardCoded.datasetSalesType ||
          datasetCat.type === config.hardCoded.datasetNeilsenType) &&
        datasetCat.id === activeDataset.id
      ) {
        if (
          datasetCat.type === config.hardCoded.datasetSalesType &&
          datasetCat.id === activeDataset.id
        ) {
          return {
            id: v4(),
            type: "radio",
            kpi_list: activeDataset.kpi_list,
            mandatory_kpi: activeDataset.mandatory_kpi_list,
            default_value: activeDataset.default_value,
            kpi_variables_list: activeDataset.kpi_variables_list,
          };
        } else if (
          datasetCat.type === config.hardCoded.datasetNeilsenType &&
          datasetCat.id === activeDataset.id
        ) {
          return {
            id: v4(),
            type: "multi",
            kpi_list: activeDataset.kpi_list,
            mandatory_kpi: activeDataset.mandatory_kpi_list,
            default_value: activeDataset.default_value,
            kpi_variables_list: activeDataset.kpi_variables_list,
          };
        }
      } else if (
        datasetCat.type === config.hardCoded.datasetMediaType &&
        datasetCat.id === activeDataset.id
      ) {
        return {
          id: v4(),
          type: "multi",
          kpi_list: activeDataset.kpi_list,
          mandatory_kpi: activeDataset.mandatory_kpi_list,
          default_value: activeDataset.default_value,
          kpi_variables_list: activeDataset.kpi_variables_list,
        };
      } else if (datasetCat.type && datasetCat.id === activeDataset.id) {
        return {
          id: v4(),
          type: "multi",
          kpi_list: activeDataset.kpi_list,
          mandatory_kpi: activeDataset.mandatory_kpi_list,
          default_value: activeDataset.default_value,
          kpi_variables_list: activeDataset.kpi_variables_list,
        };
      }
    })
    .filter((item) => item);

  return newState;
};

export const isEqualArray = (arrayVal, otherVal) => {
  return isEqual(arrayVal, otherVal);
};

export const isEqualObject = (objVal, otherVal) => {
  return isEqual(objVal, otherVal);
};

export const differenceInArray = (globalArr, otherVal) => {
  const ids = otherVal.map((o) => o.regions.join(","));
  const filtered = globalArr.filter(
    ({ regions }) => !ids.includes(regions.join(","))
  );
  return filtered;
};

const arrContainsAll = (arr1, arr2) =>
  arr2?.every((arr2Item) => arr1?.includes(arr2Item));

export const arrSameMembers = (arr1, arr2) =>
  arrContainsAll(arr1, arr2) && arrContainsAll(arr2, arr1);

export const addRegionNamePositionToTestControlData = (
  testControlData,
  regionsData
) =>
  testControlData.map((testControlRow) => {
    const currRegionObject = regionsData.find((row) =>
      arrSameMembers(row.regions, testControlRow.regions)
    );
    return {
      ...testControlRow,
      name: currRegionObject.name,
      position: currRegionObject.position,
    };
  });

const convertStringRegionsToArr = (regions) => regions?.[0]?.split(", ") || [];
export const modifyLevelDriverData = (levelDriverData) =>
  levelDriverData.map((row) => ({
    ...row,
    regions: convertStringRegionsToArr(row.regions),
  }));

export const splitToChunks = (array, parts) => {
  let mainArr = [],
    tempArr = [];
  for (let i = 0; i < parts; i++) {
    tempArr = [];
    for (let j = i; j < array.length; j = j + parts) {
      tempArr.push(array[j]);
    }
    mainArr.push({ id: i, data: tempArr });
  }
  return mainArr;
};

export const checkForAccess = (allData = {}, key, accessKey = "write") => {
  // check for specific access else will return true/false
  if (accessKey) {
    return allData[key]?.hasOwnProperty(accessKey);
  } else {
    return allData[key];
  }
};

export const findNameForShareEmail = (usersArray, name) => {
  let userName = "";
  if (usersArray && usersArray.length && name) {
    userName = usersArray.find((item) => item.preferred_username === name).name;
  }
  return userName;
};

export const convertDateForDataSetFormat = (dateString) => {
  let date = dateString.substring(0, 2);
  let month = dateString.substring(2, 4);
  let year = dateString.substring(4);
  let monthString = moment(month, "MM").format("MMMM");
  return monthString + " " + date + ", " + year;
};

export const getInitialsFromEmail = (nameString, separator) => {
  const emailName = nameString.split(separator);
  const fullName = emailName[0].split(".");
  const initials = fullName.shift().charAt(0) + fullName.pop().charAt(0);
  return initials.toUpperCase();
};

export const getInitialsFromEmailFromGlobalUsers = (nameString, separator) => {
  const emailName = nameString.split(separator);
  const fullName = emailName[0].split(".");
  let initials = "--";
  if (fullName && fullName.length) {
    initials = fullName?.shift()?.charAt(0) + fullName?.pop()?.charAt(0);
  }
  return initials.toUpperCase();
};

export const getInitials = (nameString, separator) => {
  const fullName = nameString.split(separator);
  const initials = fullName.shift().charAt(0) + fullName.pop().charAt(0);
  return initials.toUpperCase();
};

export const checkConfigurationCountryAndBrand = (allData, obj) => {
  const { globalParams = [] } = allData || {};
  const countryInfo = globalParams.find(
    (a) => a.id === "country_brand_segment_hierarchy"
  );

  obj.data = obj.data.map((configObj) => {
    const { selections: { config } = {} } = configObj || {};
    const { country, brand } = config || {};

    if (!countryInfo) {
      configObj.openRecordError =
        "Permission denied to access this country (" + country + ")";
    } else {
      const countryObj = (countryInfo.data || []).find(
        (a) => a.name === country && a.labelCat === "country"
      );
      if (!countryObj) {
        configObj.openRecordError =
          "Permission denied to access this country (" + country + ")";
      } else {
        const brandObj = (countryObj.children || []).find(
          (a) => a.name === brand && a.labelCat === "brand"
        );
        if (!brandObj) {
          configObj.openRecordError =
            "Permission denied to access this brand (" + brand + ")";
        }
      }
    }
    return configObj;
  });
  return obj;
};

//download file from given path
export const downloadFile = (path, filename) => {
  // Create a new link
  const anchor = document.createElement("a");
  anchor.href = path;
  anchor.download = filename;
  anchor.target = "_blank";

  // Append to the DOM
  document.body.appendChild(anchor);

  // Trigger `click` event
  anchor.click();

  // Remove element from DOM
  document.body.removeChild(anchor);
};

export const getTriggerTime = () => {
  const maxTime = 23;
  const timeArray = [];
  let time = "";
  for (let index = 0; index <= maxTime; index++) {
    if (index < 10) {
      time = `0${index}`;
    } else {
      time = `${index}`;
    }
    timeArray.push(`${time}:00`);
    for (let min = 15; min <= 45; min += 15) {
      timeArray.push(`${time}:${min}`);
    }
  }
  return timeArray;
};

// current year's end date i.e 31 Dec 20XX
export const getEndDayOfYear = (format = "YYYY-MM-DD") =>
  moment().endOf("year").format(format);

// get current Hour + 1's epoch in H:mm format
export const getCurrentEpochHour = (timeZone) => {
  let getAvailableHour = moment().tz(timeZone).format("H:mm");
  getAvailableHour = getAvailableHour.split(":");
  let getMinutes = +getAvailableHour[1];
  let getHour = +getAvailableHour[0];
  if (getMinutes < 15) {
    getMinutes = "15";
  } else if (getMinutes > 15 && getMinutes < 30) {
    getMinutes = "30";
  } else if (getMinutes < 45 && getMinutes > 30) {
    getMinutes = "45";
  } else if (getMinutes < 60 && getMinutes > 45) {
    getMinutes = "00";
    getHour += 1;
  }
  if (getHour < 10) {
    return `0${getHour}:${getMinutes}`;
  } else {
    return `${getHour}:${getMinutes}`;
  }
};

export const getTodayDate = (timeZone, format = "YYYY-MM-DD") => {
  return moment().tz(timeZone).format(format);
};

export const getPastHrInfo = (date, timeZone = "Asia/Chita") => {
  var dateObject = moment.tz(timeZone).toObject();
  const currentEpochHour = new Date(
    dateObject.years,
    dateObject.months,
    dateObject.date,
    dateObject.hours,
    dateObject.minutes,
    dateObject.minutes,
    dateObject.milliseconds
  ).getTime();
  const getEpochHourFromDate = moment(date, "DD/MM/YYYY H:mm")
    .tz(timeZone)
    .valueOf();
  return moment(currentEpochHour).isBefore(moment(getEpochHourFromDate));
};

export const epochFromTimezone = (timeZone) =>
  moment().tz(timeZone).format("H:mm");

// While stiching between models check SKU data and selections are valid or not
export const checkForValidSKUDatasetParameters = (data, selections) => {
  const validData = data.filter((elem) =>
    selections.some((record) =>
      record?.sku?.some((obj) => obj?.sku === elem?.sku)
    )
  );
  return validData;
};

// Transformation for KPI variables_list (object -> array)
export const transformKpiVariableList = (data) => {
  let result = [];
  if (data) {
    result = Object.entries(data).map((elem) => ({
      id: elem[0],
      kpi_parameters: Object.entries(elem[1]).map((item) => ({
        id: item[0],
        possible_values: item[1].possible_values,
        default: item[1].default,
      })),
    }));
  }
  return result;
};
//sorting data based the files name
export const sortCallBackFunc = (x, y) =>
  x.name.toLowerCase() < y.name.toLowerCase()
    ? -1
    : x.name.toLowerCase() > y.name.toLowerCase()
    ? 1
    : 0;
//arranged the folder structure and sort the folder and files

export function arrangeFolderAndFiles(filteredData) {
  let folder = filteredData.filter((e) => e.data).sort(sortCallBackFunc);
  let files = filteredData.filter((e) => !e.data).sort(sortCallBackFunc);
  return [...folder, ...files];
}

// Import required libraries
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import orderBy from "lodash/orderBy";
// import Select from "@material-ui/core/Select";
// import MenuItem from "@material-ui/core/MenuItem";
import { Badge, Chip, Modal, Popover } from "../../../uiTheme";
// import Input from "@material-ui/core/Input";
// import InputAdornment from "@material-ui/core/InputAdornment";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

// Import custom components
import Loader from "../../../components/Loader/Loader";

// Import styles
import "./ViewRuns.scss";
import {
  populateFiltersData,
  addAllInData,
  makeDefaultResponseJson,
} from "../../../utils/utils";
import { config } from "../../../config/config";

// Import action creators
import {
  updateData,
  updateSelections,
  resetFilters,
  updateUserInfo,
  updateAlertInfo,
} from "../../../redux/actions";

const useStyles = makeStyles({
  chipStyle: {
    // marginTop: "0px",
    fontFamily: "Hind Siliguri",
    fontSize: 14,
    border: "1px solid #dedede",
    color: "white !important",
    height: "30px",
    borderRadius: "3px",
    width: "fit-content",
    maxWidth: "90%",
    margin: "12px",
    background: "#4EAFB3",
    "& .MuiChip-deleteIcon": {
      // width: "16px",
      fontSize: 20,
      border: "none",
      color: "white",
    },
  },
  chipStyling: {
    marginTop: "0px",
    fontFamily: "Hind Siliguri",
    fontSize: "12px",
    border: "1px solid #dedede",
    color: "white !important",
    height: "28px",
    borderRadius: "4px",
    width: "fit-content",
    margin: "0px 5px",
    background: "#4EAFB3",
    "&:focus": {
      background: "#4EAFB3",
    },
    "& .MuiChip-deleteIcon": {
      // width: "20px",
      fontSize: 20,
      border: "none",
      color: "white",
    },
  },
  typographyStyle: {
    backgroundColor: "#f3f3f3",
    //padding: "10px",
    borderRadius: "4px",
    "& .MuiChip-root": {
      fontFamily: "Hind Siliguri",
      fontSize: 12,
      // border: "1px solid #dedede",
      // background: "white",
    },
    "& .MuiInput-underline:after": {
      borderBottom: 0,
    },
    "& .MuiFormControl-fullWidth": {
      minWidth: "250px",
      background: "white",
      border: "1px solid #dedede",
      boxSizing: "border-box",
      borderRadius: "5px",
      padding: "5px 10px",
      height: "30px",
      // maxWidth: "900px",
      // width: "100%",
    },
    "& .MuiInput-underline:before": {
      borderBottom: 0,
      transition: "none",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: "1px solid #dedede !important",
    },
    "& .Mui-focused .MuiInput-underline": {
      borderBottom: 0,
    },
    "& .MuiAutocomplete-paper": {
      fontFamily: "Hind Siliguri !important",
      fontSize: 6,
    },
    "& .MuiInputBase-input": {
      fontFamily: "Hind Siliguri",
      fontSize: 12,
    },
    "& .MuiAutocomplete-option": {
      backgrounColor: "#46596a3b",
      fontFamily: "Hind Siliguri !important",
    },
  },
  bellIconStyle: {
    "& .MuiBadge-dot": {
      backgroundColor: "#F10000 !important",
    },
    "& .MuiBadge-anchorOriginTopRightRectangle": {
      top: "2px",
      right: "-3px",
    },
  },
  multiSelect: {
    fontSize: "12px",
    fontFamily: "Hind Siliguri",
    width: "160px",
    height: "45px",
    borderRadius: "5px",
    color: "#46596a",
    backgroundColor: "white",
    boxSizing: "border-box",
    //paddingLeft: "8px",
    border: "1px solid #dedede",
    "&:before": {
      borderBottom: "0px",
    },
    "&:after": {
      borderBottom: "0px",
    },
    "&:hover:not(.Mui-disabled):before": {
      borderBottom: "0px",
    },
    "& .MuiOutlinedInput-input": {
      padding: "6px 6px",
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "transparent",
    },
    "& .MuiSelect-select.MuiSelect-select": {
      paddingLeft: "8px",
    },
    "& .MuiSelect-select:hover": {
      backgroundColor: "#4EAFB3",
    },
  },
  singleMenuItem: {
    fontSize: "12px",
    fontFamily: "Hind Siliguri",
    padding: "4px 12px",
    color: "#46596a",
    width: "auto",
    "&:hover": {
      backgroundColor: "#4EAFB3",
    },
  },
  paper: {
    position: "absolute",
    width: "50%",
    minHeight: "200px",
    backgroundColor: "#FFFFFF",
    // border: "2px solid #000",
    borderRadius: "8px",
    padding: "30px",
  },
});

const StyledAutoComplete = withStyles((theme) => ({
  endAdornment: {
    display: "none",
  },
  tag: {
    // background: "white",
    color: "#46596a !important",
    fontFamily: "Hind Siliguri !important",
  },
  paper: {
    fontFamily: "Hind Siliguri",
    fontSize: 13,
    color: "#46596a",
  },
}))(Autocomplete);

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 150,
      width: "auto",
    },
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  variant: "menu",
  getContentAnchorEl: null,
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
};
function getModalStyle() {
  const top = 35;
  const left = 30;
  const width = 100;
  return {
    top: `${top}%`,
    left: `${left}%`,
    width: `${width}%`,
    border: "none !important",
    // transform: `translate(-${top}%, -${left}%)`,
  };
}

function ViewRunFiltersNew(props) {
  let {
    user,
    allData,
    activeApp,
    country,
    brand,
    segment,
    updateData,
    updateUserInfo,
    updateSelections,
    resetFilters,
    updateAlertInfo,
  } = props;
  const initialFilters = { viewRunsTags: [] };
  const [filterSelections, setFilterSelections] = useState(initialFilters);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [modalStyle] = useState(getModalStyle);
  const classes = useStyles();

  // Get tags
  useEffect(() => {
    const url = `${config.api.getTagsUrl}?app_id=${activeApp?.id}&type=runs`;
    let status;
    fetch(url)
      .then((response) => {
        console.groupCollapsed("requesting", url);
        console.log("REPSONSE -> ", response);
        status = response.status;
        return response.clone().json();
      })
      .then((json) => {
        console.log("JSON -> ", json);
        console.groupEnd();
        if (status === 200) {
          updateData("tagsData", {
            status: "success",
            data: orderBy([...json.data.tags]),
            message: "",
          });
        } else {
          updateAlertInfo({
            open: true,
            message: json.statusMessage,
            severity: "error",
          });
        }
      });
    return () => {};
  }, [allData.apps.data]);

  const modalOpen = () => {
    setOpenModal(true);
  };

  const modalClose = () => {
    setOpenModal(false);
  };

  const openPopup = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const updatingSelections = (catName, trgtValue) => {
    let filterselection = filterSelections;
    filterselection[`${catName}`] = trgtValue;
    setFilterSelections({ ...filterselection });
  };

  const setFilterUpdations = () => {
    updateSelections("runFilters", {
      ...user.selections.runFilters,
      viewRunsTags: filterSelections.viewRunsTags,
    });
    modalClose();
  };

  const resetFiltersNew = () => {
    setFilterSelections({ viewRunsTags: [] });
    updateSelections("runFilters", {
      ...user.selections.runFilters,
      viewRunsTags: [],
    });
    modalClose();
  };

  const handleChange = (e, newValue) => {
    setFilterSelections({
      ...filterSelections,
      viewRunsTags: [...newValue],
    });
  };

  const delValue = (regValue) => {
    // delete
    let valArr = filterSelections.viewRunsTags.filter(
      (singleValue) => singleValue !== regValue
    );
    setFilterSelections({ ...filterSelections, viewRunsTags: valArr });
  };

  return (
    <>
      <div className="aide-runs-filter-new">
        <div className="right-filter-div">
          <div className="filter-btn">
            <Badge
              variant="dot"
              invisible={filterSelections.viewRunsTags.length === 0}
              className={classes.bellIconStyle}
            >
              <button onClick={modalOpen}>
                Filters
                <i
                  onMouseOver={openPopup}
                  className="material-icons filter-alt-icon"
                >
                  filter_alt
                </i>
              </button>
            </Badge>
            {allData?.tagsData?.status === "success" ? (
              <Modal
                open={openModal}
                onClose={modalClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
              >
                <div style={style} className={classes.paper}>
                  <p id="simple-modal-title" className="aide-filters-title">
                    Filters
                  </p>
                  <>
                    {allData?.tagsData?.status === "success" ? (
                      <div className={`${user.theme} aide-filters-container`}>
                        <div className="config-tags-filter">
                          <label>Select Tags</label>
                          <div className={classes.root}>
                            <StyledAutoComplete
                              multiple
                              id={`tags-standard`}
                              size="small"
                              style={{ fontFamily: "Hind Siliguri !important" }}
                              limitTags={5}
                              renderTags={() => null}
                              disableClearable
                              filterSelectedOptions
                              className={classes.typographyStyle}
                              options={allData.tagsData.data}
                              getOptionLabel={(option) => option || ""}
                              value={filterSelections.viewRunsTags}
                              onChange={(e, value, reason) => {
                                if (reason !== "remove-option") {
                                  handleChange(e, value);
                                }
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  //InputProps={{ style: { fontFamily:'Hind Siliguri' } }}
                                  variant="standard"
                                  size="small"
                                  placeholder="Type a tag"
                                  className={classes.typographyStyle}
                                />
                              )}
                            />
                            <div className="tags-chips-container">
                              {filterSelections.viewRunsTags.map((reg) => (
                                <Chip
                                  key={reg}
                                  label={reg}
                                  onDelete={() => delValue(reg)}
                                  // className={classes.chipStyling}
                                  deleteIcon={
                                    <i className="material-icons-outlined">
                                      clear
                                    </i>
                                  }
                                  style={{ margin: "5px", height: "30px" }}
                                />
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <Loader />
                    )}
                  </>
                  <div className="aide-filters-menu">
                    <button
                      className="reset-filter-btn"
                      onClick={() => {
                        resetFiltersNew();
                      }}
                    >
                      Reset Filters
                    </button>
                    <button
                      className="apply-filter-btn"
                      onClick={setFilterUpdations}
                    >
                      Apply
                    </button>
                  </div>
                </div>
              </Modal>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      {
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          PaperProps={{
            style: { width: "20%" },
          }}
        >
          <div className="aide-filter-popover">
            <div className="row filter-popover-label">Tags</div>
            <div className="row">
              {filterSelections.viewRunsTags.length ? (
                filterSelections.viewRunsTags.map((elem) => (
                  <Chip
                    key={elem}
                    label={elem}
                    // className={classes.chipStyle}
                    onDelete={() => {
                      delValue(elem);
                      let valArr = filterSelections.viewRunsTags.filter(
                        (singleValue) => singleValue !== elem
                      );
                      updateSelections("runFilters", {
                        ...user.selections.runFilters,
                        viewRunsTags: valArr,
                      });
                    }}
                    deleteIcon={
                      <i className="material-icons-outlined">clear</i>
                    }
                    style={{ margin: "5px", height: "30px" }}
                  />
                ))
              ) : (
                <div className="no-selections">No Selections Available</div>
              )}
            </div>
          </div>
        </Popover>
      }
    </>
  );
}

ViewRunFiltersNew.propTypes = {
  match: PropTypes.object,
  user: PropTypes.object,
  country: PropTypes.array,
  brand: PropTypes.array,
  segment: PropTypes.array,
  updateData: PropTypes.func,
  updateSelections: PropTypes.func,
};

const mapStateToProps = (state) => ({
  user: state.user,
  country: state.data.country,
  brand: state.data.brand,
  segment: state.data.segment,
  allData: state.data,
});

const mapDispatchToProps = {
  updateData,
  updateSelections, //actions are being dispatched using these functions,
  resetFilters,
  updateUserInfo,
  updateAlertInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewRunFiltersNew);

// Import required libraries
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// Import custom components
import RbLogo from "../RBLogo/RbLogo";
import Notifications from "../Notifications/Notifications";
import AllApps from "../AllApps/AllApps";
import UserInfo from "../UserInfo/UserInfo";

// Import styles
import "./Header.scss";

// Import config

function Header(props) {
  let { user, match, isAuthorized } = props;

  return (
    <>
      <header className={user.theme}>
        <RbLogo match={match} isAuthorized={isAuthorized} />
        <section className="header-menu">
          {isAuthorized && <Notifications />}
          {isAuthorized && <AllApps match={match} />}
          <UserInfo {...props} isAuthorized={isAuthorized} />
        </section>
      </header>
    </>
  );
}

Header.propTypes = {
  user: PropTypes.object,
  match: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Header);

import React from "react";
import PropTypes from "prop-types";
import Badge from "@mui/material/Badge";
import { makeStyles } from "@material-ui/core/styles";
import "../index.css";

/**
 * [SimpleBadge description]
 * @param {[string]} theme [The theme of the component - default: light]
 * @param {[string]} styleVariant [Used to represent colored interface elements for a user - default: primary]
 * @param {[string]} variant [The variant to use - default: dot]
 * @param {[node]} children [The content of the component.]
 * @param {[any]} ...props [Other valid props for MUI Badge]
 * @returns {[object]} [Returns the badge component]
 */
const SimpleBadge = (props) => {
  const {
    theme = "light",
    styleVariant = "primary",
    variant = "dot",
    children,
    ...remaining
  } = props;

  const useStyles = makeStyles({
    bellIconStyle: {
      "& .MuiBadge-dot": {
        backgroundColor: `var(--${styleVariant}-badge-bgcolor-${theme}) !important`,
      },
      "& .MuiBadge-anchorOriginTopRightRectangle": {
        top: "2px",
        right: "-3px",
      },
    },
  });
  const classes = useStyles();

  return (
    <Badge variant={variant} className={classes.bellIconStyle} {...remaining}>
      {children}
    </Badge>
  );
};

SimpleBadge.propTypes = {
  children: PropTypes.node,
  theme: PropTypes.oneOf(["light", "dark"]),
  styleVariant: PropTypes.oneOf(["primary", "secondary"]),
  variant: PropTypes.oneOf(["dot"]),
};

export default SimpleBadge;

import {
  Step,
  StepButton,
  stepConnectorClasses,
  Stepper,
  StepConnector,
  Box,
} from "@mui/material";
import { useState } from "react";
import { config } from "../../../../config/config";
import LocalParameterItem from "./LocalParameterItem";
import noDataImage from "../../../../../assets/images/undraw_empty.svg";
import { useEffect } from "react";
import { useNonInitialEffect } from "../../../../hooks/useNonInitialEffect";
import UserAccessDetails from "../../../../../assets/data/UserAccessDetails.json";
import { checkForAccess } from "../../../../utils/utils";
import { styled } from "@mui/material/styles";
import { makeStyles, MenuItem, Select } from "@material-ui/core";
import { MenuProps, muiCommonStyles } from "../../../../../styles/styles";
import { toggleDatasetInCommunity } from "../../../../redux/actions";
import { connect } from "react-redux";

const StyledConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.root}`]: {
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 24,
      left: "calc(-50% + 16px)",
      right: "calc(50% + 16px)",
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: "#eb367f",
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: "#eb367f",
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#eaeaf0",
      borderTopWidth: 1,
      borderRadius: 1,
    },
  },
}));

const datasetSelectStyle = makeStyles(() => ({
  singleSelect: {
    fontSize: "12px",
    fontFamily: "Hind Siliguri",
    width: "382px !important",
    height: "30px",
    borderRadius: "5px",
    color: "#46596a",
    backgroundColor: "white",
    boxSizing: "border-box",
    //paddingLeft: "8px",
    display: "flex",
    alignItems: "center",
    //textAlign: "center",
    border: "1px solid #dedede",
    "&:before": {
      borderBottom: "0px",
    },
    "&:after": {
      borderBottom: "0px",
    },
    "&:hover:not(.Mui-disabled):before": {
      borderBottom: "0px",
    },
    "& .MuiOutlinedInput-input": {
      padding: "6px 6px",
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "transparent",
    },
    "& .MuiSelect-select.MuiSelect-select": {
      paddingLeft: "8px",
      // height: "25px",
    },
  },
}));

function StepperForModelConfiguration(props) {
  const {
    activeModelId,
    match,
    user,
    activeModel,
    selectedModel,
    configUserInputs,
    updateModelLocalParameters,
    disableFlag,
    changeFlag,
    activeSelection,
    loading,
    activeApp,
    updateUserInfo,
    allData,
    updateCheckDE,
    nestedRunsArray,
    activeStep,
    stepCompleted,
    setStepCompleted,
    setActiveStep,
    onStepperChange,
    toggleDatasetInCommunity,
    validateStepper,
  } = props;
  let localParameters = activeModel?.local_parameters;

  // to show the active step according to user selection
  useEffect(() => {
    if (activeModelId === config.hardCoded.communityClusteringId) {
      if (
        selectedModel.config.community_clustering_run_type ===
        config.hardCoded.initialRunId
      ) {
        setActiveStep(0);
        setStepCompleted({ 0: true });
      } else if (
        selectedModel.config.community_clustering_run_type ===
        config.hardCoded.targetRunId
      ) {
        setActiveStep(1);
        setStepCompleted({ 0: true, 1: true });
      } else {
        setActiveStep(2);
        setStepCompleted({ 0: true, 1: true, 2: true });
      }
    } else {
      if (
        selectedModel.config.propensity_modeling_run_type ===
        config.hardCoded.initialRunId
      ) {
        setActiveStep(0);
        setStepCompleted({ 0: true });
      } else if (
        selectedModel.config.propensity_modeling_run_type ===
        config.hardCoded.targetRunId
      ) {
        setActiveStep(1);
        setStepCompleted({ 0: true, 1: true });
      } else {
        setActiveStep(2);
        setStepCompleted({ 0: true, 1: true, 2: true });
      }
    }
  }, []);
  //   for type of run radio buttons

  useNonInitialEffect(() => {
    setActiveStep(0);
    setStepCompleted({});
    let data = {
      modelId: activeModelId,
      key:
        activeModelId === config.hardCoded.communityClusteringId
          ? "community_clustering_run_type"
          : "propensity_modeling_run_type",
      value: "initial",
    };
    updateModelLocalParameters(data);
  }, [
    configUserInputs.is_schedule_config,
    configUserInputs.is_fixed_schedule_config,
    activeModel.id,
  ]);
  // data Tempering
  let data =
    //  nestedRunsArray[0].data;
    configUserInputs.configType !== "run" &&
    selectedModel.id === config.hardCoded.propensityId &&
    typeof selectedModel.config.upload_audience === "undefined" &&
    selectedModel.config.propensity_modeling_run_type !== "upload_audience"
      ? nestedRunsArray[0]?.data.filter((el) => el.id !== "upload_audience")
      : selectedModel.id === config.hardCoded.propensityId &&
        typeof selectedModel.config.upload_audience === "undefined"
      ? nestedRunsArray[0]?.data.filter((el) => el.id !== "upload_audience")
      : nestedRunsArray[0]?.data;
  let runTypeData = data;
  if (configUserInputs?.configType === "add") {
    runTypeData?.map((obj) => {
      if (
        (obj.id === "target" || obj.id === "upload_audience") &&
        !configUserInputs?.is_schedule_config
      ) {
        obj.disabled = true;
      } else {
        obj.disabled = false;
      }
    });
  } else {
    runTypeData?.map((obj) => {
      obj.disabled = false;
    });
  }
  const getModelConfigId =
    UserAccessDetails[activeModel.id].settings["upload_audience"].id;
  //Checking if user has write access then show in UI
  const displayModelConfigFlag = checkForAccess(
    allData.appAccessDetails.feature_list,
    getModelConfigId,
    "write"
  );
  if (
    !displayModelConfigFlag ||
    typeof displayModelConfigFlag === "undefined"
  ) {
    runTypeData = data?.filter(
      (singleObj) => singleObj.id !== "upload_audience"
    );
  }
  const useStyles = makeStyles((theme) => ({
    stepper: {
      display: "flex",
      justifyContent: "center",
      "& .MuiStepper-root": { width: "50%" },
      "& .MuiStepLabel-alternativeLabel": {
        marginTop: "0px !important",
        marginBottom: "5px !important",
        flexDirection: "column-reverse !important",
        fontFamily: "Hind Siliguri",
        fontSize: "13px",
        // whiteSpace: "nowrap",
      },
      "& .Mui-completed": {
        color: "#eb367f !important",
      },
      "& .Mui-active": {
        color: "#eb367f !important",
      },
      "& .MuiStepIcon-root": {
        height: "18px",
        width: "18px",
      },
      "& .MuiStepIcon-root.Mui-active": {
        // background: "transparent !important",
        // color: "transparent !important",
        // border: "3px solid #eb367f",
        border: "1px solid",
        borderRadius: "50%",
        height: "17px",
        width: "17px",
        padding: "1px",
      },
      "& .MuiStepIcon-text": {
        display: "none",
      },
    },
  }));
  const classes = useStyles();
  const muiClass = muiCommonStyles();
  const singleSelect = datasetSelectStyle();
  let datasetName = allData.datasets.filter((dataset) =>
    configUserInputs.old_pm ? dataset.id === config.hardCoded.ga360 : dataset
  );
  if (configUserInputs?.cmu_status?.data?.datasets?.length) {
    datasetName = datasetName.filter((dataset) => {
      const filteredDataset = configUserInputs?.cmu_status?.data?.datasets;
      return configUserInputs.old_pm
        ? dataset.id === config.hardCoded.ga360
        : filteredDataset?.includes(dataset.id);
    });
  }

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 150,
        width: "auto",
      },
    },
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "left",
    },
    variant: "menu",
    getContentAnchorEl: null,
  };
  // to disable the steps for view runs
  if (configUserInputs.configType === "run") {
    runTypeData = runTypeData.map((data) =>
      data.id === selectedModel.config.community_clustering_run_type
        ? { ...data, disabled: false }
        : { ...data, disabled: true }
    );
  }

  // if there is an on demand config with run type initial
  // then the third step "UA" will be disabled means user can not jump from initial to UA
  if (
    (selectedModel.config.community_clustering_run_type ||
      selectedModel.config.propensity_modeling_run_type) ===
      config.hardCoded.initialRunId &&
    !configUserInputs.is_schedule_config &&
    !configUserInputs.is_fixed_schedule_config
  ) {
    runTypeData = runTypeData.map((data) => {
      if (data.id === config.hardCoded.uploadAudienceRunId) {
        return {
          ...data,
          disabled: true,
        };
      } else {
        return data;
      }
    });
  }
  return (
    <>
      <Box
        className={`${classes.stepper} ${
          configUserInputs.is_schedule_config ||
          configUserInputs.is_fixed_schedule_config
            ? "disable-pointer"
            : ""
        }`}
        style={{
          pointerEvents: `${
            configUserInputs.is_schedule_config ||
            configUserInputs.is_fixed_schedule_config
              ? "none"
              : ""
          }`,
        }}
        sx={{
          marginTop: "25px",
          marginBottom: "10px",
        }}
      >
        <Stepper
          nonLinear
          activeStep={activeStep}
          alternativeLabel
          connector={<StyledConnector />}
        >
          {runTypeData.map((item, index) => (
            <Step
              key={item.name}
              completed={stepCompleted[index]}
              disabled={item.disabled}
              style={{ cursor: "auto" }}
            >
              <StepButton
                color="inherit"
                onClick={() => {
                  let checkValidationTrigger = true;

                  if (
                    selectedModel.id ===
                      config.hardCoded.communityClusteringId &&
                    selectedModel.config.community_clustering_run_type ===
                      config.hardCoded.targetRunId &&
                    item.id === config.hardCoded.initialRunId
                  ) {
                    checkValidationTrigger = false;
                  } else if (
                    selectedModel.id ===
                      config.hardCoded.communityClusteringId &&
                    selectedModel.config.community_clustering_run_type ===
                      config.hardCoded.uploadAudienceRunId &&
                    (item.id === config.hardCoded.targetRunId ||
                      item.id === config.hardCoded.initialRunId)
                  ) {
                    checkValidationTrigger = false;
                  }

                  if (
                    selectedModel.id === config.hardCoded.propensityId &&
                    selectedModel.config.propensity_modeling_run_type ===
                      config.hardCoded.targetRunId &&
                    item.id === config.hardCoded.initialRunId
                  ) {
                    checkValidationTrigger = false;
                  } else if (
                    selectedModel.id === config.hardCoded.propensityId &&
                    selectedModel.config.propensity_modeling_run_type ===
                      config.hardCoded.uploadAudienceRunId &&
                    (item.id === config.hardCoded.targetRunId ||
                      item.id === config.hardCoded.initialRunId)
                  ) {
                    checkValidationTrigger = false;
                  }

                  if (
                    !configUserInputs.is_schedule_config &&
                    !configUserInputs.is_fixed_schedule_config
                  ) {
                    if (
                      configUserInputs.configType !== "run" &&
                      checkValidationTrigger
                    ) {
                      const validate = validateStepper(
                        configUserInputs,
                        activeModelId
                      );
                      if (validate) {
                        setStepCompleted({
                          ...stepCompleted,
                          [activeStep]: true,
                        });
                        setActiveStep(index);
                        onStepperChange(item.id);
                      } else {
                        setStepCompleted({
                          ...stepCompleted,
                          [activeStep]: false,
                        });
                      }
                    } else if (
                      configUserInputs.configType !== "run" &&
                      !checkValidationTrigger
                    ) {
                      setActiveStep(index);
                      onStepperChange(item.id);
                    } else {
                      setStepCompleted({
                        ...stepCompleted,
                        [activeStep]: true,
                      });
                      setActiveStep(index);
                      onStepperChange(item.id);
                    }
                  }
                }}
              >
                {item.name}
              </StepButton>
            </Step>
          ))}
        </Stepper>
      </Box>
      <div className="models-selections-column right">
        {
          // activeSelection === "model config" &&
          <div
            className={`models-selection-content padding-right-12 right ${
              activeModelId === config.hardCoded.communityClusteringId &&
              (selectedModel?.config?.community_clustering_run_type ===
                config.hardCoded.targetRunId ||
                selectedModel?.config?.community_clustering_run_type ===
                  config.hardCoded.uploadAudienceRunId)
                ? "community-target"
                : ""
            } ${localParameters?.length ? "" : "no-modelconfig-container"}  `}
            style={{
              display: `${
                activeModelId === config.hardCoded.communityClusteringId &&
                selectedModel?.config?.community_clustering_run_type ===
                  config.hardCoded.initialRunId
                  ? "flex"
                  : "block"
              }`,
            }}
          >
            {(activeModelId === config.hardCoded.propensityId
              ? selectedModel.config.propensity_modeling_run_type ===
                config.hardCoded.initialRunId
              : selectedModel.config.community_clustering_run_type ===
                config.hardCoded.initialRunId) && (
              <div className="input-sub-container">
                <div
                  className={`input-field-sub-container ${
                    configUserInputs.configType === "run"
                      ? "disabled-pointer"
                      : ""
                  }`}
                  style={{ display: "block" }}
                >
                  <label
                    style={{
                      display: "inline-block",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Select Dataset:
                  </label>
                  <div className="input-text-box">
                    <Select
                      name="scoring-frequency"
                      id="scoring-frequency"
                      value={selectedModel?.data_sources[0]?.name || ""}
                      onChange={(e) => {
                        const data = allData.datasets.find(
                          (dataset) => dataset.name === e.target.value
                        );
                        toggleDatasetInCommunity({
                          ...data,
                          modelId: activeModelId,
                        });
                      }}
                      className={singleSelect.singleSelect}
                      MenuProps={MenuProps}
                    >
                      <MenuItem
                        disabled
                        value=""
                        className={muiClass.singleMenuItem}
                      >
                        Select option
                      </MenuItem>
                      {datasetName.map((obj) => (
                        <MenuItem
                          value={obj.id}
                          key={obj.id}
                          className={muiClass.singleMenuItem}
                        >
                          {obj.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                </div>
              </div>
            )}
            {localParameters?.length ? (
              localParameters
                ?.filter(
                  (obj) => !config.hardCoded.mulipleRunType.includes(obj.id)
                )
                .map((obj) => {
                  let value = selectedModel.config[obj.id];
                  let onChange = (key, value) => {
                    let data = {
                      modelId: activeModelId,
                      key,
                      value,
                    };
                    updateModelLocalParameters(data);
                  };
                  return (
                    <LocalParameterItem
                      key={obj.id}
                      {...obj}
                      value={value}
                      onChange={onChange}
                      userInputs={configUserInputs}
                      allCampaigns={configUserInputs.campaignsData}
                      userSelectedCampaigns={value}
                      activeModel={activeModel}
                      disableFlag={disableFlag}
                      match={match}
                      changeFlag={changeFlag}
                      loading={loading}
                      activeApp={activeApp}
                      userData={allData}
                      updateCheckDE={updateCheckDE}
                      selectedModel={selectedModel}
                    />
                  );
                })
            ) : (
              <div className="no-info-holder">
                <img src={noDataImage} />
                <p>{config.messages.noConfigs}</p>
              </div>
            )}
          </div>
        }
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  changeFlag: state.changeFlag,
  configUserInputs: state.configUserInputs,
  models: state.configUserInputs.models,
});

const mapDispatchToProps = {
  toggleDatasetInCommunity,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StepperForModelConfiguration);

import React from "react";
import PropTypes from "prop-types";

import UserList from "./user/UserList";
import LayoutTopSideBottom from "../../layouts/LayoutTopSideBottom/LayoutTopSideBottom";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";

function EntityList(props) {
  const { match } = props;

  return (
    <LayoutTopSideBottom match={match}>
      <Breadcrumbs match={match} />
      <UserList match={match} />
    </LayoutTopSideBottom>
  );
}

EntityList.propTypes = {
  match: PropTypes.object,
};

export default EntityList;

//Import required libraies
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

//Import custom components
import Loader from "../../../../components/Loader/Loader";
import LocalParameterItem from "./LocalParameterItem";

//Import utils
import { config } from "../../../../config/config";
import {
  makeDefaultResponseJson,
  addRunNameToRunData,
  getStartEndByDayCat,
} from "../../../../utils/utils";
import dayCat from "../../../../../assets/data/dayCat.json";

//Import actions
import InterfaceName from "./InterfaceName";
import {
  updateAlertInfo,
  updateConfigUserInputs,
  updateUserInfo,
} from "../../../../redux/actions";
import UploadAudienceCheckbox from "./UploadAudienceCheckbox";

//Import styles
//import "./TargetPropensity.scss";

const initialTargetData = {
  runs: { status: "loading", message: "", data: [] },
  audience_name: { status: "loading", message: "", data: "" },
};

let initialSelections = {
  run_id: "",
  audience_name: "",
  propensity_modeling_run_type: "target",
  config_id: "",
  dayCat: "all",
};

function TargetPropensity(props) {
  let {
    match,
    onChange,
    userInputs,
    updateUserInfo,
    disableFlag,
    activeModel,
    updateConfigUserInputs,
    userData,
  } = props;
  let initialSelectionsFromConfigUserInputs = userInputs.models.find(
    (model) => model.id === config.hardCoded.propensityId
  );
  const [loading, setLoading] = useState(true);
  const [targetData, setTargetData] = useState(initialTargetData);
  const [selections, setSelections] = useState({
    ...initialSelections,
    ...initialSelectionsFromConfigUserInputs.config,
    dayCat: initialSelections.dayCat,
    configId: userInputs.configId,
  });

  //Defining required variables
  const runIdFinal = targetData.runs.data.length
    ? selections["run_id"]
    : config.messages.noRuns;
  const audienceNameFinal =
    targetData.audience_name.data &&
    targetData.audience_name.status === "success"
      ? targetData.audience_name.data
      : config.messages.noNames;
  const runsDataFinal = targetData.runs.data.length
    ? targetData.runs.data
    : [
        {
          id: config.messages.noRuns,
          name: config.messages.noRuns,
        },
      ];
  const errorFlagFinal =
    targetData.runs.status === "error" ||
    targetData.audience_name.status === "error";
  const errorMessageFinal =
    targetData.runs.status === "error"
      ? targetData.runs.message
      : targetData.audience_name.status === "error"
      ? targetData.audience_name.message
      : "";
  const dataForInterfaceName = activeModel.local_parameters.find(
    (item) => item.id === "interface_name"
  );
  // console.groupCollapsed("--------------Prop STATE CHANGED--------------");
  // console.log("USER INPUTS -> ", userInputs);
  // console.log("PROPS -> ", props);
  // console.log("TARGET RUNS -> ", targetData);
  // console.log("SELECTIONS -> ", selections);
  // console.groupEnd();

  //updates user selections
  const onChangeCurr = (key, value) => {
    if (key !== "interface_name") {
      setSelections({ ...selections, [key]: value });
    }
    onChange(key, value);
  };

  //updated data coming from api
  const updateTargetData = (key, value) => {
    setTargetData((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  //Changing loading to false when component mounts
  useEffect(() => {
    setLoading(false);
  }, []);

  //Runs
  useEffect(() => {
    let url = `${config.api.runUrl}`;
    let body = {
      app_id: userInputs.app_id,
      country: userInputs.country,
      brand: userInputs.brand.toUpperCase(),
      segment:
        userInputs.segment.toString() === "ALL" ? null : userInputs.segment,
      search_text: selections.configId,
      offset: 0,
      limit: 100,
      order_by: "created_on",
      category: ["Success"],
      models: [config.hardCoded.propensityId],
      run_type_key: config.hardCoded.propensityRynKey, //key to get initial run details
      run_type_value: config.hardCoded.initialRunId, //key to get initial run details
      ...getStartEndByDayCat(disableFlag ? "" : selections.dayCat),
      is_shared: true,
    };
    let status;
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        console.groupCollapsed("requesting", url);
        console.log("REPSONSE -> ", response);
        status = response.status;
        return response.clone().json();
      })
      .then((json) => {
        console.log("JSON -> ", json);
        console.groupEnd();
        if (status === 200) {
          let newRunData = {
            status: "success",
            message: "",
            data: addRunNameToRunData(json.data),
          };
          updateTargetData("runs", newRunData);
          updateConfigUserInputs("histogramRuns", json.data);
        } else {
          let newRunData = {
            status: "error",
            message: json.statusMessage,
            data: [],
          };
          updateTargetData("runs", newRunData);
          updateConfigUserInputs("histogramRuns", []);
        }
      });
    return () => {
      updateTargetData("runs", { status: "loading", message: "", data: [] });
    };
  }, [selections.dayCat]);

  //Update run_id if runs changes
  useEffect(() => {
    if (targetData.runs.status === "success") {
      let run_id = selections.run_id;
      let isTargetRunIdValid = true;
      if (targetData.runs.data.length) {
        let runIds = targetData.runs.data.map((run) => run.id);
        if (!runIds.includes(selections.run_id)) {
          run_id = targetData.runs.data[0].id;
          isTargetRunIdValid = false;
        }
      } else {
        run_id = "";
        isTargetRunIdValid = false;
      }
      if (!isTargetRunIdValid) {
        onChangeCurr("run_id", run_id);
      }
    }
  }, [targetData.runs]);

  //Output Name
  useEffect(() => {
    if (targetData.runs.status === "success") {
      if (targetData.runs.data.length && selections?.run_id) {
        let url = `${config.api.audienceNameTargetRunUrl}`;
        let body = {
          run_id: selections.run_id,
          //country_code: userInputs.country,
        };
        let initalRunsArray = targetData.runs.data.map((run) => run.id);
        if (!initalRunsArray.includes(selections.run_id)) {
          return;
        }
        let newRunDataLoading = {
          status: "loading",
          message: "",
          data: "",
        };
        let status;
        updateTargetData("audience_name", newRunDataLoading);
        fetch(url, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        })
          .then((response) => {
            console.groupCollapsed("requesting", url);
            console.log("REPSONSE -> ", response);
            status = response.status;
            return response.clone().json();
          })
          .then((json) => {
            console.log("JSON -> ", json);
            console.groupEnd();
            if (status === 200) {
              let newRunData = {
                status: "success",
                message: "",
                data: json.data[0],
              };
              updateTargetData("audience_name", newRunData);
              onChange("audience_name", json.data[0]);
            } else {
              let newRunData = {
                status: "error",
                message: json.statusMessage,
                data: [],
              };
              updateTargetData("audience_name", newRunData);
              updateAlertInfo({
                open: true,
                message: json.statusMessage,
                severity: "success",
              });
            }
          });
      } else {
        let newRunData = {
          status: "success",
          message: "",
          data: "",
        };
        updateTargetData("audience_name", newRunData);
      }
    }
  }, [targetData.runs, selections.run_id]);

  return (
    <div
      className={`propensity-target-runs-conatiner ${
        userInputs.configType === "run" ? "disabled-pointer" : ""
      }`}
    >
      {targetData.runs.status === "loading" && <Loader />}
      {targetData.runs.status === "success" && (
        <>
          <LocalParameterItem
            ui_element_type="dropdown"
            value={selections["dayCat"]}
            onChange={onChangeCurr}
            enable={true}
            show={true}
            name="Select Runs For"
            id="dayCat"
            data={dayCat}
            userInputs={userInputs}
            activeModel={activeModel}
            style={{ marginLeft: "10px" }}
          />
          <LocalParameterItem
            ui_element_type="dropdown"
            value={runIdFinal}
            onChange={onChangeCurr}
            enable={targetData.runs.data.length ? true : false}
            show={true}
            name="Select Initial Run:"
            id="run_id"
            data={runsDataFinal}
            userInputs={userInputs}
            activeModel={activeModel}
          />
        </>
      )}
      {targetData.audience_name.status === "loading" && <Loader />}
      {targetData.audience_name.status === "success" && (
        <LocalParameterItem
          ui_element_type="inputbox_text"
          value={audienceNameFinal}
          onChange={onChangeCurr}
          enable={false}
          show={true}
          name="Output Name"
          id="audience_name"
          userInputs={userInputs}
          activeModel={activeModel}
          // style={{ width: "70% !important" }}
        />
      )}
      <InterfaceName
        value={initialSelectionsFromConfigUserInputs?.config?.interface_name}
        data={dataForInterfaceName.data}
        onChange={onChangeCurr}
        enable={true}
        show={true}
        name={dataForInterfaceName.name}
        id={dataForInterfaceName.id}
        userInputs={userInputs}
        activeModel={activeModel}
        className={"datasets-selections-local-parameters-row"}
        selectedModel={initialSelectionsFromConfigUserInputs}
      />
      <UploadAudienceCheckbox
        configUserInputs={userInputs}
        selectedModel={initialSelectionsFromConfigUserInputs}
        // propensity={true}
        uaAccess={
          userData.appAccessDetails.feature_list
            .propensity_modelling_audience_data.write
        }
      />
      {errorFlagFinal && <p className="info-message">{errorMessageFinal}</p>}
    </div>
  );
}

TargetPropensity.propTypes = {};

const mapStateToProps = (state) => ({
  user: state.user,
  userData: state.data,
});

const mapDispatchToProps = { updateUserInfo, updateConfigUserInputs };

export default connect(mapStateToProps, mapDispatchToProps)(TargetPropensity);

import React from "react";
import PropTypes from "prop-types";
import Modal from "@mui/material/Modal";
import "../index.css";

function getModalStyle(theme, styleVariant) {
  return {
    fontFamily: "var(--fontFamily)",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: `var(--${styleVariant}-modal-bgcolor-${theme})`,
    color: `var(--${styleVariant}-modal-textcolor-${theme})`,
    border: "none !important",
    borderRadius: "4px",
    padding: "30px",
  };
}

/**
 * [SimpleModal description]
 * @param {[string]} theme [The theme of the component - default: light]
 * @param {[string]} styleVariant [Used to represent colored interface elements for a user - default: primary]
 * @param {[node]} children [The content of the component.]
 * @param {[bool]} open [If true, the component is shown - default: false]
 * @param {[any]} ...props [Other valid props for MUI Modal]
 * @returns {[object]} [Returns the Modal component]
 */
const SimpleModal = (props) => {
  const {
    children,
    open = false,
    theme = "light",
    styleVariant = "primary",
    ...remaining
  } = props;
  const defaultStyle = getModalStyle(theme, styleVariant);
  const finalStyle = { ...defaultStyle };

  return (
    <Modal open={open} {...remaining}>
      <div style={finalStyle}>{children}</div>
    </Modal>
  );
};

SimpleModal.propTypes = {
  children: PropTypes.node,
  theme: PropTypes.oneOf(["light", "dark"]),
  styleVariant: PropTypes.oneOf(["primary", "secondary"]),
  open: PropTypes.bool,
};

export default SimpleModal;

//Import required libraies
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
//Import Custom Component
import Loader from "../../../components/Loader/Loader";

//Import tabs
import AddNewConfig from "./AddNewConfig";

// Import action creators
import {
  updateData,
  updateUserInfo,
  updateWholeConfigUserInputs,
  updateAlertInfo,
} from "../../../redux/actions";

//Import Custom Hooks

//Import utils/data
import { config } from "../../../config/config";
import { makeDefaultResponseJson } from "../../../utils/utils";
import { configUserInputs as initialConfigUserInputs } from "../../../redux/stateData";

function AddNewConfigContainer(props) {
  let {
    match,
    allData,
    user,
    updateData,
    updateUserInfo,
    disableFlag,
    runData,
    updateWholeConfigUserInputs,
    updateAlertInfo,
  } = props;
  const [pageReady, setPageReady] = useState(false);
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  let app = config.hardCoded.aeAppName;
  let activeApp = allData.apps.data
    ? allData.apps.data.filter(
        (obj) => obj.name.toLowerCase() === app.toLowerCase()
      )[0]
    : null;

  useEffect(() => {
    setLoading(false);
  }, []);

  // Get apps data
  useEffect(() => {
    let url = `${config.api.appsUrl}?vertical=${config.hardCoded.defaultVertical}&app_id=${activeApp.id}`;
    let status;
    fetch(url)
      .then((response) => {
        console.groupCollapsed("requesting", url);
        console.log("REPSONSE -> ", response);
        status = response.status;
        return response.clone().json();
      })
      .then((json) => {
        console.log("JSON -> ", json);
        console.groupEnd();
        if (status === 200) {
          let allAppsName = json.data?.map((appName) =>
            appName.name?.toLowerCase()
          );
          if (!allAppsName.includes(app.toLowerCase())) {
            updateAlertInfo({
              open: true,
              message: config.messages.noAppsAccess,
              severity: "info",
            });
            history.push(`/home/`);
          }
          updateData("apps", {
            status: "success",
            data: json.data,
            message: "",
          });
        } else {
          updateAlertInfo({
            open: true,
            message: json.statusMessage,
            severity: "error",
          });
        }
      });
    return () => {};
  }, []);

  return (
    <>
      {!loading ? (
        <AddNewConfig
          match={match}
          disableFlag={disableFlag}
          runData={runData}
        />
      ) : (
        <Loader />
      )}
    </>
  );
}

AddNewConfigContainer.propTypes = {
  match: PropTypes.object,
  allData: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.user,
  allData: state.data,
  configUserInputs: state.configUserInputs,
});

const mapDispatchToProps = {
  updateData,
  updateUserInfo,
  updateWholeConfigUserInputs,
  updateAlertInfo,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddNewConfigContainer);

// Import required libraries
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

// Import custom components
import LocalParameterItem from "../../AIDEApp/AddNewConfig/components/LocalParameterItem";
import ViewRunsFiltersNew from "../../AIDEApp/ViewRuns/ViewRunsFiltersNew";

// Import utils & data
import { config } from "../../../config/config";
import dayCat from "../../../../assets/data/dayCat.json";

// Import actions
import {
  updateData,
  updateSelections,
  updateUserInfo,
} from "../../../redux/actions";

// Import styles

let initialFilters = {
  searchText: "",
  startTime: "",
  endTime: "",
  orderBy: "created_on",
  dayCat: "today",
  viewRunsTags: [],
};

function ViewrunsFilters(props) {
  let { user, match, updateData, updateSelections, updateUserInfo, activeApp } =
    props;
  const [filters, setFilters] = useState(initialFilters);
  const history = useHistory();

  //Update filters from redux when the component mounts for the first time
  useEffect(() => {
    let initialFilters = {
      searchText: user.selections.runFilters.searchText,
      startTime: user.selections.runFilters.startTime,
      endTime: user.selections.runFilters.endTime,
      orderBy: user.selections.runFilters.orderBy,
      dayCat: user.selections.runFilters.dayCat,
    };
    setFilters({ ...filters, ...initialFilters });
  }, []);

  const handleFilterChange = (key, value) => {
    setFilters({ ...filters, [key]: value });
  };

  const handleChange = (key, value) => {
    updateSelections("runFilters", {
      ...user.selections.runFilters,
      [key]: value,
    });
  };

  return (
    <div className="view-runs-filters-container">
      <div className="view-runs-filters">
        <ViewRunsFiltersNew match={match} activeApp={activeApp} />
        {/* <div className="view-runs-filters-search-container">
        <input
          type="text"
          className="input-label"
          placeholder="Search"
          value={filters.searchText}
          onChange={(e) => {
            handleFilterChange("searchText", e.target.value);
          }}
        />
        {filters.searchText && (
          <i
            className="material-icons-outlined cancel-icon"
            onClick={() => {
              handleFilterChange("searchText", "");
              handleChange("searchText", "");
            }}
          >
            clear
          </i>
        )}
        <i
          className="material-icons search-icon"
          onClick={() => {
            handleChange("searchText", filters.searchText);
          }}
        >
          search
        </i>
      </div> */}
        <div className="view-runs-filters-date-container">
          <LocalParameterItem
            ui_element_type="dropdown"
            customClassName="side-view-runs-filter"
            value={user.selections.runFilters.dayCat}
            onChange={handleChange}
            enable={true}
            show={true}
            name={null}
            id="dayCat"
            data={dayCat}
          />
        </div>
      </div>
    </div>
  );
}

ViewrunsFilters.propTypes = {
  user: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  updateData,
  updateSelections,
  updateUserInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewrunsFilters);

import React from "react";
import PropTypes from "prop-types";
import TablePagination from "@mui/material/TablePagination";
import "../index.css";

/**
 * [BasicPagination description]
 * @param {[number]} totalRowsCount [The total number of rows in the table]
 * @param {[number]} page [The current page - default: 0 (first page)]
 * @param {[number]} rowsPerPage [The number of rows per page - default: 10]
 * @param {[string]} theme [The theme of the component - default: light]
 * @param {[string]} styleVariant [Used to represent colored interface elements for a user - default: primary]
 * @param {[any]} [...remaining] [Other valid props for MUI TablePagination]
 * @returns {[React.FC]} [Returns the Table Pagination component]
 */
function BasicPagination(props) {
  const {
    theme = "light",
    styleVariant = "primary",
    count,
    page: currentPage = 0,
    rowsPerPage: rowsInPage = 10,
    ...remaining
  } = props;

  const [page, setPage] = React.useState(currentPage);
  const [rowsPerPage, setRowsPerPage] = React.useState(rowsInPage);

  const style = {
    fontFamily: `var(--fontFamily)`,
    color: `var(--${styleVariant}-pagination-textcolor-${theme})`,
    "& .MuiTablePagination-displayedRows": {
      fontFamily: `var(--fontFamily)`,
    },
    "& .MuiTablePagination-selectLabel": {
      fontFamily: `var(--fontFamily)`,
    },
    "& .MuiSelect-select MuiSelect-standard MuiInputBase-input": {
      fontFamily: `var(--fontFamily)`,
    },
    "& .MuiButtonBase-root": {
      fontFamily: `var(--fontFamily)`,
    },
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <TablePagination
      sx={style}
      component="div"
      count={count}
      page={page}
      rowsPerPageOptions={[10]}
      onPageChange={handleChangePage}
      rowsPerPage={rowsPerPage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      {...remaining}
    />
  );
}

BasicPagination.propTypes = {
  theme: PropTypes.oneOf(["light", "dark"]),
  styleVariant: PropTypes.oneOf(["primary", "secondary"]),
  count: PropTypes.number,
};

export default BasicPagination;

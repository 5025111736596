//Import required libraies
import React, { useEffect, useState } from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";

//Import Custom Component

// Import custom hooks

// Import action creators

// Import styles

// Import utils & data
import { config } from "../../../../config/config";
import { ListItemText } from "@material-ui/core";
import { updateActivationPlatform } from "../../../../redux/actions";
import { connect } from "react-redux";
const StyledCheckbox = withStyles((theme) => ({
  root: {
    color: "#4eafb3 !important",
    "& .MuiSvgIcon-root": {
      width: 18,
      height: 18,
    },
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}))(Checkbox);
const useStyles = makeStyles({
  multiSelect: (props) => ({
    fontSize: "12px",
    fontFamily: "Hind Siliguri",
    width: "380px !important",
    height: "30px",
    borderRadius: "3px",
    color: "#46596a",
    backgroundColor: "white",
    //paddingLeft: "8px",
    border: "1px solid #dedede",
    "&:before": {
      borderBottom: "0px",
    },
    "&:after": {
      borderBottom: "0px",
    },
    "&:hover:not(.Mui-disabled):before": {
      borderBottom: "0px",
    },
    "& .MuiOutlinedInput-input": {
      padding: "6px 6px",
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "transparent",
    },
    "& .MuiSelect-select.MuiSelect-select": {
      paddingLeft: "8px",
    },
  }),
  newFlowMultiSelect: {
    width: "275px !important",
  },
  singleMenuItem: {
    fontSize: "12px !important",
    fontFamily: "Hind Siliguri !important",
    padding: "8px 12px !important",
    color: "#46596a !important",
    width: "auto",
    '&[aria-selected="true"]': {
      color: `#46596a !important`,
      backgroundColor: `rgba(0, 0, 0, 0.08) !important`,
    },
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04) !important",
      textDecoration: "none",
    },
  },
  selectItem: {
    "& .MuiMenuItem-gutters": {
      "MuiListItem-gutters": {
        padding: 0,
      },
    },
    "& .MuiTypography-body1": {
      fontSize: "12px",
      // font: "Hind Siliguri",
      fontFamily: "Hind Siliguri !important",
    },
    "& .MuiMenuItem-root": {
      fontFamily: "Hind Siliguri !important",
      fontSize: "12px",
      padding: 0,
    },
  },
});

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 150,
      width: 380,
    },
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  variant: "menu",
  getContentAnchorEl: null,
};

function InterfaceName(props) {
  let {
    id,
    data,
    name,
    enable,
    value,
    onChange,
    customClassName,
    disabledForUploadAudience,
    activeModel,
    selectedModel,
    className,
    checkInterFaceName,
    updateActivationPlatform,
  } = props;
  const param = {
    width: !checkInterFaceName ? "275px" : "300px",
    height: !checkInterFaceName ? "30px" : "26px",
  };
  const classes = useStyles(param);
  name = name.replace(":", "");
  return (
    <div
      className={`input-item row ${
        customClassName ? customClassName : ""
      } ${id} ${
        activeModel?.id === config.hardCoded.propensityId
          ? "interface-propensity column"
          : ""
      } ${className && className}`}
    >
      {name && (
        <label
          // id={
          //   activeModel?.id === config.hardCoded.propensityId ||
          //   selectedModel?.id === config.hardCoded.propensityId
          //     ? "width-10"
          //     : ""
          // }
          id={
            activeModel?.id === config.hardCoded.communityClusteringId &&
            !checkInterFaceName
              ? "width-120"
              : activeModel?.id === config.hardCoded.propensityId ||
                selectedModel?.id === config.hardCoded.propensityId
              ? "width-10"
              : ""
          }
          style={
            activeModel?.id === config.hardCoded.communityClusteringId &&
            !checkInterFaceName
              ? { marginRight: "0px" }
              : {}
          }
        >
          {name}:
        </label>
      )}
      {/* <Select
        displayEmpty
        multiple
        disabled={!enable || disabledForUploadAudience}
        value={value ? value : ""}
        onChange={(e) => {
          console.log("id, e.target.value", id, e.target.value);
          onChange(id, e.target.value);
        }}
        className={classes.multiSelect}
        MenuProps={MenuProps}
        style={{
          background: `${
            disabledForUploadAudience ? "rgba(59, 59, 59, 0.03)" : "white"
          }`,
        }}
        // id={!checkInterFaceName ? "width-275" : ""}
      >
        <MenuItem disabled value="" className={classes.singleMenuItem}>
          Select option
        </MenuItem>
        {data &&
          data.map((obj) => (
            <MenuItem
              key={obj.id}
              value={obj.id}
              className={classes.singleMenuItem}
            >
              {obj.name}
            </MenuItem>
            // <MenuItem
            //   key={obj.id}
            //   value={obj.id}
              // className={classes.singleMenuItem}>
            //    <StyledCheckbox checked={true} />
            //   <ListItemText primary={obj.name} className={classes.selectItem} />
            // </MenuItem>
          ))}
      </Select> */}
      <Select
        labelId="demo-multiple-checkbox-label"
        // id="demo-multiple-checkbox"
        displayEmpty
        multiple
        disabled={!enable || disabledForUploadAudience}
        value={value}
        onChange={(e) => {
          onChange(id, e.target.value);
          updateActivationPlatform({
            key: "activation_platform",
            presentValue: value,
            newValue: e.target.value,
            selectedModel: selectedModel,
          });
        }}
        // input={<OutlinedInput label="Tag" />}
        // renderValue={(selected) => selected.join(", ")}
        renderValue={(selected) => {
          if (selected.length === 0) {
            return <p>Select option</p>;
          }

          return selected.join(", ");
        }}
        MenuProps={MenuProps}
        className={classes.multiSelect}
        style={{
          background: `${
            disabledForUploadAudience ? "rgba(59, 59, 59, 0.03)" : "white"
          }`,
        }}
        // id={!checkInterFaceName ? "width-275" : ""}
      >
        <MenuItem disabled value="" className={classes.singleMenuItem}>
          Select option
        </MenuItem>
        {data &&
          data.map((obj) => (
            <MenuItem
              key={obj.id}
              value={obj.name}
              className={classes.singleMenuItem}
            >
              <StyledCheckbox checked={value.indexOf(obj.name) > -1} />
              <ListItemText primary={obj.name} className={classes.selectItem} />
            </MenuItem>
          ))}
      </Select>
    </div>
  );
}

const mapStateToProps = (state) => ({
  // user: state.user,
  //allData: state.data,
  // changeFlag: state.changeFlag,
  // configUserInputs: state.configUserInputs,
  // models: state.configUserInputs.models,
});

const mapDispatchToProps = {
  updateActivationPlatform,
};

export default connect(mapStateToProps, mapDispatchToProps)(InterfaceName);

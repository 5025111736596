// Import required libraries
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Popover } from "../../uiTheme";
import { useHistory } from "react-router-dom";

// Import custom components

// Import styles
import "./UserInfo.scss";

// Import config
import { config } from "../../config/config";

//import actions
import { updateAlertInfo } from "../../redux/actions";

function UserInfo(props) {
  let { user, isAuthorized, updateAlertInfo } = props;
  const history = useHistory();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const logout = () => {
    let status;
    const url = config.api.validateSignOut;
    fetch(url)
      .then((response) => {
        console.groupCollapsed("requesting", url);
        console.log("REPSONSE -> ", response);
        status = response.status;
        return response.clone().json();
      })
      .then((json) => {
        console.log("JSON -> ", json);
        console.groupEnd();
        if (status === 200) {
          window.location.href = config.api.signOutUrl;
          window.localStorage.removeItem(config.localStorageReduxStoreName);
        } else {
          updateAlertInfo({
            open: true,
            message: json.statusMessage,
            severity: "error",
          });
        }
      });
  };

  return (
    <div className="user-info-container">
      <i
        className="material-icons-outlined header-menu-icon"
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        data-testid="person-testId"
      >
        {" "}
        person{" "}
      </i>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          style: { width: "15%" },
        }}
      >
        {isAuthorized ? (
          <>
            <div className="user-info-name">
              <p className="user-info-text">{user.name}</p>
              {/* <p className="user-info-sub-text">Shraddha.Yadav@rb.com</p> */}
            </div>
            {user.role === config.hardCoded.adminRole && (
              <div
                className="user-admin-panel-container"
                onClick={() => history.push(`/home/admin-panel`)}
              >
                <p className="admin-panel-text">Admin Panel</p>
              </div>
            )}
            <div className="user-info-sign-out">
              <p
                className="user-info-sign-out-text"
                onClick={() => {
                  logout();
                }}
              >
                Sign Out
              </p>
            </div>
          </>
        ) : (
          <div className="user-info-name">
            <p className="user-info-text">Hello Guest</p>
          </div>
        )}
      </Popover>
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  updateAlertInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserInfo);

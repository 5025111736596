// Import required libraries
import React, { useEffect } from "react";
import useState from "react-usestateref";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  makeStyles,
  TableBody,
  TableRow,
  TableCell,
  withStyles,
} from "@material-ui/core";
import { IconButton, Paper } from "@mui/material";
// import PopOver from "../../components/PopOver/PopOver";
// import { Link } from "react-router-dom";
import TimeAgo from "react-timeago";
import { useHistory } from "react-router-dom";

// Import data & utils
import useTable from "../../utils/useTable";
import {
  flattenObject,
  timeDiffCalc,
  formatDate,
  makeRunNameFromRunNew,
  makeDefaultResponseJson,
  timeDifference,
  copyValueToClipboard,
  shortFormAE,
  modelForUI,
  runTypeForUI,
} from "../../utils/utils";
import { config } from "../../config/config";

//import styles
import { muiCommonStyles } from "../../../styles/styles";

// Import action creators
import {
  updateMultiSelections,
  updateSelections,
  updateAlertInfo,
  reloadRunData,
  updateDialogInfo,
  updateRunData,
  updateSharedRunsData,
  updateRunExpandInfo,
} from "../../redux/actions";

//Import styles
import "./Table.scss";
import PopOverForRun from "../PopOverForRun/PopOverForRun";

const StyledTableCell = withStyles((theme) => ({
  body: {
    color: "#46596a",
    fontFamily: "Hind Siliguri",
    borderBottom: "0px",
  },
}))(TableCell);
const InnerStyledTableCell = withStyles((theme) => ({
  body: {
    pointerEvents: "None",
    color: "#46596a",
    backgroundColor: "white !important",
    fontFamily: "Hind Siliguri",
    borderBottom: "0px",
  },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  // for thin scroll bar
  "@global": {
    "*::-webkit-scrollbar": {
      width: "0.1em",
    },
    "*::-webkit-scrollbar-track": {},
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
    },
  },
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
  },
  listIcon: {
    display: "flex",
    alignItems: "center",
  },
  name: {
    maxWidth: "500px",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "13px !important",
    lineHeight: "23px",
  },
  tableRow: {
    backgroundColor: "white !important",
    padding: "9px !important",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "13px !important",
    lineHeight: "23px",
    "&:last-child th, &:last-child td": {
      borderBottom: 0,
    },
    "&:hover": {
      backgroundColor: "#4eafb342 !important",
    },
  },
  starLoading: {
    marginRight: "5px",
    cursor: "pointer",
  },
  innerTableRow: {
    backgroundColor: "#46596a0d !important",
    padding: "9px !important",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "13px !important",
    lineHeight: "23px",
    "&:last-child th, &:last-child td": {
      borderBottom: 0,
    },
    "&:hover": {
      backgroundColor: "#4eafb342 !important",
    },
  },
  innerName: {
    maxWidth: "500px",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "13px !important",
    lineHeight: "23px",
    borderTopLeftRadius: "6px",
    borderBottomLeftRadius: "6px",
  },
}));

function TableViewRunV2(props) {
  const {
    user,
    data,
    allData,
    paginationFlag,
    match,
    updateMultiSelections,
    updateDialogInfo,
    updateSelections,
    updateRunData,
    updateAlertInfo,
    updateRunExpandInfo,
    val,
  } = props;
  const [downloadLoading, setDownloadLoading] = useState(false);
  let app = config.hardCoded.aeAppId.toLowerCase();
  const muiClass = muiCommonStyles();
  let activeApp = config.hardCoded.aeAppId;
  let headCells = [];
  const tabCheck = ["shared_with_me_runs", "starred"].includes(val);
  const ownerColumn = tabCheck
    ? [
        {
          id: "",
          label: "Triggered By",
          width: "10%",
          disableSorting: "disable",
          align: "center",
        },
      ]
    : [];
  headCells = [
    {
      id: "icon",
      label: "",
      width: "1%",
      disableSorting: "disable",
    },
    {
      id: "config_name",
      label: "Name",
      width: tabCheck ? "37.5%" : "50%",
      disableSorting: "disable",
    },
    {
      id: "model",
      label: "Run",
      width: "12.5%",
      disableSorting: "disable",
      align: "center",
    },
    ...ownerColumn,
    {
      id: "last_run_start_time",
      label: "Triggered On",
      width: tabCheck ? "14.5%" : "12.5%",
      disableSorting: "disable",
      align: "center",
    },
    {
      id: "duration",
      label: "Duration",
      width: "12.5%",
      disableSorting: "disable",
      align: "center",
    },
    {
      id: "",
      label: "Output",
      width: "6.5%",
      disableSorting: "disable",
      align: "center",
    },
    {
      id: "",
      label: "",
      width: "5%",
      disableSorting: "disable",
    },
  ];
  const classes = useStyles();
  const [records, setRecords] = useState(data.map((row) => flattenObject(row)));
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const [runLoading, setRunLoading, runLoadingRef] = useState([]);
  const history = useHistory();

  const setStarredValues = (runId) => {
    setRunLoading((prevValue) => {
      return [...prevValue, runId];
    });
  };

  const removeStarredValues = (runId) => {
    let sttValues = [...runLoadingRef.current];
    sttValues = sttValues.filter((item) => item !== runId); // remove
    setRunLoading([...sttValues]);
  };

  useEffect(() => {
    setRecords(data.map((row) => flattenObject(row)));
  }, [data]);

  const handleNo = () => {
    updateDialogInfo({ ...user.dialogInfo, open: false });
  };

  const updateRunAsStarred = (runId, runToBeStarred) => {
    // calling the APi here
    let url = `${config.api.bookmarkUrl}`;
    let body = {
      app_id: activeApp?.id,
      id: runId,
      type: "run",
    };
    let status;
    setStarredValues(runId);
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        console.groupCollapsed("requesting", url);
        console.log("REPSONSE -> ", response);
        status = response.status;
        return response.clone().json();
      })
      .then((json) => {
        if (status === 200) {
          let allRunData = allData.runData;
          let allDataForViewRuns = allRunData;
          //   allRunData?.forEach((obj) => {
          //     allDataForViewRuns = [...allDataForViewRuns, ...obj.data];
          //   });
          console.log("runId, runToBeStarred", runId, runToBeStarred);
          if (runToBeStarred.created_by === user.preferred_username) {
            allDataForViewRuns.map((singleConfigObj) => {
              singleConfigObj.runs.map((singleRunObj) => {
                if (singleRunObj?.id === runId) {
                  if (singleRunObj?.hasOwnProperty("isStarred")) {
                    singleRunObj.isStarred = !singleRunObj?.isStarred;
                  } else {
                    singleRunObj.isStarred = true;
                  }
                }
              });
            });
            updateRunData(allDataForViewRuns);
          } else {
            let allSharedRunsDataObject = allDataForViewRuns
              ?.map((el) => el.runs?.find((obj) => obj.id === runId))
              .find((el) => el);
            if (allSharedRunsDataObject) {
              allSharedRunsDataObject.shared_with.find((singleUsr) => {
                if (singleUsr.preferred_username === user.preferred_username) {
                  singleUsr.isStarred = !singleUsr.isStarred;
                }
              });
            }
            updateRunData(allDataForViewRuns);
          }
          removeStarredValues(runId);
        } else {
          updateAlertInfo({
            open: true,
            message: json.statusMessage,
            severity: "success",
          });
          removeStarredValues(runId);
        }
      });
  };

  const downloadRun = (url, body) => {
    let status;
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        console.groupCollapsed("requesting", url);
        console.log("REPSONSE -> ", response);
        status = response.status;
        return response.clone().json();
      })
      .then((json) => {
        console.log("JSON -> ", json);
        console.groupEnd();
        if (status === 200) {
          setDownloadLoading(false);
          let a = document.createElement("a");
          a.href = json.data.location;
          a.click();
        } else {
          setDownloadLoading(false);
          updateAlertInfo({
            open: true,
            message: json.statusMessage,
            severity: "error",
          });
        }
      });
  };
  const addViewRunsData = (runId) => {
    if (allData.runData) {
      //Fetch run data if runId is present
      if (runId) {
        let url = `${config.api.runUrl}?run_id=${runId}&app_id=${config.hardCoded.aeAppId}`;
        // let url = `${config.api.runIdUrl}?run_id=${runId}`;
        let status;
        fetch(url)
          .then((response) => {
            console.groupCollapsed("requesting", url);
            console.log("REPSONSE -> ", response);
            status = response.status;
            return response.clone().json();
          })
          .then((json) => {
            console.log("JSON -> ", json);
            console.groupEnd();
            if (status === 200) {
              updateSelections("activeRunPayload", json.data);
            } else {
              updateAlertInfo({
                open: true,
                message: json.statusMessage,
                severity: "success",
              });
            }
          });
      }
    }
  };
  // sort data
  useEffect(() => {
    updateMultiSelections({
      tableOrderBy: "created_on",
      tableOrder: "desc",
    });
  }, []);

  const ExpandableTableRow = ({
    children,
    expandComponent,
    id,
    isExpanded,
    ...otherProps
  }) => {
    return (
      <>
        <TableRow {...otherProps}>
          <StyledTableCell padding="checkbox">
            <IconButton
              onClick={() => {
                updateRunExpandInfo(id);
              }}
            >
              {isExpanded ? (
                <span className="material-icons-outlined">
                  keyboard_arrow_up
                </span>
              ) : (
                <span className="material-icons-outlined">
                  keyboard_arrow_down
                </span>
              )}
            </IconButton>
          </StyledTableCell>
          {children}
        </TableRow>
        {isExpanded && <>{expandComponent}</>}
      </>
    );
  };

  const innerRow = (runs, runConfig) => {
    return (
      <>
        {runs.map((item, i) => (
          <TableRow key={i} className={classes.innerTableRow}>
            <InnerStyledTableCell padding="checkbox"></InnerStyledTableCell>
            <StyledTableCell
              className={classes.innerName}
              onClick={() => {
                updateSelections("activeRunId", item.id);
                let activeRun = makeRunNameFromRunNew(item);
                addViewRunsData(item.id);
                history.push(`/home/viewruns/${activeRun}`);
              }}
            >
              <div
                style={{
                  display: "flex",
                }}
              >
                <div
                  className={`run-icon ${item.status}`}
                  onClick={(e) => {
                    copyValueToClipboard(item.id);
                    updateAlertInfo({
                      open: true,
                      message: `AIDE/RUN ID "${item.id}" is copied to clipboard.`,
                      severity: "success",
                    });
                    e.stopPropagation();
                  }}
                  title={`Click to copy AIDE/RUN ID "${item.id}"`}
                ></div>
                {runLoading?.includes(item.id) ? (
                  <div className={classes.starLoading}>
                    <CircularProgress color="secondary" size="1rem" />
                  </div>
                ) : (
                  <>
                    {item.created_by === user.preferred_username ? (
                      <>
                        <i
                          className="material-icons-outlined favourite-icon"
                          onClick={(e) => {
                            e.stopPropagation();
                            updateRunAsStarred(item.id, item);
                          }}
                        >
                          {item?.isStarred ? "star" : "star_border"}
                        </i>
                      </>
                    ) : (
                      <>
                        <i
                          className="material-icons-outlined favourite-icon"
                          onClick={(e) => {
                            e.stopPropagation();
                            updateRunAsStarred(item.id, item);
                          }}
                        >
                          {item?.shared_with?.find((singleUser) => {
                            return (
                              singleUser.preferred_username ===
                              user.preferred_username
                            );
                          })?.isStarred
                            ? "star"
                            : "star_border"}
                        </i>
                      </>
                    )}
                  </>
                )}
                <div className="run-name" title={item.name}>
                  {item.run_name}
                </div>
              </div>
            </StyledTableCell>
            <StyledTableCell
              onClick={() => {
                updateSelections("activeRunId", item.id);
                let activeRun = makeRunNameFromRunNew(item);
                addViewRunsData(item.id);
                history.push(`/home/viewruns/${activeRun}`);
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {" "}
                {`${modelForUI(item.models_enabled)}${
                  item.run_type ? `-${runTypeForUI(item.run_type)}` : ""
                }`}
              </div>
            </StyledTableCell>
            {tabCheck && (
              <StyledTableCell
                onClick={() => {
                  updateSelections("activeRunId", item.id);
                  let activeRun = makeRunNameFromRunNew(item);
                  addViewRunsData(item.id);
                  history.push(`/home/viewruns/${activeRun}`);
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    overflowWrap: "anywhere",
                  }}
                >
                  {item.created_by}
                </div>
              </StyledTableCell>
            )}
            <StyledTableCell
              onClick={() => {
                updateSelections("activeRunId", item.id);
                let activeRun = makeRunNameFromRunNew(item);
                addViewRunsData(item.id);
                history.push(`/home/viewruns/${activeRun}`);
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {timeDifference(item.created_on) < 7 ? (
                  <TimeAgo date={new Date(item.created_on)} />
                ) : (
                  formatDate(item.created_on)
                )}
              </div>
            </StyledTableCell>
            <StyledTableCell
              onClick={() => {
                updateSelections("activeRunId", item.id);
                let activeRun = makeRunNameFromRunNew(item);
                addViewRunsData(item.id);
                history.push(`/home/viewruns/${activeRun}`);
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {timeDiffCalc(item.end_time, item.start_time)}
              </div>
            </StyledTableCell>
            <StyledTableCell>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {runTypeForUI(item.run_type) !== "UA" &&
                  item.status !== "Failed" &&
                  !item.config_name.toLowerCase().includes("beko") && (
                    <i
                      className="material-icons-outlined"
                      onClick={() => {
                        if (!downloadLoading) {
                          downloadRun(config.api.downloadRunUrl, {
                            run_id: item.id,
                            type: config.hardCoded.downloadOutputType,
                            app_id: config.hardCoded.aeAppId,
                          });
                        }
                      }}
                    >
                      file_download
                    </i>
                  )}
              </div>
            </StyledTableCell>
            <StyledTableCell>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <PopOverForRun
                  user={user}
                  match={match}
                  run={item}
                  config={runConfig}
                />
              </div>
            </StyledTableCell>
          </TableRow>
        ))}
      </>
    );
  };
  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    useTable(records, headCells, filterFn, user, updateMultiSelections);

  return (
    <>
      <Paper
        sx={{
          height: "70vh",
          width: "100%",
          overflow: "auto",
          border: "none",
          boxShadow: "none",
        }}
      >
        <TblContainer>
          <TblHead />
          <TableBody>
            {recordsAfterPagingAndSorting().map((item, index) => (
              <>
                <ExpandableTableRow
                  className={classes.tableRow}
                  hover
                  key={item.config_id}
                  id={item.config_id}
                  isExpanded={item.isExpanded}
                  expandComponent={innerRow(item.runs, item)}
                >
                  <StyledTableCell
                    className={classes.name}
                    onClick={() => {
                      updateRunExpandInfo(item.config_id);
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div className="config-name" title={item.name}>
                        {shortFormAE(item.config_name)}
                      </div>
                    </div>
                  </StyledTableCell>
                  <StyledTableCell
                    onClick={() => {
                      updateRunExpandInfo(item.config_id);
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {item.is_scheduled_run ? "Schedule" : "On Demand"}
                    </div>
                  </StyledTableCell>
                  {tabCheck && (
                    <StyledTableCell
                      onClick={() => {
                        updateRunExpandInfo(item.config_id);
                      }}
                    ></StyledTableCell>
                  )}
                  <StyledTableCell
                    onClick={() => {
                      updateRunExpandInfo(item.config_id);
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {timeDifference(item.created_on) < 7 ? (
                        <TimeAgo date={new Date(item.created_on)} />
                      ) : (
                        formatDate(item.created_on)
                      )}
                    </div>
                  </StyledTableCell>
                  <StyledTableCell
                    onClick={() => {
                      updateRunExpandInfo(item.config_id);
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {timeDiffCalc(item.end_time, item.start_time)}
                    </div>
                  </StyledTableCell>
                  <StyledTableCell
                    onClick={() => {
                      updateRunExpandInfo(item.config_id);
                    }}
                  ></StyledTableCell>
                  <StyledTableCell
                    onClick={() => {
                      updateRunExpandInfo(item.config_id);
                    }}
                  ></StyledTableCell>
                </ExpandableTableRow>
              </>
            ))}
          </TableBody>
        </TblContainer>
      </Paper>
      <div style={{ height: "40px" }}>
        {records.length > 10 && <TblPagination />}
      </div>
    </>
  );
}

TableViewRunV2.propTypes = {
  data: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  paginationFlag: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
  allData: state.data,
});

const mapDispatchToProps = {
  updateMultiSelections,
  updateAlertInfo,
  updateDialogInfo,
  updateSelections,
  reloadRunData,
  updateRunData,
  updateSharedRunsData,
  updateRunExpandInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(TableViewRunV2);

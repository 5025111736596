import React, { useState } from "react";
import { connect } from "react-redux";
import Popover from "@material-ui/core/Popover";
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import { withStyles } from "@material-ui/core/styles";
import { config } from "../../../../config/config";
import { useNonInitialEffect } from "../../../../hooks/useNonInitialEffect";
import { updateModelLocalParameters } from "../../../../redux/actions";
import LocalParameterItem from "./LocalParameterItem";

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: "none",
  },
  typography: {
    fontSize: 11,
    fontFamily: "Hind Siliguri",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    color: "#46596a",
    margin: 0,
    marginTop: 4,
    marginBottom: 4,
    width: "320px",
  },
}));
const StyledCheckbox = withStyles(() => ({
  root: {
    color: "#4eafb3 !important",
    "& .MuiSvgIcon-root": {
      width: 18,
      height: 18,
    },
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}))(Checkbox);
function NumberOfTopics(props) {
  const { updateModelLocalParameters, configUserInputs, activeModel } = props;
  const nlpModel = configUserInputs.models.find(
    (model) => model.id === config.hardCoded.nlpId
  );
  const nlpNumberOfTopicsValue = activeModel?.local_parameters.find(
    (param) => param.id === "num_topics"
  );
  const gridSearchFlagInitialState = isNaN(nlpModel.config.num_topics);
  const [gridSearchFlag, setGridSearchFlag] = useState(
    gridSearchFlagInitialState
  );

  //state for the open popup for showing info
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const classes = useStyles();

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  useNonInitialEffect(() => {
    const data = {
      modelId: config.hardCoded.nlpId,
      key: "num_topics",
      value: gridSearchFlag
        ? nlpNumberOfTopicsValue?.gridSearchValue
        : nlpNumberOfTopicsValue?.defaultValue,
    };
    updateModelLocalParameters(data);
  }, [gridSearchFlag]);

  return (
    <>
      <div className="number-of-topics-checkbox">
        <StyledCheckbox
          checked={gridSearchFlag}
          onChange={(e) => {
            setGridSearchFlag(!gridSearchFlag);
          }}
        />
        <label>{nlpNumberOfTopicsValue?.gridSearchValue}</label>
        <i
          className="material-icons-outlined info-icon"
          aria-owns={open ? "mouse-over-popover" : undefined}
          aria-haspopup="true"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
          style={{ pointerEvents: "auto", marginTop: "0px" }}
        >
          {" "}
          info{" "}
        </i>
        <Popover
          id="mouse-over-popover"
          className={classes.popover}
          open={open}
          anchorEl={anchorEl}
          onClose={handlePopoverClose}
          disableRestoreFocus
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <p className={classes.typography}>{config.messages.numTopicInfo}</p>
        </Popover>
      </div>
      {!gridSearchFlag ? (
        <LocalParameterItem {...props} ui_element_type="inputbox_number" />
      ) : (
        <LocalParameterItem
          {...props}
          ui_element_type="inputbox_text"
          enable={false}
        />
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  allData: state.data,
  configUserInputs: state.configUserInputs,
});

const mapDispatchToProps = {
  updateModelLocalParameters,
};

export default connect(mapStateToProps, mapDispatchToProps)(NumberOfTopics);

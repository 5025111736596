//Import required libraies
import React from "react";
import { Redirect } from "react-router-dom";

//Import Custom Component
import LayoutTopSideBottom from "../../layouts/LayoutTopSideBottom/LayoutTopSideBottom";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import Run from "../AIDERun/Run/Run";
import AIDEAppContainer from "../AIDEAppContainer/AIDEAppContainer";

function AIDERun(props) {
  let { match } = props;

  return (
    <AIDEAppContainer>
      {match.params.runName ? (
        // <LayoutTopSideBottom match={match}>
        //   <Breadcrumbs match={match} />
        <Run match={match} />
      ) : (
        // </LayoutTopSideBottom>
        <Redirect to={`${match.url}abc`} />
      )}
    </AIDEAppContainer>
  );
}

export default AIDERun;

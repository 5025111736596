//Import required libraies
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

//Import custom components
import LocalParameterItem from "./LocalParameterItem";
import SegmentThreshhold from "./SegmentThreshhold";
import InterfaceName from "./InterfaceName";

//Import utils
import { config } from "../../../../config/config";

//Import actions
import { updateUserInfo } from "../../../../redux/actions";

//Import styles
//import "./TargetPropensity.scss";

const getInputsData = (targetPayload, name, userInputs) => {
  if (name === "interface_name" && userInputs.configType !== "add") {
    return targetPayload?.data?.[`${name}`];
  }
  return targetPayload?.data[`${name}`];
};

const getModelParams = (data, name) => {
  let models = data.models.find((model) => {
    return model.id === config.hardCoded.propensityId;
  });
  let specific_local_param = models?.local_parameters?.find((local_param) => {
    return local_param.id === name;
  });
  return specific_local_param?.data;
};

function TargetPropensityUploadAudienceForScheduleRuns(props) {
  let {
    match,
    onChange,
    userInputs,
    updateUserInfo,
    disableFlag,
    data,
    user,
    activeModel,
  } = props;
  let initialSelectionsFromConfigUserInputs = userInputs.models.find(
    (model) => model.id === config.hardCoded.propensityId
  );
  const [targetData, setTargetData] = useState({
    payload: {
      status: "success",
      message: "",
      data: initialSelectionsFromConfigUserInputs.config,
    },
  });

  useEffect(() => {
    if (targetData.payload.status === "success") {
      onChange(
        "audience_name",
        getInputsData(targetData.payload, "audience_name", userInputs)
      );
      onChange(
        "interface_name",
        getInputsData(targetData.payload, "interface_name", userInputs)
      );
      onChange(
        "segment_thresholds",
        getInputsData(targetData.payload, "segment_thresholds", userInputs)
      );
      onChange(
        "hot",
        getInputsData(
          targetData.payload,
          "segment_thresholds",
          userInputs
        )?.find((i) => i.name === "hot").maxValue
      );
      onChange(
        "cold",
        getInputsData(
          targetData.payload,
          "segment_thresholds",
          userInputs
        )?.find((i) => i.name === "cold").maxValue
      );
      onChange(
        "warm",
        getInputsData(
          targetData.payload,
          "segment_thresholds",
          userInputs
        )?.find((i) => i.name === "warm").maxValue
      );
      onChange(
        "run_id",
        getInputsData(targetData.payload, "run_id", userInputs)
      );
    }
  }, [targetData.payload]);

  return (
    <div
      className={`propensity-target-runs-conatiner ${
        userInputs.configType === "run" ? "disabled-pointer" : ""
      }`}
      style={{ display: "block " }}
    >
      {targetData.payload.status === "success" && (
        <>
          <div
            className={`datasets-selections-local-parameters-row ${
              disableFlag ? "disabled-pointer" : ""
            }`}
          >
            <InterfaceName
              data={getModelParams(data, "interface_name")}
              id="interface_name"
              name="Activation Platform"
              enable={false}
              onChange={onChange}
              value={getInputsData(
                targetData.payload,
                "interface_name",
                userInputs
              )}
              disabledForUploadAudience={true}
              activeModel={activeModel}
            />
          </div>
          <div
            className={`datasets-selections-local-parameters-row ${
              disableFlag ? "disabled-pointer" : ""
            }`}
          >
            <SegmentThreshhold
              name="Segment thresholds:"
              data={getModelParams(data, "segment_thresholds")}
              value={getInputsData(
                targetData.payload,
                "segment_thresholds",
                userInputs
              )}
              onChange={onChange}
              modelConfigId={"segment_thresholds"}
              userInputs={userInputs}
              enable={false}
              show={true}
              updateUserInfo={updateUserInfo}
              disabledForUploadAudience={true}
            />
          </div>
        </>
      )}
    </div>
  );
}

TargetPropensityUploadAudienceForScheduleRuns.propTypes = {};

const mapStateToProps = (state) => ({
  user: state.user,
  data: state.data,
});

const mapDispatchToProps = { updateUserInfo };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TargetPropensityUploadAudienceForScheduleRuns);

//Import required libraies
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";

//Import Custom Component
import TableRow from "../../../components/TableRow/TableRow";
import FilterNew from "../../../components/Filters/FilterNew";
import AppRow from "../../../components/AppRow/AppRow";
import Loader from "../../../components/Loader/Loader";
import noDataImage from "../../../../assets/images/undraw_empty.svg";

//Import utils & data
import {
  splitConfigsByCategory,
  filterMyConfigs,
  makeDefaultResponseJson,
  getStartEndByDayCat,
  checkConfigurationCountryAndBrand,
} from "../../../utils/utils";
import { config } from "../../../config/config";

// Import action creators
import {
  updateData,
  updateSelections,
  updateUserInfo,
  updateRunData,
  updateAlertInfo,
} from "../../../redux/actions";

//Import hooks
import { useIsMountedRef } from "../../../hooks/useIsMountedRef";
import { useFirstRender } from "../../../hooks/useFirstRender";

//Import style
import "./MyConfigurations.scss";

function MyConfigurationsSwitchIcons(props) {
  let { user, updateSelections } = props;
  return (
    <div className="myconfig-switch-icons">
      <i
        className={`material-icons myconfig-icons ${
          user.selections.appView === "table" ? "active" : ""
        }`}
        onClick={() => updateSelections("appView", "table")}
      >
        reorder
      </i>
      <i
        className={`material-icons myconfig-icons ${
          user.selections.appView === "grid" ? "active" : ""
        }`}
        onClick={() => updateSelections("appView", "grid")}
      >
        grid_on
      </i>
    </div>
  );
}

function MyConfigurations(props) {
  let {
    match,
    user,
    allData,
    updateSelections,
    updateData,
    updateUserInfo,
    changeFlag,
    updateRunData,
    updateAlertInfo,
    handleSwitch,
    switchScheduleConfiguration,
    ga360,
    handleGa360,
  } = props;
  const [configs, setConfigs] = useState(false);
  const [configsLoading, setConfigsLoading] = useState(false);
  const isFirstRender = useFirstRender();
  const history = useHistory();
  let app = config.hardCoded.aeAppName;
  let activeApp = allData.apps.data
    ? allData.apps.data.filter(
        (obj) => obj.name.toLowerCase() === app.toLowerCase()
      )[0]
    : null;

  // Get apps data
  useEffect(() => {
    if (!allData.apps.data || allData.apps.data.length <= 0) {
      let url = `${config.api.appsUrl}?vertical=${config.hardCoded.defaultVertical}&app_id=${activeApp?.id}`;
      let status;
      fetch(url)
        .then((response) => {
          console.groupCollapsed("requesting", url);
          console.log("REPSONSE -> ", response);
          status = response.status;
          return response.clone().json();
        })
        .then((json) => {
          console.log("JSON -> ", json);
          console.groupEnd();
          if (status === 200) {
            let allAppsName = json.data?.map((appName) =>
              appName.name?.toLowerCase()
            );
            if (!allAppsName.includes(app)) {
              updateAlertInfo({
                open: true,
                message: config.messages.noAppsAccess,
                severity: "info",
              });
              history.push(`/home/`);
            }
            updateData("apps", {
              status: "success",
              data: json.data,
              message: "",
            });
          } else {
            updateAlertInfo({
              open: true,
              message: json.statusMessage,
              severity: "info",
            });
          }
        });
    }
    return () => {};
  }, []);

  //reload saved_configurations data
  useEffect(() => {
    if (activeApp && !isFirstRender.current) {
      let url = `${config.api.configsUrl}?app_id=${activeApp.id}`;
      let status;
      setConfigsLoading(true);
      fetch(url)
        .then((response) => {
          console.groupCollapsed("requesting", url);
          console.log("REPSONSE -> ", response);
          status = response.status;
          return response.clone().json();
        })
        .then((json) => {
          console.log("JSON -> ", json);
          console.groupEnd();
          if (status === 200) {
            if (allData.appAccessDetails?.feature_list?.schedule_run) {
              let responseData = json.data;
              let url = `${config.api.scheduleConfigurl}?app_id=${activeApp.id}`;
              let status;
              fetch(url)
                .then((response) => {
                  console.groupCollapsed("requesting", url);
                  console.log("REPSONSE -> ", response);
                  status = response.status;
                  return response.clone().json();
                })
                .then((json) => {
                  console.log("JSON -> ", json);
                  console.groupEnd();
                  if (status === 200) {
                    let updatedSavedConfigurations = responseData?.map(
                      (elem) => ({
                        ...elem,
                        ...(json?.data?.find(
                          (obj) => obj.config_id === elem.id
                        ) && {
                          schedule_details: json.data.find(
                            (obj) => obj.config_id === elem.id
                          ),
                        }),
                      })
                    );
                    updateData(
                      "saved_configurations",
                      updatedSavedConfigurations
                    );
                    setConfigsLoading(false);
                  } else {
                    updateAlertInfo({
                      open: true,
                      message: json.statusMessage,
                      severity: "error",
                    });
                    setConfigsLoading(false);
                  }
                });
            } else {
              updateData("saved_configurations", json.data);
              setConfigsLoading(false);
            }
          } else {
            updateAlertInfo({
              open: true,
              message: json.statusMessage,
              severity: "error",
            });
            setConfigsLoading(false);
          }
        });
    }
    return () => {};
  }, [changeFlag.reloadConfigs]);

  // Change table if saved_configurations changes
  useEffect(() => {
    if (allData.saved_configurations) {
      let filtered_saved_configurations = filterMyConfigs(
        allData.saved_configurations,
        "name",
        user.selections,
        true
      );

      let configArr = splitConfigsByCategory({
        category: match.params.tab,
        arr: filtered_saved_configurations,
      });

      setConfigs({ filtered_saved_configurations, configArr });
    }
  }, [allData.saved_configurations, user.selections, match.params.tab]);

  const prepareRowObj = (obj) => {
    obj = checkConfigurationCountryAndBrand(allData, obj);
  };

  return (
    <>
      <FilterNew
        match={match}
        activeApp={activeApp}
        handleSwitch={handleSwitch}
        switchScheduleConfiguration={switchScheduleConfiguration}
        handleGa360={handleGa360}
        ga360={ga360}
      />
      {/* <Filters match={match} activeApp={activeApp} /> */}
      {!configs ? (
        <Loader />
      ) : configs.filtered_saved_configurations.length &&
        configs.configArr.length ? (
        <>
          {/* <MyConfigurationsSwitchIcons
            user={user}
            updateSelections={updateSelections}
          /> */}
          {configsLoading && <Loader />}
          <div
            className={`my-configs-container ${
              configsLoading ? "disabled-pointer" : ""
            }`}
          >
            {user.selections.appView === "table"
              ? configs.configArr.map((obj) => {
                  prepareRowObj(obj);
                  return (
                    <TableRow
                      key={obj.key}
                      title={obj.title}
                      match={match}
                      data={
                        ga360 && switchScheduleConfiguration
                          ? obj.data.filter(
                              (item) =>
                                item.hasOwnProperty("schedule_details") &&
                                item.old_pm
                            )
                          : !ga360 && switchScheduleConfiguration
                          ? obj.data.filter(
                              (item) =>
                                item.hasOwnProperty("schedule_details") &&
                                !item.old_pm
                            )
                          : ga360 && !switchScheduleConfiguration
                          ? obj.data.filter(
                              (item) =>
                                !item.hasOwnProperty("schedule_details") &&
                                item.old_pm
                            )
                          : obj.data.filter((item) => !item.old_pm)
                      }
                    />
                  );
                })
              : configs.configArr.map((obj) => {
                  prepareRowObj(obj);
                  return (
                    <AppRow
                      key={obj.key}
                      title={obj.title}
                      match={match}
                      data={
                        ga360 && switchScheduleConfiguration
                          ? obj.data.filter(
                              (item) =>
                                item.hasOwnProperty("schedule_details") &&
                                item.old_pm
                            )
                          : !ga360 && switchScheduleConfiguration
                          ? obj.data.filter(
                              (item) =>
                                item.hasOwnProperty("schedule_details") &&
                                !item.old_pm
                            )
                          : ga360 && !switchScheduleConfiguration
                          ? obj.data.filter(
                              (item) =>
                                !item.hasOwnProperty("schedule_details") &&
                                item.old_pm
                            )
                          : obj.data.filter((item) => !item.old_pm)
                      }
                      popOverFlag={true}
                    />
                  );
                })}
          </div>
        </>
      ) : (
        <div className="no-configs">
          <img src={noDataImage} />
          <p className="no-data">
            <b>
              {match.params.tab === "recent"
                ? config.messages.noRecentConfigs
                : config.messages.noConfigs}
            </b>
          </p>
        </div>
      )}
    </>
  );
}

MyConfigurations.propTypes = {
  user: PropTypes.object,
  match: PropTypes.object,
  saved_configurations: PropTypes.array,
  updateData: PropTypes.func,
  updateSelections: PropTypes.func,
  updateRunData: PropTypes.func,
};

const mapStateToProps = (state) => ({
  user: state.user,
  allData: state.data,
  changeFlag: state.changeFlag,
});

const mapDispatchToProps = {
  updateData,
  updateSelections,
  updateUserInfo,
  updateRunData,
  updateAlertInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyConfigurations);

//Import required libraies
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

//Import custom components
import Loader from "../../../../components/Loader/Loader";
import LocalParameterItem from "./LocalParameterItem";
import InputTextValidation from "./InputTextValidation";
import SegmentThreshhold from "./SegmentThreshhold";
import InterfaceName from "./InterfaceName";

//Import utils
import { config } from "../../../../config/config";
import {
  makeDefaultResponseJson,
  addRunNameToRunData,
  getStartEndByDayCat,
} from "../../../../utils/utils";
import dayCat from "../../../../../assets/data/dayCat.json";

//Import actions
import { updateUserInfo } from "../../../../redux/actions";

//Import styles
//import "./TargetPropensity.scss";

const initialTargetData = {
  runs: { status: "loading", message: "", data: [] },
  audience_name: { status: "loading", message: "", data: "" },
  payload: { status: "loading", message: "", data: "" },
};

let initialSelections = {
  run_id: "",
  audience_name: "",
  propensity_modeling_run_type: "upload_audience",
  config_id: "",
  dayCat: "all",
  target_run_id: "",
};

const getInputsData = (targetPayload, name) => {
  if (name === "interface_name") {
    return targetPayload?.data?.interface_name;
  }
  return targetPayload?.data[`${name}`];
};

const getModelParams = (data, name) => {
  let models = data.models.find((model) => {
    return model.id === config.hardCoded.propensityId;
  });
  let specific_local_param = models?.local_parameters?.find((local_param) => {
    return local_param.id === name;
  });
  return specific_local_param?.data;
};

function TargetPropensityUploadAudience(props) {
  let {
    match,
    onChange,
    userInputs,
    updateUserInfo,
    disableFlag,
    data,
    user,
    activeModel,
  } = props;
  let initialSelectionsFromConfigUserInputs = userInputs.models.find(
    (model) => model.id === config.hardCoded.propensityId
  );
  const [loading, setLoading] = useState(true);
  const [targetData, setTargetData] = useState(initialTargetData);
  const [selections, setSelections] = useState({
    ...initialSelections,
    ...initialSelectionsFromConfigUserInputs.config,
    dayCat: initialSelections.dayCat,
    configId: userInputs.configId,
  });

  //Defining required variables
  const runIdFinal = targetData.runs.data.length
    ? selections["target_run_id"]
    : config.messages.noRuns;
  const audienceNameFinal = targetData.audience_name.data
    ? selections["audience_name"]
    : config.messages.noNames;
  const runsDataFinal = targetData.runs.data.length
    ? targetData.runs.data
    : [
        {
          id: config.messages.noRuns,
          name: config.messages.noRuns,
        },
      ];
  const errorFlagFinal =
    targetData.runs.status === "error" ||
    targetData.audience_name.status === "error";
  const errorMessageFinal =
    targetData.runs.status === "error"
      ? targetData.runs.message
      : targetData.audience_name.status === "error"
      ? targetData.audience_name.message
      : "";

  // console.groupCollapsed("--------------Prop STATE CHANGED--------------");
  // console.log("USER INPUTS -> ", userInputs);
  // console.log("PROPS -> ", props);
  // console.log("TARGET RUNS -> ", targetData);
  // console.log("SELECTIONS -> ", selections);
  // console.groupEnd();

  //updates user selections
  const onChangeCurr = (key, value) => {
    setSelections({ ...selections, [key]: value });
    onChange(key, value);
  };

  //updated data coming from api
  const updateTargetData = (key, value) => {
    setTargetData((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  //Changing loading to false when component mounts
  useEffect(() => {
    setLoading(false);
  }, []);

  //Runs
  useEffect(() => {
    let url = `${config.api.runUrl}`;
    let body = {
      app_id: userInputs.app_id,
      country: userInputs.country,
      brand: userInputs.brand.toUpperCase(),
      segment:
        userInputs.segment.toString() === "ALL" ? null : userInputs.segment,
      search_text: selections.configId,
      offset: 0,
      limit: 100,
      order_by: "created_on",
      category: ["Success"],
      models: [config.hardCoded.propensityId],
      run_type_key: config.hardCoded.propensityRynKey, //key to get initial run details
      run_type_value: config.hardCoded.targetRunId, //key to get initial run details
      ...getStartEndByDayCat(disableFlag ? "" : selections.dayCat),
      is_shared: true,
    };
    let status;
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        console.groupCollapsed("requesting", url);
        console.log("REPSONSE -> ", response);
        status = response.status;
        return response.clone().json();
      })
      .then((json) => {
        console.log("JSON -> ", json);
        console.groupEnd();
        if (status === 200) {
          let newRunData = {
            status: "success",
            message: "",
            data: addRunNameToRunData(json.data),
          };
          updateTargetData("runs", newRunData);
        } else {
          let newRunData = {
            status: "error",
            message: json.statusMessage,
            data: [],
          };
          updateTargetData("runs", newRunData);
        }
      });
    return () => {
      updateTargetData("runs", { status: "loading", message: "", data: [] });
    };
  }, [selections.dayCat]);

  // fetch data for specific selected run
  useEffect(() => {
    if (targetData.runs.status === "success") {
      if (targetData.runs.data.length) {
        let targetRunsArray = targetData.runs.data.map((run) => run.id);
        if (!targetRunsArray.includes(selections.target_run_id)) {
          return;
        }
        let newRunPayloadDataLoading = {
          status: "loading",
          message: "",
          data: {},
        };
        updateTargetData("payload", newRunPayloadDataLoading);
        const urlRun = `${config.api.runUrl}?run_id=${selections.target_run_id}`;
        let status;
        fetch(urlRun)
          .then((response) => {
            console.groupCollapsed("requesting", urlRun);
            console.log("REPSONSE -> ", response);
            status = response.status;
            return response.clone().json();
          })
          .then((json) => {
            console.log("JSON -> ", json);
            console.groupEnd();
            if (status === 200) {
              const payloadConfig = json.data.payload.models.find(
                (model) => model.id === config.hardCoded.propensityId
              ).config;
              let newRunPayloadData = {
                status: "success",
                message: "",
                data: payloadConfig,
              };
              const newModifiedPayloadData = {
                ...newRunPayloadData,
                data: {
                  ...newRunPayloadData.data,
                  interface_name: [
                    newRunPayloadData.data.interface_details.interface_name,
                  ],
                },
              };
              updateTargetData("payload", newModifiedPayloadData);
            } else {
              let newRunPayloadData = {
                status: "error",
                message: json.statusMessage,
                data: {},
              };
              updateTargetData("payload", newRunPayloadData);
            }
          });
      } else {
        let newRunPayloadData = {
          status: "error",
          message: "No runs available under this category.",
          data: {},
        };
        updateTargetData("payload", newRunPayloadData);
      }
    }
  }, [targetData.runs, selections.target_run_id]);

  //Update run_id if runs changes
  useEffect(() => {
    if (targetData.runs.status === "success") {
      let target_run_id = selections.target_run_id;
      let isTargetRunIdValid = true;
      if (targetData.runs.data.length) {
        let runIds = targetData.runs.data.map((run) => run.id);
        if (target_run_id == "" && target_run_id?.length === 0) {
          target_run_id = user.selections.activeRunPayload?.id;
        }
        if (!runIds.includes(selections.target_run_id)) {
          target_run_id = targetData.runs.data[0].id;
          isTargetRunIdValid = false;
        }
      } else {
        target_run_id = "";
        isTargetRunIdValid = false;
      }
      if (!isTargetRunIdValid) {
        onChangeCurr("target_run_id", target_run_id);
      }
    }
  }, [targetData.runs]);

  useEffect(() => {
    if (targetData.payload.status === "success") {
      onChange(
        "audience_name",
        getInputsData(targetData.payload, "audience_name")
      );
      onChange(
        "interface_name",
        getInputsData(targetData.payload, "interface_name")
      );
      onChange(
        "segment_thresholds",
        getInputsData(targetData.payload, "segment_thresholds")
      );
      onChange(
        "hot",
        getInputsData(targetData.payload, "segment_thresholds")?.find(
          (i) => i.name === "hot"
        ).maxValue
      );
      onChange(
        "cold",
        getInputsData(targetData.payload, "segment_thresholds")?.find(
          (i) => i.name === "cold"
        ).maxValue
      );
      onChange(
        "warm",
        getInputsData(targetData.payload, "segment_thresholds")?.find(
          (i) => i.name === "warm"
        ).maxValue
      );
      onChange("run_id", getInputsData(targetData.payload, "run_id"));
    }
  }, [targetData.payload]);

  return (
    <div
      className={`propensity-target-runs-conatiner ${
        userInputs.configType === "run" ? "disabled-pointer" : ""
      }`}
    >
      {targetData.runs.status === "loading" && <Loader />}
      {targetData.runs.status === "success" && (
        <>
          <LocalParameterItem
            ui_element_type="dropdown"
            value={selections["dayCat"]}
            onChange={onChangeCurr}
            enable={true}
            show={true}
            name="Select Runs For"
            id="dayCat"
            data={dayCat}
            userInputs={userInputs}
            activeModel={activeModel}
          />
          <LocalParameterItem
            ui_element_type="dropdown"
            value={runIdFinal}
            onChange={onChangeCurr}
            enable={targetData.runs.data.length ? true : false}
            show={true}
            name="Select Target Run"
            id="target_run_id"
            data={runsDataFinal}
            userInputs={userInputs}
            activeModel={activeModel}
            className="datasets-selections-local-parameters-row"
          />
        </>
      )}

      {targetData.payload.status === "loading" && <Loader />}
      {targetData.payload.status === "success" && (
        <>
          {targetData.runs.data.length > 0 && (
            <>
              <InputTextValidation
                ui_element_type="inputbox_text"
                value={getInputsData(targetData.payload, "audience_name")}
                onChange={onChange}
                enable={false}
                show={true}
                name="Output Name"
                id="audience_name"
                userInputs={userInputs}
                activeModel={activeModel}
                className={"datasets-selections-local-parameters-row"}
              />
              <InterfaceName
                data={getModelParams(data, "interface_name")}
                id="interface_name"
                name="Activation Platform"
                enable={false}
                onChange={onChange}
                value={getInputsData(targetData.payload, "interface_name")}
                disabledForUploadAudience={true}
                activeModel={activeModel}
                className={"datasets-selections-local-parameters-row"}
              />
              <SegmentThreshhold
                name="Segment thresholds:"
                data={getModelParams(data, "segment_thresholds")}
                value={getInputsData(targetData.payload, "segment_thresholds")}
                onChange={onChange}
                modelConfigId={"segment_thresholds"}
                userInputs={userInputs}
                enable={false}
                show={true}
                updateUserInfo={updateUserInfo}
                disabledForUploadAudience={true}
                className={"datasets-selections-local-parameters-row"}
                style={{ marginTop: "10px" }}
              />
            </>
          )}
        </>
      )}

      {errorFlagFinal && <p className="info-message">{errorMessageFinal}</p>}
    </div>
  );
}

TargetPropensityUploadAudience.propTypes = {};

const mapStateToProps = (state) => ({
  user: state.user,
  data: state.data,
});

const mapDispatchToProps = { updateUserInfo };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TargetPropensityUploadAudience);

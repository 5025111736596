import React, { useState } from "react";
import Divider from "@material-ui/core/Divider";
import { config } from "../../config/config";

//import utils
// Import data & utils
import { makeDefaultResponseJson, downloadFile } from "../../utils/utils";

//create file row of the accordion
function AccordionItemRow(props) {
  const { padding, updateUserInfo, updateAlertInfo, data } = props;
  const { name, description, label, id } = data;

  const downloadDocument = (path) => {
    if (path) {
      downloadFile(path, name + ".pdf");
    } else {
      updateAlertInfo({
        open: true,
        message: config.messages.noDocuments + " for " + name + ".",
        severity: "success",
      });
    }
  };

  //handle the download button functionality
  const handleDownload = () => {
    const url = `${config.api.downloadResourcesUrl}?id=${id.replace(
      "&",
      "%26"
    )}&app_id=${config.hardCoded.aeAppId}`;
    let status;
    fetch(url)
      .then((response) => {
        console.groupCollapsed("requesting", url);
        console.log("REPSONSE -> ", response);
        status = response.status;
        return response.clone().json();
      })
      .then((json) => {
        console.log("JSON -> ", json);
        console.groupEnd();

        if (status === 200) {
          downloadDocument(json.data);
        } else {
          updateAlertInfo({
            open: true,
            message: json.statusMessage,
            severity: "success",
          });
        }
      });
  };

  return (
    <div
      key={name}
      className="list-item"
      style={{
        paddingLeft: `${padding * label}px`,
      }}
    >
      <div className="list-row-container">
        <div className="file-name-download-icon-container">
          <div className="file-name-icon">
            <i
              className="material-icons-outlined download-icon"
              style={{ marginRight: 10, cursor: "default" }}
            >
              description
            </i>
            <p className="list-primary-text">{name}</p>
          </div>
          <i
            className="material-icons-outlined download-icon"
            onClick={() => {
              handleDownload();
            }}
          >
            file_download
          </i>
        </div>
      </div>
      {description
        ? description.split("\n").map((text, key) => (
            <p className="list-secondary-text" key={key}>
              {text}
            </p>
          ))
        : null}
      <Divider />
    </div>
  );
}

//create accordion item
function AccordionItem({
  resourcesFolderFileData,
  updateUserInfo,
  updateAlertInfo,
  arrangeFolderAndFiles,
}) {
  const [flag, setFlag] = useState(false);
  let padding = 20;
  return (
    <>
      {resourcesFolderFileData?.data && (
        <div
          className="accordion-container"
          style={{
            backgroundColor: resourcesFolderFileData.data
              ? "#e5e5e5b0"
              : "white",
            paddingLeft: `${padding * resourcesFolderFileData.label}px`,
          }}
        >
          <div className="folder-name">
            <i className="material-icons-outlined download-icon folder-icon">
              folder
            </i>
            {resourcesFolderFileData.name}
          </div>
          <div
            onClick={() => {
              setFlag(!flag);
            }}
            className="folder-name"
          >
            {!flag ? (
              <i className="material-icons-outlined download-icon add-sub-icon">
                add
              </i>
            ) : (
              <i className="material-icons-outlined download-icon add-sub-icon">
                remove
              </i>
            )}
          </div>
        </div>
      )}

      {resourcesFolderFileData?.data &&
        flag &&
        arrangeFolderAndFiles(resourcesFolderFileData.data).map((data, i) => {
          if (data.data) {
            return (
              <AccordionItem
                key={data.id}
                resourcesFolderFileData={data}
                updateUserInfo={updateUserInfo}
                updateAlertInfo={updateAlertInfo}
                arrangeFolderAndFiles={arrangeFolderAndFiles}
              />
            );
          } else {
            return (
              <AccordionItemRow
                data={data}
                key={data.id}
                padding={padding}
                updateUserInfo={updateUserInfo}
                updateAlertInfo={updateAlertInfo}
              />
            );
          }
        })}
      {!resourcesFolderFileData?.data && (
        <AccordionItemRow
          data={resourcesFolderFileData}
          key={resourcesFolderFileData.id}
          padding={padding}
          updateUserInfo={updateUserInfo}
          updateAlertInfo={updateAlertInfo}
        />
      )}
    </>
  );
}

function Accordion({
  data,
  updateUserInfo,
  updateAlertInfo,
  arrangeFolderAndFiles,
}) {
  return data.map((resourcesFolderFileData) => {
    return (
      <AccordionItem
        resourcesFolderFileData={resourcesFolderFileData}
        key={resourcesFolderFileData.id}
        updateUserInfo={updateUserInfo}
        updateAlertInfo={updateAlertInfo}
        arrangeFolderAndFiles={arrangeFolderAndFiles}
      />
    );
  });
}

export default Accordion;

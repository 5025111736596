// Import required libraries
import React, { useState } from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";

//Import custom component
import ShareRunsModelComponent from "../ShareRuns/ShareRunsModelComponent";
import SaveConfigForRun from "../SaveConfigForRun/SaveConfigForRun";
import Modal from "../Modal/Modal";
// Import utils
import { config as appConfig } from "../../config/config";
import { makeDefaultResponseJson } from "../../utils/utils";

// Import action creators
import {
  updateAlertInfo,
  updateUserInfo,
  updateSelections,
  updateDialogInfo,
  reloadRunData,
} from "../../redux/actions";

// Import styles
import "../PopOver/PopOver.scss";

const useStyles = makeStyles((theme) => ({
  popover: {
    fontSize: "12px",
    color: "#46596a",
    fontFamily: "Hind Siliguri",
    fontWeight: 500,
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "#4EAFB3",
      color: "white",
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: "white",
      },
    },
  },
  root: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
  },
}));

function PopOverForRun(props) {
  let {
    user,
    match,
    updateAlertInfo,
    updateUserInfo,
    updateSelections,
    updateDialogInfo,
    reloadRunData,
    run,
    allData,
    config,
  } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [modalComponent, setModalComponent] = useState({
    component: null,
    openModal: false,
    handleCancel: () => {
      console.log("handleCancel");
    },
    handleDone: () => {
      console.log("handleDone");
    },
  });

  let app = appConfig.hardCoded.aeAppName;
  let activeApp =
    allData.apps.data.length > 0
      ? allData.apps.data.filter(
          (obj) => obj.name.toLowerCase() === app.toLowerCase()
        )[0]
      : null;

  const handleDialogOpen = (e, component) => {
    setModalComponent((prevState) => {
      return {
        ...prevState,
        openModal: true,
        component: component,
      };
    });
  };

  const handleDialogClose = () => {
    setModalComponent((prevState) => {
      return { ...prevState, openModal: false };
    });
  };

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
    e.stopPropagation();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNo = () => {
    updateDialogInfo({ ...user.dialogInfo, open: false });
  };

  const handleDelete = () => {
    updateDialogInfo({ ...user.dialogInfo, open: false });
    console.groupCollapsed("DELETE REQUEST SENT...");
    updateAlertInfo({
      open: true,
      message: appConfig.messages.runDeleteRequest,
      severity: "info",
    });
    if (run.id) {
      let url = `${appConfig.api.runUrl}?run_id=${run.id}&app_id=${appConfig.hardCoded.aeAppId}`;
      let status;
      fetch(url, {
        method: "DELETE",
      })
        .then((response) => {
          console.groupCollapsed("requesting", url);
          console.log("REPSONSE -> ", response);
          status = response.status;
          return response.clone().json();
        })
        .then((json) => {
          console.log("JSON -> ", json);
          console.groupEnd();
          if (status === 200) {
            updateAlertInfo({
              open: true,
              message: appConfig.messages.runDeleteSuccess,
              severity: "success",
            });
            reloadRunData();
          } else {
            updateAlertInfo({
              open: true,
              message: json.statusMessage,
              severity: "error",
            });
          }
        });
    }
  };

  return (
    <>
      <i
        className="material-icons more-vert-icon-style"
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        more_vert
      </i>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          className={classes.popover}
          onClick={(e) => {
            e.stopPropagation();
            handleClose();
            updateDialogInfo({
              message: appConfig.messages.confirmDelete,
              open: true,
              handleYes: handleDelete,
              handleNo: handleNo,
            });
          }}
        >
          Delete
        </MenuItem>

        <MenuItem
          className={classes.popover}
          onClick={(e) => {
            handleClose();
            handleDialogOpen(
              e,
              <ShareRunsModelComponent
                handleDialogClose={handleDialogClose}
                run={run}
              />
            );
          }}
        >
          Share
        </MenuItem>
        <Modal
          modalComponent={modalComponent}
          setModalComponent={setModalComponent}
          handleDialogClose={handleDialogClose}
        />
      </Menu>
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  saved_configurations: state.data.saved_configurations,
  allData: state.data,
});

const mapDispatchToProps = {
  updateAlertInfo,
  updateUserInfo,
  updateSelections,
  updateDialogInfo,
  reloadRunData,
};

export default connect(mapStateToProps, mapDispatchToProps)(PopOverForRun);

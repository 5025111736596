//Import required libraies
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

//Import custom components
import Loader from "../../../../components/Loader/Loader";
import LocalParameterItem from "./LocalParameterItem";

//Import utils
import { config } from "../../../../config/config";
import {
  makeDefaultResponseJson,
  addRunNameToRunData,
  getStartEndByDayCat,
} from "../../../../utils/utils";
import dayCat from "../../../../../assets/data/dayCat.json";

//Import actions
import { updateUserInfo } from "../../../../redux/actions";

//Import styles
import "./TargetCommunity.scss";

import InputTextValidation from "./InputTextValidation";
import InterfaceName from "./InterfaceName";
import CommunityType from "./CommunityType";

const initialTargetData = {
  runs: { status: "loading", message: "", data: [] },
  payload: { status: "loading", message: "", data: {} },
};

let initialSelections = {
  run_id: "",
  community_clustering_run_type: "upload_audience",
  config_id: "",
  dayCat: "all",
  community_id_list: [],
  audience_name: "",
  target_run_id: "",
};

const getInputsData = (targetPayload, name) => {
  if (name === "community_id_list") {
    let payLoadData = targetPayload?.data[`${name}`];
    return payLoadData.includes("ALL") ? "ALL" : "specific";
  }
  if (name === "interface_name") {
    return targetPayload?.data?.interface_name;
  }
  return targetPayload?.data[`${name}`];
};

const getModelParams = (data, name) => {
  let models = data.models.find((model) => {
    return model.id === config.hardCoded.communityClusteringId;
  });
  let specific_local_param = models?.local_parameters?.find((local_param) => {
    return local_param.id === name;
  });
  return specific_local_param?.data;
};

function TargetCommunityUploadAudience(props) {
  let {
    match,
    onChange,
    userInputs,
    updateUserInfo,
    disableFlag,
    data,
    user,
    selectedModel,
    activeModel,
  } = props;
  let initialSelectionsFromConfigUserInputs = userInputs.models.find(
    (model) => model.id === config.hardCoded.communityClusteringId
  );
  const [loading, setLoading] = useState(true);
  const [targetData, setTargetData] = useState(initialTargetData);
  const [selections, setSelections] = useState({
    ...initialSelections,
    ...initialSelectionsFromConfigUserInputs.config,
    dayCat: initialSelections.dayCat,
    configId: userInputs.configId,
  });

  //Defining required variables
  const runIdFinal = targetData.runs.data.length
    ? selections["target_run_id"]
    : config.messages.noRuns;
  const runsDataFinal = targetData.runs.data.length
    ? targetData.runs.data
    : [
        {
          id: config.messages.noRuns,
          name: config.messages.noRuns,
        },
      ];
  const errorFlagFinal = targetData.runs.status === "error";
  const errorMessageFinal =
    targetData.runs.status === "error" ? targetData.runs.message : "";

  //updates user selections
  const onChangeCurr = (key, value) => {
    setSelections({ ...selections, [key]: value });
    onChange(key, value);
  };

  //updated data coming from api
  const updateTargetData = (key, value) => {
    setTargetData((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  //Changing loading to false when component mounts
  useEffect(() => {
    setLoading(false);
  }, []);

  useEffect(() => {
    if (targetData.payload.status === "success") {
      onChange(
        "community_id_list",
        targetData?.payload.data["community_id_list"]
      );
      onChange(
        "community_id_type",
        getInputsData(targetData.payload, "community_id_list")
      );
      onChange(
        "interface_name",
        getInputsData(targetData.payload, "interface_name")
      );
      onChange(
        "audience_name",
        getInputsData(targetData.payload, "audience_name")
      );
      onChange("run_id", getInputsData(targetData.payload, "run_id"));
    }
  }, [targetData.payload]);

  //Fetch runs data
  useEffect(() => {
    const url = config.api.runUrl;
    const body = {
      app_id: userInputs.app_id,
      country: userInputs.country,
      brand: userInputs.brand.toUpperCase(),
      segment:
        userInputs.segment.toString() === "ALL" ? null : userInputs.segment,
      search_text: selections.configId,
      offset: 0,
      limit: 100,
      order_by: "created_on",
      category: ["Success"],
      models: [config.hardCoded.communityClusteringId],
      run_type_key: config.hardCoded.communityRunKey, //key to get initial run details
      run_type_value: config.hardCoded.targetRunId, //key to get pilot run details
      ...getStartEndByDayCat(disableFlag ? "" : selections.dayCat),
      is_shared: true,
    };
    let status;
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        console.groupCollapsed("requesting", url);
        console.log("REPSONSE -> ", response);
        status = response.status;
        return response.clone().json();
      })
      .then((json) => {
        console.log("JSON -> ", json);
        console.groupEnd();
        if (status === 200) {
          let newRunData = {
            status: "success",
            message: "",
            data: addRunNameToRunData(json.data),
          };
          updateTargetData("runs", newRunData);
        } else {
          let newRunData = {
            status: "error",
            message: json.statusMessage,
            data: [],
          };
          updateTargetData("runs", newRunData);
        }
      });

    return () => {
      updateTargetData("runs", { status: "loading", message: "", data: [] });
    };
  }, [selections.dayCat]);

  //Update target_run_id if runs changes
  useEffect(() => {
    if (targetData.runs.status === "success") {
      let target_run_id = selections.target_run_id;
      let isTargetRunIdValid = true;
      if (targetData.runs.data.length) {
        let runIds = targetData.runs.data.map((run) => run.id);
        if (target_run_id == "" && target_run_id?.length === 0) {
          target_run_id = user.selections.activeRunPayload?.id;
        }
        if (!runIds.includes(selections.target_run_id)) {
          target_run_id = targetData.runs.data[0].id;
          isTargetRunIdValid = false;
        }
      } else {
        target_run_id = "";
        isTargetRunIdValid = false;
      }
      if (!isTargetRunIdValid) {
        onChangeCurr("target_run_id", target_run_id);
      }
    }
  }, [targetData.runs]);

  useEffect(() => {
    //If Runs fetch call was a success, fetch run payload data
    if (targetData.runs.status === "success") {
      if (targetData.runs.data.length) {
        let runIds = targetData.runs.data.map((run) => run.id);
        if (
          selections.target_run_id !== "" &&
          runIds.includes(selections.target_run_id)
        ) {
          //Fetch run data if run_id is present
          const url = `${config.api.runUrl}?run_id=${selections.target_run_id}`;
          let status;
          fetch(url)
            .then((response) => {
              console.groupCollapsed("requesting", url);
              console.log("REPSONSE -> ", response);
              status = response.status;
              return response.clone().json();
            })
            .then((json) => {
              console.log("JSON -> ", json);
              console.groupEnd();
              if (status === 200) {
                const payloadConfig = json.data.payload.models.find(
                  (model) => model.id === config.hardCoded.communityClusteringId
                ).config;
                let newRunPayloadData = {
                  status: "success",
                  message: "",
                  data: payloadConfig,
                };

                const newModifiedPayloadData = {
                  ...newRunPayloadData,
                  data: {
                    ...newRunPayloadData.data,
                    interface_name: [
                      newRunPayloadData.data.interface_details.interface_name,
                    ],
                  },
                };
                updateTargetData("payload", newModifiedPayloadData);
              } else {
                let newRunPayloadData = {
                  status: "error",
                  message: json.statusMessage,
                  data: {},
                };
                updateTargetData("payload", newRunPayloadData);
              }
            });
        }
      } else {
        let newRunPayloadData = {
          status: "error",
          message: "No runs available under this category.",
          data: {},
        };
        updateTargetData("payload", newRunPayloadData);
      }
    }

    //If Runs fetch call was an error, upload runPayload also as error
    if (targetData.runs.status === "error") {
      let newRunPayloadData = {
        status: "error",
        message: "Fetching run data failed",
        data: {},
      };
      updateTargetData("payload", newRunPayloadData);
    }

    return () => {
      updateTargetData("payload", { status: "loading", message: "", data: {} });
    };
  }, [targetData.runs, selections.target_run_id]);

  return (
    <>
      {targetData.runs.status === "loading" && <Loader />}

      {targetData.runs.status === "success" && (
        <>
          <LocalParameterItem
            ui_element_type="dropdown"
            value={selections["dayCat"]}
            onChange={onChangeCurr}
            enable={true}
            show={true}
            name="Select Runs For"
            id="dayCat"
            data={dayCat}
            userInputs={userInputs}
            disableFlag={disableFlag}
          />
          <LocalParameterItem
            ui_element_type="dropdown"
            value={runIdFinal}
            onChange={onChangeCurr}
            enable={targetData.runs.data.length ? true : false}
            show={true}
            name="Select Target Run"
            id="target_run_id"
            data={runsDataFinal}
            userInputs={userInputs}
            disableFlag={disableFlag}
          />
        </>
      )}

      {targetData.payload.status === "loading" && <Loader />}
      {targetData.payload.status === "success" && (
        <>
          {targetData.runs.data.length > 0 && (
            <>
              <div
                className={`datasets-selections-local-parameters-row ${
                  disableFlag ? "disabled-pointer" : ""
                }`}
              >
                <InputTextValidation
                  id="audience_name"
                  name="Output Name"
                  enable={false}
                  onChange={onChange}
                  value={getInputsData(targetData.payload, "audience_name")}
                  disabledForUploadAudience={true}
                  activeModel={activeModel}
                />
              </div>
              <div
                className={`datasets-selections-local-parameters-row ${
                  disableFlag ? "disabled-pointer" : ""
                }`}
              >
                <InterfaceName
                  data={getModelParams(data, "interface_name")}
                  id="interface_name"
                  name="Activation Platform"
                  enable={false}
                  onChange={onChange}
                  value={getInputsData(targetData.payload, "interface_name")}
                  disabledForUploadAudience={true}
                  activeModel={activeModel}
                />
              </div>
              <div
                className={`datasets-selections-local-parameters-row ${
                  disableFlag ? "disabled-pointer" : ""
                }`}
              >
                <CommunityType
                  data={getModelParams(data, "community_id_type")}
                  name="Community Ids"
                  id="community_id_type"
                  value={getInputsData(targetData.payload, "community_id_list")}
                  enable={false}
                  onChange={onChange}
                  userInputs={userInputs}
                  disabledForUploadAudience={true}
                  selectedModel={selectedModel}
                  activeModel={activeModel}
                />
              </div>
            </>
          )}
        </>
      )}
      {errorFlagFinal && <p className="info-message">{errorMessageFinal}</p>}
    </>
  );
}

TargetCommunityUploadAudience.propTypes = {};

const mapStateToProps = (state) => ({
  user: state.user,
  data: state.data,
});

const mapDispatchToProps = { updateUserInfo };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TargetCommunityUploadAudience);

import C from "../constants";

export default function adminSetting(state = {}, payload) {
  let { type, key, data } = payload;
  switch (type) {
    case C.UPDATE_ADMIN_PANEL_USER_DETAILS:
      return {
        ...state,
        activeUser: { ...state.activeUser, [key]: data },
      };

    case C.USERS_LIST_RECEIVED:
      return {
        ...state,
        userList: { status: "success", message: "", data },
      };

    case C.FEATURE_LIST_RECEIVED:
      return {
        ...state,
        featureList: { status: "success", message: "", data },
      };

    case C.AD_USERS_LIST_RECEIVED:
      return {
        ...state,
        adUserList: { status: "success", message: "", data },
      };

    case C.USER_INFO_RECEIVED:
      return {
        ...state,
        activeUser: {
          ...state.activeUser.data,
          ...data,
        },
      };

    case C.UPDATE_USER_LIST_FILTER_STRING:
      return {
        ...state,
        userListFilterString: data,
      };

    case C.GROUPS_LIST_RECEIVED:
      return {
        ...state,
        userGroups: { status: "success", message: "", data },
      };

    case C.GET_GROUP_INFO:
      return {
        ...state,
        userGroupInfo: {
          status: "loading",
          message: "",
          data: { ...state.userGroupInfo.data },
        },
      };

    case C.GROUPS_INFO_RECEIVED:
      return {
        ...state,
        userGroupInfo: {
          status: "success",
          message: "",
          data: { ...data.data },
        },
      };

    case C.UPDATE_USER_INFO_FOR_ADMIN:
      return {
        ...state,
        activeUser: {
          status: data.status,
          message: data.message,
          data: { ...state.activeUser.data, ...data.data },
        },
      };

    case C.ADD_EDIT_STATUS_RECEIVED:
      return {
        ...state,
        userAddEditStatus: { ...state.userAddEditStatus, ...data },
      };

    case C.RESET_ADD_EDIT_STATUS_RECEIVED:
      return {
        ...state,
        userAddEditStatus: -1,
      };

    default:
      return state;
  }
}

// Import required libraries
import React, { useState } from "react";
// import Menu from "@material-ui/core/Menu";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles, withStyles } from "@material-ui/core/styles";
// import Checkbox from "@material-ui/core/Checkbox";
import { Checkbox, Avatar } from "../../uiTheme";
// import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";

// Import styles
import "./ShareConfigs.scss";

// Import utils
// import { config as appConfig } from "../../config/config";
import { getInitialsFromEmail } from "../../utils/utils";
import shareAccessOptions from "../../../assets/data/shareAccessOptions.json";

// Import action creators
// import {
//   updateAlertInfo,
//   updateUserInfo,
//   updateSelections,
//   updateDialogInfo,
//   reloadRunData,
//   refreshData,
//   reloadConfigs,
// } from "../../redux/actions";

const useStyles = makeStyles({
  multiSelect: {
    fontSize: "12px",
    fontFamily: "Hind Siliguri",
    width: "fit-content",
    height: "32px",
    borderRadius: "5px",
    color: "#46596a",
    backgroundColor: "white",
    border: "1px solid #dedede",
    "&:before": {
      borderBottom: "0px",
    },
    "&:after": {
      borderBottom: "0px",
    },
    "&:hover:not(.Mui-disabled):before": {
      borderBottom: "0px",
    },
    "& .MuiOutlinedInput-input": {
      padding: "6px 6px",
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "transparent",
    },
    "& .MuiSelect-select.MuiSelect-select": {
      paddingLeft: "8px",
    },
  },
  singleMenuItem: {
    fontSize: "12px",
    fontFamily: "Hind Siliguri",
    padding: "0px 12px",
    color: "#46596a",
    width: "auto",
  },
});

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 150,
      width: "auto",
    },
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  variant: "menu",
  getContentAnchorEl: null,
};

const StyledCheckbox = withStyles(() => ({
  root: {
    color: "#46596a !important",
    "& .MuiSvgIcon-root": {
      width: 18,
      height: 18,
    },
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}))(Checkbox);

function ShareConfigCardComponent(props) {
  let {
    email,
    user_name,
    action,
    removeUserFromList,
    sharedRunsValue,
    setUsersToShareRunsWith,
  } = props;
  let [value, setValue] = useState(null);
  const classes = useStyles();

  const changeAccessSelection = (val) => {
    setValue(val);
    if (val === "remove") {
      removeUserFromList(email, user_name);
    }
  };

  return (
    <>
      <div className="single-share-card">
        <div
          className="share-card-left"
          // data-letters={getInitialsFromEmail(email, "@")}
        >
          <Avatar
            children={getInitialsFromEmail(email, "@")}
            size="2rem"
            style={{ fontSize: "15px" }}
          />
          <div className="share-card-email">
            <label className="primary-name">{user_name}</label>
            <label className="secondary-name">{email}</label>
          </div>
        </div>
        {typeof action === "string" ? (
          <div className="share-card-action-native">{action}</div>
        ) : (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div className="share-card-action-native">
              <Select
                value={value ? value : "Edit & Run"}
                onChange={(e) => {
                  changeAccessSelection(e.target.value);
                }}
                className={classes.multiSelect}
                MenuProps={MenuProps}
              >
                {shareAccessOptions &&
                  shareAccessOptions.map((shareOption) => (
                    <MenuItem
                      key={shareOption.id}
                      value={shareOption.id}
                      disabled={shareOption.disabled}
                      className={classes.singleMenuItem}
                    >
                      {shareOption.name}
                    </MenuItem>
                  ))}
                <hr />
                <MenuItem
                  value="remove"
                  disabled={false}
                  className={classes.singleMenuItem}
                >
                  Remove
                </MenuItem>
              </Select>
            </div>
            <div className="share-runs-config-container">
              <StyledCheckbox
                checked={sharedRunsValue}
                onChange={(e) => {
                  setUsersToShareRunsWith(!sharedRunsValue, email, user_name);
                }}
              />
              <p className="share-runs-text">Share Runs</p>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  saved_configurations: state.data.saved_configurations,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShareConfigCardComponent);

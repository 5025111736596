// Import required libraries
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// Import styles
import "./AppRow.scss";

// Import custom components
import AppCard from "../AppCard/AppCard";
import noDataImage from "../../../assets/images/undraw_empty.svg";

// Import utils
import { config } from "../../config/config";

function AppRow(props) {
  let { data, title, user, match, popOverFlag, allAppsFlag, customClassName } =
    props;
  let sortedData = data;

  return (
    <div
      className={`${user.theme} apps-row ${
        customClassName ? customClassName : ""
      }`}
    >
      {title && <h1 className="apps-row-title">{title}</h1>}
      {data.length ? (
        <div
          className={`apps-row-grid ${customClassName ? customClassName : ""}`}
        >
          {sortedData.map((obj, index) => (
            <AppCard
              key={index}
              config={obj}
              name={obj.name}
              match={match}
              popOverFlag={popOverFlag}
              allAppsFlag={allAppsFlag}
              customClassName={customClassName}
            />
          ))}
        </div>
      ) : (
        <div className="no-configs">
          <img src={noDataImage} />
          <p className="no-data">
            <b>{config.messages.noConfigs}</b>
          </p>
        </div>
      )}
    </div>
  );
}

AppRow.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({ user: state.user });

export default connect(mapStateToProps)(AppRow);

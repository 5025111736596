// Import required libraries
import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Breadcrumb } from "../../uiTheme";

// Import styles
import "./Breadcrumbs.scss";

// Import Utils
import { shortFormAE, splitUrlToBreadcrumbData } from "../../utils/utils";

function Breadcrumbs(props) {
  let { match, user } = props;
  let data = splitUrlToBreadcrumbData(shortFormAE(match.url));

  return (
    <div className={`${user.theme} breadcrumb`}>
      <Breadcrumb
        children={data.map((obj, index) => {
          if (obj.label === "admin-panel") {
            obj.label = "Admin Panel";
          }
          return (
            <Link
              className="breadcrumb-item"
              key={index}
              to={obj.path}
              style={{
                pointerEvents: `${
                  data.length - 1 === index ? "none" : "cursor"
                }`,
              }}
            >
              {obj.label}
            </Link>
          );
        })}
        style={{ lineHeight: "26px" }}
      />
    </div>
  );
}

Breadcrumbs.propTypes = {
  match: PropTypes.object.isRequired,
  user: PropTypes.object,
};

const mapStateToProps = (state) => ({ user: state.user });

export default connect(mapStateToProps)(Breadcrumbs);

//Import required libraies
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

//Import Custom Component
import AddNewConfigContainer from "../../AIDEApp/AddNewConfig/AddNewConfigContainer";
import Loader from "../../../components/Loader/Loader";
import WorkInProgress from "../../../components/WorkInProgress/WorkInProgress";
import Message from "../../../components/Message/Message";

//Import style
import "./Run.scss";

//Import utils & data
import {
  makeDefaultResponseJson,
  getRunIdFromRunName,
} from "../../../utils/utils";
import { config } from "../../../config/config";

//Import actions
import {
  updateSelections,
  updateUserInfo,
  updateAlertInfo,
} from "../../../redux/actions";

function AIDERun(props) {
  let {
    match,
    user,
    updateSelections,
    updateUserInfo,
    allData,
    updateAlertInfo,
  } = props;
  const [isLoading, setIsLoading] = useState(true);
  // console.log("ACITVE RUN PAYLOAD -> ", user.selections.activeRunPayload);

  //Set activeRunId to null when this page loads
  // useEffect(() => {
  //   updateSelections("activeRunId", null);
  // }, []);

  useEffect(() => {
    if (allData.runData) {
      setIsLoading(true);
      let runId;
      if (user.selections.activeRunId) {
        runId = user.selections.activeRunId;
      } else {
        runId = getRunIdFromRunName(allData.runData, match.params.runName);
      }

      //Fetch run data if runId is present
      if (runId) {
        let url = `${config.api.runUrl}?run_id=${runId}&app_id=${config.hardCoded.aeAppId}`;
        let status;
        fetch(url)
          .then((response) => {
            console.groupCollapsed("requesting", url);
            console.log("REPSONSE -> ", response);
            status = response.status;
            return response.clone().json();
          })
          .then((json) => {
            console.log("JSON -> ", json);
            console.groupEnd();
            if (status === 200) {
              updateSelections("activeRunPayload", json.data);
              setIsLoading(false);
            } else {
              updateAlertInfo({
                open: true,
                message: json.statusMessage,
                severity: "error",
              });
            }
          });
      }
    }
  }, [user.selections.activeRunId]);

  return (
    <>
      {!isLoading ? (
        user.selections.activeRunPayload ? (
          <>
            <AddNewConfigContainer
              disableFlag={true}
              runData={user.selections.activeRunPayload}
              match={match}
            />
          </>
        ) : (
          <Redirect to={`/home/viewruns`} />
        )
      ) : (
        <Loader />
      )}
    </>
  );
}

AIDERun.propTypes = {
  user: PropTypes.object,
  match: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.user,
  allData: state.data,
});

const mapDispatchToProps = {
  updateUserInfo,
  updateSelections,
  updateAlertInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(AIDERun);

//Import required libraies
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

//Import Custom Component
import LayoutTopSideBottom from "../../layouts/LayoutTopSideBottom/LayoutTopSideBottom";
import AdminAppRow from "./AdminAppRow";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import Loader from "../../components/Loader/Loader";
import noDataImage from "../../../assets/images/undraw_empty.svg";
import AdminPanelLandingPageApps from "../../../assets/data/AdminPanelMockData/AdminPanelLandingPageApps.json";

// Import action creators

//Import data, utils
import { config } from "../../config/config";

function AdminPanelContainer(props) {
  let { match, user } = props;
  const [adminAppsLoading, setAdminAppsLoading] = useState(true);
  useEffect(() => {
    if (
      AdminPanelLandingPageApps &&
      AdminPanelLandingPageApps.data &&
      AdminPanelLandingPageApps.data.length
    ) {
      setAdminAppsLoading(false);
    }
  }, []);

  return (
    <LayoutTopSideBottom match={match}>
      <Breadcrumbs match={match} />
      <div className={`${user.theme} app-page`}>
        {user?.role !== config.hardCoded.adminRole ? (
          <>
            <div className="no-apps">
              <img src={noDataImage} />
              <p className="no-data">
                <b>You Don't have access to open admin panel</b>
              </p>
            </div>
          </>
        ) : adminAppsLoading ? (
          <Loader />
        ) : !adminAppsLoading ? (
          <>
            <AdminAppRow
              match={match}
              data={AdminPanelLandingPageApps.data}
              popOverFlag={false}
              allAppsFlag={false}
            />
          </>
        ) : (
          <>
            <div className="no-apps">
              <img src={noDataImage} />
              <p className="no-data">
                <b>You Don't have access to open admin panel</b>
              </p>
            </div>
          </>
        )}
      </div>
    </LayoutTopSideBottom>
  );
}

AdminPanelContainer.propTypes = {
  match: PropTypes.object,
  user: PropTypes.object,
  allData: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.user,
  allData: state.data,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminPanelContainer);

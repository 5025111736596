// Import required libraries
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import orderBy from "lodash/orderBy";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { Modal, Popover, Chip, Badge } from "../../uiTheme";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

// Import custom components
import Loader from "../../components/Loader/Loader";

// Import styles
import "./FiltersNew.scss";

// Import data & utils
import filtersData from "../../../assets/data/filtersData.json";
import {
  populateFiltersData,
  addAllInData,
  makeDefaultResponseJson,
} from "../../utils/utils";
import { config } from "../../config/config";

// Import action creators
import {
  updateData,
  updateSelections,
  resetFilters,
  updateUserInfo,
  updateAlertInfo,
} from "../../redux/actions";

const useStyles = makeStyles({
  typographyStyle: {
    backgroundColor: "#f3f3f3",
    //padding: "10px",
    borderRadius: "4px",
    "& .MuiChip-root": {
      fontFamily: "Hind Siliguri",
      fontSize: 12,
      border: "1px solid #dedede",
      background: "white",
      maxWidth: "250px",
    },
    "& .MuiInput-underline:after": {
      borderBottom: 0,
    },
    "& .MuiFormControl-fullWidth": {
      minWidth: "250px",
      background: "white",
      border: "1px solid #dedede",
      boxSizing: "border-box",
      borderRadius: "5px",
      padding: "5px 10px",
      // maxWidth: "900px",
      // width: "100%",
    },
    "& .MuiInput-underline:before": {
      borderBottom: 0,
      transition: "none",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: 0,
    },
    "& .Mui-focused .MuiInput-underline": {
      borderBottom: 0,
    },
    "& .MuiAutocomplete-paper": {
      fontFamily: "Hind Siliguri !important",
      fontSize: 6,
    },
    "& .MuiInputBase-input": {
      fontFamily: "Hind Siliguri",
      fontSize: 12,
    },
    "& .MuiAutocomplete-option": {
      backgrounColor: "#46596a3b",
      fontFamily: "Hind Siliguri !important",
    },
  },
  multiSelect: {
    fontSize: "12px",
    fontFamily: "Hind Siliguri",
    width: "100%",
    height: "50px",
    borderRadius: "5px",
    color: "#46596a",
    backgroundColor: "white",
    boxSizing: "border-box",
    //paddingLeft: "8px",
    display: "flex",
    alignItems: "center",
    //textAlign: "center",
    border: "1px solid #dedede",
    "&:before": {
      borderBottom: "0px",
    },
    "&:after": {
      borderBottom: "0px",
    },
    "&:hover:not(.Mui-disabled):before": {
      borderBottom: "0px",
    },
    "& .MuiOutlinedInput-input": {
      padding: "6px 6px",
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "transparent",
    },
    "& .MuiSelect-select.MuiSelect-select": {
      paddingLeft: "8px",
      // height: "25px",
    },
  },
  singleMenuItem: {
    fontSize: "13px",
    fontFamily: "Hind Siliguri",
    padding: "8px 12px",
    color: "#46596a",
    width: "auto",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    "&:hover": {
      backgroundColor: "#4EAFB3",
      color: "white",
    },
  },
  paper: {
    position: "absolute",
    width: "50%",
    minHeight: "250px",
    backgroundColor: "#FFFFFF",
    // border: "2px solid #000",
    borderRadius: "8px",
    padding: "30px",
  },
  chipStyle: {
    // marginTop: "0px",
    fontFamily: "Hind Siliguri",
    fontSize: 14,
    border: "1px solid #dedede",
    color: "white !important",
    height: "30px",
    borderRadius: "3px",
    width: "fit-content",
    maxWidth: "90%",
    margin: "12px",
    background: "#4EAFB3",
    "&:focus": {
      background: "#4EAFB3",
    },
    "& .MuiChip-deleteIcon": {
      // width: "16px",
      fontSize: 20,
      border: "none",
      color: "white",
    },
  },
  bellIconStyle: {
    "& .MuiBadge-dot": {
      backgroundColor: "#F10000 !important",
    },
    "& .MuiBadge-anchorOriginTopRightRectangle": {
      top: "2px",
      right: "-3px",
    },
  },
});

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 150,
      width: "auto",
    },
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  variant: "menu",
  getContentAnchorEl: null,
};

const StyledAutoComplete = withStyles((theme) => ({
  endAdornment: {
    display: "none",
  },
  tag: {
    // background: "white",
    color: "#46596a !important",
    fontFamily: "Hind Siliguri !important",
  },
  paper: {
    fontFamily: "Hind Siliguri",
    fontSize: 13,
    color: "#46596a",
  },
}))(Autocomplete);

function getModalStyle() {
  const top = 35;
  const left = 30;

  return {
    top: `${top}%`,
    left: `${left}%`,
    border: "none !important",
    // transform: `translate(-${top}%, -${left}%)`,
  };
}
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
};
function MyConfigurationsSwitchIcons(props) {
  let { user, updateSelections } = props;
  return (
    <div className="myconfig-switch-icon">
      <i
        className={`material-icons myconfig-icons ${
          user.selections.appView === "table" ? "active" : ""
        }`}
        onClick={() => updateSelections("appView", "table")}
      >
        toc
      </i>
      <i
        className={`material-icons myconfig-icons ${
          user.selections.appView === "grid" ? "active" : ""
        }`}
        onClick={() => updateSelections("appView", "grid")}
      >
        grid_view
      </i>
    </div>
  );
}

function FilterNew(props) {
  let {
    user,
    country,
    brand,
    segment,
    updateData,
    updateUserInfo,
    updateSelections,
    resetFilters,
    activeApp,
    allData,
    match,
    switchScheduleConfiguration,
    handleSwitch,
    handleGa360,
    ga360,
    updateAlertInfo,
  } = props;

  const initialFilters = {
    brand: "ALL",
    country: "ALL",
    segment: "ALL",
    configTags: [],
  };
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [modalStyle] = useState(getModalStyle);
  const [filterSelections, setFilterSelections] = useState(initialFilters);
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const openPopup = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  if (country && brand && !data && !loading) {
    let popFiltersData = populateFiltersData(filtersData, {
      country,
      brand,
    });
    setData(popFiltersData);
  }

  const modalOpen = () => {
    setOpenModal(true);
  };

  const modalClose = () => {
    setOpenModal(false);
  };

  const updatingSelections = (catName, trgtValue) => {
    let filterselection = filterSelections;
    filterselection[`${catName}`] = trgtValue;
    setFilterSelections({ ...filterselection });
  };

  const setFilterUpdations = () => {
    // Apply Click
    let fltrSelections = filterSelections;
    for (const prop in fltrSelections) {
      updateSelections(prop, fltrSelections[`${prop}`]);
    }
    modalClose();
  };

  const resetFiltersNew = () => {
    setFilterSelections({
      brand: "ALL",
      country: "ALL",
      segment: "ALL",
      configTags: [],
    });
    modalClose();
    resetFilters();
  };

  // Get countries
  useEffect(() => {
    let url = `${config.api.countryUrl}?app_id=${activeApp?.id}`;
    let status;
    fetch(url)
      .then((response) => {
        console.groupCollapsed("requesting", url);
        console.log("REPSONSE -> ", response);
        status = response.status;
        return response.clone().json();
      })
      .then((json) => {
        console.log("JSON -> ", json);
        console.groupEnd();
        if (status === 200) {
          updateData(
            "country",
            addAllInData(orderBy(json.data, ["name"], ["asc"]))
          );
          setLoading(false);
        } else {
          updateAlertInfo({
            open: true,
            message: json.statusMessage,
            severity: "error",
          });
        }
      });
    return () => {};
  }, [allData.apps.data]);

  // Get brands
  useEffect(() => {
    let url = `${config.api.brandUrl}?app_id=${activeApp?.id}`;
    let status;
    fetch(url)
      .then((response) => {
        console.groupCollapsed("requesting", url);
        console.log("REPSONSE -> ", response);
        status = response.status;
        return response.clone().json();
      })
      .then((json) => {
        console.log("JSON -> ", json);
        console.groupEnd();
        if (status === 200) {
          updateData(
            "brand",
            addAllInData(orderBy(json.data, ["name"], ["asc"]))
          );
        } else {
          updateAlertInfo({
            open: true,
            message: json.statusMessage,
            severity: "error",
          });
        }
      });
    return () => {};
  }, []);

  // Get tags
  useEffect(() => {
    const url = `${config.api.getTagsUrl}?app_id=${activeApp?.id}&type=config`;
    let status;
    fetch(url)
      .then((response) => {
        console.groupCollapsed("requesting", url);
        console.log("REPSONSE -> ", response);
        status = response.status;
        return response.clone().json();
      })
      .then((json) => {
        console.log("JSON -> ", json);
        console.groupEnd();
        if (status === 200)
          updateData("tagsData", {
            status: "success",
            data: orderBy([...json.data.tags]),
            message: "",
          });
        else {
          updateAlertInfo({
            open: true,
            message: json.statusMessage,
            severity: "error",
          });
        }
      });
    return () => {};
  }, [allData.apps.data]);

  useEffect(() => {
    setFilterSelections(initialFilters);
  }, [match.params.tab]);

  const delValue = (regValue) => {
    // delete
    let valArr = filterSelections.configTags.filter(
      (singleValue) => singleValue !== regValue
    );
    setFilterSelections({ ...filterSelections, configTags: valArr });
  };

  return (
    <>
      <div className="aide-filter-new">
        <div className="input-search">
          <i className={`material-icons-outlined search-icon `}>search</i>
          <input
            type="text"
            id="search"
            name="search"
            value={user.selections["searchText"]}
            className="input-label"
            onChange={(e) => {
              updateSelections("searchText", e.target.value);
            }}
            placeholder="Search"
            autoComplete="off"
          />
          <i
            className={`material-icons-outlined cancel-icon ${
              user.selections.searchText ? "visible" : ""
            }`}
            onClick={() => {
              updateSelections("searchText", "");
            }}
          >
            clear
          </i>
        </div>
        <div className="right-filter-div">
          {config.hardCoded.tabsForGa360.includes(match.params.tab) && (
            <div className={`switch-icon-container`}>
              {
                <>
                  <p className="label">PM GA360:</p>
                  {ga360 ? (
                    <i
                      className="material-icons-outlined selected-toggle-icon selected"
                      onClick={handleGa360}
                    >
                      toggle_on
                    </i>
                  ) : (
                    <i
                      className="material-icons-outlined selected-toggle-icon "
                      onClick={handleGa360}
                    >
                      toggle_off
                    </i>
                  )}
                </>
              }
            </div>
          )}
          {config.hardCoded.tabsForScheduleToggle.includes(
            match.params.tab
          ) && (
            <div
              className={`switch-icon-container ${
                allData.appAccessDetails?.feature_list?.schedule_run
                  ? "schedule-enabled"
                  : "schedule-disabled"
              }`}
            >
              {
                <>
                  <p className="label">Only Scheduled Configs:</p>
                  {switchScheduleConfiguration ? (
                    <i
                      className="material-icons-outlined selected-toggle-icon selected"
                      onClick={handleSwitch}
                    >
                      toggle_on
                    </i>
                  ) : (
                    <i
                      className="material-icons-outlined selected-toggle-icon "
                      onClick={handleSwitch}
                    >
                      toggle_off
                    </i>
                  )}
                </>
              }
            </div>
          )}
          <div className="filter-btn">
            <Badge
              variant="dot"
              invisible={
                filterSelections.country === "ALL" &&
                filterSelections.brand === "ALL" &&
                filterSelections.segment === "ALL" &&
                filterSelections.configTags.length === 0
              }
              className={classes.bellIconStyle}
            >
              <button onClick={modalOpen}>
                Filters
                <i
                  onMouseOver={openPopup}
                  className="material-icons filter-alt-icon"
                >
                  filter_alt
                </i>
              </button>
            </Badge>
            {data ? (
              <Modal
                open={openModal}
                onClose={modalClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
              >
                <div style={style} className={classes.paper}>
                  <p id="simple-modal-title" className="aide-filters-title">
                    Filters
                  </p>
                  <>
                    {data ? (
                      <div className={`${user.theme} aide-filters-container`}>
                        <div className="filters">
                          {data.map((filterObj) => (
                            <div
                              className={`filter-item ${filterObj.name}`}
                              key={filterObj.key}
                            >
                              {filterObj.type === "text" ? (
                                false
                              ) : (
                                <>
                                  <label htmlFor={filterObj.name}>
                                    Select {filterObj.label}
                                  </label>
                                  <Select
                                    name={filterObj.name}
                                    id={filterObj.name}
                                    value={
                                      filterSelections[`${filterObj.name}`]
                                    }
                                    onChange={(e) => {
                                      updatingSelections(
                                        filterObj.name,
                                        e.target.value
                                      );
                                    }}
                                    className={classes.multiSelect}
                                    MenuProps={MenuProps}
                                  >
                                    {filterObj.data.map((obj) => (
                                      <MenuItem
                                        value={obj.name}
                                        key={obj.id}
                                        className={classes.singleMenuItem}
                                      >
                                        {obj.name}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </>
                              )}
                            </div>
                          ))}
                        </div>
                        <div className="config-tags-filter">
                          <label>Select Tags</label>
                          <StyledAutoComplete
                            multiple
                            id={`tags-standard`}
                            size="small"
                            style={{ fontFamily: "Hind Siliguri !important" }}
                            limitTags={5}
                            filterSelectedOptions
                            renderTags={(tagValue, getTagProps) =>
                              tagValue.map((option, index) => (
                                <div key={option} title={option}>
                                  <Chip
                                    label={option}
                                    {...getTagProps({ index })}
                                  />
                                </div>
                              ))
                            }
                            disableClearable
                            className={classes.typographyStyle}
                            options={allData?.tagsData?.data || []}
                            getOptionLabel={(option) => option || ""}
                            value={filterSelections.configTags}
                            onChange={(e, value) => {
                              updatingSelections("configTags", value);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                //InputProps={{ style: { fontFamily:'Hind Siliguri' } }}
                                variant="standard"
                                size="small"
                                placeholder="Type a tag"
                                className={classes.typographyStyle}
                              />
                            )}
                          />
                        </div>
                      </div>
                    ) : (
                      <Loader />
                    )}
                  </>
                  <div className="aide-filters-menu">
                    <button
                      className="reset-filter-btn"
                      onClick={() => {
                        resetFiltersNew();
                      }}
                    >
                      Reset Filters
                    </button>
                    <button
                      className="apply-filter-btn"
                      onClick={setFilterUpdations}
                    >
                      Apply
                    </button>
                  </div>
                </div>
              </Modal>
            ) : (
              <></>
            )}
          </div>
          <MyConfigurationsSwitchIcons
            user={user}
            updateSelections={updateSelections}
          />
        </div>
      </div>
      {
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          PaperProps={{
            style: { width: "20%" },
          }}
        >
          <div className="aide-filter-popover">
            <div className="row filter-popover-label">Country</div>
            <div className="row">
              {filterSelections.country != "ALL" ? (
                <Chip
                  label={filterSelections.country}
                  onDelete={() => {
                    updateSelections("country", "ALL");
                    updatingSelections("country", "ALL");
                  }}
                  deleteIcon={<i className="material-icons-outlined">clear</i>}
                  style={{ margin: "5px", height: "30px" }}
                />
              ) : (
                <div className="no-selections">No Selections Available</div>
              )}
            </div>
            <div className="row filter-popover-label">Brand</div>
            <div className="row">
              {filterSelections.brand != "ALL" ? (
                <Chip
                  label={filterSelections.brand}
                  onDelete={() => {
                    updateSelections("brand", "ALL");
                    updatingSelections("brand", "ALL");
                  }}
                  deleteIcon={<i className="material-icons-outlined">clear</i>}
                  style={{ margin: "5px", height: "30px" }}
                />
              ) : (
                <div className="no-selections">No Selections Available</div>
              )}
            </div>
            <div className="row filter-popover-label">Tags</div>
            <div className="row">
              {filterSelections.configTags.length ? (
                filterSelections.configTags.map((elem) => (
                  <Chip
                    key={elem}
                    label={elem}
                    onDelete={() => {
                      delValue(elem);
                      let valArr = filterSelections.configTags.filter(
                        (singleValue) => singleValue !== elem
                      );
                      updateSelections("configTags", valArr);
                    }}
                    deleteIcon={
                      <i className="material-icons-outlined">clear</i>
                    }
                    style={{ margin: "5px", height: "30px" }}
                  />
                ))
              ) : (
                <div className="no-selections">No Selections Available</div>
              )}
            </div>
          </div>
        </Popover>
      }
    </>
  );
}

FilterNew.propTypes = {
  match: PropTypes.object,
  user: PropTypes.object,
  country: PropTypes.array,
  brand: PropTypes.array,
  segment: PropTypes.array,
  updateData: PropTypes.func,
  updateSelections: PropTypes.func,
};

const mapStateToProps = (state) => ({
  user: state.user,
  country: state.data.country,
  brand: state.data.brand,
  segment: state.data.segment,
  allData: state.data,
});

const mapDispatchToProps = {
  updateData,
  updateSelections, //actions are being dispatched using these functions,
  resetFilters,
  updateUserInfo,
  updateAlertInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(FilterNew);

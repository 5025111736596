// Import required libraries
import React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

// Import utils & data
import {
  timeDiffCalc,
  formatDate,
  makeRunNameFromRun,
  makeDefaultResponseJson,
} from "../../../utils/utils";
import { config } from "../../../config/config";

// Import actions
import {
  updateData,
  updateUserInfo,
  updateSelections,
  updateAlertInfo,
  reloadRunData,
  updateDialogInfo,
} from "../../../redux/actions";

// Import styles
import "./ViewRunsSideDiv.scss";

// Defining custom components
const ViewrunsRow = (props) => {
  let {
    match,
    user,
    runCat,
    run,
    updateUserInfo,
    updateSelections,
    updateAlertInfo,
    reloadRunData,
    updateDialogInfo,
  } = props;
  const history = useHistory();
  let app = config.hardCoded.aeAppName;
  let activeFlag = match.params.runName === makeRunNameFromRun(run);

  const handleDelete = () => {
    updateDialogInfo({ ...user.dialogInfo, open: false });
    console.groupCollapsed("DELETE REQUEST SENT...");
    updateAlertInfo({
      open: true,
      message: config.messages.runDeleteRequest,
      severity: "info",
    });
    let url = `${config.api.runUrl}?run_id=${run.id}&app_id=${config.hardCoded.aeAppId}`;
    let status;
    fetch(url, {
      method: "DELETE",
    })
      .then((response) => {
        console.groupCollapsed("requesting", url);
        console.log("REPSONSE -> ", response);
        status = response.status;
        return response.clone().json();
      })
      .then((json) => {
        console.log("JSON -> ", json);
        console.groupEnd();
        if (status === 200) {
          updateAlertInfo({
            open: true,
            message: config.messages.runDeleteSuccess,
            severity: "success",
          });
          reloadRunData();
        } else {
          updateAlertInfo({
            open: true,
            message: json.statusMessage,
            severity: "error",
          });
        }
      });
  };

  const handleNo = () => {
    updateDialogInfo({ ...user.dialogInfo, open: false });
  };

  //On Click of Run Item
  const handleRunItemNavigation = (run) => {
    updateSelections("activeRunId", run.id);
    history.push(`/home/viewruns`);
  };

  return (
    <>
      <div
        className={`run-item disabled-style ${runCat.id} ${
          activeFlag ? "active" : ""
        }`}
        title={run.name}
        onClick={() => {
          handleRunItemNavigation(run);
        }}
        title={run.metadata.config_name}
      >
        <div className="run-title">
          <div className="run-text-container">
            <div className={`run-icon ${run.status}`}></div>
            <div className="run-text">{run.metadata.config_name}</div>
          </div>
          <div className="run-menu-container">
            {runCat.id !== config.hardCoded.runRunningId && (
              <i
                className="material-icons-outlined delete-icon"
                onClick={(e) => {
                  e.stopPropagation();
                  updateDialogInfo({
                    message: config.messages.confirmDelete,
                    open: true,
                    handleYes: handleDelete,
                    handleNo: handleNo,
                  });
                }}
              >
                delete
              </i>
            )}
          </div>
        </div>
        <div className="run-sub-title">
          {runCat.id === config.hardCoded.runRunningId && (
            <p className={`run-text ${run.status}`}>
              {config.messages.runActive}
            </p>
          )}
          {runCat.id !== config.hardCoded.runRunningId && (
            <p className="run-text">{formatDate(run.created_on)}</p>
          )}
          {runCat.id === config.hardCoded.runPrevRunsId && (
            <p className="run-text">
              {timeDiffCalc(run.end_time, run.start_time)}
            </p>
          )}
        </div>
      </div>
      <hr className="run-item-separator" />
    </>
  );
};

ViewrunsRow.propTypes = {};

const mapStateToProps = (state) => ({
  user: state.user,
  allData: state.data,
});

const mapDispatchToProps = {
  updateData,
  updateUserInfo,
  updateSelections,
  updateAlertInfo,
  reloadRunData,
  updateDialogInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewrunsRow);

//Import required libraies
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

//Import custom components
import Loader from "../../../../components/Loader/Loader";
import LocalParameterItem from "./LocalParameterItem";

//Import utils
import { config } from "../../../../config/config";
import {
  makeDefaultResponseJson,
  addRunNameToRunData,
  getStartEndByDayCat,
} from "../../../../utils/utils";
import dayCat from "../../../../../assets/data/dayCat.json";

//Import actions
import { updateUserInfo } from "../../../../redux/actions";

//Import styles
import "./TargetCommunity.scss";

const initialTargetData = {
  runs: { status: "loading", message: "", data: [] },
};

let initialSelections = {
  run_id: "",
  community_clustering_run_type: "target",
  config_id: "",
  dayCat: "all",
  community_id_list: [],
  audience_name: "",
};

function TargetCommunity(props) {
  let { match, onChange, userInputs, updateUserInfo, disableFlag } = props;
  let initialSelectionsFromConfigUserInputs = userInputs.models.find(
    (model) => model.id === config.hardCoded.communityClusteringId
  );
  const [loading, setLoading] = useState(true);
  const [targetData, setTargetData] = useState(initialTargetData);
  const [selections, setSelections] = useState({
    ...initialSelections,
    ...initialSelectionsFromConfigUserInputs.config,
    dayCat: initialSelections.dayCat,
    configId: userInputs.configId,
  });

  //Defining required variables
  const runIdFinal = targetData.runs.data.length
    ? selections["run_id"]
    : config.messages.noRuns;
  const runsDataFinal = targetData.runs.data.length
    ? targetData.runs.data
    : [
        {
          id: config.messages.noRuns,
          name: config.messages.noRuns,
        },
      ];
  const errorFlagFinal = targetData.runs.status === "error";
  const errorMessageFinal =
    targetData.runs.status === "error" ? targetData.runs.message : "";

  //updates user selections
  const onChangeCurr = (key, value) => {
    setSelections({ ...selections, [key]: value });
    onChange(key, value);
  };

  //updated data coming from api
  const updateTargetData = (key, value) => {
    setTargetData((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  //Changing loading to false when component mounts
  useEffect(() => {
    setLoading(false);
  }, []);

  //Fetch runs data
  useEffect(() => {
    const url = config.api.runUrl;
    const body = {
      app_id: userInputs.app_id,
      country: userInputs.country,
      brand: userInputs.brand.toUpperCase(),
      segment:
        userInputs.segment.toString() === "ALL" ? null : userInputs.segment,
      search_text: selections.configId,
      offset: 0,
      limit: 100,
      order_by: "created_on",
      category: ["Success"],
      models: [config.hardCoded.communityClusteringId],
      run_type_key: config.hardCoded.communityRunKey, //key to get initial run details
      run_type_value: config.hardCoded.initialRunId, //key to get pilot run details
      ...getStartEndByDayCat(disableFlag ? "" : selections.dayCat),
      is_shared: true,
    };
    let status;
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        console.groupCollapsed("requesting", url);
        console.log("REPSONSE -> ", response);
        status = response.status;
        return response.clone().json();
      })
      .then((json) => {
        console.log("JSON -> ", json);
        console.groupEnd();
        if (status === 200) {
          let newRunData = {
            status: "success",
            message: "",
            data: addRunNameToRunData(json.data),
          };
          updateTargetData("runs", newRunData);
        } else {
          let newRunData = {
            status: "error",
            message: json.statusMessage,
            data: [],
          };
          updateTargetData("runs", newRunData);
        }
      });

    return () => {
      updateTargetData("runs", { status: "loading", message: "", data: [] });
    };
  }, [selections.dayCat]);

  //Update run_id if runs changes
  useEffect(() => {
    if (targetData.runs.status === "success") {
      let run_id = selections.run_id;
      let isTargetRunIdValid = true;
      if (targetData.runs.data.length) {
        let runIds = targetData.runs.data.map((run) => run.id);
        if (!runIds.includes(selections.run_id)) {
          run_id = targetData.runs.data[0].id;
          isTargetRunIdValid = false;
        }
      } else {
        run_id = "";
        isTargetRunIdValid = false;
      }
      if (!isTargetRunIdValid) {
        onChangeCurr("run_id", run_id);
      }
    }
  }, [targetData.runs]);

  // console.groupCollapsed("--------------STATE CHANGED--------------");
  // console.log("USER INPUTS -> ", userInputs);
  // console.log("PROPS -> ", props);
  // console.log("NESTED RUNS -> ", targetData);
  // console.log("SELECTIONS -> ", selections);
  // console.groupEnd();

  return (
    <>
      {targetData.runs.status === "loading" && <Loader />}

      {targetData.runs.status === "success" && (
        <>
          <LocalParameterItem
            ui_element_type="dropdown"
            value={selections["dayCat"]}
            onChange={onChangeCurr}
            enable={true}
            show={true}
            name="Select Runs For"
            id="dayCat"
            data={dayCat}
            userInputs={userInputs}
            disableFlag={disableFlag}
          />
          <LocalParameterItem
            ui_element_type="dropdown"
            value={runIdFinal}
            onChange={onChangeCurr}
            enable={targetData.runs.data.length ? true : false}
            show={true}
            name="Select Initial Run"
            id="run_id"
            data={runsDataFinal}
            userInputs={userInputs}
            disableFlag={disableFlag}
          />
        </>
      )}
      {errorFlagFinal && <p className="info-message">{errorMessageFinal}</p>}
    </>
  );
}

TargetCommunity.propTypes = {};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = { updateUserInfo };

export default connect(mapStateToProps, mapDispatchToProps)(TargetCommunity);

// Import required libraries
import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
// import Button from "@material-ui/core/Button";
import { Button } from "../../../uiTheme";
import { makeStyles } from "@material-ui/core/styles";

// Import utils & data

// Import actions
import {
  updateAlertInfo,
  updateUserInfo,
  updateDialogInfo,
} from "../../../redux/actions";

const useStyles = makeStyles((theme) => ({
  contained: {
    color: "white",
    backgroundColor: "#46596A",
    fontFamily: "Hind Siliguri",
    fontSize: "12px",
    fontWeight: "bold",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "#46596A",
      boxShadow: "none",
    },
  },
  outlined: {
    borderColor: "#46596A",
    color: "#46596A",
    fontFamily: "Hind Siliguri",
    fontSize: "12px",
    fontWeight: "bold",
    textTransform: "capitalize",
  },
  root: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    "& .MuiButton-root:hover": {
      backgroundColor: "transparent",
    },
    "& .MuiButton-contained:hover": {
      backgroundColor: "#46596A",
      boxShadow: "none",
    },
    "& .MuiButton-contained": {
      boxShadow: "none",
    },
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
  },
}));

function Buttons({ handleSaveUpdate, disableFlag, isEdit }) {
  const classes = useStyles();
  return (
    <>
      <span className={classes.root}>
        <Link className="disabled-style" to={"/home/admin-panel/users"}>
          <Button
            variant="outlined"
            style={{ fontWeight: "bold", fontSize: "12px" }}
          >
            Cancel
          </Button>
        </Link>

        <Button
          disabled={disableFlag}
          disableRipple
          variant="contained"
          onClick={handleSaveUpdate}
          style={{ fontWeight: "bold", marginLeft: "12px", fontSize: "12px" }}
        >
          {isEdit ? "Update User" : "Create User"}
        </Button>
      </span>
    </>
  );
}

Buttons.propTypes = {};

const mapStateToProps = (state) => ({
  user: state.user,
  configUserInputs: state.configUserInputs,
  allData: state.data,
});

const mapDispatchToProps = {
  updateAlertInfo,
  updateUserInfo,
  updateDialogInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(Buttons);

import React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import "../index.css";

/**
 * [BasicButton description]
 * @param {[string]} theme [The theme of the component - default: light]
 * @param {[node]} children [The content of the component.]
 * @param {[string]} styleVariant [Used to represent colored interface elements for a user - default: primary]
 * @param {[string]} variant [The variant to use - default: contained]
 * @param {[object]} style [any extra styling]
 * @param {[any]} ...props [Other valid props for MUI Button]
 * @returns {[object]} [Returns the Button component]
 */
const BasicButton = (props) => {
  const {
    children,
    theme = "light",
    styleVariant = "primary",
    variant = "contained",
    style = {},
    ...remaining
  } = props;

  const finalStyle = {
    padding: "var(--button-padding)",
    fontSize: "var(--font-size-XXS)",
    fontFamily: "var(--fontFamily)",
    textTransform: "capitalize",
    color: `var(--${styleVariant}-textcolor-${theme})`,
    ...(variant === "contained" && {
      color: `var(--contained-${styleVariant}-textcolor-${theme})`,
      backgroundColor: `var(--contained-${styleVariant}-bgcolor-${theme})`,
    }),
    ...(variant === "outlined" && {
      color: `var(--outlined-${styleVariant}-textcolor-${theme})`,
      border: `var(--outlined-${styleVariant}-border-${theme})`,
    }),
    ...style,
  };

  return (
    <Button variant={variant} style={finalStyle} {...remaining}>
      {children}
    </Button>
  );
};

BasicButton.propTypes = {
  children: PropTypes.node,
  theme: PropTypes.oneOf(["light", "dark"]),
  styleVariant: PropTypes.oneOf(["primary", "secondary"]),
  variant: PropTypes.oneOf(["contained", "outlined", "text"]),
};

export default BasicButton;

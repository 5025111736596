import React, { useEffect, useState } from "react";

import { config } from "../../../../../config/config";
import LocalParameterItem from "../LocalParameterItem";
import noDataImage from "../../../../../../assets/images/undraw_empty.svg";
import { Checkbox, MenuItem, Select, withStyles } from "@material-ui/core";
import {
  muiCommonStyles,
  datasetSelectStyle,
} from "../../../../../../styles/styles";
import { toggleDatasetInCommunity } from "../../../../../redux/actions";
import { connect } from "react-redux";
import { useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import UploadAudienceCheckbox from "../UploadAudienceCheckbox";

function CommunityClusteringModel(props) {
  const {
    activeModelId,
    match,
    activeModel,
    selectedModel,
    configUserInputs,
    updateModelLocalParameters,
    disableFlag,
    changeFlag,
    loading,
    activeApp,
    allData,
    updateCheckDE,
    nestedRunsArray,
    toggleDatasetInCommunity,
  } = props;
  let localParameters = activeModel.local_parameters;
  const muiClass = muiCommonStyles();
  const singleSelect = datasetSelectStyle();
  let datasetName = allData.datasets;
  if (configUserInputs?.cmu_status?.data?.datasets?.length) {
    datasetName = datasetName.filter((dataset) =>
      configUserInputs?.cmu_status?.data?.datasets?.includes(dataset.id)
    );
  }

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 150,
        width: "auto",
      },
    },
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "left",
    },
    variant: "menu",
    getContentAnchorEl: null,
  };

  // if there is an on demand config with run type initial
  // then the third step "UA" will be disabled means user can not jump from initial to UA
  // data Tempering
  let data = useMemo(() => {
    return selectedModel.id === config.hardCoded.communityClusteringId &&
      selectedModel.config?.community_clustering_run_type
      ? [
          {
            id: "initial",
            name: "Initial",
            disabled: false,
          },
          {
            id: "target",
            name: "Target Community",
            disabled: false,
          },
          {
            id: "upload_audience",
            name: "Upload Audience Data",
            disabled: false,
          },
        ]
      : configUserInputs.configType !== "run" &&
        selectedModel.id === config.hardCoded.propensityId &&
        typeof selectedModel.config.upload_audience === "undefined" &&
        selectedModel.config.propensity_modeling_run_type !== "upload_audience"
      ? nestedRunsArray[0]?.data.filter((el) => el.id !== "upload_audience")
      : nestedRunsArray[0]?.data;
  }, [selectedModel]);

  let runTypeData = data;

  useEffect(() => {
    if (
      (selectedModel.config.community_clustering_run_type ||
        selectedModel.config.propensity_modeling_run_type) ===
        config.hardCoded.initialRunId &&
      !configUserInputs.is_schedule_config
    ) {
      runTypeData = runTypeData?.map((data) => {
        if (data.id === config.hardCoded.uploadAudienceRunId) {
          return {
            ...data,
            disabled: true,
          };
        } else {
          return data;
        }
      });
    }
  }, [selectedModel]);

  return (
    <>
      <div className="models-selections-column  right">
        {
          <div
            className={`models-selection-content right 
            ${localParameters?.length ? "" : "no-modelconfig-container"}  `}
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fill, minmax(400px, 1fr))",
              height: "auto",
              padding: "30px 12px 10px 12px",
            }}
          >
            <div className="input-sub-container">
              <div
                className={`input-field-sub-container ${
                  configUserInputs.configType === "run"
                    ? "disabled-pointer"
                    : ""
                }`}
              >
                <label
                  style={{
                    display: "inline-block",
                    whiteSpace: "nowrap",
                    marginRight: "0px",
                  }}
                >
                  Select Dataset:
                </label>
                <div className="input-text-box" style={{ width: "380px" }}>
                  <Select
                    name="scoring-frequency"
                    id="scoring-frequency"
                    value={selectedModel?.data_sources[0]?.name || ""}
                    onChange={(e) => {
                      const data = allData.datasets.find(
                        (dataset) => dataset.name === e.target.value
                      );
                      toggleDatasetInCommunity({
                        ...data,
                        modelId: activeModelId,
                      });
                    }}
                    className={singleSelect.singleSelect}
                    MenuProps={MenuProps}
                  >
                    <MenuItem
                      disabled
                      value=""
                      className={muiClass.singleMenuItem}
                    >
                      Select option
                    </MenuItem>
                    {datasetName.map((obj) => (
                      <MenuItem
                        value={obj.id}
                        key={obj.id}
                        className={muiClass.singleMenuItem}
                      >
                        {obj.name}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </div>
            </div>
            {localParameters?.length ? (
              <>
                {localParameters
                  ?.filter(
                    (obj) => !config.hardCoded.mulipleRunType.includes(obj.id)
                  )
                  .map((obj) => {
                    let value = selectedModel.config[obj.id];
                    let onChange = (key, value) => {
                      let data = {
                        modelId: activeModelId,
                        key,
                        value,
                      };
                      updateModelLocalParameters(data);
                    };
                    return (
                      <LocalParameterItem
                        key={obj.id}
                        {...obj}
                        value={value}
                        onChange={onChange}
                        userInputs={configUserInputs}
                        allCampaigns={configUserInputs.campaignsData}
                        userSelectedCampaigns={value}
                        activeModel={activeModel}
                        disableFlag={disableFlag}
                        match={match}
                        changeFlag={changeFlag}
                        loading={loading}
                        activeApp={activeApp}
                        userData={allData}
                        updateCheckDE={updateCheckDE}
                        selectedModel={selectedModel}
                      />
                    );
                  })}
                <UploadAudienceCheckbox
                  configUserInputs={configUserInputs}
                  selectedModel={selectedModel}
                  uaAccess={
                    allData.appAccessDetails.feature_list
                      .community_clustering_audience_data.write
                  }
                />
              </>
            ) : (
              // <p className="no-info-center">No parameters available</p>
              <div className="no-info-holder">
                <img src={noDataImage} />
                <p>{config.messages.noConfigs}</p>
              </div>
            )}
          </div>
        }
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  changeFlag: state.changeFlag,
  configUserInputs: state.configUserInputs,
  models: state.configUserInputs.models,
});

const mapDispatchToProps = {
  toggleDatasetInCommunity,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CommunityClusteringModel);

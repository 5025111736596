import { config } from "../../../../config/config";
import LocalParameterItem from "./LocalParameterItem";
import noDataImage from "../../../../../assets/images/undraw_empty.svg";
import { useEffect } from "react";

function NLPModelParameters(props) {
  const {
    activeModelId,
    match,
    activeModel,
    selectedModel,
    configUserInputs,
    updateModelLocalParameters,
    disableFlag,
    changeFlag,
    loading,
    activeApp,
    allData,
    updateCheckDE,
  } = props;

  let localParams = activeModel?.local_parameters;

  if (configUserInputs.configType === "run") {
    if (
      selectedModel?.config?.threshold_imp &&
      selectedModel?.config?.num_queries &&
      !localParams.find((e) => e.id === "threshold_imp") &&
      !localParams.find((e) => e.id === "num_queries")
    ) {
      localParams = [
        ...localParams,
        {
          id: "threshold_imp",
          ui_element_type: "inputbox_number",
          name: "Minimum number of impressions",
          minValue: 50,
          show: true,
          enable: true,
        },
        {
          id: "num_queries",
          name: "Number of top queries per topic",
          ui_element_type: "inputbox_number",
          minValue: 5,
          show: true,
          enable: true,
        },
      ];
    }
    if (typeof selectedModel?.config?.semrush === "undefined") {
      localParams = localParams.filter((el) => el.id !== "semrush");
    }
  }

  return (
    <div
      className="nlp-model-params-container"
      style={{
        display:
          typeof selectedModel?.config?.semrush === "undefined"
            ? "grid"
            : "block",
      }}
    >
      {localParams?.length ? (
        localParams
          ?.filter((obj) => !config.hardCoded.mulipleRunType.includes(obj.id))
          .map((obj) => {
            let value = selectedModel.config[obj.id];
            let onChange = (key, value) => {
              let data = {
                modelId: activeModelId,
                key,
                value,
              };
              updateModelLocalParameters(data);
            };
            return (
              <LocalParameterItem
                key={obj.id}
                {...obj}
                value={value}
                onChange={onChange}
                userInputs={configUserInputs}
                allCampaigns={configUserInputs.campaignsData}
                userSelectedCampaigns={value}
                activeModel={activeModel}
                disableFlag={disableFlag}
                match={match}
                changeFlag={changeFlag}
                loading={loading}
                activeApp={activeApp}
                userData={allData}
                updateCheckDE={updateCheckDE}
                selectedModel={selectedModel}
              />
            );
          })
      ) : (
        // <p className="no-info-center">No parameters available</p>
        <div className="no-info-holder">
          <img src={noDataImage} />
          <p>{config.messages.noConfigs}</p>
        </div>
      )}
    </div>
  );
}
export default NLPModelParameters;

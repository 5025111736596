//Import required libraies
import { setWeek } from "date-fns";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
// import Button from "@material-ui/core/Button";
import { Button } from "../../../../uiTheme";
import { v4 } from "uuid";

//Import utils
import { config } from "../../../../config/config";
import { getWeekBasedOnHistoricWeek } from "../../../../utils/utils";

// Import custom components
import LocalParameterItem from "./LocalParameterItem";

//Import Styles
import "./MultisetPopup.scss";

const useStyles = makeStyles((theme) => ({
  contained: {
    color: "#46596a",
    backgroundColor: "transparent",
    marginRight: 4,
    marginTop: "28px",
    padding: "0px 5px",
    borderRadius: "3px",
    paddingTop: "2px",
    fontSize: "12px",
    height: 23,
    minWidth: 15,
    border: "1px solid #46596a",
    textTransform: "capitalize",
    fontFamily: "Hind Siliguri",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#f1f1f1",
      boxShadow: "none",
    },
  },
}));

const validateInput = (userInputs, allData) => {
  let validFlag = true;
  allData.forEach((lp) => {
    if (
      lp.ui_element_type === "calender_picker" &&
      userInputs.pilot_start !== "null"
    ) {
      validFlag = true;
    } else if (
      lp.ui_element_type === "inputbox_text" &&
      userInputs.pagePath !== ""
    ) {
      validFlag = true;
    } else {
      let maxVal = lp.maxValue == "null" ? 1000000.0 : parseFloat(lp.maxValue);
      let minVal =
        lp.minValue == "null" ? Number.MIN_VALUE : parseFloat(lp.minValue);
      let value = userInputs[lp.id];
      if (
        parseFloat(value) < parseFloat(minVal) ||
        parseFloat(value) > parseFloat(maxVal) ||
        isNaN(value) ||
        value === ""
      ) {
        validFlag = false;
      }
    }
  });
  return validFlag;
};

function MultisetPopupRow(props) {
  let {
    id,
    name,
    data,
    value,
    modelConfigId,
    onChange,
    initialData,
    enable,
    show,
    userInputs,
    customClassName,
    setLengthError,
    index,
  } = props;
  const [currData, setCurrData] = useState(initialData);
  const [edit, setEdit] = useState(false);
  const [error, setError] = useState(false);

  const onChangeCurr = (key, value) => {
    setCurrData({ ...currData, [key]: value });
  };

  const handleDelete = () => {
    setLengthError(false);
    let newData = value.filter((item, index) => item.id !== id);
    onChange(modelConfigId, newData);
  };

  const handleUpdate = () => {
    if (validateInput(currData, data)) {
      let newData = value.map((item) =>
        item.id !== id ? { ...item } : { ...currData }
      );
      onChange(modelConfigId, newData);
      setError(false);
      setEdit(false);
    } else {
      setError(true);
    }
  };

  useEffect(() => {
    if (validateInput(currData, data)) {
      setError(false);
    } else {
      setError(true);
    }
  }, [currData]);

  return (
    <div className="multiset-popup-row-container">
      {modelConfigId === config.hardCoded.combinationsSalesId && (
        <p>Test Region Set {index + 1}</p>
      )}
      <div
        className={`multiset-popup-row ${
          customClassName ? customClassName : ""
        } ${modelConfigId}`}
      >
        {data.map((item) => (
          <LocalParameterItem
            key={item.id}
            {...item}
            value={currData[item.id]}
            onChange={onChangeCurr}
            enable={edit}
            show={show}
            userInputs={userInputs}
          />
        ))}
        {/* <button className="add-button" onClick={() => handleDelete()}>
          Delete
        </button> */}
        <i
          className="material-icons-outlined delete-icon"
          onClick={() => handleDelete()}
        >
          delete
        </i>
        {edit ? (
          <i
            className="material-icons-outlined done-icon"
            onClick={() => {
              handleUpdate();
            }}
          >
            done
          </i>
        ) : (
          <i
            className="material-icons-outlined edit-icon"
            onClick={() => {
              setEdit(true);
            }}
          >
            edit
          </i>
        )}
      </div>
      {/* {error && <p className="error">{config.messages.combinationError}</p>} */}
    </div>
  );
}

function MultisetPopup(props) {
  let {
    name,
    data,
    value,
    modelConfigId,
    onChange,
    enable,
    show,
    userInputs,
    customClassName,
  } = props;
  const classes = useStyles();
  let initialData = {};
  if (value?.length) {
    initialData = { ...value[value.length - 1] };
  } else {
    data.forEach((element) => {
      if (element.ui_element_type === "inputbox_number") {
        initialData[element.id] = parseFloat(element["minValue"]) || 0.0;
      } else if (element.ui_element_type === "inputbox_text") {
        initialData[element.id] = element["value"] || "";
      } else {
        initialData[element.id] =
          element["min"] === "null"
            ? userInputs.global_week_date_selection ===
              config.hardCoded.historicWeek
              ? getWeekBasedOnHistoricWeek(userInputs.historic_week_data).max
              : userInputs.endDate
            : element["min"];
      }
    });
  }

  const [currData, setCurrData] = useState(initialData);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [allowedLength, setAllowedLength] = useState();
  const [lengthError, setLengthError] = useState(false);

  // console.groupCollapsed("STATE CHANGED");
  // console.log("DATA -> ", data);
  // console.log("CURR DATA -> ", currData);
  // console.log("VALUE -> ", value);
  // console.groupEnd();

  useEffect(() => {
    if (validateInput(currData, data)) {
      setError(false);
    } else {
      setError(true);
    }
  }, [currData]);

  const onChangeCurr = (key, value) => {
    setCurrData({ ...currData, [key]: value });
  };

  const addCombination = () => {
    if (
      value.length >= config.hardCoded.regressionBudgetSets &&
      modelConfigId == config.hardCoded.combinationsId
    ) {
      setAllowedLength(config.hardCoded.regressionBudgetSets);
      setLengthError(true);
    } else if (
      value.length >= config.hardCoded.salesUpliftSets &&
      modelConfigId == config.hardCoded.combinationsSalesId
    ) {
      setAllowedLength(config.hardCoded.salesUpliftSets);
      setLengthError(true);
    } else if (validateInput(currData, data)) {
      onChange(modelConfigId, [...value, { ...currData, id: v4() }]);
      setCurrData(currData);
      setError(false);
    } else {
      setError(true);
    }
  };

  useEffect(() => {
    setLoading(false);
  }, []);

  // useEffect(() => {
  //   console.log("Multiset Mounted");
  //   return () => console.log("Multiset Unmounted");
  // }, []);

  // useEffect(() => {
  //   if (!loading) {
  //     console.log("Multiset Updated");
  //   }
  // });

  return (
    <div>
      <p className="header-text bold">{name}</p>
      {value.length
        ? value.map((item, index) => (
            <MultisetPopupRow
              key={item.id}
              id={item.id}
              name={name}
              data={data}
              value={value}
              onChange={onChange}
              userInputs={userInputs}
              modelConfigId={modelConfigId}
              initialData={item}
              enable={enable}
              show={show}
              customClassName={customClassName}
              setLengthError={setLengthError}
              index={index}
            />
          ))
        : null}
      <div
        className={`multiset-popup-row ${
          customClassName ? customClassName : ""
        } ${modelConfigId}`}
      >
        {data.map((item) => (
          <LocalParameterItem
            key={item.id}
            {...item}
            value={currData[item.id]}
            onChange={onChangeCurr}
            enable={enable}
            show={show}
            userInputs={userInputs}
          />
        ))}
        {/* <i
          className="material-icons done-icon"
          onClick={() => {
            addCombination();
          }}
        >
          check_circle
        </i> */}
        <Button
          variant="contained"
          // className={classes.contained}
          onClick={() => {
            addCombination();
          }}
          style={{
            color: "#46596a",
            border: "1px solid #46596a",
            height: "23px",
            padding: "0px 5px",
            fontSize: "12px",
            minWidth: "15px",
            boxShadow: "none",
            marginTop: "28px",
            paddingTop: "2px",
            marginRight: "4px",
            borderRadius: "3px",
            textTransform: "capitalize",
            backgroundColor: "transparent",
            borderRadius: "4px",
          }}
        >
          Add Set
        </Button>
      </div>
      {lengthError && (
        <p className="error">Cant add records more than {allowedLength}</p>
      )}
    </div>
  );
}

export default MultisetPopup;

//Import required libraies
import React, { useState } from "react";
import { connect } from "react-redux";

//Import custom components
import ScheduleConfigContent from "./ScheduleConfigContent";

//Import utils
import {
  defaultTimeZone,
  getTodayDate,
  getCurrentEpochHour,
} from "../../utils/utils";

//Import actions
import {
  updateAlertInfo,
  reloadConfigs,
  updateUserInfo,
} from "../../redux/actions";

// Import custom hooks

//Import styles

function ScheduleConfigContainer(props) {
  const { config, activeApp } = props;

  const [scheduleRunData, setScheduleRunData] = useState({
    configurationId: config.id,
    configurationName: config.name,
    scheduleType: config?.schedule_details ? "edit" : "add",
    frequencyType: config?.schedule_details
      ? config?.schedule_details?.frequency_type
      : "run_once",
    frequencyValue: config?.schedule_details
      ? config?.schedule_details?.frequency_value
      : getTodayDate(defaultTimeZone(), "DD/MM/YYYY"),
    triggerTime: config?.schedule_details
      ? config?.schedule_details?.trigger_time
      : getCurrentEpochHour(defaultTimeZone()),
    timeZone: config?.schedule_details
      ? config?.schedule_details?.time_zone
      : defaultTimeZone(),
  });

  return (
    <ScheduleConfigContent
      {...props}
      scheduleRunData={scheduleRunData}
      setScheduleRunData={setScheduleRunData}
    />
  );
}

const mapDispatchToProps = {
  updateAlertInfo,
  updateUserInfo,
  reloadConfigs,
};

export default connect(null, mapDispatchToProps)(ScheduleConfigContainer);

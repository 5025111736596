import C from "../constants";
import runCategories from "../../../assets/data/runCategories.json";

// function runData(state = [], payload) {
//   let { type, data } = payload;
//   let newState;
//   switch (type) {
//     case C.UPDATE_RUN_DATA:
//       if (!data) return data;
//       newState = runCategories.map((runCat) => ({
//         ...runCat,
//         data: data.filter((run) => runCat.statusList.includes(run.status)),
//       }));
//       return newState;
//     default:
//       return state;
//   }
// }
function runData(state = [], payload) {
  let { type, data } = payload;
  let newState;
  switch (type) {
    case C.UPDATE_RUN_DATA:
      if (!data) return data;
      newState = data.map((e) => {
        return { ...e, ...{ isExpanded: e.isExpanded || false } };
      });
      return newState;
    default:
      return state;
  }
}

export default function data(state = {}, payload) {
  let { type, key, data } = payload;
  switch (type) {
    case C.UPDATE_DATA:
      return { ...state, [key]: data };
    case C.UPDATE_RUN_DATA:
      return { ...state, runData: runData(state.runData, payload) };
    case C.UPDATE_RUN_EXPAND_INFO:
      const newState = {
        ...state,
        runData: state.runData.map((el) => {
          if (el.config_id === key) {
            return { ...el, isExpanded: !el.isExpanded };
          } else {
            return el;
          }
        }),
      };
      return newState;
    case C.UPDATE_SHARED_RUN_DATA:
      return { ...state, sharedRunsData: data };
    case C.APP_LIST_RECEIVED:
      return { ...state, adminApps: { status: "success", message: "", data } };
    case C.COUNTRY_LIST_RECEIVED:
      return { ...state, country: data };
    case C.BRAND_LIST_RECEIVED:
      return { ...state, brand: data };
    case C.ADMIN_GLOBAL_PARAMS_RECEIVED:
      return { ...state, adminGlobalParams: data };
    default:
      return state;
  }
}

import React from "react";
import { useRouteMatch, Route, useHistory } from "react-router-dom";
import LayoutTopSideBottom from "../../layouts/LayoutTopSideBottom/LayoutTopSideBottom";

export default function UnauthorizedAIDEAppContainer(props) {
  const { children } = props;
  const match = useRouteMatch();
  return (
    <Route>
      <LayoutTopSideBottom match={match} isAuthorized={false}>
        {children}
      </LayoutTopSideBottom>
    </Route>
  );
}

// Import required libraries
import React from "react";
import useState from "react-usestateref";
import { connect } from "react-redux";
import {
  makeStyles,
  TableBody,
  TableRow,
  TableCell,
  withStyles,
} from "@material-ui/core";

// Import data & utils
import useTable from "../../utils/useTable";

import { config } from "../../config/config";
import noDataImage from "../../../assets/images/undraw_empty.svg";

// Import action creators
import { updateAlertInfo, updateMultiSelections } from "../../redux/actions";
import moment from "moment";
import { Paper } from "@mui/material";
import { formatDate } from "../../utils/utils";
//import css file
import "./ScheduleDashboard.scss";

const StyledTableCell = withStyles((theme) => ({
  body: {
    color: "#46596a",
    fontFamily: "Hind Siliguri",
    borderBottom: "0px",
  },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  "::-webkit-scrollbar": {
    width: "1px",
  },
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
  },
  listIcon: {
    display: "flex",
    alignItems: "center",
  },
  name: {
    maxWidth: "500px",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "13px !important",
    lineHeight: "23px",
  },
  tableRow: {
    backgroundColor: "red !important",
    padding: "9px !important",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "13px !important",
    lineHeight: "23px",

    "&:last-child th, &:last-child td": {
      borderBottom: 0,
    },
    "&:nth-of-type(odd)": {
      backgroundColor: "white !important",
      "&:hover": {
        backgroundColor: "#4eafb342 !important",
      },
    },
    "&:nth-of-type(even)": {
      backgroundColor: "rgba(70, 89, 106, 0.07) !important",
      "&:hover": {
        backgroundColor: "#4eafb342 !important",
      },
    },
  },
  starLoading: {
    marginRight: "5px",
    cursor: "pointer",
  },
}));

function ScheduleDashboardTable(props) {
  let {
    records,
    user,
    updateMultiSelections,
    updateAlertInfo,
    SetFilterDataReports,
    configUserInputs,
  } = props;
  const [downloadLoading, setDownloadLoading] = useState(false);

  let headCells = [
    {
      id: "country",
      label: "Country",
      width: "10%",
      // align: "center",
      // disableSorting: "disable",
    },
    {
      id: "brand",
      label: "Brand",
      width: "10%",
      // align: "center",
      // disableSorting: "disable",
    },
    {
      id: "model",
      label: "Model",
      width: "3%",
      // align: "center",
      // disableSorting: "disable",
    },
    {
      id: "weeks",
      label: "Weeks",
      width: "4%",
      // align: "center",
      // disableSorting: "disable",
    },

    {
      id: "model_parameters",
      label: "Model Parameters",
      width: "16%",
      disableSorting: "disable",
    },
    {
      id: "frequency_type",
      label: "Frequency Type",
      width: "14%",
      align: "center",
      disableSorting: "disable",
    },
    {
      id: "frequency_value",
      label: "Frequency Value",
      width: "14%",
      align: "center",
      disableSorting: "disable",
    },
    {
      id: "owner",
      label: "Created By",
      width: "9%",
      align: "center",
      disableSorting: "disable",
    },
    {
      id: "last_successful_run",
      label: "Last Successful Run",
      width: "20%",
      align: "center",
      disableSorting: "disable",
    },
    {
      id: "output",
      label: "Output",
      width: "5%",
      disableSorting: "disable",
      align: "center",
    },
  ];
  const classes = useStyles();

  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });

  //handler for downloading and open in new tab the nlp report
  const handleDownloadReport = (country, brand, download_html) => {
    let status;
    const url = config.api.nlpReport;
    fetch(url, {
      // Adding method type
      method: "POST",
      // Adding body or contents to send
      body: JSON.stringify({
        country,
        brand,
        download_html: download_html,
      }),
      // Adding headers to the request
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => {
        status = response.status;
        return response.clone().json();
      })
      .then((json) => {
        if (status === 200) {
          const anchor = document.createElement("a");
          anchor.href = json.data[0].url;
          anchor.target = "_blank";
          // Append to the DOM
          document.body.appendChild(anchor);
          // Trigger `click` event
          anchor.click();
          // Remove element from DOM
          if (download_html) {
            document.body.removeChild(anchor);
          }
        } else {
          updateAlertInfo({
            open: true,
            message: json.statusMessage,
            severity: "error",
          });
        }
      });
  };
  const downloadRun = (url, body) => {
    let status;
    if (body.run_id) {
      fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      })
        .then((response) => {
          console.groupCollapsed("requesting", url);
          console.log("REPSONSE -> ", response);
          status = response.status;
          return response.clone().json();
        })
        .then((json) => {
          console.log("JSON -> ", json);
          console.groupEnd();
          if (status === 200) {
            setDownloadLoading(false);
            let a = document.createElement("a");
            a.href = json.data.location;
            a.click();
            updateAlertInfo({
              open: false,
              message: "",
              severity: "error",
            });
          } else {
            setDownloadLoading(false);
            updateAlertInfo({
              open: true,
              message: json?.statusMessage || "Something went wrong",
              severity: "error",
            });
          }
        });
    } else {
      updateAlertInfo({
        open: true,
        message: "No runs of this schedule were successful",
        severity: "error",
      });
    }
  };

  const modelParameterElement = (model_parameters) => {
    const obj = {};
    for (const [key, value] of Object.entries(model_parameters)) {
      // switch key
      if (key === "interface_details") {
        let activationPlatformStr = "";
        let uploadAudienceStr = "";
        activationPlatformStr =
          activationPlatformStr +
          value.map((el) => el.interface_name).join(",");
        obj.activationPlatform = activationPlatformStr;
        uploadAudienceStr =
          uploadAudienceStr +
          value
            .filter((el) => el.upload_audience)
            .map((el) => el.interface_name)
            .join(",");
        obj.uploadAudience = uploadAudienceStr;
      }
      if (key === "action_names") {
        let actionNames = [];
        const keyNames =
          value.length && "eventAction" in value[0]
            ? {
                pagePath: "Page Path",
                eventAction: "Event Action",
                eventCategory: "Event Category",
                eventLabel: "Event Label",
              }
            : {
                pagePath: "Page Path",
                eventName: "Event Name",
                eventVariable: "Event Variable",
                eventValue: "Event Value",
              };
        value.map((el) => {
          actionNames.push(
            Object.entries(el).map(([key, value]) => [keyNames[key], value])
          );
        });
        obj.actionNames = actionNames;
      }
      if (key === "num_topics") {
        obj.numTopics = value;
      }
      if (key === "semrush") {
        obj.semrush = value;
      }
    }
    return obj;
  };
  const googleDashboardCheck = true;
  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    useTable(
      records,
      headCells,
      filterFn,
      user,
      updateMultiSelections,
      googleDashboardCheck
    );

  return (
    <>
      {records.length ? (
        <>
          {" "}
          <Paper
            sx={{
              height: "66vh",
              overflow: "auto",
              border: "none",
              boxShadow: "none",
            }}
          >
            <TblContainer>
              <TblHead />
              <TableBody>
                {recordsAfterPagingAndSorting().map((item, index) => {
                  let frequency_value;
                  if (item.frequency_type === "run_once") {
                    const eDate = item.frequency_value.split("_");
                    const arr = [eDate[1], eDate[0], eDate[2]].join("/");
                    frequency_value = moment(arr).format("MMM-DD-YYYY");
                  } else if (item.frequency_type === "monthly") {
                    frequency_value = "First Day";
                  } else if (item.frequency_type === "daily") {
                    frequency_value = "";
                  } else {
                    switch (item.frequency_value) {
                      case "Mon":
                        frequency_value = "Monday";
                        break;
                      case "Tue":
                        frequency_value = "Tuesday";
                        break;
                      case "Wed":
                        frequency_value = "Wednesday";
                        break;
                      case "Thu":
                        frequency_value = "Thursday";
                        break;
                      case "Fri":
                        frequency_value = "Friday";
                        break;
                      case "Sat":
                        frequency_value = "Saturday";
                        break;
                      case "Sun":
                        frequency_value = "Sunday";
                        break;
                      default:
                        frequency_value = "";
                        break;
                    }
                  }
                  return (
                    <TableRow
                      key={item.report_name + index}
                      className={classes.tableRow}
                      hover
                      style={{ cursor: "default" }}
                    >
                      <StyledTableCell>
                        {" "}
                        <p style={{ marginLeft: 5 }}>{item.country}</p>
                      </StyledTableCell>
                      <StyledTableCell>
                        <p style={{ marginLeft: 5 }}>{item.brand}</p>
                      </StyledTableCell>
                      <StyledTableCell>
                        <p style={{ marginLeft: 5 }}>{item.model}</p>
                      </StyledTableCell>
                      <StyledTableCell>
                        <p style={{ marginLeft: 5 }}>{item.weeks}</p>
                      </StyledTableCell>
                      <StyledTableCell>
                        {modelParameterElement(item.model_parameters)
                          .activationPlatform && (
                          <p style={{ margin: " 0 0 5px 0" }}>
                            <span style={{ fontWeight: 600 }}>
                              Activation Platform:{" "}
                            </span>
                            {
                              modelParameterElement(item.model_parameters)
                                .activationPlatform
                            }
                          </p>
                        )}
                        {modelParameterElement(item.model_parameters)
                          .uploadAudience && (
                          <p style={{ margin: " 0 0 5px 0" }}>
                            <span style={{ fontWeight: 600 }}>
                              Upload Audience:{" "}
                            </span>
                            {
                              modelParameterElement(item.model_parameters)
                                .uploadAudience
                            }
                          </p>
                        )}
                        {modelParameterElement(item.model_parameters)
                          .actionNames && (
                          <p
                            style={{
                              margin: " 0 0 5px 0",
                              height: item.expand ? "max-content" : "15px",
                              overflow: "hidden",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                // justifyContent: "space-between",
                              }}
                            >
                              <span
                                style={{ fontWeight: 600, marginRight: 10 }}
                              >
                                {" "}
                                Action Names:
                              </span>
                              <i
                                className="material-icons-outlined"
                                style={{
                                  fontSize: 13,
                                  background: "#eb367f",
                                  borderRadius: 2,
                                  color: "#fff",
                                }}
                                onClick={() => {
                                  // run_id
                                  SetFilterDataReports(
                                    records.map((el, i) => {
                                      if (el.config_id === item.config_id) {
                                        return { ...el, expand: !item.expand };
                                      } else {
                                        return el;
                                      }
                                    })
                                  );
                                }}
                              >
                                {item.expand ? "remove" : "add"}
                              </i>
                            </div>
                            {modelParameterElement(
                              item.model_parameters
                            ).actionNames.map((el, i) => {
                              return (
                                <div
                                  style={{ marginLeft: 10, marginBottom: 10 }}
                                  key={i}
                                >
                                  {el.map(
                                    (e) =>
                                      e[0] === "Page Path" && (
                                        <>
                                          <span style={{ fontWeight: 600 }}>
                                            {e[0]}:
                                          </span>{" "}
                                          <span>{e[1]}</span>;{" "}
                                        </>
                                      )
                                  )}
                                  {el.map(
                                    (e) =>
                                      e[0] !== "Page Path" && (
                                        <>
                                          <span style={{ fontWeight: 600 }}>
                                            {e[0]}:
                                          </span>{" "}
                                          <span>{e[1]}</span>;{" "}
                                        </>
                                      )
                                  )}
                                </div>
                              );
                            })}
                          </p>
                        )}
                        {modelParameterElement(item.model_parameters)
                          .numTopics && (
                          <p style={{ margin: " 0 0 5px 0" }}>
                            <span style={{ fontWeight: 600 }}>
                              {" "}
                              Number of Topics:{" "}
                            </span>
                            {
                              modelParameterElement(item.model_parameters)
                                .numTopics
                            }
                          </p>
                        )}
                        {typeof modelParameterElement(item.model_parameters)
                          .semrush !== "undefined" && (
                          <p style={{ margin: " 0 0 5px 0" }}>
                            <span style={{ fontWeight: 600 }}>
                              {" "}
                              Semrush data:{" "}
                            </span>
                            {modelParameterElement(item.model_parameters)
                              .semrush
                              ? "True"
                              : "False"}
                          </p>
                        )}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {item.frequency_type === "run_once"
                          ? "Run Once"
                          : item.frequency_type === "weekly"
                          ? "Weekly"
                          : item.frequency_type === "daily"
                          ? "Daily"
                          : "Monthly"}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {frequency_value}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {item.owner}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {formatDate(item.last_successful_run)}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <i
                          className="material-icons-outlined"
                          onClick={() => {
                            if (!downloadLoading) {
                              downloadRun(config.api.downloadRunUrl, {
                                run_id: item.run_id,
                                type: config.hardCoded.downloadOutputType,
                                app_id: config.hardCoded.aeAppId,
                              });
                            }
                          }}
                        >
                          file_download
                        </i>
                      </StyledTableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </TblContainer>
          </Paper>
          <div style={{ height: "40px" }}>
            {records.length > 20 && <TblPagination />}
          </div>
        </>
      ) : (
        <div className="error-container">
          <img src={noDataImage} />
          <p>There are no schedules created for this configuration</p>
        </div>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  allData: state.data,
  configUserInputs: state.configUserInputs,
});

const mapDispatchToProps = {
  updateMultiSelections,
  updateAlertInfo,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScheduleDashboardTable);

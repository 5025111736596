import React, { useState, useMemo, useEffect } from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "../../uiTheme";

//Import Custom Component
import Loader from "../Loader/Loader";
// Import styles
import "./DashboardCountryBrandSelections.scss";

const useStyles = makeStyles((theme) => ({
  multiSelect: {
    fontSize: "12px !important",
    fontWeight: "100",
    fontFamily: "Hind Siliguri !important",
    minWidth: "200px",
    // width: "275px",
    width: "100%",
    // maxWidth: "200px",
    height: "30px",
    borderRadius: "3px",
    color: "#46596a !important",
    backgroundColor: "white",
    //paddingLeft: "8px",
    border: "1px solid #dedede",
    "&:before": {
      borderBottom: "0px",
    },
    "&:after": {
      borderBottom: "0px",
    },
    "&:hover:not(.Mui-disabled):before": {
      borderBottom: "0px",
    },
    "& .MuiOutlinedInput-input": {
      padding: "6px 6px",
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "transparent",
    },
    "& .MuiSelect-select.MuiSelect-select": {
      paddingLeft: "8px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "0px !important",
    },
  },
  menuItem: {
    fontSize: "12px !important",
    fontFamily: "Hind Siliguri !important",
    padding: "0px !important",
  },
  singleMenuItem: {
    fontSize: "13px !important",
    fontFamily: "Hind Siliguri !important",
    padding: "8px 12px !important",
    color: "#46596a !important",
    width: "auto",
    '&[aria-selected="true"]': {
      color: `#46596a !important`,
      backgroundColor: `rgba(0, 0, 0, 0.08) !important`,
    },
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04) !important",
      textDecoration: "none",
    },
  },
  listItem: {
    "& span.MuiTypography-body1": {
      fontSize: "12px",
      color: "#46596a",
      fontFamily: "Hind Siliguri",
      paddingRight: "5px",
    },
  },
  popover: {
    pointerEvents: "none",
    fontFamily: "Hind Siliguri",
    fontSize: 12,
    width: "800px",
    margin: "10px",
  },
  typographyHeader: {
    fontSize: 12,
    fontFamily: "Hind Siliguri",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    color: "#46596a",
    margin: 0,
    marginTop: 5,
    backgroundColor: "#ebeff1",
  },
  typography: {
    fontSize: 11,
    fontFamily: "Hind Siliguri",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    color: "#46596a",
    margin: 0,
    marginTop: 4,
    marginBottom: 4,
    width: "320px",
  },
  contained: {
    color: "white",
    backgroundColor: "#46596a",
    marginRight: 4,
    marginTop: "28px",
    padding: "0px 5px",
    borderRadius: "3px",
    paddingTop: "2px",
    fontSize: 11,
    height: 23,
    minWidth: 15,
    textTransform: "capitalize",
    fontFamily: "Hind Siliguri",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#46596a",
      boxShadow: "none",
    },
  },
}));

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 150,
      width: "auto",
    },
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  variant: "menu",
  getContentAnchorEl: null,
};
const usePlaceholderStyles = makeStyles((theme) => ({
  placeholder: {
    color: "#aaa",
  },
}));

const Placeholder = ({ children }) => {
  const classes = usePlaceholderStyles();
  return <div className={classes.placeholder}>{children}</div>;
};

function DashboardCountryBrandSelection(props) {
  const {
    handlerForGo,
    countryBrandData,
    country,
    setCountry,
    brand,
    setBrand,
    unit,
    setUnit,
    allData,
    scheduleTabFlag,
    // weeks,
    // setWeeks,
    // frequencyType,
    // setFrequencyType,
    model,
    setModel,
  } = props;
  const { loading, data } = countryBrandData;
  const [disable, setDisable] = useState("");

  const brands = useMemo(() => {
    let brandData = data?.find((e) => e?.name === country)?.brands;
    if (unit !== "ALL") {
      let getBrandFromReports = allData?.data?.reduce((acc, curr) => {
        if (
          curr.unit === unit &&
          (country === "ALL" || curr.country === country) &&
          !acc.includes(curr.brand)
        ) {
          acc.push(curr.brand);
        }
        return acc;
      }, []);
      return brandData?.filter(
        (e) => e === "ALL" || getBrandFromReports.includes(e)
      );
    }
    return brandData;
  }, [country, unit]);

  let unitsArr = useMemo(() => {
    return [
      "ALL",
      ...allData?.data
        ?.reduce((acc, curr) => {
          if (
            curr.unit &&
            country === "ALL" &&
            (brand === curr.brand || brand === "ALL")
          ) {
            if (!acc.includes(curr.unit)) {
              acc.push(curr.unit);
            }
          } else {
            if (
              curr.unit &&
              curr.country === country &&
              (brand === curr.brand || brand === "ALL")
            ) {
              if (!acc?.includes(curr.unit)) {
                acc.push(curr.unit);
              }
            }
          }
          return acc;
        }, [])
        ?.sort(),
    ];
  }, [country, brand, allData?.data]);

  // let weeksArr = useMemo(() => {
  //   return [
  //     "ALL",
  //     ...allData?.data
  //       ?.reduce((acc, curr) => {
  //         if (
  //           curr.weeks &&
  //           country === "ALL" &&
  //           (brand === curr.brand || brand === "ALL")
  //         ) {
  //           if (!acc.includes(curr.weeks)) {
  //             acc.push(curr.weeks);
  //           }
  //         } else {
  //           if (
  //             curr.weeks &&
  //             curr.country === country &&
  //             (brand === curr.brand || brand === "ALL")
  //           ) {
  //             if (!acc?.includes(curr.weeks)) {
  //               acc.push(curr.weeks);
  //             }
  //           }
  //         }
  //         return acc;
  //       }, [])
  //       ?.sort(),
  //   ];
  // }, [country, brand, allData?.data]);
  // let frequencyTypeArr = useMemo(() => {
  //   return [
  //     "ALL",
  //     ...allData?.data
  //       ?.reduce((acc, curr) => {
  //         if (
  //           curr.frequency_type &&
  //           country === "ALL" &&
  //           (brand === curr.brand || brand === "ALL")
  //         ) {
  //           if (!acc.includes(curr.frequency_type)) {
  //             acc.push(curr.frequency_type);
  //           }
  //         } else {
  //           if (
  //             curr.frequency_type &&
  //             curr.country === country &&
  //             (brand === curr.brand || brand === "ALL")
  //           ) {
  //             if (!acc?.includes(curr.frequency_type)) {
  //               acc.push(curr.frequency_type);
  //             }
  //           }
  //         }
  //         return acc;
  //       }, [])
  //       ?.sort(),
  //   ];
  // }, [country, brand, allData?.data]);
  let modelArr = ["ALL", "CC", "NLP", "PM"];
  useEffect(() => {
    if (!brands?.includes(brand)) {
      setBrand("ALL");
    }
    if (!unitsArr?.includes(unit)) {
      setUnit("ALL");
    }
    if (scheduleTabFlag) {
      // if (!weeksArr?.includes(weeks)) {
      //   setWeeks("ALL");
      // }
      // if (!frequencyTypeArr?.includes(frequencyType)) {
      //   setFrequencyType("ALL");
      // }
      if (!modelArr?.includes(model)) {
        setModel("ALL");
      }
    }
  }, [country, unit, brand, model]);

  const classes = useStyles();

  return (
    <div className="country-brand-container" style={{ height: "83px" }}>
      <div className={`input-item country-section`}>
        <label>Country</label>
        <Select
          value={country}
          onChange={(e) => {
            setCountry(e.target.value);
            if (country) {
              setDisable(false);
            }
          }}
          className={classes.multiSelect}
          MenuProps={MenuProps}
          displayEmpty
          renderValue={
            country
              ? undefined
              : () => <Placeholder>Select an option</Placeholder>
          }
          data-testid="select-country"
        >
          {data?.map((obj) => (
            <MenuItem
              value={obj.name}
              key={obj.name}
              className={classes.singleMenuItem}
            >
              {obj.name}
            </MenuItem>
          ))}
        </Select>
      </div>
      {!scheduleTabFlag && (
        <div className={`input-item country-section`}>
          <label>Unit</label>
          <Select
            value={unit}
            onChange={(e) => {
              setUnit(e.target.value);
              if (unit) {
                setDisable(false);
              }
            }}
            className={classes.multiSelect}
            MenuProps={MenuProps}
            displayEmpty
            renderValue={
              unit
                ? undefined
                : () => <Placeholder>Select an option</Placeholder>
            }
          >
            {unitsArr?.map((val) => (
              <MenuItem
                value={val}
                key={val}
                className={classes.singleMenuItem}
              >
                {val}
              </MenuItem>
            ))}
          </Select>
        </div>
      )}
      <div className={`input-item ${scheduleTabFlag ? "country-section" : ""}`}>
        <label>Brand</label>
        <Select
          value={brand}
          onChange={(e) => {
            setBrand(e.target.value);
            if (brand) {
              setDisable(false);
            }
          }}
          className={classes.multiSelect}
          MenuProps={MenuProps}
          displayEmpty
          renderValue={
            brand
              ? undefined
              : () => <Placeholder>Select an option</Placeholder>
          }
        >
          {brands ? (
            brands?.map((elem) => (
              <MenuItem
                value={elem}
                key={elem}
                className={classes.singleMenuItem}
              >
                {elem}
              </MenuItem>
            ))
          ) : (
            <p className="no-data">No option available</p>
          )}
        </Select>
      </div>
      {scheduleTabFlag && (
        <>
          <div className={`input-item`}>
            <label>Model</label>
            <Select
              value={model}
              onChange={(e) => {
                setModel(e.target.value);
                if (model) {
                  setDisable(false);
                }
              }}
              className={classes.multiSelect}
              MenuProps={MenuProps}
              displayEmpty
              renderValue={
                model
                  ? undefined
                  : () => <Placeholder>Select an option</Placeholder>
              }
            >
              {modelArr ? (
                modelArr?.map((elem) => (
                  <MenuItem
                    value={elem}
                    key={elem}
                    className={classes.singleMenuItem}
                  >
                    {elem}
                  </MenuItem>
                ))
              ) : (
                <p className="no-data">No option available</p>
              )}
            </Select>
          </div>
        </>
      )}
      <div className="go-button">
        <Button
          onClick={() => {
            handlerForGo();
            setDisable(true);
          }}
          disabled={!country || !brand ? true : disable ? true : false}
          style={{
            fontSize: "12px",
            fontWeight: "bold",
            minWidth: "80px",
            background:
              !country || !brand
                ? "rgba(0,0,0,0.4)"
                : disable
                ? "rgba(0,0,0,0.4)"
                : "#46596a",
          }}
        >
          Go
        </Button>
      </div>
    </div>
  );
}

export default DashboardCountryBrandSelection;

//Import required libraies
import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import Chip from "@material-ui/core/Chip";
import Button from "@material-ui/core/Button";
import orderBy from "lodash/orderBy";
import Popover from "@material-ui/core/Popover";

// Import cunstom component
import Loader from ".././../../../components/Loader/Loader";

// Import custom hooks

// Import action creators
import {
  updateAlertInfo,
  updateConfigUserInputs,
  updateUserInfo,
} from "../../../../redux/actions";

//Import data
import { config } from "../../../../config/config";

// Import utils
import { makeDefaultResponseJson } from "../../../../utils/utils";

// Import styles
import {
  muiCommonStyles,
  StyledAutoComplete,
  CssTextField,
} from "../../../../../styles/styles";

function TagsContainer(props) {
  const {
    handleTagsDialogClose,
    updateConfigUserInputs,
    configUserInputs,
    updateUserInfo,
    updateAlertInfo,
    updateData,
    allData,
    activeApp,
    initialTagsSelections,
    disableFlag,
    user,
  } = props;

  const autoC = useRef(null);
  const [tagsData, setTagsData] = useState(
    allData?.tagsData || { status: "loading", data: [], message: "" }
  );
  const [allowedLength, setAllowedLength] = useState(false);
  const [duplicateTagError, setDuplicateTagError] = useState(false);
  const [tagsSelections, setTagsSelections] = useState(initialTagsSelections); // selections
  const muiClass = muiCommonStyles();

  const handleGetTags = () => {
    const url = `${config.api.getTagsUrl}?app_id=${activeApp?.id}&type=${
      disableFlag ? "runs" : "config"
    }`;
    let status;
    fetch(url)
      .then((response) => {
        console.groupCollapsed("requesting", url);
        console.log("REPSONSE -> ", response);
        status = response.status;
        return response.clone().json();
      })
      .then((json) => {
        console.log("JSON -> ", json);
        console.groupEnd();
        if (status === 200) {
          updateData("tagsData", {
            status: "success",
            data: orderBy([...json.data.tags]),
            message: "",
          });
          const filteredData =
            json.data.tags
              .filter(
                (elem) => !configUserInputs?.metadata?.tags?.includes(elem)
              )
              .filter((data) => !tagsSelections.includes(data)) || [];
          setTagsData({
            status: "success",
            data: orderBy([...filteredData]),
            message: "",
          });
        } else {
          updateData("tagsData", {
            status: "error",
            data: [],
            message: json.statusMessage,
          });
          setTagsData({
            status: "error",
            data: [],
            message: json.statusMessage,
          });
        }
      });
  };
  useEffect(() => {
    setTagsData({
      status: "loading",
      data: [],
      message: "",
    });
    handleGetTags();
    return () => {};
  }, []);

  const deleteValues = (regValue) => {
    setAllowedLength(false);
    setDuplicateTagError(false);
    if (allData?.tagsData?.data.includes(regValue)) {
      setTagsData({
        ...tagsData,
        data: orderBy([...tagsData.data, regValue]),
      });
    }
    let valArr = tagsSelections.filter(
      (singleValue) => singleValue !== regValue
    );
    setTagsSelections(orderBy([...valArr]));
  };

  const handleChange = (event, newValue) => {
    setAllowedLength(false);
    setDuplicateTagError(false);
    if (tagsSelections.length < 5 && !tagsSelections.includes(newValue)) {
      const filteredData =
        tagsData.data?.filter((elem) => elem !== newValue) || [];
      setTagsData({
        ...tagsData,
        data: orderBy([...filteredData]),
      });
      setTagsSelections(orderBy([...tagsSelections, newValue]));
    } else {
      tagsSelections.length === 5 && setAllowedLength(true);
      tagsSelections.includes(newValue) && setDuplicateTagError(true);
    }
    const ele = autoC.current.getElementsByClassName(
      "MuiAutocomplete-clearIndicator"
    )[0];
    if (ele) ele.click();
  };

  const handleUpdateTags = () => {
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: user.selections?.activeRunPayload?.id,
        tags: tagsSelections,
        type: "run",
        app_id: config.hardCoded.aeAppId,
      }),
    };
    const url = config.api.editTags;
    let status;
    updateAlertInfo({
      open: true,
      message: "Updating the tags in run...",
      severity: "info",
    });
    fetch(url, requestOptions)
      .then((response) => {
        console.groupCollapsed("requesting", url);
        console.log("REPSONSE -> ", response);
        status = response.status;
        return response.clone().json();
      })
      .then((json) => {
        if (status === 200) {
          updateConfigUserInputs("tags", json?.data?.tags);
          updateAlertInfo({
            open: true,
            message: "Tags have been successfully updated to this run",
            severity: "info",
          });
          handleGetTags();
        } else {
          updateAlertInfo({
            open: true,
            message: "",
            severity: "something went wrong!",
          });
        }
      });
  };

  return (
    <div className="tags-action-container">
      {tagsData.status === "loading" && <Loader />}
      {tagsData.status === "success" && (
        <>
          <div>
            {!disableFlag && (
              <StyledAutoComplete
                id={`add-tags-to-config`}
                size="small"
                style={{
                  fontFamily: "Hind Siliguri !important",
                  width: "100%",
                }}
                className={muiClass.typographyStyle}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                renderTags={() => null}
                freeSolo={true}
                options={tagsData?.data || []}
                ref={autoC}
                getOptionLabel={(option) => option || ""}
                value={null}
                onChange={(event, newValue) => {
                  handleChange(event, newValue);
                }}
                renderInput={(params) => (
                  <CssTextField
                    {...params}
                    variant="standard"
                    size="small"
                    placeholder="Type a tag"
                    className={muiClass.typographyStyle}
                  />
                )}
              />
            )}
            {disableFlag &&
              user?.preferred_username ===
                user.selections.activeRunPayload.created_by && (
                <StyledAutoComplete
                  id={`add-tags-to-config`}
                  size="small"
                  style={{
                    fontFamily: "Hind Siliguri !important",
                    width: "100%",
                  }}
                  className={muiClass.typographyStyle}
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  renderTags={() => null}
                  freeSolo={true}
                  options={tagsData?.data || []}
                  ref={autoC}
                  getOptionLabel={(option) => option || ""}
                  value={null}
                  onChange={(event, newValue) => {
                    handleChange(event, newValue);
                  }}
                  renderInput={(params) => (
                    <CssTextField
                      {...params}
                      variant="standard"
                      size="small"
                      placeholder="Type a tag"
                      className={muiClass.typographyStyle}
                    />
                  )}
                />
              )}
            <div className="selected-tags-container">
              {!disableFlag && allowedLength && (
                <p className="error">
                  {config.messages.allowedTagsLimitMessage}
                </p>
              )}
              {!disableFlag && duplicateTagError && (
                <p className="error">
                  {config.messages.duplicateTagFoundMessage}
                </p>
              )}
              {!disableFlag &&
                orderBy(tagsSelections).map((elem) => (
                  <Chip
                    key={elem}
                    label={elem}
                    title={elem}
                    onDelete={() => deleteValues(elem)}
                    className={muiClass.chipStyling}
                    style={{
                      minWidth: "100px",
                      maxWidth: "250px",
                      marginBottom: "5px",
                    }}
                    deleteIcon={
                      <i className="material-icons-outlined">clear</i>
                    }
                  />
                ))}
              {disableFlag && tagsSelections.length ? (
                <>
                  {allowedLength && (
                    <p className="error">
                      {config.messages.allowedTagsLimitMessage}
                    </p>
                  )}
                  {duplicateTagError && (
                    <p className="error">
                      {config.messages.duplicateTagFoundMessage}
                    </p>
                  )}
                  {orderBy(tagsSelections).map((elem) => (
                    <Chip
                      key={elem}
                      label={elem}
                      title={elem}
                      onDelete={() => deleteValues(elem)}
                      className={muiClass.chipStyling}
                      style={{
                        minWidth: "100px",
                        maxWidth: "250px",
                        marginBottom: "5px",
                      }}
                      deleteIcon={
                        <i
                          className="material-icons-outlined"
                          style={{
                            pointerEvents: `${
                              user?.preferred_username !==
                              user?.selections?.activeRunPayload?.created_by
                                ? "none"
                                : ""
                            }`,
                          }}
                        >
                          clear
                        </i>
                      }
                    />
                  ))}
                </>
              ) : disableFlag ? (
                <div className="no-tags">No Tags Available</div>
              ) : null}
            </div>
          </div>
          <div
            className={muiClass.buttonNoteContainer}
            style={{
              justifyContent: `${
                disableFlag
                  ? user?.preferred_username ===
                    user?.selections?.activeRunPayload?.created_by
                    ? "flex-end"
                    : "space-between"
                  : "flex-end"
              }`,
            }}
          >
            {disableFlag &&
              user?.preferred_username !==
                user?.selections?.activeRunPayload?.created_by && (
                <div className={muiClass.note}>
                  <label htmlFor="" className="title">
                    Note:
                  </label>
                  <span className="">
                    {" "}
                    Only owner of the run can edit the tags
                  </span>
                </div>
              )}
            <div className="tag-btn">
              {disableFlag ? (
                <>
                  <Button
                    variant="contained"
                    className={muiClass.containedButton}
                    onClick={() => {
                      handleTagsDialogClose();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    className={muiClass.containedButton}
                    onClick={() => {
                      handleTagsDialogClose();
                      handleUpdateTags();
                    }}
                    disabled={
                      JSON.stringify(configUserInputs?.tags) ===
                        JSON.stringify(tagsSelections) ||
                      user?.preferred_username !==
                        user?.selections?.activeRunPayload?.created_by
                    }
                  >
                    Update And Close
                  </Button>
                </>
              ) : (
                <Button
                  variant="contained"
                  className={muiClass.containedButton}
                  onClick={() => {
                    updateConfigUserInputs("tags", tagsSelections);
                    handleTagsDialogClose();
                  }}
                >
                  Close
                </Button>
              )}
            </div>
          </div>
        </>
      )}
      {tagsData.status === "error" && (
        <p className="no-comments">{tagsData.message}</p>
      )}
    </div>
  );
}

function AddTags(props) {
  const {
    configUserInputs,
    user,
    disableFlag,
    updateConfigUserInputs,
    updateUserInfo,
    updateAlertInfo,
  } = props;
  const [openTagsDialog, setOpenTagsFialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const muiClass = muiCommonStyles();
  const initialTagsSelections = configUserInputs?.tags
    ? orderBy(configUserInputs?.tags, ["asc"])
    : [];

  const handleTagsDialogOpen = () => {
    setOpenTagsFialog(true);
  };

  const handleTagsDialogClose = (event, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    setOpenTagsFialog(false);
  };

  const openPopup = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      {initialTagsSelections?.length ? (
        <button
          className="update-config-tags"
          style={{
            cursor: `${
              user?.preferred_username !==
                user?.selections?.activeRunPayload?.created_by && disableFlag
                ? "default"
                : ""
            }`,
          }}
        >
          <label
            onClick={(e) => {
              handleTagsDialogOpen(e);
            }}
            style={{ cursor: "pointer" }}
          >
            {initialTagsSelections.length}{" "}
            {initialTagsSelections.length > 1 ? "Tags" : "Tag"}
          </label>
          <i onMouseOver={openPopup} className="material-icons info-alt-icon">
            info
          </i>
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            PaperProps={{
              style: { width: "20%" },
            }}
          >
            <div className="aide-filter-popover">
              <div className="row filter-popover-label">Tags</div>
              <div className="row">
                {initialTagsSelections.length !== 0 ? (
                  initialTagsSelections.map((elem) => (
                    <Chip
                      key={elem}
                      label={elem}
                      className={muiClass.chipStyle}
                      title={elem}
                    />
                  ))
                ) : (
                  <div className="no-selections">No Selections Available</div>
                )}
              </div>
            </div>
          </Popover>
        </button>
      ) : (
        <div
          aria-describedby="config-tags-popover"
          className="add-tags-container"
          onClick={(e) => {
            handleTagsDialogOpen(e);
          }}
        >
          Add Tags
        </div>
      )}
      <Dialog
        className={muiClass.dialog}
        disableEscapeKeyDown
        open={openTagsDialog}
        onClose={handleTagsDialogClose}
        onBackdropClick={() => {}}
      >
        {openTagsDialog && (
          <TagsContainer
            {...props}
            initialTagsSelections={initialTagsSelections}
            handleTagsDialogClose={handleTagsDialogClose}
            disableFlag={disableFlag}
            user={user}
            updateConfigUserInputs={updateConfigUserInputs}
            updateUserInfo={updateUserInfo}
            updateAlertInfo={updateAlertInfo}
          />
        )}
      </Dialog>
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  allData: state.data,
  configUserInputs: state.configUserInputs,
});

const mapDispatchToProps = {
  updateConfigUserInputs,
  updateUserInfo,
  updateAlertInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddTags);

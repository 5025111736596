//Import required libraies
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Select from "@material-ui/core/Select";
import { MenuItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

//Import Custom Component
// import ModelConfig from "./ModelConfig";
import noDataImage from "../../../../../assets/images/undraw_empty.svg";
// import Dataset from "./Dataset";
import Loader from "../../../../components/Loader/Loader";
// import LocalParameterItem from "./LocalParameterItem";
import Message from "../../../../components/Message/Message";
import CommunityClusteringModel from "./CommunityClusteringModel/CommunityClusteringModel";

// Import action creators
import {
  updateAlertInfo,
  updateConfigUserInputs,
  updateUserInfo,
  toggleModel,
  updateSelections,
  updateModelLocalParameters,
  updateDatasetLocalParameters,
  addKpi,
  addToggleKpi,
  filterDataset,
} from "../../../../redux/actions";

// Import styles
import "./ModelSelections.scss";

// Import utils
import { config } from "../../../../config/config";
import {
  sortDataset,
  getActiveModel,
  getActiveDataset,
  convertMinutesIntoHrMins,
  getDatasetId,
  getDatesFromWeek,
  checkDatesOverlapping,
  transformKpiVariableList,
} from "../../../../utils/utils";
import PowerBiReportForRun from "../../../../components/PowerBiReportForRun/PowerBiReportForRun";
import moment from "moment";
import StepperForModelConfiguration from "./StepperForModelConfiguration";
import NLPModelParameters from "./NLPModelParameters";
import PropensityModel from "./PropensityModel/PropensityModel";

const useStyles = makeStyles((theme) => ({
  multiSelect: {
    fontSize: "12px",
    fontFamily: "Hind Siliguri",
    width: "115px",
    height: "35px",
    borderRadius: "3px",
    color: "#46596a",
    backgroundColor: "white",
    marginLeft: "20px",
    //paddingLeft: "8px",
    border: "1px solid #dedede",
    "&:before": {
      borderBottom: "0px",
    },
    "&:after": {
      borderBottom: "0px",
    },
    "&:hover:not(.Mui-disabled):before": {
      borderBottom: "0px",
    },
    "& .MuiOutlinedInput-input": {
      padding: "6px 6px",
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "transparent",
    },
    "& .MuiSelect-select.MuiSelect-select": {
      paddingLeft: "8px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  singleMenuItem: {
    fontSize: "12px",
    fontFamily: "Hind Siliguri",
    color: "#46596a",
    width: "auto",
    display: "flex",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "#4EAFB3",
      color: "#FFF",
    },
  },
  outlined: {
    backgroundColor: "#46596a",
    color: "white",
    marginRight: 10,
    fontSize: 14,
    padding: 10,
    height: 35,
    minWidth: 15,
    width: "15%",
    textTransform: "capitalize",
    float: "right",
    fontFamily: "Hind Siliguri",
    borderRadius: "20px",
    border: "1px solid #46596a",
    "&:hover": {
      backgroundColor: "#e9f7fc",
      color: "#46596a",
      boxShadow: "none",
    },
  },
  formControl: {
    minWidth: 120,
  },
}));

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 150,
      width: "auto",
    },
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  variant: "menu",
  getContentAnchorEl: null,
};

function ModelSelections(props) {
  let {
    match,
    user,
    allData,
    configUserInputs,
    changeFlag,
    updateUserInfo,
    toggleModel,
    updateConfigUserInputs,
    loading,
    disableFlag,
    updateSelections,
    updateAlertInfo,
    addKpi,
    updateModelLocalParameters,
    updateDatasetLocalParameters,
    setActiveSelection,
    activeSelection,
    updateCheckDE,
    filterDataset,
    activeStep,
    stepCompleted,
    setStepCompleted,
    setActiveStep,
    nestedRunsArray,
    onStepperChange,
    activeModelId,
    setActiveModelId,
    activeModel,
    validateStepper,
    scheduleRunData,
  } = props;

  const classes = useStyles();

  const [activeDatasetId, setActiveDatasetId] = useState(
    getActiveDataset(configUserInputs, allData)
  );
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [inputDownloadLoading, setInputDownloadLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [didLoad, setDidLoad] = useState(false);
  const [datasetsLoading, setDatasetsLoading] = useState(false);
  let app = config.hardCoded.aeAppName;
  let activeApp =
    allData.apps.data.length > 0
      ? allData.apps.data.filter(
          (obj) => obj.name.toLowerCase() === app.toLowerCase()
        )[0]
      : null;

  const initialDatasets = allData?.datasets
    .filter((dataset) => dataset.countries.includes(configUserInputs.country))
    .filter((activeDatasetnew) =>
      activeModel?.dataset_ids?.some(
        (datasetOfActiveModel) =>
          datasetOfActiveModel.id === activeDatasetnew.id
      )
    );
  const [datasets, setDatasets] = useState(initialDatasets);

  useEffect(() => {
    setActiveModelId(getActiveModel(configUserInputs));
  }, [
    configUserInputs.is_config_manual,
    configUserInputs.is_schedule_config,
    configUserInputs.is_fixed_schedule_config,
    updateCheckDE,
  ]);

  //filter datasets based on the country
  // let filteredDatasets = allData.datasets.filter((dataset) =>
  //   dataset.countries.includes(configUserInputs.country)
  // );
  // let datasets = filteredDatasets.filter((dataset) =>
  //   activeModel?.dataset_ids?.some(
  //     (datasetOfActiveModel) => datasetOfActiveModel.id === dataset.id
  //   )
  // );
  // datasets = datasets.filter((dataset) =>
  //   allData?.datasetDetails?.some(
  //     (datasetDetailActive) =>
  //       datasetDetailActive.dataset_id === dataset.id &&
  //       datasetDetailActive.country_name === configUserInputs.country &&
  //       datasetDetailActive.brand_name === configUserInputs.brand
  //   )
  // );

  let activeDataset = datasets.find(
    (dataset) => dataset.id === activeDatasetId
  );

  let activeModelsDatasetFromModelsData = activeModel?.dataset_ids?.map(
    (elem) => ({
      ...elem,
      kpi_variables_list: transformKpiVariableList(elem.kpi_variables_list),
    })
  );

  let activeDatasetDates;
  if (configUserInputs?.datasetDetailsData?.status === "success") {
    let datasetsDatesArray = configUserInputs.datasetDetailsData.data.map(
      (elem) => ({ ...elem, dataset_new_id: getDatasetId(elem.dataset_id) })
    );
    activeDatasetDates = datasetsDatesArray?.find(
      (dataset) => dataset.dataset_new_id === activeDatasetId
    );
  }
  let selectedModel = configUserInputs.models.find(
    (model) => model.id === activeModelId
  );
  let selectedDatasetsIds = selectedModel?.data_sources.filter((el) =>
    configUserInputs?.datasetDetailsData?.data.find(
      (e) => e.dataset_id === el.id
    )
  );
  if (activeModelId !== "STATUS") {
    selectedDatasetsIds = selectedDatasetsIds.map((dataset) => dataset.id);
  }
  let selectedModelDatasets = selectedModel?.data_sources.filter((el) =>
    configUserInputs?.datasetDetailsData?.data.find(
      (e) => e.dataset_id === el.id
    )
  );

  let selectedDataset = selectedModel?.data_sources.find(
    (dataset) => dataset.id === activeDatasetId
  );
  let userSelectedModelsIds = configUserInputs.models
    .filter((model) => model.run)
    .map((model) => model.id);
  let filteredModels = disableFlag
    ? allData.models?.filter((model) =>
        userSelectedModelsIds.includes(model.id)
      )
    : allData.models;
  if (configUserInputs.configType === "run") {
    filteredModels = [
      ...filteredModels,
      {
        id: "STATUS",
        name: "STATUS",
        run: true,
      },
    ];
  }
  const datasetDisabled =
    activeApp.id === config.hardCoded.aeAppId ||
    configUserInputs?.is_config_manual
      ? "disabled"
      : "";

  let onChange = (key, value) => {
    let data = {
      modelId: activeModelId,
      key,
      value,
    };
    updateModelLocalParameters(data);
  };

  // CONSOLE LOG
  // console.groupCollapsed("MODEL SELECTIONS");
  // console.log("ACTIVE MODEL ID ->", activeModelId);
  // console.log("ACTIVE DATASET ID ->", activeDatasetId);
  // console.log("DATASETS -> ", datasets);
  // console.log("ACTIVE MODEL -> ", activeModel);
  // console.log("SELECTED MODEL DATASETS -> ", selectedModelDatasets);
  // console.log("ACTIVE DATASET -> ", activeDataset);
  // console.log("SELECTED MODEL -> ", selectedModel);
  // console.log("SELECTED DATASET IDS -> ", selectedDatasetsIds);
  // console.log("SELECTED DATASET -> ", selectedDataset);
  // console.log("ACTIVE DATASET DATES ->", activeDatasetDates);
  // console.log(`userSelectedModelsIds ->`, userSelectedModelsIds);
  // console.log(`activeDatasetForKpi`, activeDatasetForKpi);
  // console.groupEnd();

  useEffect(() => {
    setPageLoading(false);
  }, []);

  useEffect(() => {
    // Checking if models are changing for Audience Engine and changing the dataset as active and selected. For AE dataset changes, please check this once.
    if (activeApp.id === config.hardCoded.aeAppId) {
      if (
        selectedModel &&
        (selectedModel?.config?.community_clustering_run_type ===
          config.hardCoded.initialRunId ||
          selectedModel?.config?.propensity_modeling_run_type ===
            config.hardCoded.initialRunId) &&
        datasets?.length
      ) {
        if (!selectedModel?.data_sources?.length) {
          let kpiData = {
            modelId: activeModelId,
            selectedDataset,
            dataset: selectedDataset ? selectedDataset : activeDataset,
            kpi: "all",
            activeModel: activeModel,
          };
          addKpi(kpiData);
        }
      }
    }
  }, [
    configUserInputs.models,
    configUserInputs.country,
    configUserInputs.brand,
  ]);

  useEffect(() => {
    if (!didLoad) {
      const index = configUserInputs.models.findIndex(
        (model) => model.id === activeModelId
      );
      if (
        index !== -1 &&
        configUserInputs.models[index].config.upload_audience
      ) {
        if (
          configUserInputs.models[index].config
            .community_clustering_run_type === "target"
        ) {
          configUserInputs.models[index].config.community_clustering_run_type =
            "upload_audience";
        } else if (
          configUserInputs.models[index].config.propensity_modeling_run_type ===
          "target"
        ) {
          configUserInputs.models[index].config.propensity_modeling_run_type =
            "upload_audience";
        }
        setDidLoad(true);
        updateConfigUserInputs("models", configUserInputs.models);
      }
    }
  }, [didLoad, configUserInputs.models, activeModelId]);

  //Make the first selected dataset active if model changes
  useEffect(() => {
    if (!pageLoading) {
      let activeDataset;
      if (activeModelId !== "STATUS") {
        if (selectedModelDatasets.length) {
          activeDataset = selectedModelDatasets[0];
        }
        if (!activeDataset) {
          if (datasets.length) {
            setActiveDatasetId(
              sortDataset(datasets, selectedDatasetsIds, ["name"])[0].id
            );
          } else {
            setActiveDatasetId(null);
          }
        } else {
          setActiveDatasetId(activeDataset.id);
        }
      }
    }
  }, [activeModelId, datasets]);

  //Change active dataset to the dataset selected by the user
  useEffect(() => {
    if (!pageLoading) {
      let datasetsIds = datasets.map((dataset) => dataset.id);
      if (datasetsIds.includes(changeFlag.datasetAddedId)) {
        setActiveDatasetId(changeFlag.datasetAddedId);
      }
    }
  }, [changeFlag.datasetAddedId]);

  //Update start date, end date, periodicity of a dataset
  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    if (!loading && !pageLoading && !configUserInputs.is_config_manual) {
      updateConfigUserInputs("datasetDetailsData", {
        status: "loading",
        message: "",
        data: [],
      });
      let body = {
        brand: configUserInputs.brand,
        country: configUserInputs.country,
        app_id: activeApp.id,
      };
      body = { ...body };
      let url = config.api.datasetDetailsUrl;
      let status;
      fetch(url, {
        signal,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      })
        .then((response) => {
          console.groupCollapsed("requesting", url);
          console.log("REPSONSE -> ", response);
          status = response.status;
          return response.clone().json();
        })
        .then((json) => {
          console.log("JSON -> ", json);
          console.groupEnd();
          if (status === 200) {
            updateConfigUserInputs("datasetDetailsData", {
              status: "success",
              message: "",
              data: json.data,
            });
          } else {
            updateAlertInfo({
              open: true,
              message: json.statusMessage,
              severity: "error",
            });
            updateConfigUserInputs("datasetDetailsData", {
              status: "error",
              message: "",
              data: [],
            });
          }
        })
        .catch((e) => {
          console.warn(`Fetch 2 error: ${e.message}`);
        });
    }
    return () => {
      controller.abort();
    };
  }, [
    configUserInputs.country,
    configUserInputs.brand,
    configUserInputs.sub_brand,
    configUserInputs.segment,
    configUserInputs.sub_segment,
    pageLoading,
    configUserInputs.is_schedule_config,
    configUserInputs.is_fixed_schedule_config,
    updateCheckDE,
  ]);

  useEffect(() => {
    if (configUserInputs?.configType !== "run") {
      setDatasetsLoading(true);
      if (
        configUserInputs.global_week_date_selection ===
        config.hardCoded.historicWeek
      ) {
        if (configUserInputs.historic_week_data > 0) {
          setDatasetsLoading(true);
          let dateRange = getDatesFromWeek(configUserInputs.historic_week_data);
          let startDateSelected = dateRange?.min;
          let endDateSelected = dateRange?.max;
          let allDatasetsDetailsData = allData.datasetDetails;
          let filteredDatasets = allData.datasets.filter((dataset) =>
            dataset.countries.includes(configUserInputs.country)
          );
          let datasetsNew = filteredDatasets.filter((dataset) =>
            activeModel?.dataset_ids?.some(
              (datasetOfActiveModel) => datasetOfActiveModel.id === dataset.id
            )
          );
          let datasetsApplied = datasetsNew.filter((dataset) =>
            allDatasetsDetailsData?.some((datasetOfActiveModel) => {
              if (
                datasetOfActiveModel.dataset_id === dataset.id &&
                datasetOfActiveModel.country_name ===
                  configUserInputs.country &&
                (datasetOfActiveModel.brand_name === configUserInputs.brand ||
                  !datasetOfActiveModel.brand_name)
              ) {
                if (
                  datasetOfActiveModel?.start_date &&
                  datasetOfActiveModel.end_date
                ) {
                  let checkForValidDataset = checkDatesOverlapping(
                    startDateSelected,
                    endDateSelected,
                    datasetOfActiveModel.start_date,
                    datasetOfActiveModel.end_date
                  );
                  const { type, status } = checkForValidDataset;
                  if (type || status) {
                    return true;
                  }
                  return false;
                } else {
                  return true;
                }
              }
              return false;
            })
          );
          setDatasets(datasetsApplied);
          setDatasetsLoading(false);
        } else {
          setDatasetsLoading(true);
          setDatasets([]);
          setDatasetsLoading(false);
        }
      } else {
        if (
          !configUserInputs.startDate ||
          !configUserInputs.endDate ||
          moment(configUserInputs.endDate, "YYYY-MM-DD").isBefore(
            moment(configUserInputs.startDate, "YYYY-MM-DD")
          )
        ) {
          setDatasetsLoading(true);
          setDatasets([]);
          setDatasetsLoading(false);
        } else {
          setDatasetsLoading(true);
          let allDatasetsDetailsData = allData.datasetDetails;
          let filteredDatasets = allData.datasets.filter((dataset) =>
            dataset.countries.includes(configUserInputs.country)
          );
          let datasetsNew = filteredDatasets.filter((dataset) =>
            activeModel?.dataset_ids?.some(
              (datasetOfActiveModel) => datasetOfActiveModel.id === dataset.id
            )
          );
          let datasetsApplied = datasetsNew.filter((dataset) =>
            allDatasetsDetailsData?.some((datasetOfActiveModel) => {
              if (
                datasetOfActiveModel.dataset_id === dataset.id &&
                datasetOfActiveModel.country_name ===
                  configUserInputs.country &&
                (datasetOfActiveModel.brand_name === configUserInputs.brand ||
                  !datasetOfActiveModel.brand_name)
              ) {
                if (
                  datasetOfActiveModel.start_date &&
                  datasetOfActiveModel.end_date
                ) {
                  let checkForValidDataset = checkDatesOverlapping(
                    configUserInputs.startDate,
                    configUserInputs.endDate,
                    datasetOfActiveModel.start_date,
                    datasetOfActiveModel.end_date
                  );
                  const { type, status } = checkForValidDataset;
                  if (type || status) {
                    return true;
                  }
                  return false;
                } else {
                  return true;
                }
              }
              return false;
            })
          );
          setDatasets(datasetsApplied);
          setDatasetsLoading(false);
        }
      }
    }
    return () => {};
  }, [
    configUserInputs.country,
    configUserInputs.brand,
    configUserInputs.sub_brand,
    configUserInputs.segment,
    configUserInputs.sub_segment,
    configUserInputs.startDate,
    configUserInputs.endDate,
    configUserInputs.historic_week_data,
    activeModelId,
    configUserInputs.global_week_date_selection,
  ]);

  const downloadRun = (url, body) => {
    body.type === config.hardCoded.downloadOutputType
      ? setDownloadLoading(true)
      : setInputDownloadLoading(true);
    let status;
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        console.groupCollapsed("requesting", url);
        console.log("REPSONSE -> ", response);
        status = response.status;
        return response.clone().json();
      })
      .then((json) => {
        console.log("JSON -> ", json);
        console.groupEnd();
        if (status === 200) {
          setDownloadLoading(false);
          setInputDownloadLoading(false);
          let a = document.createElement("a");
          a.href = json.data.location;
          a.click();
        } else {
          setDownloadLoading(false);
          setInputDownloadLoading(false);
          updateAlertInfo({
            open: true,
            message: json.statusMessage,
            severity: "error",
          });
        }
      });
  };
  //Change runView to data if run status is not success
  useEffect(() => {
    // if (!disableFlag) {
    updateSelections("runView", "data");
    // }
  }, []);

  useEffect(() => {
    if (configUserInputs?.configType === "run") {
      setActiveSelection("data");
      updateSelections("runView", "data");
    } else {
      if (activeApp.id === config.hardCoded.aeAppId) {
        setActiveSelection("model config");
      }
    }
  }, []);

  useEffect(() => {
    if (configUserInputs?.configType === "run") {
      setActiveSelection("data");
      updateSelections("runView", "data");
    }
  }, [configUserInputs?.is_config_manual, activeModelId]);
  // to get the info about the availability of models for selected CMUs
  const cmuFlag =
    activeModelId !== config.hardCoded.nlpId
      ? activeModelId === config.hardCoded.propensityId &&
        configUserInputs.old_pm
        ? false
        : !configUserInputs?.cmu_status?.data?.cc_pm &&
          !configUserInputs?.cmu_status?.data?.datasets?.length
      : !configUserInputs?.cmu_status?.data?.nlp;

  // To show the alert for fixed schedule runs
  useEffect(() => {
    if (
      configUserInputs?.configType === "edit" &&
      configUserInputs?.is_fixed_schedule_config &&
      configUserInputs?.fixed_runs === scheduleRunData.number_of_runs
    ) {
      updateAlertInfo({
        open: true,
        message: `All ${configUserInputs?.fixed_runs} ${
          configUserInputs?.fixed_runs > 1 ? "runs" : "run"
        } of this configuration were successfully triggered.`,
        severity: "error",
      });
    }
  }, []);
  // To show the alert for deleted fixed schedule runs
  useEffect(() => {
    if (
      configUserInputs?.is_fixed_schedule_config &&
      configUserInputs?.configType === "edit" &&
      !scheduleRunData.configurationId
    ) {
      updateAlertInfo({
        open: true,
        message: `Schedule of this configuration was deleted.`,
        severity: "error",
      });
    }
  }, []);
  return (
    <div className="models-container">
      <div className="models-menu">
        {filteredModels &&
          filteredModels?.length &&
          filteredModels?.map((obj) => (
            <div
              key={obj.id}
              className="models-item"
              onClick={() => {
                setActiveModelId(obj.id);
                // setActiveSelection("dataset");
                if (activeApp.id === config.hardCoded.aeAppId) {
                  setActiveSelection("model config");
                }
              }}
              style={{
                pointerEvents:
                  configUserInputs.configType === "edit" &&
                  configUserInputs.is_fixed_schedule_config
                    ? "none"
                    : "all",
              }}
            >
              <div
                className={`models-item-div ${
                  activeModelId === obj.id ? "active" : ""
                }`}
              >
                <p>{obj.name}</p>
                {((configUserInputs.models.find((model) => model.id === obj.id)
                  ? configUserInputs.models.find((model) => model.id === obj.id)
                      .run
                  : null) ||
                  activeModelId === "STATUS") && (
                  <i className="material-icons selected-model-icon">
                    check_circle
                  </i>
                )}
              </div>
            </div>
          ))}

        {disableFlag && (
          <div className="run-menu-container">
            <Select
              name="Download"
              className={classes.multiSelect}
              MenuProps={MenuProps}
              placeholder={"Download"}
              value={"Download"}
              disabled={configUserInputs.metadata.config_name
                .toLowerCase()
                .includes("beko")}
              style={{
                pointerEvents: `${
                  configUserInputs.metadata.config_name
                    .toLowerCase()
                    .includes("beko")
                    ? "none"
                    : "auto"
                }`,
              }}
            >
              <MenuItem
                value="Download"
                className={classes.singleMenuItem}
                disabled={true}
                selected={true}
              >
                <i
                  className="material-icons-outlined run-menu-item-icon"
                  style={{
                    opacity: `${
                      configUserInputs.metadata.config_name
                        .toLowerCase()
                        .includes("beko")
                        ? 0.5
                        : 1
                    }`,
                  }}
                >
                  file_download
                </i>
                Download
              </MenuItem>
              {
                <MenuItem
                  className={classes.singleMenuItem}
                  disabled={
                    !user.selections.activeRunPayload.is_output_populated
                  }
                  onClick={() => {
                    if (!downloadLoading) {
                      downloadRun(config.api.downloadRunUrl, {
                        run_id: user.selections.activeRunPayload.id,
                        type: config.hardCoded.downloadOutputType,
                        app_id: config.hardCoded.aeAppId,
                      });
                    }
                  }}
                >
                  {downloadLoading ? (
                    <Loader />
                  ) : (
                    <>
                      <span className="run-menu-item-text">Output Files</span>
                    </>
                  )}
                </MenuItem>
              }
            </Select>
          </div>
        )}
      </div>

      {nestedRunsArray?.length || !disableFlag ? (
        <div
          className="model-items-container"
          style={{
            background: configUserInputs.configType === "run" && "#f9f9f9",
          }}
        >
          {!disableFlag && (
            <div className="model-user-input">
              <div
                className={`model-switch-container ${
                  cmuFlag ? "disabled" : ""
                }`}
              >
                <p className="model-text bold">Model:</p>
                <i
                  className={`material-icons-outlined selected-model-icon ${
                    selectedModel.run ? "selected" : ""
                  }`}
                  onClick={() => {
                    let data = {
                      modelId: activeModelId,
                      modelRunFlag: !selectedModel.run,
                    };
                    toggleModel(data);
                  }}
                >
                  {selectedModel.run ? "toggle_on" : "toggle_off"}
                </i>
                <p className="model-text">
                  {selectedModel.run ? "Enabled" : "Disabled"}
                </p>
              </div>
            </div>
          )}
          {/* to be removed  */}
          {/* {nestedRunsArray?.map((obj) => {
            console.log("obj", obj);
            let value = selectedModel.config[obj.id];
            return (
              <div
                className={`run-type-local-parameters-row ${
                  selectedModel.run ? "" : "disabled"
                } ${disableFlag ? "disabled-pointer" : ""}`}
                key={obj.id}
              >
                <LocalParameterItem
                  {...obj}
                  value={value}
                  onChange={onChange}
                  userInputs={configUserInputs}
                  allCampaigns={configUserInputs.campaignsData}
                  userSelectedCampaigns={value}
                  activeModel={activeModel}
                  disableFlag={disableFlag}
                  match={match}
                  changeFlag={changeFlag}
                  loading={loading}
                  activeApp={activeApp}
                  userData={allData}
                />
              </div>
            );
          })} */}
          {!disableFlag && (
            <div className=" model-user-input approximate-time-container">
              <p className="model-text approximate-time bold">
                Approximate Model Run:{" "}
                {convertMinutesIntoHrMins(activeModel.approximate_run_time)}
              </p>
            </div>
          )}
        </div>
      ) : null}

      {
        // <div
        //   className={`models-selection-title-container ${
        //     selectedModel.run ? "" : "disabled"
        //   }`}
        // >
        //   {/* <div
        //     className={`models-selection-div ${
        //       activeSelection === "dataset" ? "active-selection-div" : ""
        //     } ${datasetDisabled}`}
        //     onClick={() => {
        //       setActiveSelection("dataset");
        //       updateSelections("runView", "data");
        //     }}
        //   >
        //     <i
        //       className={`material-icons-outlined dataset-icon ${
        //         activeSelection === "dataset" ? "active-selection" : ""
        //       }`}
        //     >
        //       storage
        //     </i>
        //     <p
        //       className={`models-selection-title ${
        //         activeSelection === "dataset" ? "active-selection" : ""
        //       }`}
        //     >
        //       Datasets
        //     </p>
        //   </div> */}
        //   {/* <div
        //     className={`models-selection-div ${
        //       activeSelection == "model config" ? "active-selection-div" : ""
        //     }`}
        //     onClick={() => {
        //       setActiveSelection("model config");
        //       updateSelections("runView", "data");
        //     }}
        //   >
        //     <i
        //       className={`material-icons-outlined dataset-icon ${
        //         activeSelection === "model config" ? "active-selection" : ""
        //       }`}
        //     >
        //       settings
        //     </i>
        //     <p
        //       className={`models-selection-title ${
        //         activeSelection === "model config" ? "active-selection" : ""
        //       }`}
        //     >
        //       Configurations
        //     </p>
        //   </div> */}
        //   {disableFlag && (
        //     <div
        //       className={`models-selection-div ${
        //         activeSelection == "view_dashboard"
        //           ? "active-selection-div"
        //           : ""
        //       }`}
        //       onClick={() => {
        //         setActiveSelection("view_dashboard");
        //         updateSelections("runView", "chart");
        //       }}
        //     >
        //       <i
        //         className={`material-icons-outlined dataset-icon ${
        //           activeSelection === "view_dashboard" ? "active-selection" : ""
        //         }`}
        //       >
        //         bar_chart
        //       </i>
        //       <p
        //         className={`models-selection-title ${
        //           activeSelection === "view_dashboard" ? "active-selection" : ""
        //         }`}
        //       >
        //         View Dashboard
        //       </p>
        //     </div>
        //   )}
        // </div>
      }

      {user.selections.runView === "chart" ? (
        user.selections.activeRunPayload.status ===
        config.hardCoded.runSuccess ? (
          <PowerBiReportForRun
            configUserInputs={configUserInputs}
            view={user.selections.runView}
            activeModel={activeModel}
            activeRunPayloadEntity={user.selections.activeRunPayload}
            status={user.selections.activeRunPayload.status}
            dagMessage={user.selections.activeRunPayload.status_message}
          />
        ) : (
          <Message
            status={user.selections.activeRunPayload.status}
            dagMessage={user.selections.activeRunPayload.status_message}
          />
        )
      ) : null}

      {user.selections.runView === "data" && (
        <div
          className={`models-selections-container ${
            activeModelId === "STATUS" || selectedModel?.run || cmuFlag
              ? ""
              : "disabled"
          }`}
        >
          {/* {datasetsLoading ? (
            <Loader />
          ) : (
            <Dataset
              activeModel={activeModel}
              datasets={datasets}
              activeDataset={activeDataset}
              selectedDataset={selectedDataset}
              activeModelId={activeModelId}
              activeDatasetId={activeDatasetId}
              selectedDatasetsIds={selectedDatasetsIds}
              setActiveDatasetId={setActiveDatasetId}
              activeModelsDatasetFromModelsData={
                activeModelsDatasetFromModelsData
              }
              disableFlag={true}
              match={match}
              activeSelection={activeSelection}
              selectedModelDatasets={selectedModelDatasets}
              activeDatasetDates={activeDatasetDates}
            />
          )} */}
          {/* <ModelConfig
            allData={allData}
            activeModel={activeModel}
            datasets={datasets}
            activeDataset={activeDataset}
            selectedDataset={selectedDataset}
            activeModelId={activeModelId}
            activeDatasetId={activeDatasetId}
            selectedDatasetsIds={selectedDatasetsIds}
            selectedModel={selectedModel}
            setActiveDatasetId={setActiveDatasetId}
            disableFlag={disableFlag}
            match={match}
            changeFlag={changeFlag}
            activeSelection={activeSelection}
            loading={loading}
            activeApp={activeApp}
            updateUserInfo={updateUserInfo}
            updateCheckDE={updateCheckDE}
          /> */}

          {configUserInputs?.cmu_status?.status === "loading" &&
          configUserInputs.configType !== "run" ? (
            <Loader />
          ) : activeModelId === "STATUS" ? (
            <Message
              status={user.selections.activeRunPayload.status}
              dagMessage={user.selections.activeRunPayload.status_message}
            />
          ) : configUserInputs.configType === "run" &&
            selectedModel.config.propensity_modeling_run_type &&
            activeModelId === config.hardCoded.propensityId ? (
            <StepperForModelConfiguration
              activeModel={activeModel}
              activeModelId={activeModelId}
              updateModelLocalParameters={updateModelLocalParameters}
              allData={allData}
              datasets={datasets}
              activeDataset={activeDataset}
              selectedDataset={selectedDataset}
              activeDatasetId={activeDatasetId}
              selectedDatasetsIds={selectedDatasetsIds}
              selectedModel={selectedModel}
              setActiveDatasetId={setActiveDatasetId}
              disableFlag={disableFlag}
              match={match}
              changeFlag={changeFlag}
              activeSelection={activeSelection}
              loading={loading}
              activeApp={activeApp}
              updateUserInfo={updateUserInfo}
              configUserInputs={configUserInputs}
              nestedRunsArray={nestedRunsArray}
              activeStep={activeStep}
              stepCompleted={stepCompleted}
              setStepCompleted={setStepCompleted}
              setActiveStep={setActiveStep}
              onStepperChange={onStepperChange}
              validateStepper={validateStepper}
            />
          ) : activeModelId === config.hardCoded.propensityId &&
            !selectedModel.config.propensity_modeling_run_type ? (
            <PropensityModel
              activeModel={activeModel}
              activeModelId={activeModelId}
              updateModelLocalParameters={updateModelLocalParameters}
              allData={allData}
              datasets={datasets}
              activeDataset={activeDataset}
              selectedDataset={selectedDataset}
              activeDatasetId={activeDatasetId}
              selectedDatasetsIds={selectedDatasetsIds}
              selectedModel={selectedModel}
              setActiveDatasetId={setActiveDatasetId}
              disableFlag={disableFlag}
              match={match}
              changeFlag={changeFlag}
              activeSelection={activeSelection}
              loading={loading}
              activeApp={activeApp}
              updateUserInfo={updateUserInfo}
              configUserInputs={configUserInputs}
              nestedRunsArray={nestedRunsArray}
            />
          ) : activeModelId === config.hardCoded.communityClusteringId &&
            !selectedModel.config.community_clustering_run_type ? (
            <CommunityClusteringModel
              activeModel={activeModel}
              activeModelId={activeModelId}
              updateModelLocalParameters={updateModelLocalParameters}
              allData={allData}
              datasets={datasets}
              activeDataset={activeDataset}
              selectedDataset={selectedDataset}
              activeDatasetId={activeDatasetId}
              selectedDatasetsIds={selectedDatasetsIds}
              selectedModel={selectedModel}
              setActiveDatasetId={setActiveDatasetId}
              disableFlag={disableFlag}
              match={match}
              changeFlag={changeFlag}
              activeSelection={activeSelection}
              loading={loading}
              activeApp={activeApp}
              updateUserInfo={updateUserInfo}
              configUserInputs={configUserInputs}
              nestedRunsArray={nestedRunsArray}
            />
          ) : 
          configUserInputs.configType !== "run" &&
            !configUserInputs?.cmu_status?.data?.nlp ? (
            <div className="no-configs cmu">
              <img src={noDataImage} alt={config.messages.invalid_nlp} />
              <p className="no-data">
                <b>{config.messages.invalid_nlp}</b>
              </p>
            </div>
          ) : (
            <NLPModelParameters
              activeModel={activeModel}
              activeModelId={activeModelId}
              updateModelLocalParameters={updateModelLocalParameters}
              allData={allData}
              selectedModel={selectedModel}
              disableFlag={disableFlag}
              match={match}
              changeFlag={changeFlag}
              loading={loading}
              activeApp={activeApp}
              configUserInputs={configUserInputs}
            />
          )}
        </div>
      )}
    </div>
  );
}

ModelSelections.propTypes = {
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
  //allData: state.data,
  changeFlag: state.changeFlag,
  configUserInputs: state.configUserInputs,
  models: state.configUserInputs.models,
});

const mapDispatchToProps = {
  updateConfigUserInputs,
  updateUserInfo,
  toggleModel,
  updateSelections,
  updateAlertInfo,
  updateModelLocalParameters,
  updateDatasetLocalParameters,
  addKpi,
  addToggleKpi,
  filterDataset,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModelSelections);

import C from "../../constants";
import { getDatesFromWeek, checkDatesOverlapping } from "../../../utils/utils";
import models from "./models";
import { config } from "../../../config/config";

export default function configUserInputs(state = {}, payload) {
  let { type, key, data } = payload;
  let newState;
  switch (type) {
    case C.UPDATE_WHOLE_CONFIG_USER_INPUTS:
      return data;
    case C.UPDATE_CONFIG_USER_INPUTS:
      return { ...state, [key]: data };
    case C.UPDATE_COUNTRY_REGIONS:
      return {
        ...state,
        countryRegions: data,
      };

    case C.UPDATE_CONTROL_REGIONS:
      return state.regionsData
        ? {
            ...state,
            controlRegionsData: state.regionsData.filter(
              (val) => !state.testRegions.includes(val)
            ),
          }
        : state;
    case C.UPDATE_TEST_REGIONS:
      return state.regionsData
        ? {
            ...state,
            testRegionsData: state.regionsData.filter(
              (val) => !state.controlRegions.includes(val)
            ),
          }
        : state;
    case C.TOGGLE_MODEL:
      return { ...state, models: models(state.models, payload) };
    case C.TOGGLE_DATASET:
      return { ...state, models: models(state.models, payload) };
    case C.TOGGLE_KPI:
      return { ...state, models: models(state.models, payload) };
    case C.ADD_TOGGLE_KPI:
      return { ...state, models: models(state.models, payload) };
    case C.ADD_DATASET:
      return { ...state, models: models(state.models, payload) };
    case C.REMOVE_DATASET:
      return { ...state, models: models(state.models, payload) };
    case C.ADD_KPI:
      return { ...state, models: models(state.models, payload) };
    case C.REMOVE_KPI:
      return { ...state, models: models(state.models, payload) };
    case C.UPDATE_DATASET_LOCAL_PARAMETERS:
      return { ...state, models: models(state.models, payload) };
    case C.UPDATE_MODEL_LOCAL_PARAMETERS:
      return {
        ...state,
        models: models(
          state.models,
          payload,
          state?.is_schedule_config,
          state.is_fixed_schedule_config
        ),
      };
    case C.SELECT_ALL:
      return { ...state, models: models(state.models, payload) };
    case C.CLEAR_ALL:
      return { ...state, models: models(state.models, payload) };
    case C.UPDATE_KPI_VARIABLES:
      return { ...state, models: models(state.models, payload) };
    case C.RESET_NEW_KPI:
      return { ...state, models: models(state.models, payload) };
    case C.ON_CHANGE_DE_CONFIG:
      let modelsData = state.models.map((e) => {
        return { ...e, config: { ...e.config, [key]: data } };
      });
      return {
        ...state,
        is_config_manual: false,
        models: modelsData,
      };
    case C.FILTERING_DATASET:
      let updateModels = state.models.map((model) => {
        return {
          ...model,
          data_sources: model.data_sources
            .filter((el) =>
              state?.datasetDetailsData?.data.find(
                (e) => e.dataset_id === el.id
              )
            )
            .filter((elem) => {
              let dateRange;
              if (state.global_week_date_selection === "historic_week_data") {
                dateRange = getDatesFromWeek(state.historic_week_data);
              } else {
                dateRange = {
                  min: state.startDate,
                  max: state.endDate,
                };
              }
              let startDateSelected = dateRange?.min;
              let endDateSelected = dateRange?.max;
              const findDataset = state?.datasetDetailsData?.data?.find(
                (elm) => elm.dataset_id === elem.id
              );
              let checkForValidDataset = checkDatesOverlapping(
                startDateSelected,
                endDateSelected,
                findDataset?.start_date,
                findDataset?.end_date
              );
              const { type, status } = checkForValidDataset;
              if (status || type) {
                return elem;
              }
            }),
        };
      });
      return {
        ...state,
        is_config_manual: false,
        models: updateModels,
      };
    case C.TOGGLE_DATASET_IN_COMMUNITY:
      return { ...state, models: models(state.models, payload) };
    case C.ADD_GLOBAL_PARAMS:
      if (data.global_week_date_selection === config.hardCoded.historicWeek) {
        return { ...state, ...{ ...data, startDate: "", endDate: "" } };
      } else {
        return { ...state, ...{ ...data, historic_week_data: 5 } };
      }
    case C.DATA_AVAILABILITY_CHANGES:
      return { ...state, models: models(state.models, payload) };

    case C.UPDATE_ACTIVATION_PLATFORM:
      return { ...state, models: models(state.models, payload) };
    default:
      return state;
  }
}

import { makeStyles, withStyles } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";

export const muiCommonStyles = makeStyles(() => ({
  uploadFileClass: {
    flexDirection: "column",
    width: "100%",
    alignItems: "center",
    minHeight: 150,
    justifyContent: "center",
    display: "flex",
    "& P": {
      margin: 0,
      padding: 0,
      color: "#4EAFB3",
      fontSize: 13,
    },
    "& .uploadImageContainer": {
      display: "flex",
      alignItems: "center",
      textAlign: "center",
      "& P": {
        margin: 0,
        padding: 0,
        color: "#4EAFB3",
        fontSize: 13,
      },
      "& .orContainer": {
        margin: "0  40px",
      },
      "& .imageContainer": {
        textAlign: "center",
      },
    },
    "& .loaderImageContainer": {
      textAlign: "center",
    },
    "& .loaderBox": {
      marginTop: 10,
      textAlign: "center",
      "& p": {
        marginTop: 10,
      },
    },
    "& .uploadedContainer": {
      display: "flex",
      marginTop: "30px",
      alignItems: "center",
    },
  },
  rulesContainer: {
    display: "flex",
    alignItems: "end",
    position: "relative",

    "& span": {
      fontSize: 12,
      color: "#415A6C",
      fontWeight: 600,
      // textDecoration: "underline",
      cursor: "default",
    },
  },
  popover: {
    display: "flex",
    fontSize: "12px",
    color: "#46596a",
    fontFamily: "Hind Siliguri",
    fontWeight: 500,
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "#4EAFB3",
      color: "white",
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: "white",
      },
    },
  },
  chipStyle: {
    // marginTop: "0px",
    fontFamily: "Hind Siliguri",
    fontSize: 14,
    border: "1px solid #dedede",
    color: "white !important",
    height: "30px",
    borderRadius: "3px",
    width: "fit-content",
    maxWidth: "90%",
    margin: "12px",
    background: "#4EAFB3",
    "& .MuiChip-deleteIcon": {
      // width: "16px",
      fontSize: 20,
      border: "none",
      color: "white",
    },
  },
  chipStyling: {
    marginTop: "0px",
    fontFamily: "Hind Siliguri",
    fontSize: "12px",
    border: "1px solid #dedede",
    color: "white !important",
    height: "28px",
    borderRadius: "4px",
    width: "fit-content",
    margin: "0px 5px",
    background: "#4EAFB3",
    "&:focus": {
      background: "#4EAFB3",
    },
    "& .MuiChip-deleteIcon": {
      // width: "20px",
      fontSize: 20,
      border: "none",
      color: "white",
    },
  },
  dialog: {
    fontFamily: "Hind Siliguri",
    fontSize: 14,
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "850px",
      //minHeight: "300px",
      height: "auto",
      maxHeight: "500px",
    },
  },
  scheduleWeekBtns: {
    fontFamily: "Hind Siliguri",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "19px",
    color: "#465A69",
    textTransform: "capitalize",
  },
  containedButton: {
    color: "white",
    backgroundColor: "#46596a",
    marginRight: 4,
    borderRadius: "4px",
    padding: 10,
    fontSize: 11,
    height: 23,
    minWidth: 15,
    textTransform: "capitalize",
    fontFamily: "Hind Siliguri",
    border: "1px solid #46596a",
    boxSizing: "border-box",
    "&:hover": {
      backgroundColor: "#46596a",
    },
  },
  outlinedButton: {
    borderColor: "#46596a",
    backgroundColor: "transparent",
    color: "#46596a",
    marginRight: 4,
    fontSize: 11,
    padding: 10,
    height: 23,
    minWidth: 15,
    textTransform: "capitalize",
    fontFamily: "Hind Siliguri",
    "&:hover": {
      backgroundColor: "transparent",
      boxShadow: "none",
    },
  },
  typographyStyle: {
    "& .MuiChip-root": {
      fontFamily: "Hind Siliguri",
      fontSize: 12,
      border: "1px solid #dedede",
      background: "white",
      maxWidth: "250px",
    },
    "& .MuiInput-underline:after": {
      borderBottom: 0,
    },
    "& .MuiInput-underline:before": {
      borderBottom: 0,
      transition: "none",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: 0,
    },
    "& .Mui-focused .MuiInput-underline": {
      borderBottom: 0,
    },
    "& .MuiAutocomplete-option": {
      fontFamily: "Hind Siliguri",
      fontSize: 12,
    },
    "& .MuiAutocomplete-paper": {
      fontFamily: "Hind Siliguri",
      fontSize: 6,
    },
    "& .MuiInputBase-input": {
      fontFamily: "Hind Siliguri",
      fontSize: 12,
      color: "#46596a",
      padding: "5px 0 4px !important",
    },
    "& .MuiOutlinedInput-root": {
      paddingTop: "0px !important",
      paddingBottom: "0px !important",
    },
    "& .MuiTextField-root": {
      background: "#fff",
    },
  },
  multiSelect: {
    fontSize: "12px",
    fontFamily: "Hind Siliguri",
    width: "100%",
    maxWidth: "275px",
    height: "30px",
    borderRadius: "5px",
    color: "#46596a",
    backgroundColor: "white",
    boxSizing: "border-box",
    //paddingLeft: "8px",
    display: "flex",
    alignItems: "center",
    //textAlign: "center",
    border: "1px solid #dedede",
    "&:before": {
      borderBottom: "0px",
    },
    "&:after": {
      borderBottom: "0px",
    },
    "&:hover:not(.Mui-disabled):before": {
      borderBottom: "0px",
    },
    "& .MuiOutlinedInput-input": {
      padding: "6px 6px",
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "transparent",
    },
    "& .MuiSelect-select.MuiSelect-select": {
      paddingLeft: "8px",
      // height: "25px",
    },
  },
  singleSelect: {
    fontSize: "12px",
    fontFamily: "Hind Siliguri",
    width: "160px",
    height: "30px",
    borderRadius: "5px",
    color: "#46596a",
    backgroundColor: "white",
    boxSizing: "border-box",
    //paddingLeft: "8px",
    display: "flex",
    alignItems: "center",
    //textAlign: "center",
    border: "1px solid #dedede",
    "&:before": {
      borderBottom: "0px",
    },
    "&:after": {
      borderBottom: "0px",
    },
    "&:hover:not(.Mui-disabled):before": {
      borderBottom: "0px",
    },
    "& .MuiOutlinedInput-input": {
      padding: "6px 6px",
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "transparent",
    },
    "& .MuiSelect-select.MuiSelect-select": {
      paddingLeft: "8px",
      // height: "25px",
    },
  },
  singleMenuItem: {
    fontSize: "12px !important",
    fontFamily: "Hind Siliguri !important",
    padding: "8px 12px !important",
    color: "#46596a !important",
    width: "auto",
    '&[aria-selected="true"]': {
      color: `#46596a !important`,
      backgroundColor: `rgba(0, 0, 0, 0.08) !important`,
    },
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04) !important",
      textDecoration: "none",
    },
  },
  note: {
    marginRight: "10px",
    "& label": {
      fontWeight: "bold",
      color: "#465a69",
    },
    "& span": {
      color: "#465a69",
    },
  },
  buttonNoteContainer: {
    display: "flex",
    alignItems: "center",
  },
}));

export const CssTextField = withStyles({
  root: {
    width: "85%",
    padding: "2px 5px",
    borderRadius: "4px",
    border: "1px solid #dedede",
    "& label.Mui-focused": {
      color: "green",
    },
    "& .MuiInput-underline:hover": {
      borderBottomColor: "red",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#dedede",
    },
    "& .MuiOutlinedInput-root": {
      backgroundColor: "white",
      "& fieldset": {
        borderColor: "#dedede",
      },
      "&:hover fieldset": {
        borderColor: "#dedede",
      },
      "&.Mui-focused fieldset": {
        border: "0.5px solid #dedede",
      },
    },
  },
})(TextField);

export const StyledAutoComplete = withStyles((theme) => ({
  // endAdornment: {
  //   display: "none",
  // },
  endAdornment: {
    top: "-3px",
  },
  tag: {
    background: "#465a69",
    color: "#46596a !important",
    fontFamily: "Hind Siliguri !important",
  },
  paper: {
    fontFamily: "Hind Siliguri",
    fontSize: 13,
    color: "#46596a",
  },
}))(Autocomplete);

export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 150,
      width: "auto",
    },
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  variant: "menu",
  getContentAnchorEl: null,
};

export const StyledCheckbox = withStyles(() => ({
  root: {
    color: "#4eafb3 !important",
    "& .MuiSvgIcon-root": {
      width: 18,
      height: 18,
    },
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}))(Checkbox);
export const datasetSelectStyle = makeStyles(() => ({
  singleSelect: {
    fontSize: "12px",
    fontFamily: "Hind Siliguri",
    width: "100%",
    height: "30px",
    borderRadius: "5px",
    color: "#46596a",
    backgroundColor: "white",
    boxSizing: "border-box",
    //paddingLeft: "8px",
    display: "flex",
    alignItems: "center",
    //textAlign: "center",
    border: "1px solid #dedede",
    "&:before": {
      borderBottom: "0px",
    },
    "&:after": {
      borderBottom: "0px",
    },
    "&:hover:not(.Mui-disabled):before": {
      borderBottom: "0px",
    },
    "& .MuiOutlinedInput-input": {
      padding: "6px 6px",
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "transparent",
    },
    "& .MuiSelect-select.MuiSelect-select": {
      paddingLeft: "8px",
      // height: "25px",
    },
  },
}));

import { config } from "../config/config";
export const configUserInputs = {
  app_id: "",
  configId: null,
  configNameFixed: "",
  configName: "",
  configNameOriginal: "",
  country: "",
  brand: "",
  category: "",
  segment: "",
  regionGranType: "",
  historicalWeeks: 0,
  global_week_date_selection: "",
  controlRegions: [],
  testRegions: [],
  regionsData: [],
  controlRegionsData: [],
  testRegionsData: [],
  models: [],
  campaignsData: -1,
  countryRegions: -1,
  metadata: {
    isStarred: false,
  },
  loading: true,
  configType: "",
  is_config_manual: false,
  is_schedule_config: false,
};

const user = {
  isAuth: false,
  theme: "light",
  name: "",
  preferred_username: "",
  email: "",
  role: "",
  token: "",
  error: null,
  errorCode: null,
  selections: {
    country: "ALL",
    segment: "ALL",
    brand: "ALL",
    searchText: "",
    appView: "table",
    tableOrder: "desc",
    tableOrderBy: "modified_on",
    runView: "data",
    activeRunId: null,
    activeRunPayload: null,
    viewrunCat: config.hardCoded.runPrevRunsId,
    runFilters: {
      searchText: "",
      startTime: "",
      endTime: "",
      orderBy: "created_on",
      dayCat: "all",
      viewRunsTags: [],
    },
  },
  alertInfo: {
    message: "",
    open: false,
    severity: "success",
  },
  dialogInfo: {
    message: "",
    open: false,
    handleYes: () => {},
    handleNo: () => {},
  },
  screen: {
    isSidenavBarExpanded: true,
  },
};

const data = {
  verticals: null,
  functions: { status: "loading", message: "", data: [] },
  apps: { status: "loading", message: "", data: [] },
  saved_configurations: null,
  country: null,
  brand: null,
  segment: null,
  subBrand: { status: "loading", message: "", data: [] },
  subSegment: { status: "loading", message: "", data: [] },
  segments: { status: "loading", message: "", data: [] },
  globalParams: null,
  globalRegions: null,
  models: null,
  datasets: null,
  runData: null,
  notificationData: null,
  appAccessDetails: null,
  shared_configurations: null,
  starred_configurations: { status: "loading", message: "", data: [] },
  adminPanelUsersList: { status: "loading", message: "", data: [] },
  globalParamsList: null,
  adminApps: { status: "loading", message: "", data: [] },
  datasetDetails: null,
  sharedRunsData: null,
};

const changeFlag = {
  selectedDatasets: false,
  selectedKpi: false,
  runData: false,
  refreshData: false, //fetch run data for given interval
  datasetAddedId: "",
  reloadConfigs: false,
  refreshComments: false, //fetch comments once user hits send button,
  reloadAdminUserList: false,
};

const adminSetting = {
  activeUser: {
    status: "loading",
    message: "",
    data: { name: "", preferred_username: "", role: "", groups: [] },
  },
  userGroupInfo: { status: "loading", message: "", data: {} },
  featureList: { status: "loading", message: "", data: [] },
  userList: { status: "loading", message: "", data: [] }, // users list
  adUserList: { status: "loading", message: "", data: [] }, // global users data
  userAddEditStatus: -1,
};

export const stateData = {
  user,
  data,
  configUserInputs,
  changeFlag,
  adminSetting,
};

// Import required libraries
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// Import custom components
import Table from "../Table/Table";

// Import utils
import { config } from "../../config/config";
import noDataImage from "../../../assets/images/undraw_empty.svg";

function AppRow(props) {
  let { data, title, user, match } = props;

  return (
    <div className={`${user.theme} apps-row`}>
      {title && <h1 className="apps-row-title">{title}</h1>}
      {data.length ? (
        <div>
          <Table data={data} match={match} paginationFlag={true} />
        </div>
      ) : (
        <div className="no-configs">
          <img src={noDataImage} />
          <p className="no-data">
            <b>{config.messages.noConfigs}</b>
          </p>
        </div>
      )}
    </div>
  );
}

AppRow.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(AppRow);

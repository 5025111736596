// Import required libraries
import { useState, useEffect } from "react";
import { connect } from "react-redux";
// Import required components
import Loader from "../Loader/Loader";
import GeoTargetingTable from "../GeoTargetingTable/GeoTargetingTable";
import UploadFile from "./UploadFile";
//import assets
import { config } from "../../config/config";
import geoTargetingImage from "../../../assets/images/geoTargetingImage.svg";
import "./GeoTargeting.scss";

//import actions
import { updateAlertInfo } from "../../redux/actions";

function GeoTargeting(props) {
  const { updateAlertInfo, user, allData } = props;
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("");
  const [message, setMessage] = useState("");
  const [value, setValue] = useState("");
  const [errorInApi, setErrorInApi] = useState(false);

  const fetchData = () => {
    let url = config.api.geoTargeting;
    let status;
    // let url = "https://run.mocky.io/v3/128a5667-49bb-4cea-80d9-c01a4dc12d08";
    setLoading(true);
    fetch(url)
      .then((response) => {
        status = response.status;
        if (response.status === 200) {
          return response.clone().json();
        }
      })
      .then((json) => {
        setLoading(false);
        if (json) {
          if (status === 200) {
            setFiles(json.data);
            setStatus(status);
          } else {
            setStatus(status);
            setMessage(json.statusMessage);
          }
        } else {
          setErrorInApi(true);
        }
      });
  };
  useEffect(() => {
    fetchData();
  }, []);

  const filesArray = (files) => {
    const filesArr = files.map((file) => file);
    return filesArr;
  };

  // Search bar requisites
  const handleFilter = (e) => {
    setValue(e.target.value);
  };
  let filteredData =
    files &&
    filesArray(files).filter((file) =>
      file.name.toLowerCase().includes(value.toLowerCase())
    );

  const getIllustration = (className, message) => (
    <div className={className}>
      <img src={geoTargetingImage} width="200" height="200" />
      <p>{message}</p>
    </div>
  );

  const handleDeleteReport = (row) => {
    updateAlertInfo({
      open: true,
      message: "Deleting the file...",
      severity: "info",
    });
    let url = config.api.geoTargeting;
    const payload = {
      country: row.country,
      brand: row.brand,
      filename: row.name,
    };
    let status;
    fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        status = response.status;
        return response.clone().json();
      })
      .then((json) => {
        if (json) {
          if (status === 200) {
            updateAlertInfo({
              open: true,
              message: json.statusMessage,
              severity: "info",
            });
            fetchData();
          } else {
            updateAlertInfo({
              open: true,
              message: json.statusMessage,
              severity: "info",
            });
          }
        } else {
          updateAlertInfo({
            open: true,
            message: json.statusMessage,
            severity: "error",
          });
        }
      });
  };

  return (
    <>
      {!loading ? (
        <div className="geo-targeting-container">
          <div className="geo-targeting-files-container">
            {errorInApi ? (
              getIllustration("no-files-available", "Something went wrong")
            ) : status === 200 ? (
              <>
                <div className="geo-targeting-files-search-container">
                  <div className="aide-resources-search">
                    <div className="input-search">
                      <i className={`material-icons-outlined search-icon `}>
                        search
                      </i>
                      <input
                        type="text"
                        id="search"
                        name="search"
                        value={value}
                        className="input-label"
                        onChange={(e) => handleFilter(e)}
                        placeholder="Type the file name you are looking for ..."
                        autoComplete="off"
                      />
                      <i
                        className={`material-icons-outlined cancel-icon ${
                          value !== "" ? "visible" : ""
                        }`}
                        onClick={() => setValue("")}
                      >
                        clear
                      </i>
                    </div>
                  </div>
                  {allData?.appAccessDetails?.feature_list
                    ?.upload_file_in_geotargeting && (
                    <UploadFile fetchData={fetchData} />
                  )}
                </div>
                {filteredData.length ? (
                  <GeoTargetingTable
                    records={filteredData}
                    handleDeleteReport={handleDeleteReport}
                  />
                ) : (
                  getIllustration(
                    "no-files-available",
                    "No files available for your selections."
                  )
                )}
              </>
            ) : (
              getIllustration("no-data-available", message)
            )}
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  allData: state.data,
});

const mapDispatchToProps = {
  updateAlertInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(GeoTargeting);

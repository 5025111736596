import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import "./UserMetricsReport.scss";
import { useState } from "react";
import { Loader } from "../../uiTheme";
import { config } from "../../config/config";
import noDataImage from "../../../assets/images/undraw_empty.svg";

const UserMetricsReport = (props) => {
  const { allData } = props;
  const history = useHistory();

  const initialReportLinkDataState = {
    data: "",
    status: "loading",
    statusMessage: "",
  };
  const [reportLinkData, setReportLink] = useState(initialReportLinkDataState);
  useEffect(() => {
    if (!allData?.appAccessDetails?.feature_list.user_metrics_report) {
      const location = "/home";
      history.push(location);
    }
  }, [allData?.appAccessDetails?.feature_list.user_metrics_report]);
  useEffect(() => {
    const url = config.api.userMetricReport;
    let status;
    fetch(url)
      .then((response) => {
        console.groupCollapsed("requesting", url);
        console.log("REPSONSE -> ", response);
        status = response.status;
        return response.clone().json();
      })
      .then((json) => {
        if (status === 200) {
          setReportLink({ ...json, status: "successful" });
        } else {
          setReportLink({ ...json, status: "failed" });
        }
        console.groupEnd();
      });
  }, []);
  return (
    <div className="user_metrics_report_container">
      {reportLinkData.status === "loading" ? (
        <Loader />
      ) : reportLinkData.status === "failed" || !reportLinkData.data ? (
        <div className="error-container">
          <img src={noDataImage} alt="user metrics report" />
          <p>{reportLinkData.statusMessage || "Something Went Wrong"}</p>
        </div>
      ) : (
        <iframe
          src={reportLinkData.data}
          title="user_metrics_report"
          className="user_metrics_report"
        />
      )}
    </div>
  );
};
const mapStateToProps = (state) => ({
  allData: state.data,
});

export default connect(mapStateToProps)(UserMetricsReport);

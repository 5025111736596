// Import required liraries
import React from "react";
import { useRouteMatch, Route, useHistory } from "react-router-dom";
// Import styles
import "./Unauthorised.scss";

// Import Custom Component
import LayoutNone from "../../layouts/LayoutNone/LayoutNone";
import LayoutTopSideBottom from "../../layouts/LayoutTopSideBottom/LayoutTopSideBottom";

// Import data/utils
import { config } from "../../config/config";

function Unauthorised() {
  const match = useRouteMatch();
  return (
    <LayoutNone>
      <div className="screen-container">
        <div className="title-container">
          <i className="material-icons-outlined screen-icon">
            sentiment_very_dissatisfied
          </i>
          <h1 className="screen-title">
            {config.messages.UnauthorisedMessage}
          </h1>
        </div>
        <div className="button-container">
          <a href={config.hardCoded.homeLocation}>
            <button className="button">Go to Admin-AIDE</button>
          </a>
        </div>
      </div>
    </LayoutNone>
  );
}

Unauthorised.propTypes = {};

export default Unauthorised;

// Import required libraries
import React, { useState } from "react";
import { connect } from "react-redux";
import capitalize from "lodash.capitalize";

// Import data
import { config } from "../../config/config";

// import components
import Message from "../Message/Message";

//Import styles
import "./PowerBiReportForRun.scss";

function PowerBiReportForRun(props) {
  let {
    status,
    dagMessage,
    activeModel,
    activeRunPayloadEntity,
    user,
    view,
    configUserInputs,
  } = props;
  let [expanded, setExpanded] = useState(false);

  let iframeUrl = "";
  // this if block is for test&Learn feature in which only country and brand are applied as filters
  if (activeModel?.powerBiReportForModel?.iframeUrlForRun) {
    if (view === "test_and_learn_chart") {
      iframeUrl = activeModel?.powerBiReportForModel?.iframeUrlForRun;
      switch (activeModel?.id) {
        default:
          iframeUrl =
            iframeUrl +
            "&filter=" +
            activeModel?.powerBiReportForModel?.tableName +
            `/country eq '${configUserInputs.country}'`;
          if (configUserInputs.brand !== "") {
            iframeUrl =
              iframeUrl +
              " and " +
              activeModel?.powerBiReportForModel?.tableName +
              `/brand eq '${configUserInputs.brand}'`;
          }
          break;
      }
    }
    // this else block is for view dashboard feature
    else {
      switch (activeModel?.id) {
        default:
          // iframeUrl = activeModel?.powerBiReportForModel?.iframeUrlForRun;
          iframeUrl =
            activeModel?.powerBiReportForModel?.iframeUrlForRun +
            "&filter=" +
            activeModel?.powerBiReportForModel?.filterNameAndCond +
            " '" +
            activeRunPayloadEntity?.id +
            "'";
          break;
      }
    }
  }

  return (
    <>
      {activeModel?.powerBiReportForModel?.iframeUrlForRun ? (
        <div className="power-bi-container">
          <div
            className={`${
              expanded
                ? user.screen.isSidenavBarExpanded
                  ? "zoom-icon-full-div-for-sidenav-expanded"
                  : "zoom-icon-full-div"
                : "zoom-icon-div"
            }`}
          >
            {expanded ? (
              <p className="zoom-icon-full-text">
                Report For {activeRunPayloadEntity?.metadata?.config_name} ||{" "}
                {activeRunPayloadEntity?.id}
              </p>
            ) : (
              <></>
            )}

            <i
              className={`material-icons-outlined ${
                expanded ? "zoom-icon-full-screen" : "zoom-icon"
              }`}
              onClick={() => {
                setExpanded(!expanded);
              }}
            >
              {expanded ? "close" : "zoom_out_map"}
            </i>
          </div>
          <div
            className={`${
              expanded
                ? user.screen.isSidenavBarExpanded
                  ? "power-bi-container-expanded-for-sideNav-expanded"
                  : "power-bi-container-expanded"
                : "power-bi-container-1"
            }`}
          >
            <iframe
              width="100%"
              height="100%"
              src={iframeUrl}
              frameBorder="0"
              allowFullScreen={true}
            ></iframe>
          </div>
        </div>
      ) : (
        <Message status={status} dagMessage={dagMessage} />
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, null)(PowerBiReportForRun);

import React, { useState } from "react";
import { Chip, Popover } from "../../uiTheme";
import { muiCommonStyles } from "../../../styles/styles";
function PopOverForTags({ index, item }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [popupIndex, setPopupIndex] = useState(0);
  const openPopup = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
  };
  const muiClass = muiCommonStyles();
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <>
      <span
        aria-describedby={id}
        style={{ marginLeft: "10px" }}
        onClick={(e) => {
          e.stopPropagation();
          openPopup(e);
          setPopupIndex(index);
        }}
      >
        +{item.tags.length - 2} View More
      </span>
      {popupIndex === index && open ? (
        <Popover
          id="simple-popover"
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          PaperProps={{
            style: { width: "20%" },
          }}
        >
          <div className="aide-filter-popover">
            <div className="row filter-popover-label">Tags</div>
            <div className="row">
              {item?.tags?.map((elem) => (
                <Chip
                  key={elem}
                  label={elem}
                  title={elem}
                  style={{ margin: "5px", height: "30px" }}
                />
              ))}
            </div>
          </div>
        </Popover>
      ) : null}
    </>
  );
}

export default PopOverForTags;

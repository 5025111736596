import { Checkbox, makeStyles, withStyles } from "@material-ui/core";
import React from "react";
import { connect } from "react-redux";
import { config } from "../../../../config/config";
import { updateActivationPlatform } from "../../../../redux/actions";
import { Popover } from "../../../../uiTheme";

const StyledCheckbox = withStyles(() => ({
  root: {
    color: "#4eafb3 !important",
    "& .MuiSvgIcon-root": {
      width: 18,
      height: 18,
    },
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}))(Checkbox);
const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: "none",
  },
  typography: {
    fontSize: 11,
    fontFamily: "Hind Siliguri",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    color: "#46596a",
    margin: 0,
    marginTop: 4,
    marginBottom: 4,
    // width: "320px",
    width: "fit-content",
  },
}));
function UploadAudienceCheckbox(props) {
  const {
    configUserInputs,
    selectedModel,
    updateActivationPlatform,
    propensity,
    uaAccess,
  } = props;
  const classes = useStyles();
  //state for the open popup for showing info
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  return (
    <div
      className={propensity ? "" : "datasets-selections-local-parameters-row"}
    >
      <div
        className={
          propensity
            ? "upload-audience-container-propensity"
            : "upload-audience-container"
        }
        style={{
          pointerEvents: `${
            !uaAccess || configUserInputs.configType === "run"
              ? "none"
              : "cursor"
          }`,
        }}
      >
        <div
          className={
            propensity
              ? "upload-audience-label-propensity"
              : "upload-audience-label"
          }
        >
          <p className="bold header-text">Upload Audience:</p>
          {/* <Tooltip
              title={config.messages.upload_audience_info}
              placement="bottom-start"
              style={{ lineHeight: "1 !important" }}
            >
              <i className="material-icons-outlined info-icon">
                info
              </i>
            </Tooltip> */}
          <i
            className={`material-icons-outlined ${
              !uaAccess ? "ua-icon" : "info-icon"
            }`}
            aria-owns={open ? "mouse-over-popover" : undefined}
            aria-haspopup="true"
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
            style={{ pointerEvents: "auto", marginTop: "0px" }}
          >
            {" "}
            info{" "}
          </i>
          <Popover
            id="mouse-over-popover"
            className={classes.popover}
            open={open}
            anchorEl={anchorEl}
            onClose={handlePopoverClose}
            disableRestoreFocus
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <p className={classes.typography}>
              {!uaAccess
                ? "You don't have permission to use UA platforms"
                : config.messages.upload_audience_info}
            </p>
          </Popover>
        </div>
        <div
          className={
            propensity
              ? "upload-audience-field-propensity"
              : "upload-audience-field"
          }
        >
          {selectedModel.config.interface_details &&
            selectedModel.config.interface_details.map((item, i) => (
              <div
                className="upload-audience-checkbox"
                id={item.interface_name + i}
              >
                <span className="upload-audience-checkbox-label">
                  {item.interface_name}
                </span>
                <StyledCheckbox
                  checked={item.upload_audience}
                  onChange={(e) => {
                    updateActivationPlatform({
                      key: "UA",
                      selectedModel: selectedModel,
                      value: !item.upload_audience,
                      interface_name: item.interface_name,
                    });
                  }}
                />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => ({
  //   user: state.user,
  //   changeFlag: state.changeFlag,
  //   configUserInputs: state.configUserInputs,
  //   models: state.configUserInputs.models,
});

const mapDispatchToProps = {
  updateActivationPlatform,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UploadAudienceCheckbox);

//Import required libraies
import { setWeek } from "date-fns";
import React, { useEffect, useState } from "react";
import { v4 } from "uuid";
import { makeStyles } from "@material-ui/core/styles";
import { Popover } from "../../../../uiTheme";
import Button from "@material-ui/core/Button";

//Import utils
import { config } from "../../../../config/config";
import { getWeekBasedOnHistoricWeek } from "../../../../utils/utils";

// Import custom components
import LocalParameterItem from "./LocalParameterItem";

//Import Styles
import "./MultisetPopup.scss";

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: "none",
  },
  typographyHeader: {
    fontSize: 12,
    fontFamily: "Hind Siliguri",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    color: "#46596a",
    margin: 0,
    marginTop: 5,
    backgroundColor: "#ebeff1",
  },
  typography: {
    fontSize: 11,
    fontFamily: "Hind Siliguri",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    color: "#46596a",
    margin: 0,
    marginTop: 4,
    marginBottom: 4,
    width: "320px",
  },
  contained: {
    color: "#46596a",
    backgroundColor: "transparent",
    marginRight: 4,
    marginTop: "28px",
    padding: "0px 5px",
    borderRadius: "3px",
    paddingTop: "2px",
    fontSize: "12px",
    height: 23,
    minWidth: 15,
    border: "1px solid #46596a",
    textTransform: "capitalize",
    fontFamily: "Hind Siliguri",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#f1f1f1",
      boxShadow: "none",
    },
  },
}));

const validateInput = (userInputs, allData) => {
  let validFlag = true;
  allData.forEach((lp) => {
    if (
      lp.ui_element_type === "calender_picker" &&
      userInputs.pilot_start !== "null"
    ) {
      validFlag = true;
    } else if (
      lp.ui_element_type === "inputbox_text" &&
      userInputs.pagePath !== ""
    ) {
      validFlag = true;
    } else {
      let maxVal = lp.maxValue == "null" ? 1000000.0 : parseFloat(lp.maxValue);
      let minVal =
        lp.minValue == "null" ? Number.MIN_VALUE : parseFloat(lp.minValue);
      let value = userInputs[lp.id];
      if (
        parseFloat(value) < parseFloat(minVal) ||
        parseFloat(value) > parseFloat(maxVal) ||
        isNaN(value) ||
        value === ""
      ) {
        validFlag = false;
      }
    }
  });
  return validFlag;
};

function ActionNamesRow(props) {
  let {
    id,
    name,
    data,
    value,
    modelConfigId,
    onChange,
    initialData,
    enable,
    show,
    userInputs,
    customClassName,
    setLengthError,
    index,
    activeApp,
  } = props;
  const [currData, setCurrData] = useState(initialData);
  const [edit, setEdit] = useState(false);
  const [error, setError] = useState(false);

  const onChangeCurr = (key, value) => {
    setCurrData({ ...currData, [key]: value });
  };

  const handleDelete = () => {
    setLengthError(false);
    let newData = value.filter((item, index) => item.id !== id);
    onChange(modelConfigId, newData);
  };

  const handleUpdate = () => {
    if (validateInput(currData, data)) {
      let newData = value.map((item) =>
        item.id !== id ? { ...item } : { ...currData }
      );
      onChange(modelConfigId, newData);
      setError(false);
      setEdit(false);
    } else {
      setError(true);
    }
  };

  useEffect(() => {
    if (validateInput(currData, data)) {
      setError(false);
    } else {
      setError(true);
    }
  }, [currData]);

  return (
    <div className="multiset-popup-row-container">
      {modelConfigId === config.hardCoded.combinationsSalesId && (
        <p>Test Region Set {index + 1}</p>
      )}
      <div
        className={`multiset-popup-row ${
          customClassName ? customClassName : ""
        } ${modelConfigId}`}
      >
        {data.map((item) => (
          <LocalParameterItem
            key={item.id}
            {...item}
            value={currData[item.id]}
            onChange={onChangeCurr}
            enable={edit}
            show={show}
            userInputs={userInputs}
            activeApp={activeApp}
          />
        ))}
        {/* <button className="add-button" onClick={() => handleDelete()}>
          Delete
        </button> */}
        <div className="edit-delete-action-row" style={{ marginBottom: "2px" }}>
          <i
            className="material-icons-outlined delete-icon"
            onClick={() => handleDelete()}
          >
            delete
          </i>
          {edit ? (
            <i
              className="material-icons-outlined done-icon"
              onClick={() => {
                handleUpdate();
              }}
            >
              done
            </i>
          ) : (
            <i
              className="material-icons-outlined edit-icon"
              onClick={() => {
                setEdit(true);
              }}
            >
              edit
            </i>
          )}
        </div>
      </div>
      {/* {error && <p className="error">{config.messages.combinationError}</p>} */}
    </div>
  );
}

function ActionNames(props) {
  let {
    name,
    data,
    value,
    modelConfigId,
    onChange,
    enable,
    show,
    userInputs,
    customClassName,
    activeApp,
  } = props;

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  let initialData = {};
  data.forEach((element) => {
    if (element.ui_element_type === "inputbox_number") {
      initialData[element.id] = parseFloat(element["minValue"]) || 0.0;
    } else if (element.ui_element_type === "inputbox_text") {
      initialData[element.id] = element["value"] || "";
    } else if (element.ui_element_type === "inputbox_text_event_action") {
      initialData[element.id] = element["value"] || "";
    } else if (element.ui_element_type === "inputbox_text_event_category") {
      initialData[element.id] = element["value"] || "";
    } else {
      initialData[element.id] =
        element["min"] === "null"
          ? userInputs.global_week_date_selection ===
            config.hardCoded.historicWeek
            ? getWeekBasedOnHistoricWeek(userInputs.historic_week_data).max
            : userInputs.endDate
          : element["min"];
    }
  });
  const [currData, setCurrData] = useState(initialData);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [allowedLength, setAllowedLength] = useState();
  const [lengthError, setLengthError] = useState(false);

  // console.groupCollapsed("STATE CHANGED");
  // console.log("DATA -> ", data);
  // console.log("CURR DATA -> ", currData);
  // console.log("VALUE -> ", value);
  // console.groupEnd();

  useEffect(() => {
    if (validateInput(currData, data)) {
      setError(false);
    } else {
      setError(true);
    }
  }, [currData]);

  const onChangeCurr = (key, value) => {
    setCurrData({ ...currData, [key]: value });
  };

  const addCombination = () => {
    if (
      value.length >= config.hardCoded.regressionBudgetSets &&
      modelConfigId == config.hardCoded.combinationsId
    ) {
      setAllowedLength(config.hardCoded.regressionBudgetSets);
      setLengthError(true);
    } else if (
      value.length >= config.hardCoded.salesUpliftSets &&
      modelConfigId == config.hardCoded.combinationsSalesId
    ) {
      setAllowedLength(config.hardCoded.salesUpliftSets);
      setLengthError(true);
    } else if (validateInput(currData, data)) {
      onChange(modelConfigId, [...value, { ...currData, id: v4() }]);
      setCurrData(initialData);
      setError(false);
    } else {
      setError(true);
    }
  };

  useEffect(() => {
    setLoading(false);
  }, []);

  // useEffect(() => {
  //   // console.log("Multiset Mounted");
  //   return () => console.log("Multiset Unmounted");
  // }, []);

  useEffect(() => {
    if (!loading) {
      // console.log("Multiset Updated");
    }
  });

  return (
    <div>
      <div className="action-name-info-container">
        <p className="bold header-text">{name}</p>
        <i
          className="material-icons-outlined info-icon"
          aria-owns={open ? "mouse-over-popover" : undefined}
          aria-haspopup="true"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        >
          {" "}
          info{" "}
        </i>
        <Popover
          id="mouse-over-popover"
          className={classes.popover}
          open={open}
          anchorEl={anchorEl}
          onClose={handlePopoverClose}
          disableRestoreFocus
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <p className={classes.typography}>
            Each Action Name Configuration set consists of 4 things - Page Path,
            {userInputs?.old_pm
              ? "Event Action, Event Category, Event label."
              : "Event Name, Event Variable, Event Value."}
          </p>
          <p className={classes.typography}>
            In case of multiple Action Names, each of these set of Action Name
            configuration is “OR”ed with each other set of Action Name
            configurations.
          </p>
        </Popover>
      </div>
      {value.length
        ? value.map((item, index) => (
            <ActionNamesRow
              key={item.id}
              id={item.id}
              name={name}
              data={data}
              value={value}
              onChange={onChange}
              userInputs={userInputs}
              modelConfigId={modelConfigId}
              initialData={item}
              enable={enable}
              show={show}
              customClassName={customClassName}
              setLengthError={setLengthError}
              index={index}
              activeApp={activeApp}
            />
          ))
        : null}
      <div
        className={`multiset-popup-row ${
          customClassName ? customClassName : ""
        } ${modelConfigId}`}
      >
        {data.map((item) => (
          <LocalParameterItem
            key={item.id}
            {...item}
            value={currData[item.id]}
            onChange={onChangeCurr}
            enable={enable}
            show={show}
            userInputs={userInputs}
            activeApp={activeApp}
          />
        ))}
        {/* <i
          className="material-icons done-icon"
          onClick={() => {
            addCombination();
          }}
        >
          check_circle
        </i> */}
        <Button
          variant="contained"
          className={classes.contained}
          onClick={() => {
            addCombination();
          }}
          style={{ marginBottom: "2px" }}
        >
          Add Set
        </Button>
      </div>
      {lengthError && (
        <p className="error">Cant add records more than {allowedLength}</p>
      )}
    </div>
  );
}

export default ActionNames;

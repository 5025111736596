// Import required libraries
import React from "react";
import useState from "react-usestateref";
import { connect } from "react-redux";
import {
  makeStyles,
  TableBody,
  TableRow,
  TableCell,
  withStyles,
} from "@material-ui/core";
import { Paper } from "@mui/material";

// Import data & utils
import useTable from "../../utils/useTable";

import { config } from "../../config/config";

// Import action creators
import { updateMultiSelections, updateDialogInfo } from "../../redux/actions";
import { formatDate } from "../../utils/utils";

const StyledTableCell = withStyles((theme) => ({
  body: {
    color: "#46596a",
    fontFamily: "Hind Siliguri",
    borderBottom: "0px",
  },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
  },
  listIcon: {
    display: "flex",
    alignItems: "center",
  },
  name: {
    maxWidth: "500px",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "13px !important",
    lineHeight: "23px",
  },
  tableRow: {
    backgroundColor: "red !important",
    padding: "9px !important",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "13px !important",
    lineHeight: "23px",
    "&:last-child th, &:last-child td": {
      borderBottom: 0,
    },
    "&:nth-of-type(odd)": {
      backgroundColor: "white !important",
      "&:hover": {
        backgroundColor: "#4eafb342 !important",
      },
    },
    "&:nth-of-type(even)": {
      backgroundColor: "rgba(70, 89, 106, 0.07) !important",
      "&:hover": {
        backgroundColor: "#4eafb342 !important",
      },
    },
  },
  starLoading: {
    marginRight: "5px",
    cursor: "pointer",
  },
}));
function GeoTargetingTable(props) {
  let {
    records,
    user,
    updateMultiSelections,
    handleDeleteReport,
    allData,
    updateDialogInfo,
  } = props;
  let headCells = [
    {
      id: "country",
      label: "Country",
      width: "13%",
    },
    {
      id: "brand",
      label: "Brand",
      width: "13%",
    },
    { id: "name", label: "File Name", width: "35%" },
    { id: "added_by", label: "Added By", width: "15%" },
    {
      id: "upload_date",
      label: "Upload Date",
      width: "14%",
    },
    {
      id: "links",
      label: "Links",
      width: "10%",
      disableSorting: "disable",
      align: "center",
    },
  ];
  const classes = useStyles();

  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const downloadFiles = (name, country, brand) => {
    let url = `${config.api.geoTargetingDownload}`;
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name,
        country,
        brand,
      }),
    })
      .then((response) => response.json())
      .then((json) => {
        let a = document.createElement("a");
        a.href = json.data.location;
        a.target = "_blank";
        a.click();
      });
  };

  const jioDashboardCheck = true;
  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    useTable(
      records,
      headCells,
      filterFn,
      user,
      updateMultiSelections,
      jioDashboardCheck
    );

  const handleNo = () => {
    updateDialogInfo({ ...user.dialogInfo, open: false });
  };

  const handleDelete = (row) => {
    updateDialogInfo({ ...user.dialogInfo, open: false });
    handleDeleteReport(row);
  };

  return (
    <>
      <Paper
        sx={{
          height: "66vh",
          overflow: "auto",
          border: "none",
          boxShadow: "none",
        }}
      >
        <TblContainer>
          <TblHead />
          <TableBody>
            {recordsAfterPagingAndSorting().map((item, index) => (
              <TableRow
                key={item.id + index}
                className={classes.tableRow}
                hover
                style={{ cursor: "default" }}
              >
                <StyledTableCell>{item.country}</StyledTableCell>
                <StyledTableCell>{item.brand}</StyledTableCell>
                <StyledTableCell>
                  {item.name.split(" ").map((e) => {
                    return e === "" ? <span>&nbsp;</span> : <span>{e} </span>;
                  })}
                </StyledTableCell>
                <StyledTableCell>{item?.added_by || ""}</StyledTableCell>
                <StyledTableCell>
                  {formatDate(item.upload_date)}
                </StyledTableCell>
                <StyledTableCell align="center">
                  <i
                    className="material-icons-outlined"
                    onClick={() => {
                      downloadFiles(item.name, item.country, item.brand);
                    }}
                    style={{ marginRight: 5, fontSize: 21 }}
                  >
                    file_download
                  </i>
                  {allData?.appAccessDetails?.feature_list
                    ?.upload_file_in_geotargeting && (
                    <i
                      className="material-icons-outlined"
                      style={{ fontSize: 21 }}
                      onClick={() => {
                        updateDialogInfo({
                          message: config.messages.confirmDelete,
                          open: true,
                          handleYes: () => {
                            handleDelete(item);
                          },
                          handleNo: handleNo,
                        });
                      }}
                    >
                      delete
                    </i>
                  )}
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </TblContainer>
      </Paper>
      <div style={{ height: "40px" }}>
        {records.length > 20 && <TblPagination />}
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  allData: state.data,
});

const mapDispatchToProps = {
  updateMultiSelections,
  updateDialogInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(GeoTargetingTable);

//Import required libraies
import React, { useRef } from "react";
import { useHistory } from "react-router-dom";

//Import custom libraries
// import Button from "@material-ui/core/Button";
import { Button } from "../../uiTheme";

//Import custom components

//Import utils, data
import { config } from "../../config/config";
import { makeDefaultResponseJson, formatDate } from "../../utils/utils";

//Import actions

// Import custom hooks

//Import styles
import { muiCommonStyles } from "../../../styles/styles";
import "./ScheduleConfig.scss";

function ScheduleButtons(props) {
  const {
    match,
    handleDialogClose,
    scheduleRunData,
    updateAlertInfo,
    reloadConfigs,
    updateUserInfo,
    checkValidSelections,
    activeApp,
    finalConfig,
  } = props;
  const muiClass = muiCommonStyles();
  const history = useHistory();

  const scheduleDeleteConfig = () => {
    updateAlertInfo({
      open: true,
      message: config.scheduleDeleteRequestMessage,
      severity: "info",
    });
    let deleteBody = {
      config_id: scheduleRunData.configurationId,
      frequency_type: scheduleRunData.frequencyType,
      app_id: config.hardCoded.aeAppId,
    };
    let url = `${config.api.scheduleConfigurl}`;
    let status;
    fetch(url, {
      method: "DELETE",
      body: JSON.stringify(deleteBody),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        console.groupCollapsed("DELETE REQUEST SENT...", url);
        console.log("REPSONSE -> ", response);
        status = response.status;
        return response.clone().json();
      })
      .then((json) => {
        console.log("JSON -> ", json);
        console.groupEnd();
        if (status === 200) {
          updateAlertInfo({
            open: true,
            message: config.scheduleSuccessDeleteMessage,
            severity: "success",
          });
          history.push(
            match.params.tab === "addnewconfig"
              ? `/home/myconfigurations/`
              : `/home/${match.params.tab}/`
          );
          reloadConfigs();
        } else {
          updateAlertInfo({
            open: true,
            message: json.statusMessage,
            severity: "error",
          });
        }
      });
  };

  const scheduleUpdateConfig = () => {
    if (checkValidSelections()) {
      let newScheduleBody = {
        time_zone: scheduleRunData.timeZone,
        trigger_time: scheduleRunData.triggerTime,
        frequency_type: scheduleRunData.frequencyType,
        frequency_value: scheduleRunData.frequencyValue,
      };
      let scheduledBody =
        finalConfig &&
        scheduleRunData.scheduleType === "add" &&
        !finalConfig?.id
          ? newScheduleBody
          : { ...newScheduleBody, config_id: scheduleRunData.configurationId };

      let body = finalConfig
        ? {
            config_info: finalConfig,
            schedule_info: scheduledBody,
          }
        : scheduledBody;

      let url = finalConfig
        ? config.api.configAndSchedule
        : config.api.scheduleConfigurl;
      let status;
      updateAlertInfo({
        open: true,
        message:
          scheduleRunData.scheduleType === "add"
            ? config.scheduleConfigRequestMessage
            : config.scheduleConfigUpdateRequestMessage,
        severity: "info",
      });

      fetch(url, {
        method: finalConfig
          ? scheduleRunData.scheduleType === "add" && !finalConfig?.id
            ? "POST"
            : "PUT"
          : scheduleRunData.scheduleType === "add"
          ? "POST"
          : "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      })
        .then((response) => {
          console.groupCollapsed("requesting", url);
          console.log("REPSONSE -> ", response);
          status = response.status;
          return response.clone().json();
        })
        .then((json) => {
          console.log("JSON -> ", json);
          console.groupEnd();
          if (status === 200) {
            if (finalConfig && scheduleRunData.scheduleType === "add") {
              updateAlertInfo({
                open: true,
                message: "Configuration saved and scheduled successfully",
              });
            } else if (finalConfig) {
              updateAlertInfo({
                open: true,
                message: "Configuration updated and scheduled successfully",
              });
            } else {
              updateAlertInfo({
                open: true,
                message:
                  scheduleRunData.scheduleType === "add"
                    ? config.scheduleConfigSuccessMessage
                    : config.scheduleConfigUpdateSuccess,
                severity: "success",
              });
            }

            history.push(
              match.params.tab === "addnewconfig"
                ? `/home/myconfigurations/`
                : `/home/${match.params.tab}/`
            );
            reloadConfigs();
          } else {
            // if (status === 500) {
            //   updateAlertInfo({
            //     open: true,
            //     message: config.messages.duplicateName,
            //     severity: "success",
            //   });
            // } else {
            updateAlertInfo({
              open: true,
              message: json.statusMessage,
              severity: "success",
            });
            // }
          }
        });
    }
  };

  let saveInfoOfScheduleRun = useRef(scheduleRunData);
  console.log(
    saveInfoOfScheduleRun,
    saveInfoOfScheduleRun.current.frequencyValue
  );

  let changeDateInEpoch = (date) => {
    let arrOfDate = date.split("/");
    return Date.parse(`${arrOfDate[1]}/${arrOfDate[0]}/${arrOfDate[2]}`);
  };

  return (
    <>
      <Button
        disableRipple
        variant="outlined"
        // className={muiClass.outlinedButton}
        onClick={handleDialogClose}
        style={{
          height: "23px",
          padding: "10px",
          fontSize: "11px",
          minWidth: "15px",
          boxSizing: "border-box",
          marginRight: "4px",
        }}
      >
        Cancel
      </Button>
      {scheduleRunData.scheduleType === "edit" && (
        <Button
          disableRipple
          // className={muiClass.outlinedButton}
          onClick={() => {
            handleDialogClose();
            scheduleDeleteConfig();
          }}
          variant="outlined"
          disabled={
            saveInfoOfScheduleRun.current.frequencyType === "run_once" &&
            changeDateInEpoch(saveInfoOfScheduleRun.current.frequencyValue) <
              changeDateInEpoch(formatDate(new Date(), "DD/MM/YYYY"))
              ? true
              : false
          }
          style={{
            height: "23px",
            padding: "10px",
            fontSize: "11px",
            minWidth: "15px",
            boxSizing: "border-box",
            marginRight: "4px",
          }}
        >
          Delete Schedule
        </Button>
      )}
      <Button
        disableRipple
        variant="contained"
        // className={muiClass.containedButton}
        onClick={() => {
          handleDialogClose();
          scheduleUpdateConfig();
        }}
        disabled={
          scheduleRunData.scheduleType === "edit" &&
          scheduleRunData.frequencyType === "run_once" &&
          scheduleRunData.frequencyValue ===
            saveInfoOfScheduleRun.current.frequencyValue &&
          scheduleRunData.timeZone === saveInfoOfScheduleRun.current.timeZone &&
          scheduleRunData.triggerTime ===
            saveInfoOfScheduleRun.current.triggerTime
            ? true
            : false
        }
        style={{
          height: "23px",
          padding: "10px",
          fontSize: "11px",
          minWidth: "15px",
          boxSizing: "border-box",
          marginRight: "4px",
        }}
      >
        {scheduleRunData.scheduleType === "add"
          ? "Schedule Run"
          : "Update Schedule"}
      </Button>
    </>
  );
}

export default ScheduleButtons;

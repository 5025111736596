//Import required libraies
import React, { useEffect, useState } from "react";

//Import Custom Component

// Import custom hooks

// Import action creators

// Import styles

// Import utils & data
import { config } from "../../../../config/config";

function InputNumberValidation(props) {
  let {
    id,
    name,
    enable,
    value,
    minValue,
    maxValue,
    onChange,
    customClassName,
    step,
    onKeyPress = () => {},
    activeModel,
  } = props;
  const [error, setError] = useState(false);
  useEffect(() => {
    let maxVal = maxValue === "null" ? Number.MAX_VALUE : parseFloat(maxValue);
    let minVal = minValue === "null" ? Number.MIN_VALUE : parseFloat(minValue);
    if (
      parseFloat(value) < parseFloat(minVal) ||
      parseFloat(value) > parseFloat(maxVal) ||
      isNaN(value) ||
      value === ""
    ) {
      if (
        id === "warm" ||
        id === "cold" ||
        id === "no_of_similar_regions" ||
        id === "corr_upper_limit"
      )
        setError(false);
      else setError(true);
    } else {
      setError(false);
    }
  }, [value]);

  const handleDecimalChange = (e) => {
    if (id === "no_of_similar_regions") {
      if (
        !(
          (e.keyCode > 95 && e.keyCode < 106) ||
          (e.keyCode > 47 && e.keyCode < 58) ||
          e.keyCode === 8
        )
      ) {
        e.preventDefault();
      }
    }
  };

  return (
    <div
      className={`input-item ${customClassName ? customClassName : ""} ${id} ${
        activeModel.id === config.hardCoded.nlpId ? "nlp-params" : ""
      }`}
    >
      <label title={name}>{name}:</label>
      <input
        type="number"
        onWheel={() => document.activeElement.blur()}
        disabled={!enable}
        value={isNaN(value) ? "" : value}
        min={minValue}
        max={maxValue}
        onChange={(e) => {
          onChange(id, parseFloat(e.target.value));
        }}
        onKeyDown={(e) => handleDecimalChange(e)}
        onKeyPress={onKeyPress}
        step={id === "no_of_similar_regions" ? 1 : step ? step : "any"}
        className={id === "no_of_similar_regions" ? "similar_regions" : ""}
      />
      {enable && (
        <span>
          {minValue && <label className="input_info">min: {minValue}</label>}
          {maxValue && <label className="input_info"> | Max: {maxValue}</label>}
        </span>
      )}
      {error && <p className="error">{config.inputNumberValidationError}</p>}
    </div>
  );
}

export default InputNumberValidation;

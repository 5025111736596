import C from "../../constants";
import {
  manipulateDataset,
  removeDupFromArray,
  convertArrToLowerCase,
  toggleDatasetReducer,
  toggleKpiReducer,
} from "../../../utils/utils";

import { config } from "../../../config/config";

export default function models(
  state = [],
  payload,
  is_schedule_config,
  is_fixed_schedule_config
) {
  let { type, data } = payload;
  let {
    modelId,
    kpi,
    key,
    kpiList,
    value,
    modelRunFlag,
    dataset,
    selectedDataset,
    activeModel,
    activeDatasetForKpi,
    activeDatasetDates,
    kpi_variables_list,
  } = data;

  let newState;
  switch (type) {
    case C.TOGGLE_MODEL:
      newState = state.map((model) =>
        model.id !== modelId ? model : { ...model, run: modelRunFlag }
      );
      return newState;
    case C.TOGGLE_DATASET:
      newState = state.map((model) =>
        model.id !== modelId
          ? { ...model }
          : {
              ...model,
              data_sources: toggleDatasetReducer(model.data_sources, data),
            }
      );
      return newState;
    case C.ADD_TOGGLE_KPI:
      //hardCoded stuff
      let addToggleDatasetList = config.hardCoded.radioDatasetList;
      // console.log(dataset.type);
      // console.log(selectedDataset);
      let addToggleDatasetFlag = addToggleDatasetList.includes(dataset.type);
      newState = state.map((model) =>
        model.id !== modelId
          ? { ...model }
          : {
              ...model,
              data_sources: selectedDataset
                ? model.data_sources.map((data_source) =>
                    data_source.id !== dataset.id
                      ? { ...data_source }
                      : {
                          ...dataset,
                          top_n_competitor: data_source.top_n_competitor,
                          kpi_list: kpiList,
                          kpi_variables_list: kpi_variables_list,
                        }
                  )
                : addToggleDatasetFlag
                ? [
                    ...toggleKpiReducer(model.data_sources, {
                      ...data,
                      activeModel,
                      flag: false,
                    }),
                    manipulateDataset({
                      dataset,
                      activeModel,
                      kpi,
                      kpiList,
                      kpiListFlag: true,
                      activeDatasetDates,
                      kpi_variables_list,
                      modelId,
                    }),
                  ]
                : [
                    ...model.data_sources,
                    manipulateDataset({
                      dataset,
                      activeModel,
                      kpi,
                      kpiList,
                      kpiListFlag: true,
                      activeDatasetDates,
                      kpi_variables_list,
                      modelId,
                    }),
                  ],
            }
      );
      return newState;
    case C.TOGGLE_KPI:
      //hardCoded stuff
      let toggleDatasetList = config.hardCoded.radioDatasetList;
      let toggleDatasetFlag = toggleDatasetList.includes(dataset.type);
      newState = state.map((model) =>
        model.id !== modelId
          ? { ...model }
          : {
              ...model,
              data_sources: selectedDataset
                ? model.data_sources.map((data_source) =>
                    data_source.id !== dataset.id
                      ? { ...data_source }
                      : {
                          ...dataset,
                          kpi_list: [kpi],
                          // removeDupFromArray([
                          //   ...dataset.kpi_list,
                          //   kpi,
                          // ]),
                        }
                  )
                : toggleDatasetFlag
                ? [
                    ...toggleKpiReducer(model.data_sources, {
                      ...data,
                      activeModel,
                      flag: false,
                    }),
                    manipulateDataset({
                      dataset,
                      activeModel,
                      kpi,
                      singleKpiFlag: true,
                      activeDatasetDates,
                      modelId,
                    }),
                  ]
                : [
                    ...model.data_sources,
                    manipulateDataset({
                      dataset,
                      activeModel,
                      kpi,
                      singleKpiFlag: true,
                      activeDatasetDates,
                      modelId,
                    }),
                  ],
            }
      );
      return newState;
    case C.ADD_DATASET:
      newState = state.map((model) =>
        model.id !== modelId
          ? { ...model }
          : {
              ...model,
              data_sources: [
                ...model.data_sources,
                manipulateDataset({ dataset, activeModel, modelId }),
              ],
            }
      );
      return newState;
    case C.REMOVE_DATASET:
      newState = state.map((model) =>
        model.id !== modelId
          ? { ...model }
          : {
              ...model,
              data_sources: model.data_sources.filter(
                (obj) => dataset.id !== obj.id
              ),
            }
      );
      return newState;
    case C.ADD_KPI:
      //hardCoded stuff
      let radioDatasetList = config.hardCoded.radioDatasetList;
      let toggleFlag = radioDatasetList.includes(dataset?.type);
      newState = state.map((model) =>
        model.id !== modelId
          ? { ...model }
          : {
              ...model,
              data_sources: selectedDataset
                ? model.data_sources.map((data_source) =>
                    data_source.id !== dataset.id
                      ? { ...data_source }
                      : {
                          ...dataset,
                          kpi_variables_list: kpi_variables_list,
                          kpi_list: removeDupFromArray([
                            ...dataset.kpi_list,
                            kpi,
                          ]),
                        }
                  )
                : toggleFlag
                ? [
                    ...toggleKpiReducer(model.data_sources, {
                      ...data,
                      activeModel,
                      flag: false,
                    }),
                    manipulateDataset({
                      dataset,
                      activeModel,
                      kpi,
                      singleKpiFlag: true,
                      activeDatasetDates,
                      kpi_variables_list,
                      modelId,
                    }),
                  ]
                : [
                    ...model.data_sources,
                    manipulateDataset({
                      dataset,
                      activeModel,
                      kpi,
                      singleKpiFlag: true,
                      activeDatasetDates,
                      kpi_variables_list,
                      modelId,
                    }),
                  ],
            }
      );
      return newState;
    case C.REMOVE_KPI:
      newState = state.map((model) =>
        model.id !== modelId
          ? { ...model }
          : {
              ...model,
              data_sources:
                dataset.kpi_list.length === 1 //user unchecks all KPIs
                  ? model.data_sources.filter((obj) => dataset.id !== obj.id)
                  : model.data_sources.map((data_source) =>
                      data_source.id !== dataset.id
                        ? { ...data_source }
                        : {
                            ...data_source,
                            kpi_list: data_source.kpi_list.filter(
                              (val) => val.toLowerCase() !== kpi.toLowerCase()
                            ),
                          }
                    ),
            }
      );
      return newState;
    case C.UPDATE_DATASET_LOCAL_PARAMETERS:
      //hardCoded stuff
      let toggleDatasetParameterFlag =
        config.hardCoded.radioDatasetList.includes(dataset.type);
      newState = state.map((model) =>
        model.id !== modelId
          ? { ...model }
          : {
              ...model,
              data_sources: selectedDataset
                ? model.data_sources.map((data_source) =>
                    data_source.id !== dataset.id
                      ? { ...data_source }
                      : {
                          ...dataset,
                          [key]: value,
                        }
                  )
                : toggleDatasetParameterFlag
                ? [
                    ...toggleKpiReducer(model.data_sources, {
                      ...data,
                      activeModel,
                      flag: false,
                    }),
                    manipulateDataset({
                      dataset,
                      kpi,
                      singleKpiFlag: true,
                      key,
                      value,
                      activeDatasetForKpi,
                      activeDatasetDates,
                      modelId,
                    }),
                  ]
                : [
                    ...model.data_sources,
                    manipulateDataset({
                      dataset,
                      kpi,
                      singleKpiFlag: true,
                      key,
                      value,
                      activeDatasetForKpi,
                      activeDatasetDates,
                      modelId,
                    }),
                  ],
            }
      );
      return newState;
    case C.UPDATE_MODEL_LOCAL_PARAMETERS:
      newState = state.map((model) => {
        if (model.id !== modelId) {
          return { ...model };
        } else if (
          !is_schedule_config &&
          !is_fixed_schedule_config &&
          modelId === config.hardCoded.propensityId &&
          (value === config.hardCoded.targetRunId ||
            value === config.hardCoded.uploadAudienceRunId) &&
          model?.config?.propensity_modeling_run_type ===
            config.hardCoded.initialRunId
        ) {
          if (
            model?.propensity_saved_selections?.target_selections &&
            value === config.hardCoded.targetRunId
          ) {
            return {
              ...model,
              config: {
                ...model.config,
                ...model?.propensity_saved_selections?.target_selections
                  ?.config,
                [key]: value,
              },
              propensity_saved_selections: {
                ...model?.propensity_saved_selections,
                initial_selections: {
                  config: {
                    action_names: model.config?.action_names,
                    audience_name: model.config?.audience_name,
                  },
                  data_sources: model?.data_sources,
                },
              },
            };
          } else {
            return {
              ...model,
              config: { ...model.config, [key]: value },
              propensity_saved_selections: {
                ...model?.propensity_saved_selections,
                initial_selections: {
                  config: {
                    action_names: model.config?.action_names,
                    audience_name: model.config?.audience_name,
                  },
                  data_sources: model?.data_sources,
                },
              },
            };
          }
        } else if (
          !is_schedule_config &&
          !is_fixed_schedule_config &&
          modelId === config.hardCoded.propensityId &&
          value === config.hardCoded.uploadAudienceRunId &&
          model.config?.propensity_modeling_run_type ===
            config.hardCoded.targetRunId
        ) {
          let {
            action_names,
            audience_name,
            custom_target_proppensity_run,
            propensity_modeling_run_type,
            region_granularity,
            target_run_id,
            upload_audience,
            ...remainingObject
          } = model?.config;
          return {
            ...model,
            config: { ...model.config, [key]: value },
            propensity_saved_selections: {
              ...model?.propensity_saved_selections,
              target_selections: {
                config: remainingObject,
              },
            },
          };
        } else if (
          !is_schedule_config &&
          !is_fixed_schedule_config &&
          modelId === config.hardCoded.propensityId &&
          value === config.hardCoded.targetRunId &&
          model.config?.propensity_modeling_run_type ===
            config.hardCoded.uploadAudienceRunId &&
          model?.propensity_saved_selections?.target_selections
        ) {
          return {
            ...model,
            config: {
              ...model.config,
              ...model?.propensity_saved_selections?.target_selections?.config,
              [key]: value,
            },
            propensity_saved_selections: {
              ...model?.propensity_saved_selections,
            },
          };
        } else if (
          !is_schedule_config &&
          !is_fixed_schedule_config &&
          modelId === config.hardCoded.propensityId &&
          value === config.hardCoded.initialRunId &&
          model.config?.propensity_modeling_run_type ===
            config.hardCoded.targetRunId
        ) {
          let {
            action_names,
            audience_name,
            custom_target_proppensity_run,
            propensity_modeling_run_type,
            region_granularity,
            target_run_id,
            upload_audience,
            ...remainingObject
          } = model?.config;
          return {
            ...model,
            config: {
              ...model.config,
              ...model?.propensity_saved_selections?.initial_selections?.config,
              [key]: value,
            },
            data_sources: model?.propensity_saved_selections?.initial_selections
              ? [
                  ...model?.propensity_saved_selections?.initial_selections
                    ?.data_sources,
                ]
              : [...model?.data_sources],
            propensity_saved_selections: {
              ...model?.propensity_saved_selections,
              target_selections: {
                config: remainingObject,
              },
            },
          };
        } else if (
          !is_schedule_config &&
          !is_fixed_schedule_config &&
          modelId === config.hardCoded.propensityId &&
          value === config.hardCoded.initialRunId &&
          model.config?.propensity_modeling_run_type ===
            config.hardCoded.uploadAudienceRunId
        ) {
          return {
            ...model,
            config: {
              ...model.config,
              ...model?.propensity_saved_selections?.initial_selections?.config,
              [key]: value,
            },
            data_sources: model?.propensity_saved_selections?.initial_selections
              ? [
                  ...model?.propensity_saved_selections?.initial_selections
                    ?.data_sources,
                ]
              : [...model?.data_sources],
          };
        } else if (
          !is_schedule_config &&
          !is_fixed_schedule_config &&
          modelId === config.hardCoded.communityClusteringId &&
          (value === config.hardCoded.targetRunId ||
            value === config.hardCoded.uploadAudienceRunId) &&
          model.config?.community_clustering_run_type ===
            config.hardCoded.initialRunId
        ) {
          if (
            model?.communityClustering_selections?.target_selections &&
            value === config.hardCoded.targetRunId
          ) {
            return {
              ...model,
              config: {
                ...model.config,
                ...model?.communityClustering_selections?.target_selections
                  .config,
                [key]: value,
              },
              communityClustering_selections: {
                ...model?.communityClustering_selections,
                initial_selections: {
                  config: { algorithm: model?.config.algorithm },
                  data_sources: model?.data_sources,
                },
              },
            };
          } else {
            return {
              ...model,
              config: { ...model.config, [key]: value },
              communityClustering_selections: {
                ...model?.communityClustering_selections,
                initial_selections: {
                  config: { algorithm: model?.config.algorithm },
                  data_sources: model?.data_sources,
                },
              },
            };
          }
        } else if (
          !is_schedule_config &&
          !is_fixed_schedule_config &&
          modelId === config.hardCoded.communityClusteringId &&
          value === config.hardCoded.uploadAudienceRunId &&
          model.config?.community_clustering_run_type ===
            config.hardCoded.targetRunId
        ) {
          let {
            algorithm,
            community_clustering_run_type,
            custom_target_community_run,
            target_run_id,
            upload_audience,
            region_granularity,
            ...remainingObject
          } = model?.config;
          return {
            ...model,
            config: { ...model.config, [key]: value },
            communityClustering_selections: {
              ...model?.communityClustering_selections,
              target_selections: {
                config: remainingObject,
              },
            },
          };
        } else if (
          !is_schedule_config &&
          !is_fixed_schedule_config &&
          modelId === config.hardCoded.communityClusteringId &&
          value === config.hardCoded.targetRunId &&
          model.config?.community_clustering_run_type ===
            config.hardCoded.uploadAudienceRunId &&
          model?.communityClustering_selections?.target_selections
        ) {
          return {
            ...model,
            config: {
              ...model?.config,
              ...model?.communityClustering_selections?.target_selections
                ?.config,
              [key]: value,
            },
            communityClustering_selections: {
              ...model?.communityClustering_selections,
            },
          };
        } else if (
          !is_schedule_config &&
          !is_fixed_schedule_config &&
          modelId === config.hardCoded.communityClusteringId &&
          value === config.hardCoded.initialRunId &&
          model.config?.community_clustering_run_type ===
            config.hardCoded.targetRunId
        ) {
          let {
            algorithm,
            community_clustering_run_type,
            custom_target_community_run,
            target_run_id,
            upload_audience,
            region_granularity,
            ...remainingObject
          } = model?.config;
          return {
            ...model,
            config: {
              ...model?.config,
              ...model?.communityClustering_selections?.initial_selections
                ?.config,
              [key]: value,
            },
            data_sources: model?.communityClustering_selections
              ?.initial_selections
              ? [
                  ...model?.communityClustering_selections?.initial_selections
                    ?.data_sources,
                ]
              : [...model?.data_sources],
            communityClustering_selections: {
              ...model.communityClustering_selections,
              target_selections: {
                config: remainingObject,
              },
            },
          };
        } else if (
          !is_schedule_config &&
          !is_fixed_schedule_config &&
          modelId === config.hardCoded.communityClusteringId &&
          value === config.hardCoded.initialRunId &&
          model.config?.community_clustering_run_type ===
            config.hardCoded.uploadAudienceRunId
        ) {
          return {
            ...model,
            config: {
              ...model?.config,
              ...model?.communityClustering_selections?.initial_selections
                ?.config,
              [key]: value,
            },
            data_sources: model?.communityClustering_selections
              ?.initial_selections
              ? [
                  ...model?.communityClustering_selections?.initial_selections
                    ?.data_sources,
                ]
              : [...model?.data_sources],
          };
        } else {
          return {
            ...model,
            config: { ...model.config, [key]: value },
          };
        }
      });
      return newState;
    case C.SELECT_ALL:
      newState = state.map((model) =>
        model.id !== modelId
          ? { ...model }
          : {
              ...model,
              data_sources: selectedDataset
                ? model.data_sources.map((data_source) =>
                    data_source.id !== dataset.id
                      ? { ...data_source }
                      : {
                          ...data_source,
                          kpi_list: kpiList,
                          kpi_variables_list,
                        }
                  )
                : [
                    ...model.data_sources,
                    manipulateDataset({
                      dataset,
                      activeModel,
                      addAllKpi: true,
                      kpiList,
                      kpi_variables_list,
                      modelId,
                    }),
                  ],
            }
      );
      return newState;
    case C.CLEAR_ALL:
      newState = state.map((model) =>
        model.id !== modelId
          ? { ...model }
          : {
              ...model,
              data_sources: model.data_sources.filter(
                (obj) => dataset.id !== obj.id
              ),
            }
      );
      return newState;
    case C.UPDATE_KPI_VARIABLES:
      newState = state.map((model) =>
        model.id !== modelId
          ? { ...model }
          : {
              ...model,
              data_sources: model.data_sources.map((data_source) =>
                data_source.id !== dataset.id
                  ? { ...data_source }
                  : {
                      ...dataset,
                      kpi_variables_list: kpi_variables_list.map((elem) =>
                        elem.id === kpi
                          ? { ...elem, [key]: value }
                          : { ...elem }
                      ),
                    }
              ),
            }
      );
      return newState;
    case C.RESET_NEW_KPI:
      newState = state.map((model) =>
        model.id !== modelId
          ? { ...model }
          : {
              ...model,
              data_sources: model.data_sources.map((data_source) => {
                if (data_source.id !== dataset.id) {
                  return { ...data_source };
                } else {
                  const kpi_list = dataset.kpi_list.filter(
                    (e) =>
                      !config.hardCoded.avgPriceDependentKpiList.includes(e)
                  );
                  return {
                    ...dataset,
                    kpi_list,
                    kpi_variables_list: kpi_variables_list
                      .map((elem) =>
                        elem.id === kpi
                          ? { ...elem, [key]: value }
                          : { ...elem }
                      )
                      .filter(
                        (e) =>
                          !config.hardCoded.avgPriceDependentKpiList.includes(
                            e.id
                          )
                      ),
                  };
                }
              }),
            }
      );
      return newState;
    case C.TOGGLE_DATASET_IN_COMMUNITY:
      const {
        id,
        kpi_list,
        name,
        pipeline_dataset_id,
        source,
        type,
        ...remaining
      } = data;
      newState = state.map((model) =>
        model.id !== modelId
          ? { ...model }
          : {
              ...model,
              data_sources: [
                {
                  id,
                  kpi_list,
                  name,
                  pipeline_dataset_id,
                  source,
                  type,
                },
              ],
            }
      );
      return newState;
    case C.DATA_AVAILABILITY_CHANGES:
      const { allData, cmu_data, configUserInputs } = data;
      let datasetName = allData.datasets;
      if (cmu_data?.datasets?.length) {
        datasetName = datasetName.filter((dataset) =>
          cmu_data?.datasets?.includes(dataset.id)
        );
      }
      //   {
      //     "id": "GA360",
      //     "kpi_list": [
      //         "all"
      //     ],
      //     "name": "GA360",
      //     "pipeline_dataset_id": "GA360",
      //     "source": "PLATFORM",
      //     "type": "MEDIA"
      //
      newState = state.map((model) => {
        if (model.run) {
          if (model.id === config.hardCoded.nlpId) {
            return {
              ...model,
              run: cmu_data?.nlp ? true : false,
            };
          } else {
            if (
              model.id === config.hardCoded.propensityId &&
              configUserInputs.old_pm
            ) {
              return model;
            } else {
              let filteredDataSources = model.data_sources.filter((dataset) => {
                const datasetIds = datasetName.map((el) => el.id);
                if (datasetIds.includes(dataset.id)) {
                  return true;
                } else {
                  return false;
                }
              });
              if (!filteredDataSources.length) {
                const {
                  id,
                  kpi_list,
                  name,
                  pipeline_dataset_id,
                  source,
                  type,
                } = datasetName[0];
                const firstDataset = {
                  id,
                  kpi_list,
                  name,
                  pipeline_dataset_id,
                  source,
                  type,
                };
                filteredDataSources = [firstDataset];
              }
              return {
                ...model,
                run: cmu_data?.cc_pm ? true : false,
                data_sources: filteredDataSources,
              };
            }
          }
        } else {
          return model;
        }
      });
      // newState = state.map((model) =>
      //   model.id !== modelId
      //     ? { ...model }
      //     : {
      //         ...model,
      //         data_sources: [
      //           {
      //             id,
      //             kpi_list,
      //             name,
      //             pipeline_dataset_id,
      //             source,
      //             type,
      //           },
      //         ],
      //       }
      // );
      return newState;

    case C.UPDATE_ACTIVATION_PLATFORM:
      console.log("key", data);
      let newEntry;
      let removedValue;
      if (data.key === "UA") {
        // key: "UA",
        // selectedModel: selectedModel,
        // value: !item.upload_audience,
        // interface_name: item.interface_name,
        newState = state.map((model) => {
          if (model.id !== data.selectedModel.id) {
            return { ...model };
          } else {
            const changedValueArray = model.config.interface_details.map((el) =>
              el.interface_name === data.interface_name
                ? {
                    ...el,
                    upload_audience: value,
                  }
                : el
            );
            return {
              ...model,
              config: {
                ...model.config,
                interface_details: changedValueArray,
              },
            };
          }
        });
      } else {
        if (data.newValue.length > data.presentValue.length) {
          newEntry = data.newValue.at(-1);
          console.log("newEntry", newEntry);

          newState = state.map((model) =>
            model.id !== data.selectedModel.id
              ? { ...model }
              : {
                  ...model,
                  config: {
                    ...model.config,
                    interface_details: [
                      ...(model.config.interface_details
                        ? model.config.interface_details
                        : []),

                      { interface_name: newEntry, upload_audience: false },
                    ],
                  },
                }
          );
        } else {
          removedValue = data.presentValue.find(
            (x) => !data.newValue.includes(x)
          );
          newState = state.map((model) =>
            model.id !== data.selectedModel.id
              ? { ...model }
              : {
                  ...model,
                  config: {
                    ...model.config,
                    interface_details: model.config.interface_details.filter(
                      (e) => e.interface_name !== removedValue
                    ),
                  },
                }
          );
          console.log("removedValue", removedValue);
        }
      }
      //   "interface_details": [{
      //     "interface_name": "GA",
      //     "upload_audience":true
      // }],

      return newState;
    default:
      return state;
  }
}

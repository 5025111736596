//Import required libraies
import React from "react";
import Radio from "@material-ui/core/Radio";
import { withStyles } from "@material-ui/core/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";

//Import Custom Component
import Loader from "../../../../components/Loader/Loader";

//Import utils
import { config } from "../../../../config/config";
import {
  getCurrentDate,
  convertEpochToDate,
  getDaysFromDates,
} from "../../../../utils/utils";

// Import styles

const BlueRadio = withStyles({
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
    color: "#4eafb3 !important",
    "&$checked": {
      color: "#4eafb3 !important",
    },
    "&$disabled": {
      color: "#4eafb3",
      opacity: 0.5,
    },
    "& .MuiSvgIcon-root": {
      height: 15,
      weight: 15,
    },
  },
  checked: {},
  disabled: {
    color: "#4eafb3",
    opacity: 0.5,
  },
})((props) => <Radio color="default" {...props} />);

const theme = createMuiTheme({
  overrides: {
    MuiRadio: {
      root: {
        padding: 0,
      },
    },
  },
});

const GlobalWeekDateSelection = (props) => {
  let {
    data,
    customClassName,
    userInputs,
    id,
    setUserInputs,
    enable,
    disableFlag,
    globalParamsOnChange,
    globalParamsObj,
  } = props;

  let displayValue =
    // userInputs[id];
    globalParamsObj[id];
  const calculateDays = () => {
    let diffDays = 0;
    if (
      globalParamsObj.global_week_date_selection ===
      config.hardCoded.historicWeek
    ) {
      diffDays = globalParamsObj.historic_week_data * 7;
    } else {
      if (
        globalParamsObj?.startDate?.length &&
        globalParamsObj?.endDate?.length
      ) {
        diffDays = getDaysFromDates(
          globalParamsObj.startDate,
          globalParamsObj.endDate
        );
      }
    }
    return diffDays;
  };

  return (
    <>
      {data.length === -1 ? (
        <Loader />
      ) : (
        <>
          <div
            className={`${disableFlag ? "disabled-pointer" : ""}`}
            style={{ height: "75px" }}
          >
            <div
              className={`input-item row ${
                customClassName ? customClassName : ""
              } ${id}`}
            >
              {/* <p className="date-range-text">Date Range</p> */}
              <div className="radio-container">
                {data.map((obj) => {
                  return (userInputs.is_schedule_config ||
                    userInputs.is_fixed_schedule_config) &&
                    config.hardCoded.dateRange === obj.id ? null : (
                    <div className="radio-icon" key={obj.id}>
                      <ThemeProvider theme={theme}>
                        <BlueRadio
                          value={obj.id}
                          checked={
                            displayValue
                              ? obj.id.toLowerCase() ===
                                displayValue.toLowerCase()
                              : false
                          }
                          style={{
                            color: "#4eafb3 !important",
                          }}
                          onChange={(e) => {
                            // setUserInputs(id, e.target.value);
                            globalParamsOnChange(id, e.target.value);
                            // if (
                            //   globalParamsObj.global_week_date_selection ===
                            //   "date_range"
                            // ) {
                            //   globalParamsOnChange("historic_week_data", 5);
                            // } else {
                            //   globalParamsOnChange("startDate", "");
                            //   globalParamsOnChange("endDate", "");
                            // }
                          }}
                          name={id}
                          size="small"
                          disabled={enable}
                          disableRipple
                        />
                      </ThemeProvider>
                      <label className="radio-label bold testTransform">
                        {obj.name}
                      </label>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className={`${id}`}>
              {config.hardCoded.historicWeek === displayValue ? (
                <>
                  <div className="input-item">
                    <input
                      type="number"
                      onWheel={() => document.activeElement.blur()}
                      min="1"
                      // value={userInputs.historic_week_data}
                      value={globalParamsObj.historic_week_data}
                      style={{
                        background:
                          userInputs.configType === "edit" &&
                          userInputs.is_fixed_schedule_config
                            ? "rgba(0,0,0,0.1)"
                            : "#ffffff",
                        pointerEvents:
                          (userInputs.configType === "edit" &&
                            userInputs.is_fixed_schedule_config) ||
                          userInputs.configType === "run"
                            ? "none"
                            : "all",
                      }}
                      onChange={(e) => {
                        globalParamsOnChange(displayValue, +e.target.value);
                        // setUserInputs(displayValue, e.target.value);
                        // setUserInputs("startDate", "");
                        // setUserInputs("endDate", "");
                      }}
                      id="date-range-input-start"
                    />
                  </div>
                </>
              ) : config.hardCoded.dateRange === displayValue ? (
                <>
                  <div className="date-range">
                    <div className="input-item">
                      <input
                        type="date"
                        min={convertEpochToDate(1514764800000, "YYYY-MM-DD")}
                        max={getCurrentDate()}
                        onKeyDown={(e) => e.preventDefault()}
                        // value={userInputs.startDate}
                        value={globalParamsObj.startDate}
                        onChange={(e) => {
                          // setUserInputs(config.hardCoded.historicWeek, 0);
                          // setUserInputs("startDate", e.target.value);
                          globalParamsOnChange("startDate", e.target.value);
                        }}
                        id="date-range-input-start"
                      />
                    </div>
                    <div className="input-item">
                      <input
                        type="date"
                        min={convertEpochToDate(1514764800000, "YYYY-MM-DD")}
                        max={getCurrentDate()}
                        onKeyDown={(e) => e.preventDefault()}
                        // value={userInputs.endDate}
                        value={globalParamsObj.endDate}
                        onChange={(e) => {
                          // setUserInputs(config.hardCoded.historicWeek, 0);
                          // setUserInputs("endDate", e.target.value);
                          globalParamsOnChange("endDate", e.target.value);
                        }}
                        id="date-range-input-end"
                      />
                    </div>
                  </div>
                </>
              ) : null}
            </div>
            {calculateDays() ? (
              <p className="days-counter">
                You have Selected {calculateDays()}{" "}
                {calculateDays() > 1 ? "Days" : "Day"}
              </p>
            ) : null}
          </div>
        </>
      )}
    </>
  );
};

export default GlobalWeekDateSelection;

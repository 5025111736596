//Import required libraies
import React from "react";
import PropTypes from "prop-types";
import Radio from "@mui/material/Radio";
import { withStyles } from "@material-ui/core/styles";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@material-ui/styles";
import "../index.css";

const newTheme = createTheme({
  overrides: {
    MuiRadio: {
      root: {
        padding: 0,
      },
    },
  },
});

/**
 * [RadioButtons description]
 * @param {[string]} theme [The theme of the component - default: light]
 * @param {[string]} styleVariant [Used to represent colored interface elements for a user - default: primary]
 * @param {[any]} ...props [Other valid props for MUI Radio]
 * @returns {[object]} [Returns the Radio component]
 */
const RadioButtons = (props) => {
  const { theme = "light", styleVariant = "primary", ...remaining } = props;

  const BlueRadio = withStyles({
    root: {
      "&:hover": {
        backgroundColor: "transparent !important", // To avoid halo effect
      },
      color: `var(--${styleVariant}-radio-${theme}) !important`,
      "&$checked": {
        // Reference the local rule "checked".
        color: `var(--${styleVariant}-radio-${theme}) !important`,
      },
      "& .MuiSvgIcon-root": {
        height: "var(--radio-element-size)",
        weight: "var(--radio-element-size)",
      },
    },
    checked: {},
  })(Radio);

  return (
    <ThemeProvider theme={newTheme}>
      <BlueRadio disableRipple {...remaining} />
    </ThemeProvider>
  );
};

RadioButtons.propTypes = {
  theme: PropTypes.oneOf(["light", "dark"]),
  styleVariant: PropTypes.oneOf(["primary", "secondary"]),
};

export default RadioButtons;

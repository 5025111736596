import React, { useEffect } from "react";
import { DatePicker, Button } from "../../../../uiTheme";
import {
  changeDateInEpoch,
  formatDate,
  getPastHrInfo,
  getTZData,
  getTimezoneOffset,
  getTriggerTime,
} from "../../../../utils/utils";
import { MenuItem, Select } from "@material-ui/core";
import FrequencyType from "../../../../../assets/data/ScheduleRun/FrequencyType.json";

const FixScheduleTypeForm = (props) => {
  const {
    scheduleRunData,
    updateScheduleDetails,
    updateScoringValue,
    MenuProps,
    muiClass,
    ScoringFrequencyType,
    StyledAutoComplete,
    CssTextField,
    changeInGlobalParamsFlag,
    scheduleDeleteConfig,
    configUserInputs,
    globalParamsObj,
    addGlobalParams,
    setDefaultGlobalParams,
    setChangeInGlobalParamsFlag,
    setScheduleRunData,
  } = props;
  const isDisabled =
    scheduleRunData.frequencyType === "run_once" &&
    changeDateInEpoch(scheduleRunData.frequencyValue) <
      changeDateInEpoch(formatDate(new Date(), "DD/MM/YYYY"));
  useEffect(() => {
    updateScheduleDetails("frequencyType", "weekly");
    updateScoringValue("weekly");
    updateScheduleDetails("number_of_runs", 1);
  }, []);
  const disableObj = {
    background:
      configUserInputs.configType === "edit" ? "rgba(0,0,0,0.1)" : "white",
  };
  const styleObj = {
    label: {
      marginBottom: 0,
      textTransform: "inherit",
    },
  };

  return (
    <div
      className="schedule-input-field-container"
      style={{
        pointerEvents: configUserInputs.configType === "edit" ? "none" : "all",
      }}
    >
      <div className="run-frequency-container">
        <div className="input-field-row-container">
          <div className="input-field-sub-container" style={{ width: "47.5%" }}>
            <label>Run Frequency</label>
            <div className="input-text-box">
              <Select
                name="scoring-frequency"
                id="scoring-frequency"
                value={scheduleRunData.frequencyType}
                onChange={(e) => {
                  updateScheduleDetails("frequencyType", e.target.value);
                  updateScoringValue(e.target.value);
                }}
                className={muiClass.multiSelect}
                MenuProps={MenuProps}
                style={{
                  width: "100%",
                  maxWidth: "none",
                  ...disableObj,
                }}
              >
                {FrequencyType.filter((item) => item.id === "weekly").map(
                  (obj) => (
                    <MenuItem
                      value={obj.id}
                      key={obj.id}
                      className={muiClass.singleMenuItem}
                    >
                      {obj.name}
                    </MenuItem>
                  )
                )}
              </Select>
            </div>
          </div>
          <div className="input-field-sub-container" style={{ width: "47.5%" }}>
            <div className="input-text-box">
              <div className={"input-item number_of_runs"}>
                <label title="No. of Runs" style={styleObj?.label}>
                  No. of runs
                </label>
                <input
                  type="number"
                  onWheel={() => document.activeElement.blur()}
                  disabled={false}
                  value={
                    isNaN(scheduleRunData.number_of_runs)
                      ? ""
                      : scheduleRunData.number_of_runs
                  }
                  min={0}
                  //   //   max={maxValue}
                  onChange={(e) => {
                    updateScheduleDetails(
                      "number_of_runs",
                      parseFloat(e.target.value)
                    );
                  }}
                  style={disableObj}
                  step={1}
                  className="number_of_runs_input"
                  onKeyPress={(event) => {
                    if (!/^([0-9])/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="input-field-row-container">
          <div className="input-field-sub-container no-label">
            <div
              className={`input-text-box ${
                scheduleRunData.frequencyType === "weekly"
                  ? "scoring-week-selection"
                  : ""
              }`}
            >
              <ScoringFrequencyType type={scheduleRunData.frequencyType} />
            </div>
          </div>
        </div>
      </div>
      <div style={{ flex: "0 1 42%", padding: "0 10px", height: "3.1rem" }}>
        <div
          className="input-field-row-container"
          style={{ justifyContent: "start" }}
        >
          <div className="input-field-sub-container" style={{ width: "18%" }}>
            <label>Time</label>
            <div className="input-text-box">
              <StyledAutoComplete
                id="trigger-zone"
                name="trigger-zone"
                value={scheduleRunData.triggerTime}
                options={getTriggerTime().map((elem) => elem)}
                disableClearable
                getOptionLabel={(option) => option || ""}
                getOptionDisabled={(option) => {
                  return scheduleRunData.frequencyType === "run_once"
                    ? !getPastHrInfo(
                        scheduleRunData.frequencyValue + " " + option,
                        scheduleRunData.timeZone
                      )
                      ? true
                      : false
                    : false;
                }}
                onChange={(e, newVal) => {
                  updateScheduleDetails("triggerTime", newVal);
                }}
                noOptionsText={"No options Found"}
                className={muiClass.typographyStyle}
                renderInput={(params) => (
                  <CssTextField
                    {...params}
                    variant="standard"
                    size="small"
                    placeholder="Type Time Here"
                    className={muiClass.typographyStyle}
                    style={{
                      background:
                        configUserInputs.configType === "edit"
                          ? "rgba(0,0,0,0.1)"
                          : "white",
                    }}
                  />
                )}
              />
            </div>
          </div>
          <div
            className="input-field-sub-container"
            style={{
              width: "65%",
              marginLeft: "20px",
            }}
          >
            <label>Timezone</label>
            <div className="input-text-box">
              <StyledAutoComplete
                id="trigger-zone"
                name="trigger-zone"
                value={{
                  id: scheduleRunData.timeZone,
                  name: getTimezoneOffset(scheduleRunData.timeZone),
                }}
                options={getTZData().map((elem) => ({
                  id: elem,
                  name: getTimezoneOffset(elem),
                }))}
                disableClearable
                getOptionLabel={(option) => option?.name || ""}
                getOptionSelected={(option, value) => option.id === value.id}
                onChange={(e, newValue) => {
                  updateScheduleDetails("timeZone", newValue?.id);
                }}
                noOptionsText={"No options Found"}
                className={muiClass.typographyStyle}
                renderInput={(params) => (
                  <CssTextField
                    {...params}
                    variant="standard"
                    size="small"
                    placeholder="Type Name Here"
                    className={muiClass.typographyStyle}
                    style={{
                      background:
                        configUserInputs.configType === "edit"
                          ? "rgba(0,0,0,0.1)"
                          : "white",
                    }}
                  />
                )}
              />
            </div>
          </div>
          {scheduleRunData.scheduleType === "edit" && (
            <div
              className={
                changeInGlobalParamsFlag
                  ? "input-field-pending-container"
                  : "input-field-delete-container"
              }
            >
              {/* <Button
                    disableRipple
                    // className={muiClass.outlinedButton}
                    onClick={() => {
                      scheduleDeleteConfig();
                    }}
                    variant="contained"
                    disabled={
                      scheduleRunData.frequencyType === "run_once" &&
                      changeDateInEpoch(scheduleRunData.frequencyValue) <
                        changeDateInEpoch(formatDate(new Date(), "DD/MM/YYYY"))
                        ? true
                        : false
                    }
                    style={{
                      height: "28px",
                      padding: "10px",
                      fontSize: "11px",
                      minWidth: "15px",
                      boxSizing: "border-box",
                      marginLeft: "7px",
                    }}
                  >
                    Delete Schedule
                  </Button> */}
              <i
                className={`material-icons-outlined delete-icon ${
                  isDisabled ? "icon-disabled" : ""
                }`}
                onClick={() => {
                  scheduleDeleteConfig();
                }}
                style={{
                  color: "#46596a",
                  fontSize: "20px",
                  pointerEvents: "all",
                }}
              >
                delete
              </i>
            </div>
          )}
          {configUserInputs.configType !== "run" && (
            <div className={`update-global-params`}>
              <Button
                disabled={
                  configUserInputs.configType === "run"
                    ? true
                    : globalParamsObj.global_week_date_selection ===
                        "date_range" &&
                      (!globalParamsObj.startDate || !globalParamsObj.endDate)
                    ? true
                    : changeInGlobalParamsFlag
                    ? false
                    : true
                }
                variant="contained"
                onClick={() => {
                  addGlobalParams(globalParamsObj);
                  setDefaultGlobalParams(globalParamsObj);
                  setChangeInGlobalParamsFlag(false);
                }}
                style={{
                  pointerEvents: "all",
                  fontSize: "12px",
                  fontWeight: "bold",
                  minWidth: "80px",
                  background:
                    configUserInputs.configType === "run"
                      ? "rgba(0,0,0,0.4)"
                      : globalParamsObj.global_week_date_selection ===
                          "date_range" &&
                        (!globalParamsObj.startDate || !globalParamsObj.endDate)
                      ? "rgba(0,0,0,0.4)"
                      : changeInGlobalParamsFlag
                      ? "#46596a"
                      : "rgba(0,0,0,0.4)",
                }}
              >
                Submit
              </Button>
              {changeInGlobalParamsFlag && (
                <p className="pending-changes-warning">Pending Changes</p>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default FixScheduleTypeForm;

//Import required libraies
import React, { useEffect } from "react";
import Radio from "@material-ui/core/Radio";
import { withStyles } from "@material-ui/core/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";

//Import Custom Component

// Import custom hooks

// Import action creators

// Import styles
import "./RadioButtons.scss";

// Import utils & data
import { config } from "../../../../config/config";
import { splitStringInArray } from "../../../../utils/utils";

const BlueRadio = withStyles({
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
    color: "#4eafb3 !important",
    "&$checked": {
      color: "#4eafb3 !important",
    },
    "&$disabled": {
      color: "#4eafb3",
      opacity: 0.5,
    },
    "& .MuiSvgIcon-root": {
      height: 15,
      weight: 15,
    },
  },
  checked: {},
  disabled: {
    color: "#4eafb3",
    opacity: 0.5,
  },
})((props) => <Radio color="default" {...props} />);

const theme = createMuiTheme({
  overrides: {
    MuiRadio: {
      root: {
        padding: 0,
      },
    },
  },
});

function CommunityType(props) {
  let {
    data,
    name,
    id,
    customClassName,
    value,
    onChange,
    enable,
    activeModel,
    userInputs,
    disabledForUploadAudience,
    selectedModel,
  } = props;

  let communityIdList = userInputs.models.find(
    (item) => item.id === config.hardCoded.communityClusteringId
  ).config.community_id_list;

  useEffect(() => {
    let updData = communityIdList.length ? communityIdList : ["ALL"];
    onChange("community_id_list", updData);
  }, [communityIdList]);

  return (
    <>
      <div
        className={`input-item row ${
          customClassName ? customClassName : ""
        } ${id}`}
      >
        <label>{name}:</label>
        <div className="radio-container">
          {data.map((obj) => {
            return (
              <div
                className="radio-icon"
                key={obj.id}
                style={{ marginLeft: "-10px" }}
              >
                <ThemeProvider theme={theme}>
                  <BlueRadio
                    value={obj.id}
                    checked={
                      value.length
                        ? obj.id.toLowerCase() === value.toLowerCase()
                        : false
                    }
                    onChange={(e) => {
                      onChange(id, e.target.value);
                      e.target.value === "ALL" &&
                        onChange("community_id_list", [e.target.value]);
                    }}
                    name={id}
                    size="small"
                    style={{
                      width: 15,
                      height: 15,
                      paddingRight: "3px",
                      color: "#4eafb3 !important",
                    }}
                    stylevariant="secondary"
                    disabled={!enable || disabledForUploadAudience}
                    disableRipple
                  />
                </ThemeProvider>
                <label className="radio-label">{obj.name}</label>
              </div>
            );
          })}
        </div>
      </div>

      {"specific" === value ? (
        <>
          <div
            className={`input-item row ${
              customClassName ? customClassName : ""
            } ${id}-text-area`}
          >
            <textarea
              rows="4"
              disabled={!enable || disabledForUploadAudience}
              value={
                communityIdList
                  ? communityIdList.toString() == ["ALL"].toString()
                    ? ""
                    : communityIdList
                  : ""
              }
              onChange={(e) => {
                onChange(
                  "community_id_list",
                  splitStringInArray(e.target.value)
                );
              }}
            ></textarea>
          </div>
        </>
      ) : null}
    </>
  );
}

export default CommunityType;

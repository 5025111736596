// https://run.mocky.io/v3/4bf7cd48-955f-4b26-bb81-6654be0727e2
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { config } from "../../config/config";

//import custom components
import DashboardCountryBrandSelection from "../DashboardCountryBrandSelections";
import Loader from "../Loader/Loader";

import noDataImage from "../../../assets/images/undraw_empty.svg";

//import css file
import "./ScheduleDashboard.scss";
import ScheduleDashboardTable from "./ScheduleDashboardTable";

const initialState = {
  data: [],
  loading: false,
  status: "",
  message: "",
};

function ScheduleDashboard(props) {
  const [data, setData] = useState(initialState);
  const [filterDataReports, SetFilterDataReports] = useState([]);
  const [countryBrandData, SetCountryBrandData] = useState({
    data: [],
    loading: true,
  });
  const [brand, setBrand] = useState("");
  const [country, setCountry] = useState("");
  const [model, setModel] = useState("");
  const [unit, setUnit] = useState("");
  const [showRunOnce, setShowRunOnce] = useState(true);

  //handler for go functionality
  const handlerForGo = () => {
    let filteredData =
      country !== "ALL"
        ? data.data.filter(
            (item) => item.country.toLowerCase() === country.toLowerCase()
          )
        : data.data;
    filteredData =
      model !== "ALL"
        ? filteredData.filter(
            (item) => item.model.toLowerCase() === model.toLowerCase()
          )
        : filteredData;
    filteredData =
      brand !== "ALL"
        ? filteredData.filter(
            (item) => item.brand.toLowerCase() === brand.toLowerCase()
          )
        : filteredData;
    // filteredData =
    //   weeks !== "ALL"
    //     ? filteredData.filter((item) => item.weeks === weeks)
    //     : filteredData;
    // filteredData =
    //   frequencyType !== "ALL"
    //     ? filteredData.filter(
    //         (item) =>
    //           item.frequency_type.toLowerCase() === frequencyType.toLowerCase()
    //       )
    //     : filteredData;
    SetFilterDataReports(filteredData);
  };

  useEffect(() => {
    setData({ ...initialState, loading: true });
    const url = config.api.optimizedSchedules;
    // const url = "https://run.mocky.io/v3/4357e97b-11e7-46c6-b99e-7783b43c8958";
    const controller = new AbortController();
    const { signal } = controller;
    let status;
    fetch(url, { signal })
      .then((response) => {
        status = response.status;
        return response.clone().json();
      })
      .then((json) => {
        setData({ ...data, loading: false });
        if (status === 200) {
          setData({
            ...data,
            data: json.data.schedule_list,
            loading: false,
            status: status,
          });
          SetFilterDataReports(
            json.data.schedule_list.map((el) => {
              return { ...el, expand: false };
            })
          );
          let arr = [];
          for (const iterator of Object.entries(json.data.country_brand)) {
            arr.push({ name: iterator[0], brands: iterator[1] });
          }
          const brandArr = [];
          arr.map((e) => {
            brandArr.push(...e.brands);
            return null;
          });
          let uniqueBrandArr = [...new Set(brandArr)];
          const newArr = [
            { name: "ALL", brands: uniqueBrandArr },
            ...arr.sort(),
          ];

          const finalArr = newArr.map((e) => {
            return {
              ...e,
              brands: ["ALL", ...e.brands?.sort()],
            };
          });
          SetCountryBrandData((pre) => {
            return { ...pre, data: finalArr, loading: false };
          });
        } else {
          setData({
            ...data,
            status: status,
            loading: false,
            message: json?.data?.statusMessage,
          });
        }
        setCountry("ALL");
        setBrand("ALL");
        setModel("ALL");
        setUnit("ALL");
        // setFrequencyType("ALL");
        // setWeeks("ALL");
      });
    return () => {
      controller.abort();
    };
  }, []);

  return (
    <div>
      {data.loading ? (
        <Loader />
      ) : data.status === 200 ? (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              position: "fixed",
              right: "2.2rem",
              top: "4rem",
              color: "#46596a",
              fontSize: "13px",
            }}
          >
            <p className="label" style={{ fontWeight: 600 }}>
              Include Run Once Schedules:{" "}
            </p>

            <i
              className="material-icons-outlined selected-toggle-icon selected"
              onClick={() => setShowRunOnce(!showRunOnce)}
              style={{
                color: showRunOnce ? "#4eafb3" : "rgba(70, 92, 106,0.5)",
                marginLeft: 5,
              }}
            >
              {showRunOnce ? "toggle_on" : "toggle_off"}
            </i>
          </div>
          <DashboardCountryBrandSelection
            handlerForGo={handlerForGo}
            countryBrandData={countryBrandData}
            brand={brand}
            country={country}
            setCountry={setCountry}
            setBrand={setBrand}
            unit={unit}
            setUnit={setUnit}
            model={model}
            setModel={setModel}
            allData={data}
            scheduleTabFlag={true}
            // weeks={weeks}
            // setWeeks={setWeeks}
            // frequencyType={frequencyType}
            // setFrequencyType={setFrequencyType}
          />
          {filterDataReports.length ? (
            <ScheduleDashboardTable
              records={
                showRunOnce
                  ? filterDataReports
                  : filterDataReports.filter(
                      (el) => el.frequency_type !== "run_once"
                    )
              }
              SetFilterDataReports={SetFilterDataReports}
            />
          ) : (
            <div className="error-container">
              <img src={noDataImage} />
              <p>There are no schedules created for this configuration</p>
            </div>
          )}
        </>
      ) : (
        <div className="error-container">
          <img src={noDataImage} />
          <p>{data.message || "Something Went Wrong"}</p>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  allData: state.data,
});

export default connect(mapStateToProps)(ScheduleDashboard);

//Import required libraies
import React, { useEffect, useState } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import { withStyles } from "@material-ui/core/styles";
import { Tooltip } from "../../../../uiTheme";
import { config } from "../../../../config/config";

//Import Custom Component

// Import custom hooks

// Import action creators

// Import styles

// Import utils & data

const StyledCheckbox = withStyles((theme) => ({
  root: {
    color: "#4eafb3 !important",
    paddingLeft: "0px",
    "& .MuiSvgIcon-root": {
      width: 18,
      height: 18,
    },
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}))(Checkbox);

function CustomCheckbox(props) {
  let { id, name, value, onChange } = props;
  const styleObj =
    id === "semrush"
      ? {
          display: "flex",
          flexDirection: "row-reverse",
          justifyContent: "flex-end",
          alignItems: "center",
        }
      : {};
  return (
    <div style={styleObj}>
      {id === "semrush" && (
        <Tooltip
          sx={{ fontSize: "11.5px" }}
          title={config.messages.semrush}
          placement="right-start"
        >
          <i
            className="material-icons-outlined info-icon"
            style={{
              pointerEvents: "all",
              fontSize: "17px !important",
              marginTop: "7px",
            }}
          >
            info
          </i>
        </Tooltip>
      )}
      <StyledCheckbox
        disableRipple
        checked={value}
        onChange={(e) => {
          onChange(id, !value);
        }}
        inputProps={{ "aria-label": "secondary checkbox" }}
        style={{ padding: id === "semrush" ? "0 0 0 9px" : "" }}
      />
      <label className="bold">{`${name}${id === "semrush" && ":"}`}</label>
    </div>
  );
}

export default CustomCheckbox;

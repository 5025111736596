// Import required libraries
import React, { useEffect } from "react";
import useState from "react-usestateref";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  makeStyles,
  TableBody,
  TableRow,
  TableCell,
  withStyles,
  Button,
} from "@material-ui/core";
// import PopOver from "../../components/PopOver/PopOver";
// import { Link } from "react-router-dom";
import TimeAgo from "react-timeago";
import { useHistory } from "react-router-dom";

// Import data & utils
import useTable from "../../utils/useTable";
import {
  flattenObject,
  timeDiffCalc,
  formatDate,
  makeRunNameFromRunNew,
  makeDefaultResponseJson,
  timeDifference,
} from "../../utils/utils";
import { config } from "../../config/config";

//import styles
import { muiCommonStyles } from "../../../styles/styles";

// Import action creators
import {
  updateMultiSelections,
  updateUserInfo,
  updateSelections,
  updateAlertInfo,
  reloadRunData,
  updateDialogInfo,
  updateRunData,
  updateSharedRunsData,
} from "../../redux/actions";

//Import styles
import "./Table.scss";
import PopOverForRun from "../PopOverForRun/PopOverForRun";
//import custom component
import PopOverForTags from "../PopOverForRun/PopOverForTags";

const StyledTableCell = withStyles((theme) => ({
  body: {
    color: "#46596a",
    fontFamily: "Hind Siliguri",
    borderBottom: "0px",
  },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
  },
  listIcon: {
    display: "flex",
    alignItems: "center",
  },
  name: {
    maxWidth: "500px",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "13px !important",
    lineHeight: "23px",
  },
  tableRow: {
    backgroundColor: "red !important",
    padding: "9px !important",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "13px !important",
    lineHeight: "23px",
    "&:last-child th, &:last-child td": {
      borderBottom: 0,
    },
    "&:nth-of-type(odd)": {
      backgroundColor: "white !important",
      "&:hover": {
        backgroundColor: "#4eafb342 !important",
      },
    },
    "&:nth-of-type(even)": {
      backgroundColor: "rgba(70, 89, 106, 0.07) !important",
      "&:hover": {
        backgroundColor: "#4eafb342 !important",
      },
    },
  },
  starLoading: {
    marginRight: "5px",
    cursor: "pointer",
  },
}));

// const headCells = [
//   { id: "run_status", label: "" },
//   { id: "name", label: "Name" },
//   { id: "owned_by", label: "Owned By" },
//   { id: "created_on", label: "Triggered On" },
//   { id: "duration", label: "Duration" },
//   { id: "run_action", label: "" },
// ];

function TableViewRun(props) {
  let {
    user,
    data,
    allData,
    paginationFlag,
    match,
    updateMultiSelections,
    updateDialogInfo,
    updateSelections,
    updateRunData,
    updateAlertInfo,
  } = props;
  let app = config.hardCoded.aeAppName;
  const muiClass = muiCommonStyles();

  let activeApp =
    allData.apps.data.length > 0
      ? allData.apps.data.filter(
          (obj) => obj.name.toLowerCase() === app.toLowerCase()
        )[0]
      : null;
  let headCells = [];

  headCells = [
    // { id: "run_status", label: "" },
    // { id: "run_bookmark", label: "" },
    { id: "config_name", label: "Name", width: "35%" },
    {
      id: "tags",
      label: "Tags",
      disableSorting: "disable",
      width: "20%",
    },
    { id: "created_by", label: "Owned By", width: "10%" },
    { id: "created_on", label: "Triggered On", width: "10%" },
    { id: "duration", label: "Duration", width: "10%" },
    { id: "run_action", label: "", width: "5%" },
  ];

  const classes = useStyles();
  const [records, setRecords] = useState(data.map((row) => flattenObject(row)));
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const [runLoading, setRunLoading, runLoadingRef] = useState([]);
  const history = useHistory();

  const setStarredValues = (runId) => {
    setRunLoading((prevValue) => {
      return [...prevValue, runId];
    });
  };

  const removeStarredValues = (runId) => {
    let sttValues = [...runLoadingRef.current];
    sttValues = sttValues.filter((item) => item !== runId); // remove
    setRunLoading([...sttValues]);
  };

  useEffect(() => {
    setRecords(data.map((row) => flattenObject(row)));
  }, [data]);

  // useEffect(() => {
  //   updateSelections("activeRunId", null);
  // }, []);

  const handleNo = () => {
    updateDialogInfo({ ...user.dialogInfo, open: false });
  };

  const updateRunAsStarred = (runId, runToBeStarred) => {
    // calling the APi here
    let url = `${config.api.bookmarkUrl}`;
    let body = {
      app_id: activeApp.id,
      id: runId,
      type: "run",
    };
    let status;
    setStarredValues(runId);
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        console.groupCollapsed("requesting", url);
        console.log("REPSONSE -> ", response);
        status = response.status;
        return response.clone().json();
      })
      .then((json) => {
        if (status === 200) {
          let allRunData = allData.runData;
          let allDataForViewRuns = [];
          allRunData?.forEach((obj) => {
            allDataForViewRuns = [...allDataForViewRuns, ...obj.data];
          });
          if (runToBeStarred.created_by === user.preferred_username) {
            allDataForViewRuns.map((singleRunObj) => {
              if (singleRunObj?.id === runId) {
                if (singleRunObj?.hasOwnProperty("isStarred")) {
                  singleRunObj.isStarred = !singleRunObj?.isStarred;
                } else {
                  singleRunObj.isStarred = true;
                }
              }
            });
            updateRunData(allDataForViewRuns);
          } else {
            let allSharedRunsDataObject = allDataForViewRuns?.find(
              (obj) => obj.id === runId
            );
            if (allSharedRunsDataObject) {
              allSharedRunsDataObject.metadata.shared_with.find((singleUsr) => {
                if (singleUsr.preferred_username === user.preferred_username) {
                  singleUsr.isStarred = !singleUsr.isStarred;
                }
              });
            }
            updateRunData(allDataForViewRuns);
          }
          removeStarredValues(runId);
        } else {
          updateAlertInfo({
            open: true,
            message: json.statusMessage,
            severity: "success",
          });
          removeStarredValues(runId);
        }
      });
  };

  const addViewRunsData = (runId) => {
    if (allData.runData) {
      //Fetch run data if runId is present
      if (runId) {
        let url = `${config.api.runUrl}?run_id=${runId}&app_id=${config.hardCoded.aeAppId}`;
        // let url = `${config.api.runIdUrl}?run_id=${runId}`;
        let status;
        fetch(url)
          .then((response) => {
            console.groupCollapsed("requesting", url);
            console.log("REPSONSE -> ", response);
            status = response.status;
            return response.clone().json();
          })
          .then((json) => {
            console.log("JSON -> ", json);
            console.groupEnd();
            if (status === 200) {
              updateSelections("activeRunPayload", json.data);
            } else {
              updateAlertInfo({
                open: true,
                message: json.statusMessage,
                severity: "success",
              });
            }
          });
      }
    }
  };

  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    useTable(records, headCells, filterFn, user, updateMultiSelections);

  return (
    <>
      <TblContainer>
        <TblHead />
        <TableBody>
          {recordsAfterPagingAndSorting().map((item, index) => (
            <TableRow
              key={item.id + item.config_name + index}
              className={classes.tableRow}
              hover
            >
              <StyledTableCell
                className={classes.name}
                onClick={() => {
                  updateSelections("activeRunId", item.id);
                  let activeRun = makeRunNameFromRunNew(item);
                  addViewRunsData(item.id);
                  history.push(`/home/viewruns/${activeRun}`);
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignContent: "center",
                    width: "500px",
                  }}
                >
                  <div className={`run-icon ${item.status}`}></div>
                  {runLoading?.includes(item.id) ? (
                    <div className={classes.starLoading}>
                      <CircularProgress color="secondary" size="1rem" />
                    </div>
                  ) : (
                    // <i
                    //   className="material-icons-outlined favourite-icon"
                    //   onClick={(e) => {
                    //     e.stopPropagation();
                    //     updateRunAsStarred(item.id, item);
                    //   }}
                    // >
                    //   {item?.isStarred ? "star" : "star_border"}
                    // </i>
                    <>
                      {item.created_by === user.preferred_username ? (
                        <>
                          <i
                            className="material-icons-outlined favourite-icon"
                            onClick={(e) => {
                              e.stopPropagation();
                              updateRunAsStarred(item.id, item);
                            }}
                          >
                            {item?.isStarred ? "star" : "star_border"}
                          </i>
                        </>
                      ) : (
                        <>
                          <i
                            className="material-icons-outlined favourite-icon"
                            onClick={(e) => {
                              e.stopPropagation();
                              updateRunAsStarred(item.id, item);
                            }}
                          >
                            {item?.shared_with?.find((singleUser) => {
                              return (
                                singleUser.preferred_username ===
                                user.preferred_username
                              );
                            })?.isStarred
                              ? "star"
                              : "star_border"}
                          </i>
                        </>
                      )}
                    </>
                  )}
                  <div className="config-name" title={item.name}>
                    {item.config_name}
                  </div>
                </div>
              </StyledTableCell>
              <StyledTableCell
                onClick={() => {
                  updateSelections("activeRunId", item.id);
                  let activeRun = makeRunNameFromRunNew(item);
                  addViewRunsData(item.id);
                  history.push(`/home/viewruns/${activeRun}`);
                }}
              >
                <div className="edit-tags">
                  {item.tags.length > 2 ? (
                    <>
                      <div className="chip-container">
                        {item.tags.slice(0, 2).map((elem) => (
                          <div className="chip-item" key={elem} title={elem}>
                            <span>{elem}</span>
                          </div>
                        ))}
                        <PopOverForTags index={index} item={item} />
                      </div>
                    </>
                  ) : item.tags.length <= 2 && item.tags.length !== 0 ? (
                    <div className="chip-container">
                      {item.tags.map((elem) => (
                        <div className="chip-item" title={elem}>
                          <span>{elem}</span>
                        </div>
                      ))}
                    </div>
                  ) : (
                    "-"
                  )}
                </div>
              </StyledTableCell>
              <StyledTableCell
                onClick={() => {
                  updateSelections("activeRunId", item.id);
                  let activeRun = makeRunNameFromRunNew(item);
                  addViewRunsData(item.id);
                  history.push(`/home/viewruns/${activeRun}`);
                }}
              >
                {item?.created_by ? item?.created_by : item.id.split("_")[0]}
              </StyledTableCell>
              <StyledTableCell
                onClick={() => {
                  updateSelections("activeRunId", item.id);
                  let activeRun = makeRunNameFromRunNew(item);
                  addViewRunsData(item.id);
                  history.push(`/home/viewruns/${activeRun}`);
                }}
              >
                {/* {formatDate(item.created_on)} */}
                {timeDifference(item.created_on) < 7 ? (
                  <TimeAgo date={new Date(item.created_on)} />
                ) : (
                  formatDate(item.created_on)
                )}
              </StyledTableCell>
              <StyledTableCell
                onClick={() => {
                  updateSelections("activeRunId", item.id);
                  let activeRun = makeRunNameFromRunNew(item);
                  addViewRunsData(item.id);
                  history.push(`/home/viewruns/${activeRun}`);
                }}
              >
                {timeDiffCalc(item.end_time, item.start_time)}
              </StyledTableCell>
              <StyledTableCell>
                <PopOverForRun user={user} match={match} run={item} />
              </StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </TblContainer>
      {paginationFlag && <TblPagination />}
    </>
  );
}

TableViewRun.propTypes = {
  data: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  paginationFlag: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
  allData: state.data,
});

const mapDispatchToProps = {
  updateMultiSelections,
  updateAlertInfo,
  updateDialogInfo,
  updateSelections,
  reloadRunData,
  updateRunData,
  updateSharedRunsData,
};

export default connect(mapStateToProps, mapDispatchToProps)(TableViewRun);

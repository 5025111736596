import React, { useEffect, useState } from "react";

import { connect } from "react-redux";
import { Button } from "react-bootstrap";
import { Modal } from "../../uiTheme";
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import orderBy from "lodash/orderBy";

//import assets
import { config } from "../../config/config";
import runSuccess from "../../../assets/images/run-success.svg";
import runError from "../../../assets/images/run-error.svg";
import uploadFile from "../../../assets/images/upload-file.svg";
import addUploadFile from "../../../assets/images/addUpload-file.svg";

//custom components
import CustomFileInput from "../CustomFileInput/CustomFileInput";

//actions
import { updateAlertInfo } from "../../redux/actions";

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: "none",
  },
  popoverTypography: {
    fontSize: 11,
    fontFamily: "Hind Siliguri",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    color: "#46596a",
    margin: 0,
    marginTop: 4,
    marginBottom: 4,
    width: "150px",
  },

  multiSelect: {
    fontSize: "12px !important",
    fontWeight: "100",
    fontFamily: "Hind Siliguri !important",
    minWidth: "200px",
    width: "100%",
    height: "30px",
    borderRadius: "3px",
    color: "#46596a !important",
    backgroundColor: "white",
    //paddingLeft: "8px",
    border: "1px solid #dedede",
    "&:before": {
      borderBottom: "0px",
    },
    "&:after": {
      borderBottom: "0px",
    },
    "&:hover:not(.Mui-disabled):before": {
      borderBottom: "0px",
    },
    "& .MuiOutlinedInput-input": {
      padding: "6px 6px",
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "transparent",
    },
    "& .MuiSelect-select.MuiSelect-select": {
      paddingLeft: "8px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "0px !important",
    },
  },
  singleMenuItem: {
    fontSize: "13px",
    fontFamily: "Hind Siliguri",
    padding: "8px 12px",
    color: "#46596a",
    width: "auto",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    "&:hover": {
      backgroundColor: "#4EAFB3",
      color: "white",
    },
  },
  typographyStyle: {
    fontFamily: "Hind Siliguri",
    fontSize: 14,
    color: "#46596a",
    minWidth: 400,
    maxWidth: 600,
  },
  contained: {
    color: "white",
    backgroundColor: "#46596a",
    marginRight: 4,
    fontSize: 11,
    padding: 10,
    height: 23,
    minWidth: 15,
    textTransform: "capitalize",
    fontFamily: "Hind Siliguri",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#46596a",
      boxShadow: "none",
    },
  },
  outlined: {
    borderColor: "#46596a",
    backgroundColor: "transparent",
    color: "#46596a",
    marginRight: 4,
    fontSize: 11,
    padding: 10,
    height: 23,
    minWidth: 15,
    textTransform: "capitalize",
    fontFamily: "Hind Siliguri",
    "&:hover": {
      backgroundColor: "transparent",
      boxShadow: "none",
    },
  },
  uploadFileClass: {
    flexDirection: "column",
    width: "100%",
    alignItems: "center",
    minHeight: 150,
    justifyContent: "center",
    display: "flex",
    "& P": {
      margin: 0,
      padding: 0,
      color: "#4EAFB3",
      fontSize: 13,
    },
    "& .uploadImageContainer": {
      display: "flex",
      alignItems: "center",
      textAlign: "center",
      "& P": {
        margin: 0,
        padding: 0,
        color: "#4EAFB3",
        fontSize: 13,
      },
      "& .orContainer": {
        margin: "0  40px",
      },
      "& .imageContainer": {
        textAlign: "center",
      },
    },
    "& .loaderImageContainer": {
      textAlign: "center",
    },
    "& .loaderBox": {
      marginTop: 10,
      textAlign: "center",
      "& p": {
        marginTop: 10,
      },
    },
    "& .uploadedContainer": {
      display: "flex",
      marginTop: "30px",
      alignItems: "center",
    },
  },
}));

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 150,
      width: "auto",
    },
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  variant: "menu",
  getContentAnchorEl: null,
};

const usePlaceholderStyles = makeStyles((theme) => ({
  placeholder: {
    color: "#aaa",
  },
}));

const Placeholder = ({ children }) => {
  const classes = usePlaceholderStyles();
  return <div className={classes.placeholder}>{children}</div>;
};

function UploadFile(props) {
  const { allData, updateAlertInfo, fetchData, user } = props;
  const initialState = {
    country: "",
    brand: "",
    selectedFiles: "",
  };
  const [openModal, setOpenModal] = useState(false);
  const [inputSelection, setInputSelection] = useState(initialState);
  const [uiError, setUiError] = useState("");
  const [uploadState, setUploadState] = useState("");
  const [fileName, setFileName] = useState("");

  const classes = useStyles();

  const countryBrandData = allData?.globalParams?.find(
    (data) => data.id === "country_brand_segment_hierarchy"
  );
  const countryData = countryBrandData?.data?.map((elem) => ({
    countryName: elem.name,
    data: elem.children,
  }));
  const brandData =
    countryData?.find((elem) => elem.countryName === inputSelection?.country)
      ?.data || [];

  const handleInputChange = (key, value) => {
    setInputSelection((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleFileChange = (e, option) => {
    setUiError("");
    const maxAllowedSize = 20 * 1024 * 1024;
    if (option === "select") {
      if (e.target?.files[0]?.size === 0) {
        updateAlertInfo({
          open: true,
          message: "Please select a non blank file",
          severity: "error",
        });
      } else if (e.target?.files[0]?.size > maxAllowedSize) {
        updateAlertInfo({
          open: true,
          message: "Allowed maximum size is 20MB",
          severity: "error",
        });
      } else {
        setUploadState("success");
        setFileName(e.target.files[0]?.name);
        setInputSelection((pre) => ({
          ...pre,
          selectedFiles: Array.from(e.target.files),
        }));
      }
    }
    if (option === "drop") {
      if (e.dataTransfer.files[0]?.size === 0) {
        updateAlertInfo({
          open: true,
          message: "Please select a non blank file",
          severity: "error",
        });
      } else if (e.dataTransfer.files[0]?.size > maxAllowedSize) {
        updateAlertInfo({
          open: true,
          message: "Allowed maximum size is 20MB",
          severity: "error",
        });
      } else {
        setUploadState("success");
        setFileName(e.dataTransfer.files[0]?.name);
        setInputSelection((pre) => ({
          ...pre,
          selectedFiles: Array.from(e.dataTransfer.files),
        }));
      }
    }
  };

  const uploadFileHandler = (formData) => {
    updateAlertInfo({
      open: true,
      message: "Uploading the file...",
      severity: "info",
    });

    let url = config.api.geoTargeting;
    let status;

    fetch(url, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        status = response.status;
        return response.clone().json();
      })
      .then((json) => {
        if (json) {
          if (status === 200) {
            updateAlertInfo({
              open: true,
              message: json.statusMessage,
              severity: "info",
            });
            fetchData();
          } else {
            updateAlertInfo({
              open: true,
              message: json.statusMessage,
              severity: "error",
            });
          }
        } else {
          updateAlertInfo({
            open: true,
            message: json.statusMessage,
            severity: "error",
          });
        }
      });
  };

  const handleSubmit = () => {
    var formData = new FormData();
    inputSelection?.selectedFiles?.forEach((file) => {
      formData.append("selectedAttachments", file);
    });
    formData.set("app_id", "AE");
    formData.set(
      "country",
      inputSelection.country ? inputSelection.country : ""
    );
    formData.set("brand", inputSelection.brand ? inputSelection.brand : "");
    formData.set("added_by", user?.preferred_username);
    formData.set("type", inputSelection?.selectedFiles[0].type);
    uploadFileHandler(formData);
    setOpenModal((pre) => !pre);
    setInputSelection({ ...initialState });
    setUiError("");
    setUploadState("");
    setFileName("");
  };

  useEffect(() => {
    if (inputSelection.selectedFiles) {
      setInputSelection((pre) => ({ ...pre, selectedFiles: "" }));
      setUiError("");
      setUploadState("");
      setFileName("");
    }
    return () => {};
  }, [inputSelection.country, inputSelection.brand]);

  const handleClose = () => {
    setOpenModal((pre) => !pre);
    setInputSelection({ ...initialState });
    setUiError("");
    setUploadState("");
    setFileName("");
  };

  return (
    <>
      <div>
        <Button
          disableRipple
          variant="contained"
          onClick={() => {
            setOpenModal((pre) => !pre);
          }}
          style={{
            marginLeft: "16px",
            fontSize: "13px",
            fontWeight: "bold",
            minWidth: "80px",
            background: "#46596a",
            color: "#fff",
            display: "flex",
            alignItems: "center",
            border: "1px solid #46596a",
          }}
        >
          <i
            className="material-icons-outlined"
            style={{ fontSize: 18, marginRight: 5 }}
          >
            upload_file
          </i>
          Upload File
        </Button>
      </div>

      <Modal open={openModal} onClose={handleClose}>
        <div style={{ width: "700px" }}>
          <div className="geo-input-field-row-container">
            <div className="input-field-sub-container">
              <label
                className="input-label"
                style={{
                  color: "#46596a",
                  fontWeight: 600,
                  textTransform: "capitalize",
                  fontSize: "13px",
                }}
              >
                Country:
              </label>
              <div className="select-input-search">
                <Select
                  name="country"
                  id="country"
                  value={inputSelection.country ? inputSelection.country : ""}
                  onChange={(e) => {
                    handleInputChange("country", e.target.value);
                    handleInputChange("brand", "");
                  }}
                  className={classes.multiSelect}
                  MenuProps={MenuProps}
                  displayEmpty
                  renderValue={
                    inputSelection.country
                      ? undefined
                      : () => <Placeholder>Select an option</Placeholder>
                  }
                >
                  {orderBy(countryData, ["countryName"], ["asc"])?.map(
                    (obj) => (
                      <MenuItem
                        value={obj.countryName}
                        key={obj.countryName}
                        className={classes.singleMenuItem}
                      >
                        {obj.countryName}
                      </MenuItem>
                    )
                  )}
                </Select>
              </div>
            </div>
            <div className="input-field-sub-container">
              <label
                className="input-label"
                style={{
                  color: "#46596a",
                  fontWeight: 600,
                  textTransform: "capitalize",
                  fontSize: "13px",
                }}
              >
                Brand:
              </label>
              <div className="select-input-search">
                <Select
                  name="brand"
                  id="brand"
                  value={inputSelection.brand ? inputSelection.brand : ""}
                  onChange={(e) => handleInputChange("brand", e.target.value)}
                  className={classes.multiSelect}
                  MenuProps={MenuProps}
                  displayEmpty
                  renderValue={
                    inputSelection.brand
                      ? undefined
                      : () => <Placeholder>Select an option</Placeholder>
                  }
                >
                  {brandData.length ? (
                    orderBy(brandData, ["name"], ["asc"])?.map((obj) => (
                      <MenuItem
                        value={obj.name}
                        key={obj.name}
                        className={classes.singleMenuItem}
                      >
                        {obj.name}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem className={classes.singleMenuItem} disabled>
                      {config.messages.noOptions}
                    </MenuItem>
                  )}
                </Select>
              </div>
            </div>
          </div>
          <CustomFileInput
            accept=".csv,.xlsx"
            onChange={handleFileChange}
            uploadState={uploadState}
            fileName={fileName}
            disabled={
              inputSelection.country || inputSelection.brand ? false : true
            }
            setUiError={setUiError}
            setUploadState={setUploadState}
            id={""}
            updateAlertInfo={updateAlertInfo}
            style={{
              pointerEvents: `${
                !inputSelection.country || !inputSelection.brand
                  ? "none"
                  : "auto"
              }`,
            }}
          >
            <div>
              <div className={`${classes.uploadFileClass}`}>
                {uploadState !== "loading" &&
                  uploadState !== "success" &&
                  !fileName &&
                  uploadState !== "error" && (
                    <div className="uploadImageContainer">
                      <div className="imageContainer">
                        <img src={uploadFile} alt="" draggable="false" />
                        <p>Drag and Drop</p>
                      </div>
                      <div className="orContainer">or</div>
                      <div className="imageContainer">
                        <img src={addUploadFile} alt="" draggable="false" />
                        <p>Upload from local</p>
                      </div>
                    </div>
                  )}
                {uploadState === "error" && (
                  <>
                    <div className="loaderImageContainer">
                      <img src={addUploadFile} alt="" draggable="false" />
                      <p style={{ textAlign: "center", cursor: "pointer" }}>
                        {fileName}
                      </p>
                    </div>
                    <div
                      style={{
                        fontSize: "10px",
                        color: "red",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <img src={runError} alt="" draggable="false" />
                      <p
                        style={{
                          fontSize: "10px",
                          marginRight: "10px",
                          color: "red",
                        }}
                      >
                        {uiError}
                      </p>
                    </div>
                  </>
                )}
                {uploadState === "success" && (
                  <>
                    <div className="loaderImageContainer">
                      <img src={addUploadFile} alt="" draggable="false" />
                      <p style={{ textAlign: "center", cursor: "pointer" }}>
                        {/* {fileName} */}
                        {fileName.split(" ").map((e) => {
                          return e === "" ? (
                            <span>&nbsp;</span>
                          ) : (
                            <span>{e} </span>
                          );
                        })}
                      </p>
                    </div>
                    <div className="uploadedContainer">
                      <img
                        src={runSuccess}
                        title="Validated Successfully"
                        alt="success"
                        draggable="false"
                      />
                      <p>Validated Successfully</p>
                    </div>
                  </>
                )}
              </div>
            </div>
          </CustomFileInput>
          <div
            className=""
            style={{
              display: "flex",
              justifyContent: `${
                !inputSelection.country || !inputSelection.brand
                  ? "space-between"
                  : "flex-end"
              }`,
              marginTop: 20,
            }}
          >
            {(!inputSelection.country || !inputSelection.brand) && (
              <div
                style={{
                  textAlign: "center",
                  color: "#eb367f",
                  fontSize: "13px",
                }}
              >
                Please select the Country and Brand
              </div>
            )}
            <div>
              <Button
                style={{
                  marginLeft: "16px",
                  fontSize: "12px",
                  fontWeight: "bold",
                  minWidth: "80px",
                  color: "#46596a",
                  background: "#fff",
                  border: "1px solid #46596a",
                }}
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                style={{
                  marginLeft: "16px",
                  fontSize: "13px",
                  fontWeight: "bold",
                  minWidth: "80px",
                  background: `${fileName ? "#46596a" : "rgb(179 186 192"}`,
                  color: "#fff",
                  border: "1px solid  #46596",
                  pointerEvents: `${!fileName ? "none" : "auto"}`,
                }}
                disabled={fileName ? false : true}
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  allData: state.data,
});

const mapDispatchToProps = {
  updateAlertInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadFile);

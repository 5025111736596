import { combineReducers } from "redux";
import user from "./user";
import data from "./data";
import configUserInputs from "./configUserInputs/configUserInputs";
import changeFlag from "./changeFlag";
import adminSetting from "./adminSetting";
export default combineReducers({
  user,
  data,
  configUserInputs,
  changeFlag,
  adminSetting,
});

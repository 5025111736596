//Import required libraies
import React, { useState } from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

// Import cunstom component
import Modal from "../../../../components/Modal/Modal";
// Import custom hooks

// Import action creators

//Import data
import configAccessDetails from "../../../../../assets/data/configAccessDetails.json";

// Import utils

// Import styles
import { muiCommonStyles } from "../../../../../styles/styles";
import "./ConfigSetting.scss";
import { config } from "../../../../config/config";

function AddTags(props) {
  const {
    configUserInputs,
    updateConfigUserInputs,
    allData,
    activeApp,
    createDefault,
  } = props;

  const muiClass = muiCommonStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [modalComponent, setModalComponent] = useState({
    component: null,
    openModal: false,
    handleCancel: () => {},
    handleDone: () => {},
  });

  const open = Boolean(anchorEl);
  const isConfigManualOptionDisabled = configUserInputs.is_schedule_config;

  const handleDialogClose = () => {
    setModalComponent((prevState) => {
      return { ...prevState, openModal: false };
    });
  };
  const handleDialogOpen = (component) => {
    setModalComponent((prevState) => {
      return {
        ...prevState,
        openModal: true,
        component: component,
      };
    });
  };

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
    e.stopPropagation();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="config-settings-container">
      <i
        className="material-icons more-vert-icon"
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        more_vert
      </i>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        onClose={handleClose}
      >
        <p className="other-option-text">Other Options</p>
        {configAccessDetails.map((elem) => (
          <div key={elem.id}>
            {elem.avaialbleApps.includes(activeApp.id) && (
              <>
                {allData?.appAccessDetails?.feature_list[elem?.id] ? (
                  <MenuItem
                    className={muiClass.popover}
                    onClick={(e) => {
                      handleClose();
                      handleDialogOpen(
                        <div className="dialog-popup-container">
                          <div>
                            This action will discard your changes.
                            <br />
                            <br />
                            Do you want to continue?
                          </div>
                          <div className="button-container">
                            <button
                              onClick={handleDialogClose}
                              className="primary-button"
                            >
                              no
                            </button>
                            <button
                              onClick={() => {
                                createDefault();
                                updateConfigUserInputs(elem.configKey, true);
                                handleDialogClose();
                              }}
                              className="secondary-button"
                            >
                              yes
                            </button>
                          </div>
                        </div>
                      );
                    }}
                    disabled={
                      configUserInputs.configType === "edit" ? true : false
                    }
                  >
                    <i className="material-icons-outlined arrow-circle-right-icon">
                      {elem.material_icon_name}
                    </i>
                    {elem.name}
                  </MenuItem>
                ) : (
                  <p className="other-option-text">No options available</p>
                )}
              </>
            )}
          </div>
        ))}
      </Menu>
      <Modal
        modalComponent={modalComponent}
        setModalComponent={setModalComponent}
        handleDialogClose={handleDialogClose}
      />
    </div>
  );
}

export default AddTags;

// Import required libraries
import React from "react";

// Import data
import { config } from "../../config/config";

// Import images
import failed_run from "../../../assets/images/failed_run.svg";
import successful_run from "../../../assets/images/successful_run.svg";
import queued_run from "../../../assets/images/currently_running.svg";

//Import styles
import "./Message.scss";

function Message(props) {
  const { status, dagMessage = "" } = props;

  const statusImageMapping = {
    [config.hardCoded.runFailed]: failed_run,
    [config.hardCoded.runQueued]: queued_run,
    [config.hardCoded.runRunning]: queued_run,
    [config.hardCoded.runScheduled]: queued_run,
    [config.hardCoded.runSuccess]: successful_run,
  };

  const statusMessageMapping = {
    [config.hardCoded.runFailed]: config.messages.runFailed,
    [config.hardCoded.runQueued]: config.messages.runQueued,
    [config.hardCoded.runRunning]: config.messages.runRunning,
    [config.hardCoded.runScheduled]: config.messages.runScheduledRunning,
    [config.hardCoded.runSuccess]: config.messages.runSuccess,
  };

  return (
    <div className="message-container">
      <img src={statusImageMapping[status]} alt="" />
      <span className="message-text">
        <b>{dagMessage ? dagMessage : statusMessageMapping[status]}</b>
      </span>
      {status === "Success" && (
        <span className="success-info-message">
          {config.messages.runSuccessInfo}
        </span>
      )}
    </div>
  );
}

export default Message;

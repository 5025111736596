// Import required libraies
import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@mui/material/CircularProgress";
import "../index.css";

/**
 * [Loader description]
 * @param {[string]} theme [The theme of the component - default: light]
 * @param {[string]} styleVariant [Used to represent colored interface elements for a user - default: primary]
 * @param {[object]} style [The style object which is passed to the component]
 * @param {[string]} message [Text to display]
 * @param {[any]} ...props [Other valid props for MUI CircularProgress]
 * @returns {[object]} [Returns the CircularProgress component]
 */
const Loader = (props) => {
  const {
    theme = "light",
    styleVariant = "primary",
    style = {},
    message = "",
    ...remaining
  } = props;
  const useStyles = makeStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      "& > * + *": {
        margin: "25px",
      },
    },
    text: {
      fontFamily: `var(--fontFamily)`,
      color: `var(--${styleVariant}-loader-color-${theme})`,
    },
  });
  const classes = useStyles();

  const { color = `var(--${styleVariant}-loader-color-${theme})` } = style;
  const finalStyle = {
    color,
    ...style,
  };

  return (
    <div className={classes.root}>
      {message && <div className={classes.text}>{message}</div>}
      <CircularProgress style={finalStyle} size="1rem" {...remaining} />
    </div>
  );
};

Loader.propTypes = {
  theme: PropTypes.oneOf(["light", "dark"]),
  styleVariant: PropTypes.oneOf(["primary", "secondary"]),
  message: PropTypes.string,
};

export default Loader;

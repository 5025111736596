// Import required libraries
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// Import custom components
import Table from "../Table/Table";

// Import utils
import { config } from "../../config/config";
import noData from "../../../assets/images/undraw_empty.svg";
import TableViewRunV2 from "../Table/TableViewRunV2";
import _ from "lodash";

function TableRowViewRunV2(props) {
  let { data, title, user, match, val } = props;
  const sortTheArray = (array) => {
    return array.sort(
      (objA, objB) => Number(objB.created_on) - Number(objA.created_on)
    );
  };
  const tableData = data
    ? data.map((item) => {
        const sortedDesc = sortTheArray(item.runs);
        const firstRun = sortedDesc[0];
        return {
          ...item,
          runs: sortedDesc,
          created_on: firstRun.created_on,
          start_time: firstRun.start_time,
          end_time: firstRun.end_time,
          is_scheduled_run: firstRun.is_scheduled_run,
        };
      })
    : [];
  const sortedTableData = tableData.length ? sortTheArray(tableData) : [];

  return (
    <div className={`${user.theme} apps-row`}>
      {title && <h1 className="apps-row-title">{title}</h1>}
      {data?.length ? (
        <div>
          <TableViewRunV2
            data={sortedTableData}
            match={match}
            paginationFlag={true}
            val={val}
          />
        </div>
      ) : (
        <div className="no-runs">
          <img src={noData} />
          <b>{config.messages.noRuns}</b>
        </div>
      )}
    </div>
  );
}

TableRowViewRunV2.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({ user: state.user });

export default connect(mapStateToProps)(TableRowViewRunV2);

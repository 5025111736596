//Import required libraies
import React from "react";

//Import Custom Component
import LocalParameterItem from "./LocalParameterItem";

// Import data
import UserAccessDetails from "../../../../../assets/data/UserAccessDetails.json";

// Import custom hooks

// Import action creators

// Import styles

// Import utils
import { checkForAccess } from "../../../../utils/utils";

function UploadAudience(props) {
  let { id, name, value, onChange, userData, activeModel } = props;

  //upload_audience is available in community_clustering, propensity_modelling
  //to have model and user specific access we are checking model wise if access is there
  const getModelConfigId = UserAccessDetails[activeModel.id].settings[id].id;

  //Checking if user has write access then show in UI
  const displayModelConfigFlag = checkForAccess(
    userData.appAccessDetails.feature_list,
    getModelConfigId,
    "write"
  );

  return (
    <div>
      {displayModelConfigFlag ? (
        <LocalParameterItem
          value={value}
          name={name}
          ui_element_type="checkbox"
          onChange={onChange}
          id={id}
        />
      ) : null}
    </div>
  );
}

export default UploadAudience;

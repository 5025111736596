import React from "react";
import PropTypes from "prop-types";
import Popover from "@mui/material/Popover";
import "../index.css";

function getPopoverStyle(theme, styleVariant) {
  return {
    fontFamily: "var(--fontFamily)",
    backgroundColor: `var(--${styleVariant}-popover-bgcolor-${theme})`,
    color: `var(--${styleVariant}-popover-textcolor-${theme})`,
  };
}

/**
 * [SimplePopover description]
 * @param {[string]} theme [The theme of the component - default: light]
 * @param {[string]} styleVariant [Used to represent colored interface elements for a user - default: primary]
 * @param {[node]} children [The content of the component.]
 * @param {[bool]} open [If true, the component is shown - default: false]
 * @param {[any]} ...props [Other valid props for MUI Popover]
 * @returns {[object]} [Returns the Popover component]
 */
const SimplePopover = (props) => {
  const {
    theme = "light",
    open = false,
    children,
    styleVariant = "primary",
    ...remaining
  } = props;
  const defaultStyle = getPopoverStyle(theme, styleVariant);
  const finalStyle = { ...defaultStyle };

  return (
    <div>
      <Popover open={open} {...remaining}>
        <div style={finalStyle}>{children}</div>
      </Popover>
    </div>
  );
};

SimplePopover.propTypes = {
  children: PropTypes.node,
  open: PropTypes.bool,
  theme: PropTypes.oneOf(["light", "dark"]),
  styleVariant: PropTypes.oneOf(["primary", "secondary"]),
};

export default SimplePopover;

//Import required libraries
import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import debounce from "lodash.debounce";
import { useHistory } from "react-router";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

// Import utils
import { makeDefaultResponseJson } from "../../../utils/utils";

//Import Custom Component
import Loader from "../../../components/Loader/Loader";
import noDataImage from "../../../../assets/images/undraw_empty.svg";
import Buttons from "./Buttons";
import GroupsInfo from "./GroupsInfo";
import { config } from "../../../config/config";

//Import utils/data
import { getInitialsFromEmailFromGlobalUsers } from "../../../utils/utils";

// Import action creators
import {
  updateAlertInfo,
  resetAddEditAlert,
  updateUserInfo,
  updateFeatureList,
  updateUserInfoForAdmin,
  groupsInfoReceived,
} from "../../../redux/actions";

//Import styles
import "../AdminPanel.scss";

const useStyles = makeStyles({
  chipStyle: {
    display: "flex",
    alignItems: "center",
    "& .MuiChip-root": {
      fontFamily: "Hind Siliguri",
      fontSize: 12,
      border: "1px solid #dedede",
      color: "#46596a !important",
      height: "25px",
      marginRight: "5px",
      background: "white",
      marginTop: "0px",
      fontFamily: "Hind Siliguri",
      fontSize: 14,
      border: "1px solid #dedede",
      color: "white !important",
      height: "30px",
      borderRadius: "3px",
      width: "15%",
      margin: "5px",
      background: "#4EAFB3",
      display: "flex",
      justifyContent: "space-between",
      "& .MuiChip-deleteIcon": {
        fontSize: 20,
        border: "none",
        color: "white",
      },
    },
  },
  dialogStyle: {
    fontFamily: "Hind Siliguri",
    "& .MuiTypography-h6": {
      fontSize: 14,
      color: "#46596a !important",
    },
    "& .MuiDialogContent-root": {
      fontSize: 12,
      color: "#46596a !important",
      opacity: 0.8,
    },
    "& .MuiButton-textPrimary": {
      color: "#46596a",
    },
    "& .MuiButton-root": {
      fontFamily: "Hind Siliguri",
      fontSize: 14,
    },
    "& .MuiDialogContent-dividers": {
      padding: "0px 24px",
    },
  },
  typographyStyle: {
    width: "100%",
    "& .MuiInput-underline:after": {
      borderBottom: 0,
    },
    "& .MuiInput-underline:before": {
      borderBottom: 0,
      transition: "none",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: "0px solid #dedede !important",
    },
    "& .Mui-focused .MuiInput-underline": {
      borderBottom: 0,
    },
    "& .MuiAutocomplete-option": {
      fontFamily: "Hind Siliguri",
      fontSize: 12,
    },
    "& .MuiAutocomplete-paper": {
      fontFamily: "Hind Siliguri",
      fontSize: 6,
    },
    "& .MuiInputBase-input": {
      fontFamily: "Hind Siliguri",
      fontSize: 12,
      color: "#46596a",
    },
    "& .MuiOutlinedInput-root": {
      paddingTop: "0px !important",
      paddingBottom: "0px !important",
    },
  },
  multiSelect: {
    fontSize: "12px",
    fontFamily: "Hind Siliguri",
    width: "100%",
    height: "30px",
    borderRadius: "3px",
    color: "#46596a",
    backgroundColor: "white",
    boxSizing: "border-box",
    //paddingLeft: "8px",
    display: "flex",
    alignItems: "center",
    //textAlign: "center",
    border: "1px solid #dedede",
    "&:before": {
      borderBottom: "0px",
    },
    "&:after": {
      borderBottom: "0px",
    },
    "&:hover:not(.Mui-disabled):before": {
      borderBottom: "0px",
    },
    "& .MuiOutlinedInput-input": {
      padding: "6px 6px",
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "transparent",
    },
    "& .MuiSelect-select.MuiSelect-select": {
      paddingLeft: "8px",
      // height: "25px",
    },
  },
  singleMenuItem: {
    fontSize: "12px",
    fontFamily: "Hind Siliguri",
    padding: "4px 12px",
    color: "#46596a",
    width: "auto",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    "&:hover": {
      backgroundColor: "#4EAFB3",
      color: "white",
    },
  },
});
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 150,
      width: "auto",
    },
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  variant: "menu",
  getContentAnchorEl: null,
};
const CssTextField = withStyles({
  root: {
    width: "100%",
    borderRadius: "4px",
    "& label.Mui-focused": {
      color: "green",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#dedede",
    },
    "& .MuiOutlinedInput-root": {
      backgroundColor: "white",
      "& fieldset": {
        borderColor: "#dedede",
      },
      "&:hover fieldset": {
        borderColor: "#dedede",
      },
      "&.Mui-focused fieldset": {
        border: "0.5px solid #dedede",
      },
    },
  },
})(TextField);
const StyledAutoComplete = withStyles((theme) => ({
  tag: {
    background: "#465a69",
    color: "#46596a !important",
    fontFamily: "Hind Siliguri !important",
  },
  paper: {
    fontFamily: "Hind Siliguri",
    fontSize: 13,
    color: "#46596a",
  },
}))(Autocomplete);

function UserDetails({
  userInfoObj,
  adUserListObj,
  preferredUser,
  userAddEditStatus,
  updateUserInfo,
  updateAlertInfo,
  updateFeatureList,
  featureList,
  updateUserInfoForAdmin,
  activeUser,
  groupsInfoReceived,
  userGroupInfo,
}) {
  const history = useHistory();

  const activeUserInfo = userInfoObj?.data || {};
  const isEdit = !!activeUserInfo.preferred_username;

  const classes = useStyles();
  const [data, setData] = useState({});
  const [associatedGroups, setAssociatedGroups] = useState([]);
  const [currentLoadedGroup, setCurrentLoadedGroup] = useState(null);
  const [open, setOpen] = useState(false);
  const [usersApiLoading, setUsersApiLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const loading = open && usersApiLoading;
  const [featureData, setFeatureData] = useState(-1);

  const debounceCall = useCallback(
    debounce((nextValue) => onChangeHandle(nextValue), 500),
    []
  );

  const onChangeHandle = async (value) => {
    // use the changed value to make request and then use the result
    if (value) {
      setUsersApiLoading(true);
      let dataToPass = {
        search_by: "displayName",
        search_text: value,
        limit: 50,
      };
      let url = config.api.allUsersUrl;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataToPass),
      });
      const allUsersData = await response.json();
      setOptions(allUsersData.data);
      setUsersApiLoading(false);
    }
  };

  useEffect(() => {
    if (!open) {
      setUsersApiLoading(false);
      setOptions([]);
    }
  }, [open]);

  useEffect(() => {
    if (preferredUser !== "createnewuser") {
      updateUserInfoForAdmin({ status: "loading", message: "", data: {} });
      getUserInfo(preferredUser);
    } else {
      updateUserInfoForAdmin({
        status: "success",
        message: "",
        data: {
          name: "",
          preferred_username: "",
          groups: [],
          email: "",
        },
      });
    }
  }, []);

  useEffect(() => {
    setData(userInfoObj?.data || {});
    setAssociatedGroups(activeUserInfo?.groups || []);
  }, [userInfoObj]);

  // need to check this
  useEffect(() => {
    if (userAddEditStatus?.status === "error") {
      updateAlertInfo({
        open: true,
        message: userAddEditStatus?.message || "Not able to save/update User",
        severity: "info",
      });
      resetAddEditAlert();
    } else if (userAddEditStatus != -1) {
      updateAlertInfo({
        open: true,
        message: "User details saved",
        severity: "info",
      });
      history.push("/home/admin-panel/users");
      resetAddEditAlert();
    }
  }, [userAddEditStatus]);

  useEffect(() => {
    fetchFeatureList();
    return () => {};
  }, []);

  useEffect(() => {
    if (preferredUser !== "createnewuser") {
      if (
        featureList.status === "success" &&
        userGroupInfo.status === "success"
      ) {
        let featureListReceived = featureList?.data?.[0]?.feature_list;
        let userGroupInfoReceived =
          userGroupInfo.data.access_list[config.hardCoded.aeAppId].feature_list;
        let res = [];
        featureListReceived.forEach((elem) => {
          if (
            Object.entries(userGroupInfoReceived).find(
              ([key, newValue]) => elem.id === key
            )
          ) {
            Object.entries(userGroupInfoReceived).forEach(
              ([key, newValue]) =>
                elem.id === key && res.push({ ...elem, value: newValue })
            );
          } else {
            res.push({ ...elem, value: elem.value });
          }
        });
        setFeatureData(res);
      }
    } else {
      if (featureList.status === "success") {
        setFeatureData(featureList?.data?.[0]?.feature_list);
      }
    }
    return () => {};
  }, [featureList, userGroupInfo]);

  useEffect(() => {
    if (
      activeUser.status === "success" &&
      preferredUser !== "createnewuser" &&
      activeUser?.data?.preferred_username
    ) {
      getGroupInfo();
    }
    return () => {};
  }, [activeUser]);

  const getGroupInfo = () => {
    let url = `${config.api.groupUrl}?group_id=${activeUser.data.preferred_username}_${config.hardCoded.aeAppId}`;
    let status;
    fetch(url)
      .then((response) => {
        console.groupCollapsed("requesting", url);
        console.log("REPSONSE -> ", response);
        status = response.status;
        return response.clone().json();
      })
      .then((json) => {
        console.log("JSON -> ", json);
        console.groupEnd();
        if (status === 200) {
          groupsInfoReceived({
            status: "success",
            message: "",
            data: json.data,
          });
        } else {
          updateAlertInfo({
            open: true,
            message: json.statusMessage,
            severity: "error",
          });
        }
      });
  };

  const updateUserData = (field, value) => {
    const updatedData = { ...data, [field]: value };
    setData({ ...updatedData });
  };

  const getUserInfo = (preferredUser) => {
    let url = `${config.api.userUrl}?preferred_username=${preferredUser}`;
    let status;
    fetch(url)
      .then((response) => {
        console.groupCollapsed("requesting", url);
        console.log("REPSONSE -> ", response);
        status = response.status;
        return response.clone().json();
      })
      .then((json) => {
        console.log("JSON -> ", json);
        console.groupEnd();
        if (status === 200) {
          updateUserInfoForAdmin({
            status: "success",
            message: "",
            data: json.data,
          });
        } else {
          updateAlertInfo({
            open: true,
            message: json.statusMessage,
            severity: "error",
          });
        }
      });
  };

  const getNameField = () => {
    if (isEdit) {
      return (
        <input
          type="text"
          value={data.name}
          onChange={(e) => updateUserData("name", e.target.value)}
          disabled={isEdit ? true : false}
          placeholder="Type user name here"
        />
      );
    }
    return (
      <StyledAutoComplete
        id={`share-select-user`}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        filterOptions={(x) => x}
        getOptionSelected={(option, value) => option.name === value.name}
        getOptionLabel={(option) => option.name}
        renderOption={(option) => (
          <div className="option-users">
            <div className="user-initials">
              <p
                className="commented_by_user"
                data-letters={getInitialsFromEmailFromGlobalUsers(
                  option.preferred_username,
                  "@"
                )}
              ></p>
            </div>
            <div className="user-detail">
              <span>{option.name}</span>
              <span>{option.preferred_username}</span>
            </div>
          </div>
        )}
        options={options}
        loading={loading}
        onChange={(event, newValue) => {
          setOptions(newValue ? [newValue, ...options] : options);
          if (newValue && newValue?.preferred_username && newValue?.name) {
            let dataToSubmit = {
              ...data,
              name: newValue.name,
              email: newValue?.preferred_username,
              preferred_username: newValue.preferred_username,
            };
            setData({ ...dataToSubmit });
          } else {
            let dataToSubmit = {
              ...data,
              name: newValue?.name ? newValue?.name : "",
              email: newValue?.preferred_username,
              preferred_username: newValue?.preferred_username
                ? newValue?.preferred_username
                : "",
            };
            setData({ ...dataToSubmit });
          }
        }}
        onInputChange={(event, newInputValue) => {
          if (event.target.value) {
            debounceCall(event.target.value);
          }
        }}
        noOptionsText={"No Users Found"}
        className={classes.typographyStyle}
        renderInput={(params) => (
          <CssTextField
            {...params}
            variant="standard"
            size="small"
            placeholder="Type Name Here"
            className={classes.typographyStyle}
          />
        )}
      />
    );
  };

  const onGroupSelected = (groupId) => {
    setCurrentLoadedGroup(groupId);
  };

  const mapUnMapGroup = () => {
    const copy = [...associatedGroups];
    const index = copy.indexOf(currentLoadedGroup);
    if (index === -1) {
      copy.push(currentLoadedGroup);
    } else {
      copy.splice(index, 1);
    }
    setAssociatedGroups(copy);
  };

  const handleSaveUpdate = () => {
    updateAlertInfo({
      open: true,
      message: "Saving User details",
      severity: "info",
    });
    const configToSave = featureData.map((elem) => ({
      [elem.id]: elem.value,
    }));
    let configToSaveObj = {};
    if (isEdit) {
      for (let elem of configToSave) {
        configToSaveObj = { ...configToSaveObj, ...elem };
      }
      modifyGroup(configToSaveObj);
    } else {
      for (let elem of configToSave) {
        configToSaveObj = { ...configToSaveObj, ...elem };
      }
      addGroup(configToSaveObj);
    }
  };

  const addGroup = (configToSaveObj) => {
    let body = {
      group_id: `${data?.email.split("@")[0]}_${config.hardCoded.aeAppId}`,
      group_name: `${data?.email.split("@")[0]}_${config.hardCoded.aeAppId}`,
      feature_list: { AE: { ...configToSaveObj } },
    };
    let url = config.api.groupUrl;
    let status;
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        console.groupCollapsed("requesting", url);
        console.log("REPSONSE -> ", response);
        status = response.status;
        return response.clone().json();
      })
      .then((json) => {
        console.log("JSON -> ", json);
        console.groupEnd();
        if (status === 200) {
          addUser();
        } else {
          updateAlertInfo({
            open: true,
            message: json.statusMessage,
            severity: "error",
          });
        }
      });
  };

  const modifyGroup = (configToSaveObj) => {
    let body = {
      group_id: `${data?.email.split("@")[0]}_${config.hardCoded.aeAppId}`,
      group_name: `${data?.email.split("@")[0]}_${config.hardCoded.aeAppId}`,
      feature_list: { AE: { ...configToSaveObj } },
    };
    let url = config.api.groupUrl;
    let status;
    fetch(url, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        console.groupCollapsed("requesting", url);
        console.log("REPSONSE -> ", response);
        status = response.status;
        return response.clone().json();
      })
      .then((json) => {
        console.log("JSON -> ", json);
        console.groupEnd();
        if (status === 200) {
          updateUser();
        } else {
          updateAlertInfo({
            open: true,
            message: json.statusMessage,
            severity: "error",
          });
        }
      });
  };

  const addUser = () => {
    let body = {
      email: data.email,
      groups: [`${data?.email.split("@")[0]}_${config.hardCoded.aeAppId}`],
      name: data.name,
      preferred_username: data.preferred_username,
    };
    let url = config.api.userUrl;
    let status;
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        console.groupCollapsed("requesting", url);
        console.log("REPSONSE -> ", response);
        status = response.status;
        return response.clone().json();
      })
      .then((json) => {
        console.log("JSON -> ", json);
        console.groupEnd();
        if (status === 200) {
          updateAlertInfo({
            open: true,
            message: "User details saved",
            severity: "info",
          });
          history.push("/home/admin-panel/users");
        } else {
          updateAlertInfo({
            open: true,
            message: json.statusMessage,
            severity: "error",
          });
        }
      });
  };

  const updateUser = () => {
    let body = {
      email: data.email,
      groups: [`${data?.email.split("@")[0]}_${config.hardCoded.aeAppId}`],
      name: data.name,
      preferred_username: data.preferred_username,
    };
    let url = config.api.userUrl;
    let status;
    fetch(url, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        console.groupCollapsed("requesting", url);
        console.log("REPSONSE -> ", response);
        status = response.status;
        return response.clone().json();
      })
      .then((json) => {
        console.log("JSON -> ", json);
        console.groupEnd();
        if (status === 200) {
          updateAlertInfo({
            open: true,
            message: "User details saved",
            severity: "info",
          });
          history.push("/home/admin-panel/users");
        } else {
          updateAlertInfo({
            open: true,
            message: json.statusMessage,
            severity: "error",
          });
        }
      });
  };

  const isValidSubmit = () => {
    // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const emailRegex =
      /^[a-zA-Z0-9.!#$%&'*+=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    if (!data.email || !data.email.trim()) {
      return false;
    }
    if (!emailRegex.test(data.email)) {
      return false;
    }
    const domain = data.email.split("@").pop();
    if (domain !== "rb.com" && domain !== "reckitt.com") {
      return false;
    }
    if (!data.name || !data.name.trim()) {
      return false;
    }
    return true;
  };

  const fetchFeatureList = () => {
    const url = `${config.api.listFeatureList}?app_id=${config.hardCoded.aeAppId}`;
    let status;
    fetch(url)
      .then((response) => {
        console.groupCollapsed("requesting", url);
        console.log("REPSONSE -> ", response);
        status = response.status;
        return response.clone().json();
      })
      .then((json) => {
        console.log("JSON -> ", json);
        console.groupEnd();
        if (status === 200) {
          updateFeatureList(json.data);
        } else {
          updateAlertInfo({
            open: true,
            message: json.statusMessage,
            severity: "error",
          });
        }
      });
  };

  const isGroupSelected = associatedGroups.includes(currentLoadedGroup);

  const className = `user-name-container ${isEdit ? "disabled-pointer" : ""}`;
  return (
    <>
      {userInfoObj.status === "loading" && <Loader />}
      {userInfoObj.status === "success" && (
        <>
          <div className="top-panel">
            <div className="admin-panel-user-details-container">
              <div className="admin-panel-user-details-sub-container">
                <div className={className}>
                  <label htmlFor="title" className="title">
                    {" "}
                    Name{" "}
                  </label>
                  <div className="name-config">{getNameField()}</div>
                </div>

                <div
                  className={`user-name-container ${
                    isEdit ? "disabled-pointer" : ""
                  }`}
                >
                  <label htmlFor="title" className="title">
                    Email
                  </label>
                  <div className="name-config">
                    <input
                      disabled={true}
                      type="text"
                      value={data?.email || ""}
                      onChange={(e) => updateUserData("email", e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
            {featureList?.status === "loading" && <Loader />}
            {featureList?.status === "success" && featureData !== -1 && (
              <GroupsInfo
                selectedGroupsList={associatedGroups}
                onGroupSelected={onGroupSelected}
                featureData={featureData}
                setFeatureData={setFeatureData}
              />
            )}
          </div>

          <div className="admin-bottom-panel">
            <div className="user-actions-buttons">
              <Buttons
                isEdit={isEdit}
                disableFlag={!isValidSubmit()}
                mapUnMapGroup={mapUnMapGroup}
                isGroupSelected={isGroupSelected}
                handleSaveUpdate={handleSaveUpdate}
              />
            </div>
          </div>
        </>
      )}
      {userInfoObj.status === "error" && (
        <div className="no-user-data">
          <img src={noDataImage} />
          <p className="no-data info-message">
            <b>{userInfoObj.message}</b>
          </p>
        </div>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  userDetails: state.user,
  userInfoObj: state.adminSetting.activeUser,
  adUserListObj: state.adminSetting.adUserList,
  userAddEditStatus: state.adminSetting.userAddEditStatus,
  featureList: state.adminSetting.featureList,
  activeUser: state.adminSetting.activeUser,
  userGroupInfo: state.adminSetting.userGroupInfo,
});

const mapDispatchToProps = {
  updateUserInfo,
  updateAlertInfo,
  updateFeatureList,
  updateUserInfoForAdmin,
  groupsInfoReceived,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserDetails);

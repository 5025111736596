//Import required libraies
import React, { useState } from "react";

//Import custom libraries
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { v4 } from "uuid";
import { DatePicker } from "../../uiTheme";

//Import custom components
import ScheduleButtons from "./ScheduleButtons";
import { config as appConfig } from "../../config/config";

//Import utils, data
import {
  getTriggerTime,
  getFutureDate,
  formatDate,
  formatDateToMoment,
  getEndDayOfYear,
  getTZData,
  getTimezoneOffset,
  getPastHrInfo,
  getCurrentEpochHour,
  getTodayDate,
  getFutureDateFromGivenDate,
} from "../../utils/utils";
import FrequencyType from "../../../assets/data/ScheduleRun/FrequencyType.json";
import ScoringMonthlyValues from "../../../assets/data/ScheduleRun/ScoringMonthlyValues.json";
import ScoringWeeklyValues from "../../../assets/data/ScheduleRun/ScoringWeeklyValues.json";

//Import actions

// Import custom hooks
import { useNonInitialEffect } from "../../hooks/useNonInitialEffect";

//Import styles
import {
  muiCommonStyles,
  MenuProps,
  StyledAutoComplete,
  CssTextField,
} from "../../../styles/styles";
import "./ScheduleConfig.scss";

function ScoringFrequencyType(props) {
  const { type, scheduleRunData, updateScheduleDetails } = props;
  const muiClass = muiCommonStyles();
  let Component;

  const getSelctedStyle = (selectedDay) =>
    selectedDay === scheduleRunData.frequencyValue
      ? {
          background: "#465A69",
          color: "#ffffff",
          minWidth: "50px",
          width: "50px",
        }
      : {
          background: "#ECECEC",
          color: "#465A69",
          minWidth: "50px",
          width: "50px",
        };

  switch (type) {
    case "run_once":
      Component = (
        <DatePicker
          min={getTodayDate(scheduleRunData.timeZone, "YYYY-MM-DD")}
          max={getEndDayOfYear()}
          onKeyDown={(e) => e.preventDefault()}
          value={
            scheduleRunData.frequencyValue
              ? formatDateToMoment(scheduleRunData.frequencyValue, "YYYY-MM-DD")
              : ""
          }
          onChange={(e) => {
            updateScheduleDetails(
              "frequencyValue",
              formatDate(e.target.value, "DD/MM/YYYY")
            );
          }}
        />
        // <input
        //   type="date"
        //   min={getTodayDate(scheduleRunData.timeZone, "YYYY-MM-DD")}
        //   max={getEndDayOfYear()}
        //   onKeyDown={(e) => e.preventDefault()}
        //   value={
        //     scheduleRunData.frequencyValue
        //       ? formatDateToMoment(scheduleRunData.frequencyValue, "YYYY-MM-DD")
        //       : ""
        //   }
        //   onChange={(e) => {
        //     updateScheduleDetails(
        //       "frequencyValue",
        //       formatDate(e.target.value, "DD/MM/YYYY")
        //     );
        //   }}
        // />
      );
      break;
    case "weekly":
      Component = (
        <>
          {ScoringWeeklyValues.map((day) => (
            <Button
              className={muiClass.scheduleWeekBtns}
              style={getSelctedStyle(day.id)}
              value={day.id}
              key={day.id}
              onClick={(e) => {
                updateScheduleDetails("frequencyValue", e.currentTarget.value);
              }}
              name="scoring-weekly-value"
            >
              {day.name}
            </Button>
          ))}
        </>
      );
      break;
    case "monthly":
      Component = (
        <Select
          name="scoring-monthly-value"
          id="scoring-monthly-value"
          value={scheduleRunData.frequencyValue}
          onChange={(e) =>
            updateScheduleDetails("frequencyValue", e.target.value)
          }
          className={muiClass.multiSelect}
          MenuProps={MenuProps}
        >
          {ScoringMonthlyValues.map((obj) => (
            <MenuItem
              value={obj.id}
              key={obj.id}
              className={muiClass.singleMenuItem}
            >
              {obj.name}
            </MenuItem>
          ))}
        </Select>
      );
      break;
    default:
      Component = null;
  }

  return Component;
}

function ScheduleConfigContent(props) {
  const { activeApp, scheduleRunData, setScheduleRunData } = props;
  const muiClass = muiCommonStyles();
  const [error, setError] = useState(null);

  const updateScheduleDetails = (key, value) => {
    setScheduleRunData((prevState) => ({ ...prevState, [key]: value }));
  };

  const checkValidSelections = () => {
    let validData = true;
    if (scheduleRunData.frequencyType === "run_once") {
      validData = getPastHrInfo(
        scheduleRunData.frequencyValue + " " + scheduleRunData.triggerTime,
        scheduleRunData.timeZone
      );
      if (!validData) {
        setError("Please select valid frequency value and trigger time");
      }
    }
    return validData;
  };

  useNonInitialEffect(() => {
    if (scheduleRunData.frequencyType === "run_once") {
      let validData = getPastHrInfo(
        scheduleRunData.frequencyValue + " " + scheduleRunData.triggerTime,
        scheduleRunData.timeZone
      );
      if (!validData) {
        updateScheduleDetails(
          "triggerTime",
          getCurrentEpochHour(scheduleRunData.timeZone)
        );
      }
    }
    return () => {};
  }, [scheduleRunData.frequencyValue, scheduleRunData.frequencyType]);

  useNonInitialEffect(() => {
    const result = getCurrentEpochHour(scheduleRunData.timeZone);
    const currentDate = getTodayDate(scheduleRunData.timeZone, "DD/MM/YYYY");
    updateScheduleDetails("triggerTime", result);
    if (scheduleRunData.frequencyType === "run_once") {
      if (result == "00:00") {
        const newData = getFutureDateFromGivenDate(
          formatDateToMoment(scheduleRunData.frequencyValue),
          1,
          "DD/MM/YYYY"
        );
        updateScheduleDetails("frequencyValue", newData);
      } else {
        updateScheduleDetails("frequencyValue", currentDate);
      }
    }
    return () => {};
  }, [scheduleRunData.timeZone, scheduleRunData.frequencyType]);

  const updateScoringValue = (type) => {
    type === "daily"
      ? setScheduleRunData((prevState) => ({
          ...prevState,
          frequencyValue: "",
        }))
      : type === "run_once"
      ? setScheduleRunData((prevState) => ({
          ...prevState,
          frequencyValue: formatDate(getFutureDate(0), "DD-MM-YYYY"),
        }))
      : type === "weekly"
      ? setScheduleRunData((prevState) => ({
          ...prevState,
          frequencyValue: "Mon",
        }))
      : type === "monthly"
      ? setScheduleRunData((prevState) => ({
          ...prevState,
          frequencyValue: "first_day",
        }))
      : setScheduleRunData((prevState) => ({
          ...prevState,
          frequencyValue: "",
        }));
  };

  return (
    <>
      <div className="schedule-config-dialog-container">
        <p className="popup-title">Schedule Run</p>
        <>
          <div className="schedule-input-field-container">
            <label>Configuration Name</label>
            <div className="input-text-box">
              <input
                name="configuration-name"
                type="text"
                value={scheduleRunData.configurationName}
                className="input-label disabled"
                disabled={true}
              />
            </div>
          </div>
          <div className="input-field-row-container">
            <div className="input-field-sub-container">
              <label>Frequency</label>
              <div className="input-text-box">
                <Select
                  name="scoring-frequency"
                  id="scoring-frequency"
                  value={scheduleRunData.frequencyType}
                  onChange={(e) => {
                    updateScheduleDetails("frequencyType", e.target.value);
                    updateScoringValue(e.target.value);
                  }}
                  className={muiClass.multiSelect}
                  MenuProps={MenuProps}
                >
                  {FrequencyType.map((obj) => (
                    <MenuItem
                      value={obj.id}
                      key={obj.id}
                      className={muiClass.singleMenuItem}
                    >
                      {obj.name}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </div>
            <div className="input-field-sub-container no-label">
              <div
                className={`input-text-box ${
                  scheduleRunData.frequencyType === "weekly"
                    ? "scoring-week-selection"
                    : ""
                }`}
              >
                <ScoringFrequencyType
                  type={scheduleRunData.frequencyType}
                  {...props}
                  updateScheduleDetails={updateScheduleDetails}
                />
              </div>
            </div>
          </div>
          <div className="input-field-row-container">
            <div className="input-field-sub-container">
              <label>Time</label>
              <div className="input-text-box">
                <StyledAutoComplete
                  id="trigger-zone"
                  name="trigger-zone"
                  value={scheduleRunData.triggerTime}
                  options={getTriggerTime().map((elem) => elem)}
                  disableClearable
                  getOptionLabel={(option) => option || ""}
                  getOptionDisabled={(option) => {
                    return scheduleRunData.frequencyType === "run_once"
                      ? !getPastHrInfo(
                          scheduleRunData.frequencyValue + " " + option,
                          scheduleRunData.timeZone
                        )
                        ? true
                        : false
                      : false;
                  }}
                  onChange={(e, newVal) => {
                    updateScheduleDetails("triggerTime", newVal);
                  }}
                  noOptionsText={"No options Found"}
                  className={muiClass.typographyStyle}
                  renderInput={(params) => (
                    <CssTextField
                      {...params}
                      variant="standard"
                      size="small"
                      placeholder="Type Time Here"
                      className={muiClass.typographyStyle}
                    />
                  )}
                />
              </div>
            </div>
            <div className="input-field-sub-container">
              <label>Timezone</label>
              <div className="input-text-box">
                <StyledAutoComplete
                  id="trigger-zone"
                  name="trigger-zone"
                  value={{
                    id: scheduleRunData.timeZone,
                    name: getTimezoneOffset(scheduleRunData.timeZone),
                  }}
                  options={getTZData().map((elem) => ({
                    id: elem,
                    name: getTimezoneOffset(elem),
                  }))}
                  disableClearable
                  getOptionLabel={(option) => option?.name || ""}
                  getOptionSelected={(option, value) => option.id === value.id}
                  onChange={(e, newValue) => {
                    updateScheduleDetails("timeZone", newValue?.id);
                  }}
                  noOptionsText={"No options Found"}
                  className={muiClass.typographyStyle}
                  renderInput={(params) => (
                    <CssTextField
                      {...params}
                      variant="standard"
                      size="small"
                      placeholder="Type Name Here"
                      className={muiClass.typographyStyle}
                    />
                  )}
                />
                {/* <Select
                  name="trigger-zone"
                  id="trigger-zone"
                  value={scheduleRunData.timeZone}
                  onChange={(e) =>
                    updateScheduleDetails("timeZone", e.target.value)
                  }
                  className={muiClass.multiSelect}
                  MenuProps={MenuProps}
                >
                  {getTZData()
                    .map((elem) => ({
                      id: elem,
                      name: getTimezoneOffset(elem),
                    }))
                    .map((obj) => (
                      <MenuItem
                        value={obj.id}
                        key={obj.id}
                        className={muiClass.singleMenuItem}
                      >
                        {obj.name}
                      </MenuItem>
                    ))}
                </Select> */}
              </div>
            </div>
          </div>
        </>
        {error && <p className="error">{error}</p>}
        <div className="popup-btn">
          <ScheduleButtons
            {...props}
            checkValidSelections={checkValidSelections}
          />
        </div>
      </div>
    </>
  );
}

export default ScheduleConfigContent;

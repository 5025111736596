import React from "react";
import PropTypes from "prop-types";
import Chip from "@material-ui/core/Chip";
import { makeStyles } from "@material-ui/core/styles";
import "../index.css";

/**
 * [BasicChips description]
 * @param {[string]} theme [The theme of the component - default: light]
 * @param {[string]} variant [The variant to use - default: "filled"]
 * @param {[string]} styleVariant [Used to represent colored interface elements for a user - default: "primary"]
 * @param {[any]} ...props [Other valid props for MUI Chip]
 * @returns {[object]} [Returns the Chip component]
 */
const BasicChips = (props) => {
  const {
    theme = "light",
    styleVariant = "primary",
    variant = "filled",
    ...remaining
  } = props;

  const useStyles = makeStyles(() => ({
    chipStyle: {
      fontFamily: "var(--fontFamily)",
      fontSize: "var(--font-size-XS)",
      border: "1px solid #dedede",
      color: `var(--chip-${styleVariant}-textcolor-${theme}) !important`,
      borderRadius: "var(--border-radius)",
      minWidth: "100px",
      maxWidth: "250px",
      ...(variant === "filled" && {
        background: `var(--chip-${styleVariant}-bgcolor-${theme})`,
        "&:focus": {
          background: `var(--chip-${styleVariant}-bgcolor-${theme})`,
        },
      }),
      ...(variant === "outlined" && {
        background: "transparent",
        color: `var(--outlined-chip-${styleVariant}-textcolor-${theme}) !important`,
        "&:focus": {
          background: "transparent !important",
        },
      }),
      "& .MuiChip-deleteIcon": {
        fontSize: 20,
        border: "none",
        color: `var(--chip-${styleVariant}-textcolor-${theme})`,
      },
    },
  }));
  const classes = useStyles();

  return <Chip className={classes.chipStyle} {...remaining} />;
};

BasicChips.propTypes = {
  theme: PropTypes.oneOf(["light", "dark"]),
  styleVariant: PropTypes.oneOf(["primary", "secondary"]),
  variant: PropTypes.oneOf(["filled", "outlined"]),
  label: PropTypes.string,
};

export default BasicChips;

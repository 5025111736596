import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { config } from "../../config/config";

//import custom components
import DashboardCountryBrandSelection from "../DashboardCountryBrandSelections";
import Loader from "../Loader/Loader";
import NLPGoogleDashboardTable from "./NLPGoogleDashboardTable";
import noDataImage from "../../../assets/images/undraw_empty.svg";

//import css file
import "./GoogleDashboard.scss";

const initialState = {
  data: [],
  loading: false,
  status: "",
  message: "",
};

function NLPAndGoogleDashboard(props) {
  const { isAuthorized, nlpReport } = props;
  const [data, setData] = useState(initialState);
  const [filterDataReports, SetFilterDataReports] = useState([]);
  const [countryBrandData, SetCountryBrandData] = useState({
    data: [],
    loading: true,
  });
  const [brand, setBrand] = useState("");
  const [country, setCountry] = useState("");
  const [unit, setUnit] = useState("");

  //handler for go functionality
  const handlerForGo = () => {
    let filteredData =
      country !== "ALL"
        ? data.data.filter(
            (item) => item.country.toLowerCase() === country.toLowerCase()
          )
        : data.data;
    filteredData =
      unit !== "ALL"
        ? filteredData.filter(
            (item) => item.unit.toLowerCase() === unit.toLowerCase()
          )
        : filteredData;
    filteredData =
      brand !== "ALL"
        ? filteredData.filter(
            (item) => item.brand.toLowerCase() === brand.toLowerCase()
          )
        : filteredData;
    SetFilterDataReports(filteredData);
  };

  //handler for fetching the country brand and report data
  const fetchData = (url) => {
    let status;
    fetch(url)
      .then((response) => {
        status = response.status;
        return response.clone().json();
      })
      .then((json) => {
        setData({ ...data, loading: false });
        if (status === 200) {
          setData({
            ...data,
            data: json.data.report_list,
            loading: false,
            status: status,
          });
          SetFilterDataReports(json.data.report_list);
          let arr = [];
          for (const iterator of Object.entries(json.data.country_brand)) {
            arr.push({ name: iterator[0], brands: iterator[1] });
          }
          const brandArr = [];
          arr.map((e) => {
            brandArr.push(...e.brands);
            return null;
          });
          let uniqueBrandArr = [...new Set(brandArr)];
          const newArr = [
            { name: "ALL", brands: uniqueBrandArr },
            ...arr.sort(),
          ];

          const finalArr = newArr.map((e) => {
            return {
              ...e,
              brands: ["ALL", ...e.brands?.sort()],
            };
          });
          SetCountryBrandData((pre) => {
            return { ...pre, data: finalArr, loading: false };
          });
        } else {
          setData({ ...data, status: status, message: json.statusMessage });
        }
        setCountry("ALL");
        setBrand("ALL");
        setUnit("ALL");
      });
  };

  useEffect(() => {
    setData({ ...initialState, loading: true });
    if (nlpReport) {
      const url = config.api.countryWiseBrands;
      fetchData(url);
    } else {
      let url = config.api.dataStudioReport;
      fetchData(url);
    }
  }, [nlpReport]);

  return (
    <div>
      {data.loading ? (
        <Loader />
      ) : data.status === 200 ? (
        <>
          <DashboardCountryBrandSelection
            handlerForGo={handlerForGo}
            countryBrandData={countryBrandData}
            brand={brand}
            country={country}
            setCountry={setCountry}
            setBrand={setBrand}
            unit={unit}
            setUnit={setUnit}
            allData={data}
          />
          {filterDataReports.length ? (
            <NLPGoogleDashboardTable
              records={filterDataReports}
              nlpReport={nlpReport}
            />
          ) : (
            <div className="error-container">
              <img src={noDataImage} />
              <p>No reports available for the current selections</p>
            </div>
          )}
        </>
      ) : (
        <div className="error-container">
          <img src={noDataImage} />
          <p>{data.message}</p>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  allData: state.data,
});

export default connect(mapStateToProps)(NLPAndGoogleDashboard);

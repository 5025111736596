import React from "react";
import PropTypes from "prop-types";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import "../index.css";

function getBreadcrumbsStyle(theme, styleVariant) {
  return {
    fontFamily: "var(--fontFamily)",
    fontSize: "13px",
    textDecoration: "none !important",
    textTransform: "capitalize",
    fontWeight: "600",
    item: {
      color: `var(--breadcrumbs-${styleVariant}-textcolor-${theme})`,
    },
    active: {
      color: `var(--breadcrumbs-${styleVariant}-active-textcolor-${theme})`,
    },
  };
}

/**
 * [CustomBreadcrumb description]
 * @param {[string]} theme [The theme of the component - default: light]
 * @param {[string]} styleVariant [Used to represent colored interface elements for a user - default: primary]
 * @param {[node]} children [The content of the component.]
 * @param {[string]} separator [Custom separator node - default: /]
 * @param {[object]} style [The style object which is passed to the component]
 * @param {[any]} ...props [Other valid props for MUI Breadcrumb]
 * @returns {[object]} [Returns the Breadcrumb component]
 */
const CustomBreadcrumb = (props) => {
  const {
    theme = "light",
    styleVariant = "primary",
    children,
    separator = "/",
    ...remaining
  } = props;

  const defaultStyle = getBreadcrumbsStyle(theme, styleVariant);
  const finalStyle = { ...defaultStyle };

  return (
    <Breadcrumbs separator={separator} {...remaining}>
      {children.map((elem, index) => (
        <div key={index} style={finalStyle}>
          {index !== children.length - 1 ? (
            <span style={finalStyle.item}>{elem}</span>
          ) : (
            <span style={finalStyle.active}>{elem}</span>
          )}
        </div>
      ))}
    </Breadcrumbs>
  );
};

CustomBreadcrumb.propTypes = {
  children: PropTypes.node,
  theme: PropTypes.oneOf(["light", "dark"]),
  styleVariant: PropTypes.oneOf(["primary", "secondary"]),
  separator: PropTypes.oneOf(["/"]),
};

export default CustomBreadcrumb;

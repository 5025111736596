//Import required libraies
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import orderBy from "lodash/orderBy";
import { useHistory } from "react-router-dom";
import { isEmpty, isEqual } from "lodash";
import { withStyles, createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";

//Import custom component
import ModelSelections from "./components/ModelSelections";
import Buttons from "./components/Buttons";
import Loader from "../../../components/Loader/Loader";
import LocalParameterItem from "./components/LocalParameterItem";
import AddTags from "../AddNewConfig/components/AddTags";
import ConfigSettings from "../AddNewConfig/components/ConfigSettings";
import Modal from "../../../components/Modal/Modal";
import { DatePicker, Button } from "../../../uiTheme";
import Radio from "@material-ui/core/Radio";
import { useNonInitialEffect } from "../../../hooks/useNonInitialEffect";
import { MenuItem, Select } from "@material-ui/core";

// Import custom hooks

// Import action creators
import {
  updateConfigUserInputs,
  updateCountryRegions,
  updateUserInfo,
  updateRegionsData,
  emptyTestControl,
  updateControlRegions,
  updateTestRegions,
  updateWholeConfigUserInputs,
  updateData,
  onChangeDERunConfig,
  updateModelLocalParameters,
  updateAlertInfo,
  reloadConfigs,
  addGlobalParams,
  dataAvailabilityChanges,
} from "../../../redux/actions";

//Import data

// Import utils
import {
  defaultTimeZone,
  formatDate,
  formatDateToMoment,
  getCurrentEpochHour,
  getEndDayOfYear,
  getFutureDate,
  getFutureDateFromGivenDate,
  getPastHrInfo,
  getTimezoneOffset,
  getTodayDate,
  getTriggerTime,
  getTZData,
  makeConfigUserInputs,
  getActiveModel,
  validateAudienceNameForSchedule,
  validatePropensityActionNamesParametersForScheduleRun,
  changeDateInEpoch,
  shortFormAE,
  convertDateToFormatForCmu,
} from "../../../utils/utils";
import { config as appConfig } from "../../../config/config";

import FrequencyType from "../../../../assets/data/ScheduleRun/FrequencyType.json";
import ScoringWeeklyValues from "../../../../assets/data/ScheduleRun/ScoringWeeklyValues.json";
import ScoringMonthlyValues from "../../../../assets/data/ScheduleRun/ScoringMonthlyValues.json";

// Import styles
import "./AddNewConfig.scss";
import "./components/ConfigSetting.scss";
import {
  CssTextField,
  MenuProps,
  muiCommonStyles,
  StyledAutoComplete,
} from "../../../../styles/styles";
import FixScheduleTypeForm from "./components/FixScheduleTypeForm";

const BlueRadio = withStyles({
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
    color: "#4eafb3 !important",
    "&$checked": {
      color: "#4eafb3 !important",
    },
    "&$disabled": {
      color: "#4eafb3",
      opacity: 0.5,
    },
    "& .MuiSvgIcon-root": {
      height: 15,
      weight: 15,
      color: "#4eafb3",
    },
  },
  checked: {},
  disabled: {
    color: "#4eafb3",
    opacity: 0.5,
  },
})((props) => <Radio color="default" {...props} />);

const theme = createMuiTheme({
  overrides: {
    MuiRadio: {
      root: {
        padding: 0,
      },
    },
  },
});

function AddNewConfig(props) {
  let {
    match,
    user,
    allData,
    configUserInputs,
    updateConfigUserInputs,
    updateCountryRegions,
    updateUserInfo,
    updateRegionsData,
    emptyTestControl,
    updateControlRegions,
    updateTestRegions,
    updateWholeConfigUserInputs,
    updateData,
    disableFlag,
    runData,
    onChangeDERunConfig,
    updateModelLocalParameters,
    updateAlertInfo,
    reloadConfigs,
    addGlobalParams,
    dataAvailabilityChanges,
  } = props;
  const scheduleConfigDetails =
    allData.saved_configurations.find(
      (elem) => elem.id === configUserInputs.configId
    )?.schedule_details || {};
  const config = {
    ...(Object.keys(scheduleConfigDetails).length !== 0 && {
      schedule_details: scheduleConfigDetails,
      id: scheduleConfigDetails.config_id,
    }),
  };
  const [loading, setLoading] = useState(true);
  const [globalParamsOpen, setGlobalParamsOpen] = useState(true);
  const [activeSelection, setActiveSelection] = useState("dataset");
  const [scheduleRunData, setScheduleRunData] = useState({
    configurationId: configUserInputs.configId,
    configurationName: configUserInputs.configName,
    scheduleType: config?.schedule_details ? "edit" : "add",
    frequencyType: config?.schedule_details
      ? config?.schedule_details?.frequency_type
      : "run_once",
    frequencyValue: config?.schedule_details
      ? config?.schedule_details?.frequency_value
      : getTodayDate(defaultTimeZone(), "DD/MM/YYYY"),
    triggerTime: config?.schedule_details
      ? config?.schedule_details?.trigger_time
      : getCurrentEpochHour(defaultTimeZone()),
    timeZone: config?.schedule_details
      ? config?.schedule_details?.time_zone
      : defaultTimeZone(),
  });
  const [globalParamsObj, setGlobalParamsObj] = useState({});
  const [changeInGlobalParamsFlag, setChangeInGlobalParamsFlag] =
    useState(false);
  const [defaultGlobalParamsObj, setDefaultGlobalParamsObj] = useState({});
  // loading the default global params
  useEffect(() => {
    if (configUserInputs.country !== "") {
      setDefaultGlobalParamsObj({
        country: configUserInputs.country,
        brand: configUserInputs.brand,
        global_week_date_selection: configUserInputs.global_week_date_selection,
        historic_week_data: configUserInputs.historic_week_data,
        startDate: configUserInputs.startDate,
        endDate: configUserInputs.endDate,
      });
    }
  }, [configUserInputs.models.length]);
  // setting default globalParams obj
  useEffect(() => {
    if (isEmpty(globalParamsObj) && !isEmpty(defaultGlobalParamsObj)) {
      setGlobalParamsObj(defaultGlobalParamsObj);
    }
  });
  // function to get the different keys
  function getObjectDiff(obj1, obj2) {
    const diff = Object.keys(obj1).reduce((result, key) => {
      if (!obj2.hasOwnProperty(key)) {
        result.push(key);
      } else if (isEqual(obj1[key], obj2[key])) {
        const resultKeyIndex = result.indexOf(key);
        result.splice(resultKeyIndex, 1);
      }
      return result;
    }, Object.keys(obj2));
    return diff;
  }
  // to get the flag for change in globalParams
  useEffect(() => {
    if (configUserInputs.models.length) {
      const diff = getObjectDiff(defaultGlobalParamsObj, globalParamsObj);
      if (diff.length) {
        setChangeInGlobalParamsFlag(true);
      } else {
        setChangeInGlobalParamsFlag(false);
      }
    }
  }, [
    globalParamsObj.country,
    globalParamsObj.brand,
    globalParamsObj.global_week_date_selection,
    globalParamsObj.historic_week_data,
    globalParamsObj.startDate,
    globalParamsObj.endDate,
    configUserInputs.models.length,
  ]);

  // onChange function for globalParamsObj
  const globalParamsOnChange = (key, value) => {
    if (!isEmpty(globalParamsObj)) {
      setGlobalParamsObj({
        ...globalParamsObj,
        [key]: value,
      });
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    if (
      configUserInputs.country &&
      configUserInputs.brand &&
      configUserInputs.configType &&
      configUserInputs.configType !== "run"
    ) {
      let url = appConfig.api.checkCmu;
      updateConfigUserInputs("cmu_status", {
        status: "loading",
        message: "",
        data: [],
      });
      let body = {
        brand: configUserInputs.brand,
        country: configUserInputs.country,
      };
      if (
        configUserInputs.global_week_date_selection === "historic_week_data"
      ) {
        body = { ...body, past_weeks: +configUserInputs.historic_week_data };
      } else {
        body = {
          ...body,
          start_date: convertDateToFormatForCmu(configUserInputs.startDate),
          end_date: convertDateToFormatForCmu(configUserInputs.endDate),
        };
      }
      fetch(url, {
        signal,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      })
        .then((response) => response.json())
        .then((json) => {
          updateConfigUserInputs("cmu_status", {
            status: "success",
            message: "",
            data: json.data,
          });
          dataAvailabilityChanges({
            cmu_data: json.data,
            configUserInputs: configUserInputs,
            allData: allData,
          });
        });
    }
    return () => {
      controller.abort();
    };
  }, [
    configUserInputs.country,
    configUserInputs.brand,
    configUserInputs.startDate,
    configUserInputs.endDate,
    configUserInputs.historic_week_data,
    configUserInputs.global_week_date_selection,
  ]);
  // this use effect is for edit schedule configs, so the schedule info will be updated in the state
  useEffect(() => {
    if (config.schedule_details) {
      setScheduleRunData({
        configurationId: configUserInputs.configId,
        configurationName: configUserInputs.configName,
        scheduleType: config?.schedule_details ? "edit" : "add",
        frequencyType: config?.schedule_details
          ? config?.schedule_details?.frequency_type
          : "run_once",
        frequencyValue: config?.schedule_details
          ? config?.schedule_details?.frequency_value
          : getTodayDate(defaultTimeZone(), "DD/MM/YYYY"),
        triggerTime: config?.schedule_details
          ? config?.schedule_details?.trigger_time
          : getCurrentEpochHour(defaultTimeZone()),
        timeZone: config?.schedule_details
          ? config?.schedule_details?.time_zone
          : defaultTimeZone(),
        ...(configUserInputs.is_fixed_schedule_config && {
          number_of_runs: config?.schedule_details?.number_of_runs,
        }),
      });
    }
  }, [config.schedule_details]);
  const history = useHistory();
  let [activeModelId, setActiveModelId] = useState(null);
  useEffect(() => {
    if (configUserInputs.models.length && allData.models?.length) {
      setActiveModelId(getActiveModel(configUserInputs));
    }
  }, [configUserInputs.models.length]);

  let activeModel = allData?.models?.find(
    (model) => model.id === activeModelId
  );
  //for using the stepper
  const [activeStep, setActiveStep] = useState(0);
  const [stepCompleted, setStepCompleted] = useState({});

  //stepper validation
  const validateStepper = (configUserInputs, activeModelId) => {
    let validationInfo;
    switch (activeModelId) {
      case appConfig.hardCoded.communityClusteringId:
        validationInfo = validateAudienceNameForSchedule(configUserInputs);
        if (!validationInfo.status) {
          updateAlertInfo({
            open: true,
            message: validationInfo.message,
            severity: "error",
          });
          return false;
        } else {
          return true;
        }
      case appConfig.hardCoded.propensityId:
        validationInfo =
          validatePropensityActionNamesParametersForScheduleRun(
            configUserInputs
          );
        if (!validationInfo.status) {
          updateAlertInfo({
            open: true,
            message: validationInfo.message,
            severity: "error",
          });
          return false;
        } else {
          return true;
        }

      default:
        console.log("default");
        break;
    }
  };

  //variable to get nested/target/budget runs
  let nestedRunsArray = activeModel?.local_parameters?.filter((obj) =>
    appConfig.hardCoded.mulipleRunType.includes(obj.id)
  );

  useEffect(() => {
    if (
      configUserInputs.configType === "edit" &&
      configUserInputs.is_schedule_config &&
      nestedRunsArray &&
      activeModelId !== appConfig.hardCoded.nlpId
    ) {
      let stepObj = {};
      nestedRunsArray[0].data.map((el, i) => {
        stepObj = {
          ...stepObj,
          [i]: true,
        };
      });
      if (isEmpty(stepCompleted)) {
        setStepCompleted(stepObj);
      }
    }
  }, [nestedRunsArray]);
  const onStepperChange = (value) => {
    let data = {
      modelId: activeModelId,
      key:
        activeModelId === appConfig.hardCoded.communityClusteringId
          ? "community_clustering_run_type"
          : "propensity_modeling_run_type",
      value,
    };
    updateModelLocalParameters(data);
  };
  const muiClass = muiCommonStyles();
  const updateScheduleDetails = (key, value) => {
    setScheduleRunData((prevState) => ({ ...prevState, [key]: value }));
  };
  const updateScoringValue = (type) => {
    type === "daily"
      ? setScheduleRunData((prevState) => ({
          ...prevState,
          frequencyValue: "",
        }))
      : type === "run_once"
      ? setScheduleRunData((prevState) => ({
          ...prevState,
          frequencyValue: formatDate(getFutureDate(0), "DD/MM/YYYY"),
        }))
      : type === "weekly"
      ? setScheduleRunData((prevState) => ({
          ...prevState,
          frequencyValue: "Mon",
        }))
      : type === "monthly"
      ? setScheduleRunData((prevState) => ({
          ...prevState,
          frequencyValue: "first_day",
        }))
      : setScheduleRunData((prevState) => ({
          ...prevState,
          frequencyValue: "",
        }));
  };
  //Defining required variables
  let app = appConfig.hardCoded.aeAppName;
  let activeApp =
    allData.apps.data.length > 0
      ? allData.apps.data.filter(
          (obj) => obj.name.toLowerCase() === app.toLowerCase()
        )[0]
      : null;

  const [modalComponent, setModalComponent] = useState({
    component: null,
    openModal: false,
  });

  const handleModalDialogClose = () => {
    setModalComponent((prevState) => {
      return { ...prevState, openModal: false };
    });
  };
  const handleModalDialogOpen = (component) => {
    setModalComponent((prevState) => {
      return {
        ...prevState,
        openModal: true,
        component: component,
      };
    });
  };

  // useEffect(() => {
  //   if (configUserInputs.is_schedule_config) {
  //     handleModalDialogOpen(
  //       <div className="dialog-popup-container">
  //         <div>
  //           For scheduled configuration, please provide inputs for all types of
  //           runs - Initial, Target community and Upload audience data.
  //         </div>
  //         <div className="button-container">
  //           <button
  //             onClick={() => {
  //               handleModalDialogClose();
  //             }}
  //             className="secondary-button"
  //           >
  //             OK
  //           </button>
  //         </div>
  //       </div>
  //     );
  //   }
  // }, [configUserInputs.is_schedule_config]);

  const changeRunType = (name) => {
    handleModalDialogOpen(
      <div className="dialog-popup-container">
        <div>
          This action will discard your changes.
          <br />
          <br />
          Do you want to continue?
        </div>
        <div className="button-container">
          <button onClick={handleModalDialogClose} className="primary-button">
            no
          </button>
          <button
            onClick={() => {
              createDefault(
                configUserInputs.country,
                configUserInputs.brand,
                configUserInputs.configName,
                configUserInputs.historic_week_data
              );
              updateScheduleDetails("frequencyType", "run_once");
              updateScheduleDetails("timeZone", defaultTimeZone());
              if (name === "fixed_schedule_run") {
                updateConfigUserInputs("is_fixed_schedule_config", true);
              } else {
                updateConfigUserInputs("is_fixed_schedule_config", false);
                if (name === "on_demand_run") {
                  updateConfigUserInputs("is_schedule_config", false);
                } else {
                  updateConfigUserInputs("is_schedule_config", true);
                }
              }
              handleModalDialogClose();
            }}
            className="secondary-button"
          >
            yes
          </button>
        </div>
      </div>
    );
  };

  //Update configData

  useEffect(() => {
    let initialConfigData, configUserInputs;
    let config_type = "add";
    let initialConfigDataFallback = allData.apps.data.find(
      (obj) =>
        obj.name.toLowerCase() === appConfig.hardCoded.aeAppName.toLowerCase()
    ).default_config_template;
    if (disableFlag) {
      configUserInputs = makeConfigUserInputs({
        initialConfigData: {
          selections: runData.payload,
          id: runData.metadata.config_id,
          name: runData.metadata.config_name,
          app_id: runData.app_id,
          metadata: runData?.metadata,
        },
        match,
        disableFlag,
        activeApp,
        allData,
        configType: "run",
        is_scheduled_run: runData?.is_scheduled_run,
        is_fixed_schedule_run: runData?.is_fixed_schedule_config,
      });
    } else {
      //Make selections based on add/edit tab
      if (match.params.configName) {
        initialConfigData =
          allData.saved_configurations?.find(
            (config) =>
              config.name.toLowerCase() ===
              match.params.configName.toLowerCase()
          ) ||
          allData.shared_configurations?.find(
            (config) =>
              config.name.toLowerCase() ===
              match.params.configName.toLowerCase()
          ) ||
          allData.starred_configurations.data?.find(
            (config) =>
              config.name.toLowerCase() ===
              match.params.configName.toLowerCase()
          );
        config_type = "edit";
      } else {
        initialConfigData = allData.apps.data.find(
          (obj) =>
            obj.name.toLowerCase() ===
            appConfig.hardCoded.aeAppName.toLowerCase()
        ).default_config_template;
        config_type = "add";
      }

      configUserInputs = makeConfigUserInputs({
        initialConfigData,
        match,
        disableFlag,
        activeApp,
        initialConfigDataFallback,
        allData,
        configType: config_type,
      });
    }
    updateWholeConfigUserInputs(configUserInputs);
    setDefaultGlobalParamsObj({
      country: configUserInputs.country,
      brand: configUserInputs.brand,
      global_week_date_selection: configUserInputs.global_week_date_selection,
      historic_week_data: configUserInputs.historic_week_data,
      startDate: configUserInputs.startDate,
      endDate: configUserInputs.endDate,
    });
    setGlobalParamsObj(defaultGlobalParamsObj);
    setLoading(false);
  }, []);

  const createDefault = (country, brand, configName, historic_week_data) => {
    let initialConfigData, defaultConfigUserInputs;
    let config_type = "add";
    let initialConfigDataFallback = allData.apps.data.find(
      (obj) =>
        obj.name.toLowerCase() === appConfig.hardCoded.aeAppName.toLowerCase()
    ).default_config_template;

    initialConfigData = allData.apps.data.find(
      (obj) =>
        obj.name.toLowerCase() === appConfig.hardCoded.aeAppName.toLowerCase()
    ).default_config_template;
    config_type = "add";
    defaultConfigUserInputs = makeConfigUserInputs({
      initialConfigData,
      match,
      disableFlag,
      activeApp,
      initialConfigDataFallback,
      allData,
      configType: config_type,
      country,
      brand,
      configName,
      historic_week_data,
    });
    globalParamsOnChange("global_week_date_selection", "historic_week_data");
    updateWholeConfigUserInputs({
      ...defaultConfigUserInputs,
      cmu_status: configUserInputs.cmu_status,
      custom_dimensions_data: configUserInputs.custom_dimensions_data,
    });
    dataAvailabilityChanges({
      cmu_data: configUserInputs.cmu_status.data,
      configUserInputs: configUserInputs,
      allData: allData,
    });
  };

  // Update segment and config fixed name every time user changes country or brand
  useEffect(() => {
    if (!loading) {
      if (configUserInputs?.is_config_manual) {
        if (configUserInputs.brand) {
          updateConfigUserInputs(
            "configNameFixed",
            `${appConfig.hardCoded.aeAppName}-${configUserInputs.country}-${configUserInputs.brand}-`
          );
        } else {
          updateConfigUserInputs(
            "configNameFixed",
            `${appConfig.hardCoded.aeAppName}-${configUserInputs.country}-`
          );
        }
      } else {
        updateConfigUserInputs(
          "configNameFixed",
          `${appConfig.hardCoded.aeAppName}-${configUserInputs.country}-${configUserInputs.brand}-`
        );
      }
    }
  }, [configUserInputs.country, configUserInputs.brand]);

  function ScoringFrequencyType(props) {
    const { type } = props;
    const muiClass = muiCommonStyles();
    let Component;

    const getSelctedStyle = (selectedDay) =>
      selectedDay === scheduleRunData.frequencyValue
        ? {
            background: "#465A69",
            color: "#ffffff",
            minWidth: "30px",
            width: "30px",
            boxShadow: "none",
          }
        : // configUserInputs.configType === "edit" &&
          //   configUserInputs.is_fixed_schedule_config
          // ? {}
          // :
          {
            background:
              configUserInputs.configType === "edit" &&
              configUserInputs.is_fixed_schedule_config
                ? "rgba(0,0,0,0.1)"
                : "#ffffff",
            color: "#465A69",
            minWidth: "30px",
            width: "30px",
            boxShadow: "none",
          };

    switch (type) {
      case "run_once":
        Component = (
          <DatePicker
            style={{ height: 30, maxWidth: "none" }}
            min={getTodayDate(scheduleRunData.timeZone, "YYYY-MM-DD")}
            max={getEndDayOfYear()}
            onKeyDown={(e) => e.preventDefault()}
            value={
              scheduleRunData.frequencyValue
                ? formatDateToMoment(
                    scheduleRunData.frequencyValue,
                    "YYYY-MM-DD"
                  )
                : ""
            }
            onChange={(e) => {
              updateScheduleDetails(
                "frequencyValue",
                formatDate(e.target.value, "DD/MM/YYYY")
              );
            }}
          />
        );
        break;
      case "weekly":
        Component = (
          <>
            {ScoringWeeklyValues.map((day) => (
              <Button
                className={muiClass.scheduleWeekBtns}
                style={getSelctedStyle(day.id)}
                value={day.id}
                key={day.id}
                onClick={(e) => {
                  updateScheduleDetails(
                    "frequencyValue",
                    e.currentTarget.value
                  );
                }}
                name="scoring-weekly-value"
              >
                {day.name}
              </Button>
            ))}
          </>
        );
        break;
      case "monthly":
        Component = (
          <Select
            name="scoring-monthly-value"
            id="scoring-monthly-value"
            value={scheduleRunData.frequencyValue}
            onChange={(e) =>
              updateScheduleDetails("frequencyValue", e.target.value)
            }
            className={muiClass.multiSelect}
            MenuProps={MenuProps}
            style={{ maxWidth: "none" }}
          >
            {ScoringMonthlyValues.map((obj) => (
              <MenuItem
                value={obj.id}
                key={obj.id}
                className={muiClass.singleMenuItem}
              >
                {obj.name}
              </MenuItem>
            ))}
          </Select>
        );
        break;
      default:
        Component = null;
    }

    return Component;
  }
  const scheduleForm = () => {
    const isDisabled =
      scheduleRunData.frequencyType === "run_once" &&
      changeDateInEpoch(scheduleRunData.frequencyValue) <
        changeDateInEpoch(formatDate(new Date(), "DD/MM/YYYY"));
    return (
      <div className="schedule-input-field-container">
        <div className="run-frequency-container">
          <div className="input-field-row-container">
            <div className="input-field-sub-container">
              <label>Run Frequency</label>
              <div className="input-text-box">
                <Select
                  name="scoring-frequency"
                  id="scoring-frequency"
                  value={scheduleRunData.frequencyType}
                  onChange={(e) => {
                    updateScheduleDetails("frequencyType", e.target.value);
                    updateScoringValue(e.target.value);
                  }}
                  className={muiClass.multiSelect}
                  MenuProps={MenuProps}
                  style={{ width: "100%", maxWidth: "none" }}
                >
                  {FrequencyType.map((obj) => (
                    <MenuItem
                      value={obj.id}
                      key={obj.id}
                      className={muiClass.singleMenuItem}
                    >
                      {obj.name}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </div>
          </div>
          <div className="input-field-row-container">
            <div className="input-field-sub-container no-label">
              <div
                className={`input-text-box ${
                  scheduleRunData.frequencyType === "weekly"
                    ? "scoring-week-selection"
                    : ""
                }`}
              >
                <ScoringFrequencyType type={scheduleRunData.frequencyType} />
              </div>
            </div>
          </div>
        </div>
        <div style={{ flex: "0 1 42%", padding: "0 10px", height: "3.1rem" }}>
          <div
            className="input-field-row-container"
            style={{ justifyContent: "start" }}
          >
            <div className="input-field-sub-container" style={{ width: "18%" }}>
              <label>Time</label>
              <div className="input-text-box">
                <StyledAutoComplete
                  id="trigger-zone"
                  name="trigger-zone"
                  value={scheduleRunData.triggerTime}
                  options={getTriggerTime().map((elem) => elem)}
                  disableClearable
                  getOptionLabel={(option) => option || ""}
                  getOptionDisabled={(option) => {
                    return scheduleRunData.frequencyType === "run_once"
                      ? !getPastHrInfo(
                          scheduleRunData.frequencyValue + " " + option,
                          scheduleRunData.timeZone
                        )
                        ? true
                        : false
                      : false;
                  }}
                  onChange={(e, newVal) => {
                    updateScheduleDetails("triggerTime", newVal);
                  }}
                  noOptionsText={"No options Found"}
                  className={muiClass.typographyStyle}
                  renderInput={(params) => (
                    <CssTextField
                      {...params}
                      variant="standard"
                      size="small"
                      placeholder="Type Time Here"
                      className={muiClass.typographyStyle}
                    />
                  )}
                />
              </div>
            </div>
            <div
              className="input-field-sub-container"
              style={{
                width: "65%",
                marginLeft: "20px",
              }}
            >
              <label>Timezone</label>
              <div className="input-text-box">
                <StyledAutoComplete
                  id="trigger-zone"
                  name="trigger-zone"
                  value={{
                    id: scheduleRunData.timeZone,
                    name: getTimezoneOffset(scheduleRunData.timeZone),
                  }}
                  options={getTZData().map((elem) => ({
                    id: elem,
                    name: getTimezoneOffset(elem),
                  }))}
                  disableClearable
                  getOptionLabel={(option) => option?.name || ""}
                  getOptionSelected={(option, value) => option.id === value.id}
                  onChange={(e, newValue) => {
                    updateScheduleDetails("timeZone", newValue?.id);
                  }}
                  noOptionsText={"No options Found"}
                  className={muiClass.typographyStyle}
                  renderInput={(params) => (
                    <CssTextField
                      {...params}
                      variant="standard"
                      size="small"
                      placeholder="Type Name Here"
                      className={muiClass.typographyStyle}
                    />
                  )}
                />
              </div>
            </div>
            {scheduleRunData.scheduleType === "edit" && (
              <div
                className={
                  changeInGlobalParamsFlag
                    ? "input-field-pending-container"
                    : "input-field-delete-container"
                }
              >
                {/* <Button
                disableRipple
                // className={muiClass.outlinedButton}
                onClick={() => {
                  scheduleDeleteConfig();
                }}
                variant="contained"
                disabled={
                  scheduleRunData.frequencyType === "run_once" &&
                  changeDateInEpoch(scheduleRunData.frequencyValue) <
                    changeDateInEpoch(formatDate(new Date(), "DD/MM/YYYY"))
                    ? true
                    : false
                }
                style={{
                  height: "28px",
                  padding: "10px",
                  fontSize: "11px",
                  minWidth: "15px",
                  boxSizing: "border-box",
                  marginLeft: "7px",
                }}
              >
                Delete Schedule
              </Button> */}
                <i
                  className={`material-icons-outlined delete-icon ${
                    isDisabled ? "icon-disabled" : ""
                  }`}
                  onClick={() => {
                    scheduleDeleteConfig();
                  }}
                  style={{
                    color: "#46596a",
                    fontSize: "20px",
                  }}
                >
                  delete
                </i>
              </div>
            )}
            {configUserInputs.configType !== "run" && (
              <div className={`update-global-params`}>
                <Button
                  disabled={
                    configUserInputs.configType === "run"
                      ? true
                      : globalParamsObj.global_week_date_selection ===
                          "date_range" &&
                        (!globalParamsObj.startDate || !globalParamsObj.endDate)
                      ? true
                      : changeInGlobalParamsFlag
                      ? false
                      : true
                  }
                  variant="contained"
                  onClick={() => {
                    addGlobalParams(globalParamsObj);
                    setDefaultGlobalParams(globalParamsObj);
                    setChangeInGlobalParamsFlag(false);
                  }}
                  style={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    minWidth: "80px",
                    background:
                      configUserInputs.configType === "run"
                        ? "rgba(0,0,0,0.4)"
                        : globalParamsObj.global_week_date_selection ===
                            "date_range" &&
                          (!globalParamsObj.startDate ||
                            !globalParamsObj.endDate)
                        ? "rgba(0,0,0,0.4)"
                        : changeInGlobalParamsFlag
                        ? "#46596a"
                        : "rgba(0,0,0,0.4)",
                  }}
                >
                  Submit
                </Button>
                {changeInGlobalParamsFlag && (
                  <p className="pending-changes-warning">Pending Changes</p>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };
  useNonInitialEffect(() => {
    if (scheduleRunData.frequencyType === "run_once") {
      let validData = getPastHrInfo(
        scheduleRunData.frequencyValue + " " + scheduleRunData.triggerTime,
        scheduleRunData.timeZone
      );
      if (
        !validData &&
        (config?.schedule_details?.trigger_time !==
          scheduleRunData?.triggerTime ||
          config?.schedule_details?.frequencyType !==
            scheduleRunData?.frequencyType)
      ) {
        updateScheduleDetails(
          "triggerTime",
          getCurrentEpochHour(scheduleRunData.timeZone)
        );
      }
    }
    return () => {};
  }, [scheduleRunData.frequencyValue, scheduleRunData.frequencyType]);

  useNonInitialEffect(() => {
    if (
      config?.schedule_details?.trigger_time !== scheduleRunData?.triggerTime
    ) {
      const result = getCurrentEpochHour(scheduleRunData.timeZone);
      const currentDate = getTodayDate(scheduleRunData.timeZone, "DD/MM/YYYY");
      updateScheduleDetails("triggerTime", result);
      if (scheduleRunData.frequencyType === "run_once") {
        if (result === "00:00") {
          const newData = getFutureDateFromGivenDate(
            formatDateToMoment(scheduleRunData.frequencyValue, "DD/MM/YYYY"),
            1,
            "DD/MM/YYYY"
          );
          updateScheduleDetails("frequencyValue", newData);
        } else {
          updateScheduleDetails("frequencyValue", currentDate);
        }
      }
    }
    return () => {};
  }, [scheduleRunData.timeZone, scheduleRunData.frequencyType]);

  // for deleting a schedule
  const scheduleDeleteConfig = () => {
    updateAlertInfo({
      open: true,
      message: appConfig.scheduleDeleteRequestMessage,
      severity: "info",
    });
    let deleteBody = {
      config_id: scheduleRunData.configurationId,
      frequency_type: scheduleRunData.frequencyType,
      app_id: appConfig.hardCoded.aeAppId,
    };
    let url = `${appConfig.api.scheduleConfigurl}`;
    let status;
    fetch(url, {
      method: "DELETE",
      body: JSON.stringify(deleteBody),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        console.groupCollapsed("DELETE REQUEST SENT...", url);
        console.log("REPSONSE -> ", response);
        status = response.status;
        return response.clone().json();
      })
      .then((json) => {
        console.log("JSON -> ", json);
        console.groupEnd();
        if (status === 200) {
          updateAlertInfo({
            open: true,
            message: appConfig.scheduleSuccessDeleteMessage,
            severity: "success",
          });
          history.push(
            match.params.tab === "addnewconfig"
              ? `/home/myconfigurations/`
              : `/home/${match.params.tab}/`
          );
          reloadConfigs();
        } else {
          updateAlertInfo({
            open: true,
            message: json.statusMessage,
            severity: "error",
          });
        }
      });
  };
  // function for removing startDate endDate or changing weeks to default)
  const setDefaultGlobalParams = (globalParamsObj) => {
    if (
      globalParamsObj.global_week_date_selection ===
      appConfig.hardCoded.historicWeek
    ) {
      setDefaultGlobalParamsObj({
        ...globalParamsObj,
        startDate: "",
        endDate: "",
      });
      setGlobalParamsObj({
        ...globalParamsObj,
        startDate: "",
        endDate: "",
      });
    } else {
      setDefaultGlobalParamsObj({ ...globalParamsObj, historic_week_data: 5 });
      setGlobalParamsObj({
        ...globalParamsObj,
        historic_week_data: 5,
      });
    }
  };
  useEffect(() => {
    let url = appConfig.api.cmuFile;
    if (
      configUserInputs.country &&
      configUserInputs.brand &&
      configUserInputs.configType !== "run"
    ) {
      updateConfigUserInputs("custom_dimensions_data", "");
      fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          country: configUserInputs.country,
          brand: configUserInputs.brand,
        }),
      })
        .then((response) => {
          console.groupCollapsed("requesting", url);
          console.log("REPSONSE -> ", response);
          return response.clone().json();
        })
        .then((json) => {
          updateConfigUserInputs("custom_dimensions_data", json.data);
          console.groupEnd();
        });
    }
  }, [configUserInputs.country, configUserInputs.brand]);

  return (
    <>
      <div className="config-additional-settings">
        <AddTags
          configUserInputs={configUserInputs}
          updateConfigUserInputs={updateConfigUserInputs}
          updateUserInfo={updateUserInfo}
          updateData={updateData}
          allData={allData}
          activeApp={activeApp}
          disableFlag={disableFlag}
          user={user}
        />
        {/* {!disableFlag &&
          appConfig.hardCoded.nativeAppIdList.includes(activeApp?.id) && (
            <ConfigSettings
              configUserInputs={configUserInputs}
              updateConfigUserInputs={updateConfigUserInputs}
              allData={allData}
              activeApp={activeApp}
              match={match}
              createDefault={createDefault}
              activeSelection={activeSelection}
              setActiveSelection={setActiveSelection}
              onChangeDERunConfig={onChangeDERunConfig}
            />
          )} */}
      </div>
      <div className="add-new-config">
        {!loading && !configUserInputs.loading ? (
          <>
            <div className={`${disableFlag ? "no-bottom-panel" : "top-panel"}`}>
              {/* TOGGLE ICON */}
              <div className="toggle-icon">
                <label
                  onClick={() => {
                    setGlobalParamsOpen(!globalParamsOpen);
                  }}
                >
                  {globalParamsOpen ? "Collapse" : "Expand"}
                </label>
                <i
                  className="material-icons global-params-toggle-icon"
                  onClick={() => {
                    setGlobalParamsOpen(!globalParamsOpen);
                  }}
                >
                  {globalParamsOpen
                    ? "keyboard_arrow_up"
                    : "keyboard_arrow_down"}
                </i>
              </div>

              {/* NAME + GLOBAL PARAMETERS */}
              <div className="add-config">
                <div className="row collapsed-config">
                  <div className="name">
                    <div className="config-name-label-container">
                      <label htmlFor="title" className="title">
                        Configuration Name:{" "}
                      </label>
                      {/* {disableFlag
                        ? user.selections.activeRunPayload
                            ?.is_scheduled_run && (
                            <button className="name-chip">
                              <label>Schedule Run</label>
                            </button>
                          )
                        : configUserInputs?.is_schedule_config && (
                            <button className="name-chip">
                              <label>Schedule Run</label>
                            </button>
                          )} */}
                    </div>
                    <div className="name-config">
                      <div
                        className="config-name"
                        title={configUserInputs.configNameFixed}
                      >
                        {shortFormAE(configUserInputs.configNameFixed)}
                      </div>
                      <input
                        type="text"
                        value={configUserInputs.configName}
                        onChange={(e) => {
                          updateConfigUserInputs("configName", e.target.value);
                        }}
                        className={`${disableFlag ? "disabled-pointer" : ""}`}
                      />
                    </div>
                  </div>
                  <div
                    className={`type-of-run ${
                      !allData?.appAccessDetails?.feature_list?.schedule_run ||
                      configUserInputs.configId ||
                      configUserInputs.configType === "run"
                        ? "disabled-pointer"
                        : ""
                    }`}
                  >
                    <label htmlFor="title" className="title">
                      Type of run:
                    </label>
                    <div className="radio-container">
                      <div className="radio-icon">
                        <ThemeProvider theme={theme}>
                          <BlueRadio
                            checked={
                              disableFlag &&
                              user.selections.activeRunPayload?.is_scheduled_run
                                ? false
                                : !configUserInputs.is_schedule_config &&
                                  !configUserInputs.is_fixed_schedule_config
                            }
                            onChange={(e) => changeRunType(e.target.name)}
                            name="on_demand_run"
                            size="small"
                            style={{
                              width: 15,
                              height: 15,
                              paddingRight: "3px",
                              color: "#4eafb3 !important",
                            }}
                            stylevariant="secondary"
                            disableRipple
                          />
                        </ThemeProvider>
                        <label className="radio-label bold">On Demand</label>
                      </div>
                      <div className="radio-icon" style={{ marginLeft: "9px" }}>
                        <ThemeProvider theme={theme}>
                          <BlueRadio
                            checked={
                              disableFlag
                                ? user.selections.activeRunPayload
                                    ?.is_scheduled_run &&
                                  !user.selections.activeRunPayload
                                    ?.is_fixed_schedule_config
                                : configUserInputs.is_schedule_config
                            }
                            onChange={(e) => changeRunType(e.target.name)}
                            name="schedule_run"
                            size="small"
                            style={{
                              width: 15,
                              height: 15,
                              paddingRight: "3px",
                              color: "#4eafb3 !important",
                            }}
                            stylevariant="secondary"
                            disableRipple
                          />
                        </ThemeProvider>
                        <label className="radio-label bold">
                          Recurring Schedule
                        </label>
                      </div>
                      <div className="radio-icon" style={{ marginLeft: "9px" }}>
                        <ThemeProvider theme={theme}>
                          <BlueRadio
                            checked={
                              disableFlag
                                ? user.selections.activeRunPayload
                                    ?.is_fixed_schedule_config
                                : configUserInputs.is_fixed_schedule_config
                            }
                            onChange={(e) => changeRunType(e.target.name)}
                            size="small"
                            style={{
                              width: 15,
                              height: 15,
                              paddingRight: "3px",
                              color: "#4eafb3 !important",
                            }}
                            stylevariant="secondary"
                            disableRipple
                            name="fixed_schedule_run"
                          />
                        </ThemeProvider>
                        <label className="radio-label bold">
                          Fixed Schedule
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                {globalParamsOpen && (
                  <div className="row globalParameter collapsed-config">
                    <div className="globalParameter-container">
                      {/* <div className="content"> */}
                      {orderBy(allData.globalParams, ["name"]).map(
                        (obj) =>
                          obj.id !== "global_week_date_selection" && (
                            <div
                              key={obj.id}
                              className={`input-item-container`}
                              style={{ flexGrow: "inherit", flex: "0 1 50%" }}
                            >
                              <LocalParameterItem
                                {...obj}
                                userInputs={configUserInputs}
                                setUserInputs={updateConfigUserInputs}
                                disableFlag={disableFlag}
                                match={match}
                                globalParamsOnChange={globalParamsOnChange}
                                globalParamsObj={globalParamsObj}
                              />
                            </div>
                          )
                      )}

                      <div
                        className="date-range"
                        style={{
                          padding: "0 10px",
                          // flex: "0 30%",
                          height: "53px",
                        }}
                      >
                        {orderBy(allData.globalParams, ["name"]).map(
                          (obj) =>
                            obj.id === "global_week_date_selection" && (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                key={obj.id}
                              >
                                <LocalParameterItem
                                  {...obj}
                                  userInputs={configUserInputs}
                                  setUserInputs={updateConfigUserInputs}
                                  disableFlag={disableFlag}
                                  match={match}
                                  globalParamsOnChange={globalParamsOnChange}
                                  globalParamsObj={globalParamsObj}
                                />
                              </div>
                            )
                        )}
                        {!configUserInputs.is_schedule_config &&
                          !configUserInputs.is_fixed_schedule_config &&
                          configUserInputs.configType !== "run" && (
                            <div className={`update-global-params`}>
                              <Button
                                disabled={
                                  configUserInputs.configType === "run"
                                    ? true
                                    : globalParamsObj.global_week_date_selection ===
                                        "date_range" &&
                                      (!globalParamsObj.startDate ||
                                        !globalParamsObj.endDate)
                                    ? true
                                    : changeInGlobalParamsFlag
                                    ? false
                                    : true
                                }
                                variant="contained"
                                onClick={() => {
                                  addGlobalParams(globalParamsObj);
                                  setDefaultGlobalParams(globalParamsObj);
                                  setChangeInGlobalParamsFlag(false);
                                }}
                                style={{
                                  fontSize: "12px",
                                  fontWeight: "bold",
                                  minWidth: "80px",
                                  background:
                                    configUserInputs.configType === "run"
                                      ? "rgba(0,0,0,0.4)"
                                      : globalParamsObj.global_week_date_selection ===
                                          "date_range" &&
                                        (!globalParamsObj.startDate ||
                                          !globalParamsObj.endDate)
                                      ? "rgba(0,0,0,0.4)"
                                      : changeInGlobalParamsFlag
                                      ? "#46596a"
                                      : "rgba(0,0,0,0.4)",
                                }}
                              >
                                Submit
                              </Button>
                              {changeInGlobalParamsFlag && (
                                <p className="pending-changes-warning">
                                  Pending Changes
                                </p>
                              )}
                            </div>
                          )}
                      </div>
                    </div>

                    {configUserInputs.is_schedule_config &&
                      !disableFlag &&
                      scheduleForm()}
                    {configUserInputs.is_fixed_schedule_config &&
                      !disableFlag && (
                        <FixScheduleTypeForm
                          scheduleRunData={scheduleRunData}
                          setScheduleRunData={setScheduleRunData}
                          updateScheduleDetails={updateScheduleDetails}
                          updateScoringValue={updateScoringValue}
                          MenuProps={MenuProps}
                          muiClass={muiClass}
                          ScoringFrequencyType={ScoringFrequencyType}
                          StyledAutoComplete={StyledAutoComplete}
                          CssTextField={CssTextField}
                          changeInGlobalParamsFlag={changeInGlobalParamsFlag}
                          scheduleDeleteConfig={scheduleDeleteConfig}
                          configUserInputs={configUserInputs}
                          globalParamsObj={globalParamsObj}
                          addGlobalParams={addGlobalParams}
                          setDefaultGlobalParams={setDefaultGlobalParams}
                          setChangeInGlobalParamsFlag={
                            setChangeInGlobalParamsFlag
                          }
                          activeModel={activeModel}
                        />
                      )}
                  </div>
                )}
              </div>
              {/* HTML graph */}
              {configUserInputs?.custom_dimensions_data &&
                configUserInputs?.cmu_status?.data?.cc_pm &&
                activeModelId !== appConfig.hardCoded.nlpId &&
                configUserInputs.configType !== "run" && (
                  <div
                    style={{
                      border: "none",
                      height: "320px",
                      overflowY: "none",
                    }}
                  >
                    <iframe
                      title="graph"
                      srcDoc={configUserInputs?.custom_dimensions_data}
                      width={"100%"}
                      height={"100%"}
                      frameborder="0"
                      scrolling="no"
                    />
                  </div>
                )}
              {/* MODEL SELECTIONS */}
              <div className="user-input colorless">
                <div className="content">
                  {configUserInputs.models.length &&
                  allData.models?.length &&
                  activeModelId ? (
                    <ModelSelections
                      match={match}
                      loading={loading}
                      disableFlag={disableFlag}
                      allData={allData}
                      activeSelection={activeSelection}
                      setActiveSelection={setActiveSelection}
                      activeStep={activeStep}
                      stepCompleted={stepCompleted}
                      setStepCompleted={setStepCompleted}
                      setActiveStep={setActiveStep}
                      nestedRunsArray={nestedRunsArray}
                      onStepperChange={onStepperChange}
                      activeModelId={activeModelId}
                      setActiveModelId={setActiveModelId}
                      activeModel={activeModel}
                      validateStepper={validateStepper}
                      scheduleRunData={scheduleRunData}
                    />
                  ) : (
                    <div className="loader-center">
                      <Loader />
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* BUTTONS */}
            {!disableFlag && nestedRunsArray && (
              <div className="bottom-panel">
                <div className="user-actions-buttons">
                  <Buttons
                    match={match}
                    configUserInputs={configUserInputs}
                    activeStep={activeStep}
                    stepCompleted={stepCompleted}
                    setStepCompleted={setStepCompleted}
                    setActiveStep={setActiveStep}
                    onStepperChange={onStepperChange}
                    nestedRunsArray={nestedRunsArray}
                    activeModelId={activeModelId}
                    scheduleRunData={scheduleRunData}
                    validateStepper={validateStepper}
                    changeInGlobalParamsFlag={changeInGlobalParamsFlag}
                    allData={allData}
                  />
                </div>
              </div>
            )}

            <Modal
              modalComponent={modalComponent}
              setModalComponent={setModalComponent}
              handleDialogClose={() => {}}
            />
          </>
        ) : (
          <Loader />
        )}
      </div>
    </>
  );
}

AddNewConfig.propTypes = {
  user: PropTypes.object,
  match: PropTypes.object,
  data: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.user,
  allData: state.data,
  configUserInputs: state.configUserInputs,
});

const mapDispatchToProps = {
  updateConfigUserInputs,
  updateCountryRegions,
  updateUserInfo,
  updateRegionsData,
  emptyTestControl,
  updateControlRegions,
  updateTestRegions,
  updateWholeConfigUserInputs,
  updateData,
  onChangeDERunConfig,
  updateModelLocalParameters,
  updateAlertInfo,
  reloadConfigs,
  addGlobalParams,
  dataAvailabilityChanges,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddNewConfig);

import C from "./constants";

export const changeTheme = (theme) => ({
  type: C.CHANGE_THEME,
  theme,
});

export const updateData = (key, data) => ({
  type: C.UPDATE_DATA,
  key,
  data,
});

export const updateSelections = (key, data) => ({
  type: C.UPDATE_SELECTIONS,
  key,
  data,
});

export const updateMultiSelections = (data) => ({
  type: C.UPDATE_MULTI_SELECTIONS,
  data,
});

export const updateAlertInfo = (data) => ({
  type: C.UPDATE_ALERT_INFO,
  data,
});

export const updateUserInfo = (data) => ({
  type: C.UPDATE_USER_INFO,
  data,
});

export const resetFilters = (data) => ({
  type: C.RESET_FILTERS,
});

export const updateConfigUserInputs = (key, data) => ({
  type: C.UPDATE_CONFIG_USER_INPUTS,
  key,
  data,
});

export const updateCountryRegions = (data) => ({
  type: C.UPDATE_COUNTRY_REGIONS,
  data,
});

export const emptyTestControl = () => ({
  type: C.EMPTY_TEST_CONTROL,
});

export const updateControlRegions = () => ({
  type: C.UPDATE_CONTROL_REGIONS,
});

export const updateTestRegions = () => ({
  type: C.UPDATE_TEST_REGIONS,
});

export const updateRegionsData = () => ({
  type: C.UPDATE_REGIONS_DATA,
});

export const updateWholeConfigUserInputs = (data) => ({
  type: C.UPDATE_WHOLE_CONFIG_USER_INPUTS,
  data,
});

export const toggleModel = (data) => ({
  type: C.TOGGLE_MODEL,
  data,
});

export const addDataset = (data) => ({
  type: C.ADD_DATASET,
  data,
});

export const removeDataset = (data) => ({
  type: C.REMOVE_DATASET,
  data,
});

export const addKpi = (data) => ({
  type: C.ADD_KPI,
  data,
});

export const removeKpi = (data) => ({
  type: C.REMOVE_KPI,
  data,
});

export const updateDatasetLocalParameters = (data) => ({
  type: C.UPDATE_DATASET_LOCAL_PARAMETERS,
  data,
});

export const updateModelLocalParameters = (data) => ({
  type: C.UPDATE_MODEL_LOCAL_PARAMETERS,
  data,
});

export const updateRunData = (data) => ({
  type: C.UPDATE_RUN_DATA,
  data,
});

export const reloadRunData = (data) => ({
  type: C.RELOAD_RUN_DATA,
});

export const updateSharedRunsData = (data) => ({
  type: C.UPDATE_SHARED_RUN_DATA,
  data,
});

export const refreshData = (data) => ({
  type: C.REFRESH_DATA,
});

export const updateDialogInfo = (data) => ({
  type: C.UPDATE_DIALOG_INFO,
  data,
});

export const reloadConfigs = () => ({
  type: C.RELOAD_CONFIGS,
});

export const toggleDataset = (data) => ({
  type: C.TOGGLE_DATASET,
  data,
});

export const toggleKpi = (data) => ({
  type: C.TOGGLE_KPI,
  data,
});

export const addToggleKpi = (data) => ({
  type: C.ADD_TOGGLE_KPI,
  data,
});

export const selectAll = (data) => ({
  type: C.SELECT_ALL,
  data,
});

export const clearAll = (data) => ({
  type: C.CLEAR_ALL,
  data,
});

export const toggleSidenav = (data) => ({
  type: C.TOGGLE_SIDENAV,
});

export const refreshComments = (data) => ({
  type: C.REFRESH_COMMENTS,
});

export const updateAdminPanelUserDetails = (key, data) => ({
  type: C.UPDATE_ADMIN_PANEL_USER_DETAILS,
  key,
  data,
});

export const getUsersList = () => ({
  type: C.GET_USERS_LIST,
});

export const getADUsers = (data) => ({
  type: C.GET_AD_USERS,
  data,
});

export const getUserInfo = (preferredUsername) => ({
  type: C.GET_USER_INFO,
  data: { preferredUsername },
});

export const addUser = (data) => ({
  type: C.ADD_USER,
  data,
});

export const modifyUser = (data) => ({
  type: C.MODIFY_USER_INFO,
  data,
});

export const getGroups = () => ({
  type: C.GET_GROUPS,
});

export const getGroupInfo = (groupId) => ({
  type: C.GET_GROUP_INFO,
  data: { groupId },
});

export const updateUserListFilterString = (data) => ({
  type: C.UPDATE_USER_LIST_FILTER_STRING,
  data,
});

export const updateAdminUserList = (data) => ({
  type: C.USERS_LIST_RECEIVED,
  data,
});

export const groupsInfoReceived = (data) => ({
  type: C.GROUPS_INFO_RECEIVED,
  data,
});

export const updateFeatureList = (data) => ({
  type: C.FEATURE_LIST_RECEIVED,
  data,
});

export const updateUserInfoForAdmin = (data) => ({
  type: C.UPDATE_USER_INFO_FOR_ADMIN,
  data,
});

export const updateAdminGroupsList = (data) => ({
  type: C.GROUPS_LIST_RECEIVED,
  data,
});

export const reloadAdminUserList = () => ({
  type: C.RELOAD_ADMIN_USER_LIST,
});

export const resetAddEditAlert = () => ({
  type: C.RESET_ADD_EDIT_STATUS_RECEIVED,
});

export const addGroup = (data) => ({
  type: C.ADD_GROUP,
  data,
});

export const modifyGroup = (data) => ({
  type: C.MODIFY_GROUP_INFO,
  data,
});

export const getApps = () => ({
  type: C.GET_APPS,
});

export const getCountries = (data) => ({
  type: C.GET_COUNTRIES,
  data,
});

export const getGlobalParams = (data) => ({
  type: C.GET_GLOBAL_PARAMS,
  data,
});

export const getBrands = (data) => ({
  type: C.GET_BRANDS,
  data,
});

export const getAdminGlobalParams = (data) => ({
  type: C.GET_ADMIN_GLOBAL_PARAMS,
  data,
});

export const updateKpiVariables = (data) => ({
  type: C.UPDATE_KPI_VARIABLES,
  data,
});

export const resetNewKPI = (data) => ({
  type: C.RESET_NEW_KPI,
  data,
});

export const onChangeDERunConfig = (key, data) => ({
  type: C.ON_CHANGE_DE_CONFIG,
  key,
  data,
});

export const filterDataset = () => ({
  type: C.FILTERING_DATASET,
});

export const toggleDatasetInCommunity = (data) => ({
  type: C.TOGGLE_DATASET_IN_COMMUNITY,
  data,
});
export const updateRunExpandInfo = (key) => ({
  type: C.UPDATE_RUN_EXPAND_INFO,
  key,
});
export const addGlobalParams = (data) => ({
  type: C.ADD_GLOBAL_PARAMS,
  data,
});
export const dataAvailabilityChanges = (data) => ({
  type: C.DATA_AVAILABILITY_CHANGES,
  data,
});
export const updateActivationPlatform = (data) => ({
  type: C.UPDATE_ACTIVATION_PLATFORM,
  data,
});

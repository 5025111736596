import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import "../index.css";

function getTooltipStyle() {
  return {
    width: "fit-content",
  };
}

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme, styleVariant }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    fontFamily: "var(--fontFamily)",
    backgroundColor: `var(--${styleVariant}-tooltip-bgcolor-${theme})`,
    color: `var(--${styleVariant}-tooltip-textcolor-${theme})`,
    padding: "10px",
    fontSize: "11.5px",
  },
}));

/**
 * [BasicTooltip description]
 * @param {[string]} theme [The theme of the component - default: light]
 * @param {[string]} styleVariant [Used to represent colored interface elements for a user - default: primary]
 * @param {[node]} children [The content of the component.]
 * @param {[string]} title [Text to display]
 * @param {[any]} ...props [Other valid props for MUI Tooltip - standard]
 * @returns {[object]} [Returns the Tooltip component]
 */
const BasicTooltip = (props) => {
  const {
    theme = "light",
    styleVariant = "primary",
    children,
    title = "Your message here",
    ...remaining
  } = props;

  const defaultStyle = getTooltipStyle();
  const finalStyle = { ...defaultStyle };

  return (
    <LightTooltip
      theme={theme}
      styleVariant={styleVariant}
      title={title}
      {...remaining}
    >
      <div style={finalStyle}>{children}</div>
    </LightTooltip>
  );
};

BasicTooltip.propTypes = {
  children: PropTypes.node,
  theme: PropTypes.oneOf(["light", "dark"]),
  styleVariant: PropTypes.oneOf(["primary", "secondary"]),
  title: PropTypes.string,
};

export default BasicTooltip;

//Import required libraies
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

//Import Custom Component
import WorkInProgress from "../../components/WorkInProgress/WorkInProgress";
import AIDEAppContainer from "../AIDEAppContainer/AIDEAppContainer";

//Import tabs
import AddNewConfigContainer from "./AddNewConfig/AddNewConfigContainer";
import MyConfigurations from "./MyConfigurations/MyConfigurations";
import SharedConfigurations from "./SharedConfigurations/SharedConfigurations";
import ViewRunsContainerNew from "./ViewRuns/ViewRunsContainerNew";

// Import action creators
import {
  updateData,
  reloadRunData,
  updateUserInfo,
  updateRunData,
  updateSelections,
  updateWholeConfigUserInputs,
  refreshData,
} from "../../redux/actions";
import StarredConfigurations from "./StarredConfigurations/StarredConfigurations";
import GeoTargeting from "../../components/GeoTargeting/GeoTargeting";
import NLPAndGoogleDashboard from "../../components/GoogleDashBoard";
import ViewRunsV2 from "./ViewRunsV2/ViewRunsV2";
import ScheduleDashboard from "../../components/ScheduleDashboard/ScheduleDashboard";
import UserMetricsReport from "../../components/UserMetricsReport/UserMetricsReport";

//Import Custom Hooks

//Import utils/data
function AIDEApp(props) {
  let { match } = props;
  const [switchScheduleConfiguration, setSwitchScheduleConfiguration] =
    useState(false);
  const [ga360, setGa360] = useState(false);
  const handleGa360 = () => {
    setGa360(!ga360);
  };
  const handleSwitch = () => {
    setSwitchScheduleConfiguration(!switchScheduleConfiguration);
  };
  useEffect(() => {
    setSwitchScheduleConfiguration(false);
  }, [match.params.tab]);
  useEffect(() => {
    setGa360(false);
  }, [match.params.tab]);
  const tabMapping = {
    addnewconfig: {
      component: <AddNewConfigContainer match={match} />,
    },
    myconfigurations: {
      component: (
        <MyConfigurations
          match={match}
          handleSwitch={handleSwitch}
          switchScheduleConfiguration={switchScheduleConfiguration}
          handleGa360={handleGa360}
          ga360={ga360}
        />
      ),
    },
    recent: {
      component: (
        <MyConfigurations
          match={match}
          handleSwitch={handleSwitch}
          handleGa360={handleGa360}
          ga360={ga360}
          switchScheduleConfiguration={switchScheduleConfiguration}
        />
      ),
    },
    sharedwithme: {
      component: (
        <SharedConfigurations
          match={match}
          handleGa360={handleGa360}
          ga360={ga360}
        />
      ),
    },
    sharedwithothers: {
      component: <WorkInProgress match={match} />,
    },
    starred: {
      component: (
        <StarredConfigurations
          match={match}
          handleSwitch={handleSwitch}
          handleGa360={handleGa360}
          ga360={ga360}
          switchScheduleConfiguration={switchScheduleConfiguration}
        />
      ),
    },
    viewruns: {
      component: <ViewRunsV2 match={match} />,
    },
    geoTargeting: {
      component: <GeoTargeting match={match} />,
    },
    nlpreports: {
      component: (
        <NLPAndGoogleDashboard
          match={match}
          isAuthorized={true}
          nlpReport={true}
        />
      ),
    },
    portraitdashboards: {
      component: <NLPAndGoogleDashboard match={match} />,
    },
    usermetricsreport: {
      component: <UserMetricsReport match={match} />,
    },
    scheduledconfigurations: {
      component: <ScheduleDashboard match={match} />,
    },
  };

  return (
    <AIDEAppContainer>
      {match.params.tab ? (
        <>
          {tabMapping[match.params.tab] ? (
            tabMapping[match.params.tab].component
          ) : (
            <WorkInProgress />
          )}
        </>
      ) : (
        <Redirect
          to={
            match.url.endsWith("/")
              ? `${match.url}myconfigurations`
              : `${match.url}/myconfigurations`
          }
        />
      )}
    </AIDEAppContainer>
  );
}

AIDEApp.propTypes = {
  match: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
  allData: state.data,
  changeFlag: state.changeFlag,
});

const mapDispatchToProps = {
  updateData,
  reloadRunData,
  updateUserInfo,
  updateRunData,
  updateSelections,
  updateWholeConfigUserInputs,
  refreshData,
};

export default connect(mapStateToProps, mapDispatchToProps)(AIDEApp);

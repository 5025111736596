import React from "react";
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import "../index.css";

function getDialogStyle(theme, styleVariant) {
  return {
    fontFamily: "var(--fontFamily)",
    backgroundColor: `var(--${styleVariant}-dialog-bgcolor-${theme})`,
    color: `var(--${styleVariant}-dialog-textcolor-${theme})`,
  };
}

/**
 * [SimpleDialog description]
 * @param {[string]} theme [The theme of the component - default: light]
 * @param {[string]} styleVariant [Used to represent colored interface elements for a user - default: primary]
 * @param {[node]} children [The content of the component.]
 * @param {[bool]} open [If true, the component is shown - default: false]
 * @param {[any]} ...props [Other valid props for MUI Dialog]
 * @returns {[object]} [Returns the Dialog component]
 */
const SimpleDialog = (props) => {
  const {
    children,
    open = false,
    theme = "light",
    styleVariant = "primary",
    style = {},
    ...remaining
  } = props;
  const defaultStyle = getDialogStyle(theme, styleVariant);
  const finalStyle = { ...defaultStyle, ...style };

  return (
    <Dialog open={open} {...remaining}>
      <div style={finalStyle}>{children}</div>
    </Dialog>
  );
};

SimpleDialog.propTypes = {
  children: PropTypes.node,
  theme: PropTypes.oneOf(["light", "dark"]),
  styleVariant: PropTypes.oneOf(["primary", "secondary"]),
  open: PropTypes.bool,
};

export default SimpleDialog;

//Import required libraies
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ListItemText from "@material-ui/core/ListItemText";
// import Checkbox from "@material-ui/core/Checkbox";
import { Checkbox, Popover } from "../../../../uiTheme";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import orderBy from "lodash/orderBy";

//Import Custom Component
import Loader from "../../../../components/Loader/Loader";
import SingleTreeSelect from "./SingleTreeSelect";

//Import data & utils
import { config } from "../../../../config/config";
import {
  addAllInData,
  makeDefaultResponseJson,
  populateFiltersData,
  addAllNodeAndSortSingleTree,
  arrSameMembers,
} from "../../../../utils/utils";

// Import action creators
import {
  updateData,
  updateSelections,
  resetFilters,
  updateUserInfo,
} from "../../../../redux/actions";

// Import styles
import "./CountryBrandSegmentHierarchy.scss";

const useStyles = makeStyles((theme) => ({
  multiSelect: {
    fontSize: "12px",
    fontFamily: "Hind Siliguri",
    //width: "190px",
    minWidth: "200px",
    maxWidth: "200px",
    height: "30px",
    borderRadius: "3px",
    color: "#46596a !important",
    backgroundColor: "white",
    alignItems: "center",
    display: "flex",
    //paddingLeft: "8px",
    border: "1px solid #dedede",
    "&:before": {
      borderBottom: "0px",
    },
    "&:after": {
      borderBottom: "0px",
    },
    "&:hover:not(.Mui-disabled):before": {
      borderBottom: "0px",
    },
    "& .MuiOutlinedInput-input": {
      padding: "6px 6px",
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "transparent",
    },
    "& .MuiSelect-select.MuiSelect-select": {
      paddingLeft: "8px",
    },
  },
  menuItem: {
    fontSize: "12px",
    fontFamily: "Hind Siliguri",
    padding: "0px",
  },
  singleMenuItem: {
    fontSize: "12px",
    fontFamily: "Hind Siliguri",
    padding: "0px 12px",
    color: "#46596a",
    width: "auto",
  },
  listItem: {
    "& span.MuiTypography-body1": {
      fontSize: "12px",
      color: "#46596a",
      fontFamily: "Hind Siliguri",
      paddingRight: "5px",
    },
  },
  popover: {
    pointerEvents: "none",
    fontFamily: "Hind Siliguri",
    fontSize: 12,
    width: "800px",
    margin: "10px",
  },
  typographyHeader: {
    fontSize: 12,
    fontFamily: "Hind Siliguri",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    color: "#46596a",
    margin: 0,
    marginTop: 5,
    backgroundColor: "#ebeff1",
  },
  typography: {
    fontSize: 11,
    fontFamily: "Hind Siliguri",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    color: "#46596a",
    margin: 0,
    marginTop: 4,
    marginBottom: 4,
    width: "320px",
  },
  contained: {
    color: "white",
    backgroundColor: "#25b1e6",
    marginRight: 4,
    marginTop: "28px",
    padding: "0px 5px",
    borderRadius: "3px",
    paddingTop: "2px",
    fontSize: 11,
    height: 23,
    minWidth: 15,
    textTransform: "capitalize",
    fontFamily: "Hind Siliguri",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#46596a",
      boxShadow: "none",
    },
  },
}));

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 150,
      width: "auto",
    },
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  variant: "menu",
  getContentAnchorEl: null,
};

const StyledCheckbox = withStyles((theme) => ({
  root: {
    color: "#4eafb3 !important",
    "& .MuiSvgIcon-root": {
      width: 18,
      height: 18,
    },
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}))(Checkbox);

function MultiSelectDropdown(props) {
  const { userInputs, obj, globalParamsOnChange, globalParamsObj } = props;
  const [anchorEl, setAnchorEl] = useState(null);

  const classes = useStyles();

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  return (
    <>
      <Select
        multiple
        labelId="demo-mutiple-checkbox-label"
        id="demo-mutiple-checkbox"
        className={classes.multiSelect}
        // value={userInputs[obj.id]}
        value={globalParamsObj[obj.id]}
        renderValue={(selected) => {
          return selected.join(", ");
        }}
        aria-owns={open ? obj.id : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        disabled
        input={<Input />}
        MenuProps={MenuProps}
      ></Select>
      <Popover
        id={obj.id}
        className={classes.popover}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        disableRestoreFocus
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <div className="popover-content">
          {userInputs[obj.id].map((value) => (
            <p key={value}>{value}</p>
          ))}
        </div>
      </Popover>
    </>
  );
}

const dropdownOptions = {
  subBrandsMapping: {},
  segmentsMapping: {},
  subSegmentMapping: {},
};
const CountryBrandSegmentHierarchy = (props) => {
  let {
    data,
    segments,
    subBrand,
    subSegment,
    allData,
    match,
    customClassName,
    userInputs,
    id,
    setUserInputs,
    enable,
    disableFlag,
    updateUserInfo,
    updateData,
    globalParamsOnChange,
    globalParamsObj,
  } = props;

  const [hierarchyData, setHierarchyData] = useState(null);

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleChange = (key, e) => {
    if (e.target.value.length) {
      if (
        e.target.value[e.target.value.length - 1] ===
        config.hardCoded.allSelection
      ) {
        let segArray = [config.hardCoded.allSelection];
        // setUserInputs(key, segArray);
        globalParamsOnChange(key, segArray);
      } else {
        let segArray = e.target.value.filter(
          (item) => item !== config.hardCoded.allSelection
        );
        // setUserInputs(key, segArray);
        globalParamsOnChange(key, segArray);
      }
    } else {
      let segArray = [config.hardCoded.allSelection];
      // setUserInputs(key, segArray);
      globalParamsOnChange(key, segArray);
    }
  };

  //clean up function
  useEffect(() => {
    return () => {
      updateData("subBrand", { status: "loading", message: "", data: [] });
      updateData("subSegment", { status: "loading", message: "", data: [] });
      updateData("segments", { status: "loading", message: "", data: [] });
    };
  }, []);

  return (
    <>
      {disableFlag ? (
        <>
          <div
            className={`input-item ${disableFlag ? "disabled-pointer" : ""}`}
          >
            <label>Select Country</label>
            <input
              type="text"
              id="country-width"
              name="country"
              // value={userInputs["country"]}
              value={globalParamsObj["country"]}
              onChange={(e) => {
                // setUserInputs("country", e.target.value);
                globalParamsOnChange("country", e.target.value);
              }}
              className="country-brand-width disabled-input"
              style={{ width: "100% !important" }}
            />
          </div>
          {(!userInputs?.is_config_manual || userInputs.brand) && (
            <div
              className={`input-item ${disableFlag ? "disabled-pointer" : ""}`}
            >
              <label>Select Brand</label>
              <input
                type="text"
                id="brand-width"
                name="brand"
                // value={userInputs["brand"]}
                value={globalParamsObj["brand"]}
                onChange={(e) => {
                  // setUserInputs("brand", e.target.value);
                  globalParamsOnChange("brand", e.target.value);
                }}
                className="country-brand-width disabled-input"
              />
            </div>
          )}
        </>
      ) : (
        <SingleTreeSelect
          data={addAllNodeAndSortSingleTree(
            data.filter((obj) => obj.is_geo_targeting_country !== true),
            {
              segment: false,
            }
          )}
          userInputs={userInputs}
          setUserInputs={setUserInputs}
          disableFlag={disableFlag}
          globalParamsOnChange={globalParamsOnChange}
          globalParamsObj={globalParamsObj}
        />
      )}
    </>
  );
};

CountryBrandSegmentHierarchy.propTypes = {
  match: PropTypes.object,
  user: PropTypes.object,
  segment: PropTypes.array,
  updateData: PropTypes.func,
  updateSelections: PropTypes.func,
};

const mapStateToProps = (state) => ({
  user: state.user,
  segments: state.data.segments,
  subBrand: state.data.subBrand,
  subSegment: state.data.subSegment,
  allData: state.data,
});

const mapDispatchToProps = {
  updateData,
  updateSelections, //actions are being dispatched using these functions,
  updateUserInfo,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CountryBrandSegmentHierarchy);

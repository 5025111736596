// Import required libraries
import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Checkbox from "@mui/material/Checkbox";
import "../index.css";

/**
 * [SimpleCheckbox description]
 * @param {[string]} theme [The theme of the component - default: light]
 * @param {[string]} styleVariant [Used to represent colored interface elements for a user - default: primary]
 * @param {[any]} ...props [Other valid props for MUI Checkbox]
 * @returns {[object]} [Returns the Checkbox component]
 */
const SimpleCheckbox = (props) => {
  const { theme = "light", styleVariant = "primary", ...remaining } = props;

  const StyledCheckbox = withStyles(() => ({
    root: {
      color: `var(--${styleVariant}-checkbox-${theme}) !important`,
      "& .MuiSvgIcon-root": {
        width: "var(--checkbox-element-size)",
        height: "var(--checkbox-element-size)",
      },
      "&:hover": {
        backgroundColor: "transparent !important",
      },
    },
  }))(Checkbox);

  return <StyledCheckbox disableRipple {...remaining} />;
};

SimpleCheckbox.propTypes = {
  theme: PropTypes.oneOf(["light", "dark"]),
  styleVariant: PropTypes.oneOf(["primary", "secondary"]),
};

export default SimpleCheckbox;

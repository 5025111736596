// Import required libraries
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
// import Button from "@material-ui/core/Button";
import { Button } from "../../uiTheme";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";

// Import utils
import {
  convertDateToFormatForCmu,
  makeDefaultResponseJson,
  shortFormAE,
} from "../../utils/utils";

// Import action creators
import {
  updateAlertInfo,
  updateUserInfo,
  updateSelections,
  updateDialogInfo,
  reloadRunData,
  refreshData,
  reloadConfigs,
} from "../../redux/actions";
import { config } from "../../config/config";

// Import styles
import "./CloneConfiguration.scss";

const dialogUseStyles = makeStyles((theme) => ({
  dialog: {
    fontFamily: "Hind Siliguri",
    fontSize: 14,
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "850px",
      //minHeight: "300px",
      height: "auto",
      maxHeight: "700px",
    },
  },
  contained: {
    color: "white",
    backgroundColor: "#46596A",
    marginRight: 10,
    borderRadius: "5px",
    padding: 15,
    fontSize: 14,
    height: 40,
    minWidth: 100,
    textTransform: "capitalize",
    fontFamily: "Hind Siliguri",
    border: "1.5px solid #46596A",
    boxSizing: "border-box",
    "&:hover": {
      backgroundColor: "#46596A",
    },
  },
  doneButton: {
    color: "white",
    backgroundColor: "#46596a",
    marginRight: 4,
    borderRadius: "4px",
    padding: 10,
    fontSize: 11,
    height: 23,
    minWidth: 15,
    textTransform: "capitalize",
    fontFamily: "Hind Siliguri",
    border: "1px solid #46596a",
    boxSizing: "border-box",
    "&:hover": {
      backgroundColor: "#46596a",
    },
  },
  outlined: {
    borderColor: "#46596a",
    backgroundColor: "transparent",
    color: "#46596a",
    marginRight: 4,
    fontSize: 11,
    padding: 10,
    height: 23,
    minWidth: 15,
    textTransform: "capitalize",
    fontFamily: "Hind Siliguri",
    "&:hover": {
      backgroundColor: "transparent",
      boxShadow: "none",
    },
  },
  cancelBtn: {
    color: "#46596A",
    backgroundColor: "#FFF",
    marginRight: 10,
    borderRadius: "3px",
    padding: 15,
    fontSize: 14,
    height: 40,
    minWidth: 100,
    textTransform: "capitalize",
    fontFamily: "Hind Siliguri",
    border: "1.5px solid #46596A",
    boxSizing: "border-box",
    "&:hover": {
      backgroundColor: "#46596A",
      color: "#FFF",
    },
  },
  typographyStyle: {
    "& .MuiChip-root": {
      fontFamily: "Hind Siliguri",
      fontSize: 12,
    },
    "& .MuiInput-underline:after": {
      borderBottom: 0,
    },
    "& .MuiInput-underline:before": {
      borderBottom: 0,
      transition: "none",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: "1px solid #dedede !important",
    },
    "& .Mui-focused .MuiInput-underline": {
      borderBottom: 0,
    },
    "& .MuiAutocomplete-option": {
      fontFamily: "Hind Siliguri",
      fontSize: 12,
    },
    "& .MuiAutocomplete-paper": {
      fontFamily: "Hind Siliguri",
      fontSize: 6,
    },
    "& .MuiInputBase-input": {
      fontFamily: "Hind Siliguri",
      fontSize: 12,
      color: "#46596a",
    },
    "& .MuiOutlinedInput-root": {
      paddingTop: "0px !important",
      paddingBottom: "0px !important",
    },
  },
}));

function CloneConfiguration(props) {
  let {
    user,
    match,
    name,
    config: configUserInputs,
    handleDialogClose,
    reloadConfigs,
    updateAlertInfo,
    all_datasets,
  } = props;
  const dialogClass = dialogUseStyles();
  const history = useHistory();
  const [newConfigName, setNewConfigName] = useState("");
  const configNameFixed = configUserInputs.selections.config.brand
    ? `${config.hardCoded.aeAppName}-${configUserInputs.selections.config.country}-${configUserInputs.selections.config.brand}-`
    : `${config.hardCoded.aeAppName}-${configUserInputs.selections.config.country}-`;
  const [cmuObject, setCmuObject] = useState({
    status: false,
    message: "",
    datasets: [],
  });
  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    if (configUserInputs.old_pm) {
      setCmuObject({
        status: false,
        message:
          "This config contains GA360 dataset which is no longer applicable. Please select some other config to make a copy.",
        datasets: [],
      });
    } else {
      setCmuObject({
        status: "loading",
        message: "",
        datasets: [],
      });

      const enabledModel = configUserInputs.selections.models.find(
        (model) => model.run
      );

      let body = {
        country: configUserInputs.selections.config.country,
        brand: configUserInputs.selections.config.brand,
      };
      if (
        configUserInputs.selections.config.start_date &&
        configUserInputs.selections.config.end_date
      ) {
        body = {
          ...body,
          start_date: convertDateToFormatForCmu(
            configUserInputs.selections.config.start_date
          ),
          end_date: convertDateToFormatForCmu(
            configUserInputs.selections.config.end_date
          ),
        };
      } else {
        body = {
          ...body,
          past_weeks: +configUserInputs.selections.config.historic_week_data,
        };
      }
      let url = config.api.checkCmu;
      let cmuObj;
      // updateAlertInfo({
      //   open: true,
      //   message: "Checking The Data availability for config's CMU....",
      //   severity: "error",
      // });
      // handleClose();
      fetch(url, {
        signal,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      })
        .then((response) => response.json())
        .then((json) => {
          cmuObj = json.data;
          if (enabledModel.id === config.hardCoded.nlpId) {
            if (cmuObj.nlp) {
              if (enabledModel.config.semrush) {
                if (!cmuObj.semrush) {
                  setCmuObject({
                    status: cmuObj.semrush,
                    message: config.messages.semrushNA,
                  });
                } else {
                  setCmuObject({ status: cmuObj.nlp, message: "" });
                }
              } else {
                setCmuObject({ status: cmuObj.nlp, message: "" });
              }
            } else {
              setCmuObject({
                status: cmuObj.nlp,
                message: config.messages.invalid_nlp,
              });
              // updateAlertInfo({
              //   open: true,
              //   message: config.messages.invalid_nlp,
              //   severity: "error",
              // });
            }
          } else {
            if (cmuObj.cc_pm && cmuObj?.datasets?.length) {
              setCmuObject({
                status: cmuObj.cc_pm,
                message: "",
                datasets: cmuObj.datasets,
              });
            } else {
              setCmuObject({
                status: cmuObj.cc_pm,
                message: config.messages.invalid_cc_pm,
              });
              // updateAlertInfo({
              //   open: true,
              //   message: config.messages.invalid_cc_pm,
              //   severity: "error",
              // });
            }
          }
        });
    }
    return () => {
      controller.abort();
    };
  }, []);
  const handleCloneConfig = () => {
    handleDialogClose();
    if (newConfigName) {
      // filtering the datasets in the enabled model by comparing them to the cmu datasets
      let datasetName = all_datasets;
      if (cmuObject?.datasets?.length) {
        datasetName = datasetName.filter((dataset) =>
          cmuObject?.datasets?.includes(dataset.id)
        );
      }
      let newState = configUserInputs.selections.models.map((model) => {
        if (model.run) {
          if (model.id !== config.hardCoded.nlpId) {
            let filteredDataSources = model.data_sources.filter((dataset) => {
              const datasetIds = datasetName.map((el) => el.id);
              if (datasetIds.includes(dataset.id)) {
                return true;
              } else {
                return false;
              }
            });
            if (!filteredDataSources.length) {
              const { id, kpi_list, name, pipeline_dataset_id, source, type } =
                datasetName[0];
              const firstDataset = {
                id,
                kpi_list,
                name,
                pipeline_dataset_id,
                source,
                type,
              };
              filteredDataSources = [firstDataset];
            }
            return {
              ...model,
              data_sources: filteredDataSources,
            };
          } else {
            return model;
          }
        } else {
          return model;
        }
      });
      updateAlertInfo({
        open: true,
        message: config.messages.RequestingToCloneConfig,
        severity: "info",
      });
      let body = {
        app_id: configUserInputs.selections.config.app_id,
        app_name: configUserInputs.selections.config.app_name,
        metadata: {
          isStarred: false,
          tags: configUserInputs?.tags,
        },
        name: `${configNameFixed}${newConfigName.trim()}`,
        selections: {
          ...configUserInputs.selections,
          models: newState,
        },
        operation: "clone",
        old_config_id: configUserInputs.id,
      };
      let url = config.api.configUrl;
      let status;
      fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      })
        .then((response) => {
          console.groupCollapsed("requesting", url);
          console.log("REPSONSE -> ", response);
          status = response.status;
          return response.clone().json();
        })
        .then((json) => {
          console.log("JSON -> ", json);
          console.groupEnd();
          if (status === 200) {
            updateAlertInfo({
              open: true,
              message: match.params.configName
                ? config.messages.configSuccessUpdate
                : config.messages.configSuccessSave,
              severity: "success",
            });
            reloadConfigs();
            history.push(
              match.params.tab === "addnewconfig"
                ? `/home/myconfigurations/`
                : `/home/${match.params.tab}/`
            );
          } else {
            updateAlertInfo({
              open: true,
              message: json.statusMessage,
              severity: "error",
            });
          }
        });
    }
  };

  return (
    <>
      <div className="clone-dialog-container">
        {cmuObject.status === "loading" && (
          <p className="dialog-config-name">
            Checking The Data availability for config's CMU....
          </p>
        )}
        {!cmuObject.status && (
          <p className="dialog-config-name">{cmuObject.message}</p>
        )}
        <p>
          Clone{" "}
          <span className="dialog-config-name">
            {shortFormAE(configUserInputs.name)}
          </span>{" "}
          Configuration
        </p>
        <div className="content">
          <p className="config-name" title={configNameFixed}>
            {shortFormAE(configNameFixed)}
          </p>
          <div className="config-name-input-container">
            <input
              type="text"
              id="clone-config"
              name="clone-config"
              value={newConfigName}
              className="input-label"
              onChange={(e) => {
                setNewConfigName(e.target.value);
              }}
              placeholder="Please enter new configuration name"
            />
            <i
              className={`material-icons-outlined cancel-icon ${
                newConfigName ? "visible" : ""
              }`}
              onClick={() => {
                setNewConfigName("");
              }}
            >
              clear
            </i>
          </div>
        </div>
        <div className="popup-btn">
          <Button
            variant="outlined"
            // className={dialogClass.outlined}
            onClick={handleDialogClose}
            style={{
              height: "23px",
              padding: "10px",
              fontSize: "11px",
              minWidth: "15px",
              boxSizing: "border-box",
              marginRight: "4px",
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            // className={dialogClass.doneButton}
            onClick={() => {
              handleCloneConfig();
            }}
            disabled={!cmuObject.status || cmuObject.status === "loading"}
            style={{
              height: "23px",
              padding: "10px",
              fontSize: "11px",
              minWidth: "15px",
              boxSizing: "border-box",
              marginRight: "4px",
              backgroundColor:
                cmuObject.status && cmuObject.status !== "loading"
                  ? "#46596a"
                  : "rgba(0,0,0,0.4)",
            }}
          >
            Done
          </Button>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  saved_configurations: state.data.saved_configurations,
  all_datasets: state.data.datasets,
});

const mapDispatchToProps = {
  updateAlertInfo,
  updateUserInfo,
  updateSelections,
  updateDialogInfo,
  reloadRunData,
  refreshData,
  reloadConfigs,
};

export default connect(mapStateToProps, mapDispatchToProps)(CloneConfiguration);

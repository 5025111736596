import React from "react";
import PropTypes from "prop-types";
import Avatar from "@mui/material/Avatar";
import "../index.css";

/**
 * [SimpleAvatar description]
 * @param {[string]} theme [The theme of the component - default: light]
 * @param {[string]} size  [Size of the component - default: 2em]
 * @param {[string]} styleVariant [Used to represent colored interface elements for a user - default: primary]
 * @param {[any]} ...props [Other valid props for MUI Avatar]
 * @returns {[object]} [Returns the Avatar component]
 */
const SimpleAvatar = (props) => {
  const {
    children,
    theme = "light",
    size = "2em",
    styleVariant = "primary",
    style = {},
    ...remaining
  } = props;
  const finalStyle = {
    background: `var(--avatar-${styleVariant}-bgcolor-${theme})`,
    color: `var(--avatar-${styleVariant}-textcolor-${theme})`,
    fontFamily: "var(--fontFamily)",
    height: size,
    width: size,
    ...style,
  };
  return (
    <Avatar style={finalStyle} {...remaining}>
      {children}
    </Avatar>
  );
};

SimpleAvatar.propTypes = {
  children: PropTypes.node,
  theme: PropTypes.oneOf(["light", "dark"]),
  size: PropTypes.string,
  styleVariant: PropTypes.oneOf(["primary", "secondary"]),
};

export default SimpleAvatar;

//Import required libraies
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

//Import custom components
import InputTextValidation from "./InputTextValidation";
import InterfaceName from "./InterfaceName";
import CommunityType from "./CommunityType";

//Import utils
import { config } from "../../../../config/config";

//Import actions
import { updateUserInfo } from "../../../../redux/actions";

//Import styles
import "./TargetCommunity.scss";

const getInputsData = (targetPayload, name, userInputs) => {
  if (name === "community_id_list") {
    let payLoadData = targetPayload?.data[`${name}`];
    return payLoadData.includes("ALL") ? "ALL" : "specific";
  }
  if (name === "interface_name" && userInputs.configType !== "add") {
    return targetPayload?.data?.[`${name}`];
  }
  return targetPayload?.data[`${name}`];
};

const getModelParams = (data, name) => {
  let models = data.models.find((model) => {
    return model.id === config.hardCoded.communityClusteringId;
  });
  let specific_local_param = models?.local_parameters?.find((local_param) => {
    return local_param.id === name;
  });
  return specific_local_param?.data;
};

function TargetCommunityUploadAudienceForScheduleRuns(props) {
  let { onChange, userInputs, data, selectedModel, disableFlag } = props;
  let initialSelectionsFromConfigUserInputs = userInputs.models.find(
    (model) => model.id === config.hardCoded.communityClusteringId
  );
  const [targetData, setTargetData] = useState({
    payload: {
      status: "success",
      message: "",
      data: initialSelectionsFromConfigUserInputs.config,
    },
  });

  useEffect(() => {
    if (targetData.payload.status === "success") {
      onChange(
        "community_id_list",
        targetData?.payload.data["community_id_list"]
      );
      onChange(
        "community_id_type",
        getInputsData(targetData.payload, "community_id_list", userInputs)
      );
      onChange(
        "interface_name",
        getInputsData(targetData.payload, "interface_name", userInputs)
      );
      onChange(
        "audience_name",
        getInputsData(targetData.payload, "audience_name", userInputs)
      );
      onChange(
        "run_id",
        getInputsData(targetData.payload, "run_id", userInputs)
      );
    }
  }, [targetData.payload]);

  return (
    <div className="target-runs-conatiner">
      {targetData.payload.status === "success" && (
        <>
          <>
            <div
              className={`datasets-selections-local-parameters-row ${
                disableFlag ? "disabled-pointer" : ""
              }`}
            >
              <InputTextValidation
                id="audience_name"
                name="Output Name"
                enable={false}
                onChange={onChange}
                value={getInputsData(
                  targetData.payload,
                  "audience_name",
                  userInputs
                )}
                disabledForUploadAudience={true}
              />
            </div>
            <div
              className={`datasets-selections-local-parameters-row ${
                disableFlag ? "disabled-pointer" : ""
              }`}
            >
              <InterfaceName
                data={getModelParams(data, "interface_name")}
                id="interface_name"
                name="Activation Platform"
                enable={false}
                onChange={onChange}
                value={getInputsData(
                  targetData.payload,
                  "interface_name",
                  userInputs
                )}
                disabledForUploadAudience={true}
                selectedModel={selectedModel}
              />
            </div>
            <div
              className={`datasets-selections-local-parameters-row ${
                disableFlag ? "disabled-pointer" : ""
              }`}
            >
              <CommunityType
                data={getModelParams(data, "community_id_type")}
                name="Community Ids"
                id="community_id_type"
                value={getInputsData(
                  targetData.payload,
                  "community_id_list",
                  userInputs
                )}
                enable={false}
                onChange={onChange}
                userInputs={userInputs}
                disabledForUploadAudience={true}
                selectedModel={selectedModel}
              />
            </div>
          </>
        </>
      )}
    </div>
  );
}

TargetCommunityUploadAudienceForScheduleRuns.propTypes = {};

const mapStateToProps = (state) => ({
  user: state.user,
  data: state.data,
});

const mapDispatchToProps = { updateUserInfo };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TargetCommunityUploadAudienceForScheduleRuns);

//Import required libraies
import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import { Dialog } from "../../../../uiTheme";
import { makeStyles } from "@material-ui/core/styles";

//Import utils
import { config } from "../../../../config/config";
import { makeDefaultResponseJson } from "../../../../utils/utils";

// Import custom components

//Import Styles
import "./SegmentThreshhold.scss";

const useStyles = makeStyles(() => ({
  dialog: {
    fontFamily: "Hind Siliguri",
    fontSize: 14,
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "900px",
      width: "900px",
      //minHeight: "300px",
      height: "auto",
      maxHeight: "775px",
    },
  },
  disabled: {
    borderColor: "#46596a",
    marginRight: 4,
    fontSize: 11,
    backgroundColor: "#e1e1e1",
    padding: "0px 5px",
    height: "26px",
    minWidth: "auto",
    color: "#798087",
    fontFamily: "Hind Siliguri",
    fontSize: "12px",
    fontWeight: "bold",
    textTransform: "capitalize",
    pointerEvents: "none",
  },
  outlined: {
    borderColor: "#46596a",
    marginRight: 4,
    fontSize: 11,
    //padding: 10,
    padding: "0px 5px",
    height: "26px",
    minWidth: "auto",
    color: "#46596a",
    fontFamily: "Hind Siliguri",
    fontSize: "12px",
    fontWeight: "bold",
    textTransform: "capitalize",
  },
  contained: {
    color: "white",
    backgroundColor: "#46596a",
    marginRight: 4,
    padding: "0px 5px",
    borderRadius: "3px",
    paddingTop: "2px",
    fontSize: 11,
    height: "26px",
    minWidth: 15,
    textTransform: "capitalize",
    fontFamily: "Hind Siliguri",
    "&:hover": {
      backgroundColor: "#46596a",
    },
  },
}));

function SegmentThreshhold(props) {
  let {
    name,
    data,
    value,
    modelConfigId,
    onChange,
    enable,
    show,
    userInputs,
    customClassName,
    id,
    updateAlertInfo,
    disabledForUploadAudience,
    className,
  } = props;
  let initialData = {};
  const [runAvailableFlag, setRunAvailableFlag] = useState(false);
  useEffect(() => {
    if (userInputs?.histogramRuns?.length) {
      setRunAvailableFlag(true);
    }
  }, [userInputs?.histogramRuns?.length]);
  let segmentLimit = userInputs.models.find(
    (model) => model.id === config.hardCoded.propensityId
  ).config;
  if (segmentLimit.propensity_modeling_run_type === "target") {
    segmentLimit.segment_thresholds.forEach((element) => {
      if (element.name === "hot")
        initialData[element.name] = parseFloat(element["maxValue"]) || 0.001;
      else initialData[element.name] = parseFloat(element["maxValue"]) || 0.0;
    });
  } else {
    value.forEach((element) => {
      if (element.name === "hot")
        initialData[element.name] = parseFloat(element["maxValue"]) || 0.001;
      else initialData[element.name] = parseFloat(element["maxValue"]) || 0.0;
    });
  }
  const [currData, setCurrData] = useState(initialData);
  const [segmentLocalData, setSegmentLocalData] = useState(initialData);
  const [historicData, setHistoricData] = useState("");
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const [area, setArea] = useState({ width: 688, height: 860 }); //this width and height is based on 4:5 ratio
  let configRunId = segmentLimit.run_id;
  const changeArea = (param, value = 50) => {
    let height;
    let width;
    if (param === "+") {
      if (area.height < 2500 && area.width < 2000) {
        height = area.height + value;
        width = (area.height + value) * (4 / 5);
        setArea({ width: width, height: height });
      }
    }
    if (param === "-") {
      if (area.height > 860 && area.width > 688) {
        height = area.height - value;
        width = (area.height - value) * (4 / 5);
        setArea({ width: width, height: height });
      }
    }
  };
  useEffect(() => {
    setLoading(false);
  }, []);

  //Fetching histogram data when run id changes
  useEffect(() => {
    if (
      configRunId &&
      runAvailableFlag &&
      segmentLimit.propensity_modeling_run_type !== "upload_audience"
    ) {
      let url = `${config.api.histogramUrl}`;
      let status;
      let body = {
        run_id: configRunId,
      };
      fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      })
        .then((response) => {
          console.groupCollapsed("requesting", url);
          console.log("REPSONSE -> ", response);
          status = response.status;
          return response.clone().json();
        })
        .then((json) => {
          console.log("JSON -> ", json);
          console.groupEnd();
          if (status === 200) {
            setHistoricData(json.data.location);
          } else {
            updateAlertInfo({
              open: true,
              message: json.statusMessage,
              severity: "info",
            });
          }
        });
    }
  }, [configRunId, runAvailableFlag]);

  useEffect(() => {
    if (!loading) {
      segmentLimit.segment_thresholds.forEach((element) => {
        if (!segmentLimit[element.name])
          onChange([element.name], element["maxValue"]);
      });
    }
  }, [loading]);

  useEffect(() => {
    setSegmentLocalData(currData);
    onChange("segment_thresholds", [
      {
        name: "hot",
        maxValue: currData.hot ? parseFloat(currData.hot) : 0.001,
      },
      {
        name: "cold",
        maxValue: currData.cold ? parseFloat(currData.cold) : 0,
      },
      {
        name: "warm",
        maxValue: currData.warm ? parseFloat(currData.warm) : 0,
      },
    ]);
  }, [currData]);

  const onChangeCurr = (key, value) => {
    setCurrData({ ...currData, [key]: value });
    onChange(key, value);
  };

  // console.groupCollapsed("STATE CHANGED");
  // console.log("DATA -> ", data);
  // console.log("segment limit -> ", segmentLimit);
  // console.log("VALUE -> ", value);
  // console.log("Curr data", currData);
  // console.log("segmentLocalData", segmentLocalData);
  // console.groupEnd();

  const handleOpen = () => {
    setSegmentLocalData(currData);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleApplyChanges = () => {
    for (let key in segmentLocalData) {
      onChange(key, segmentLocalData[key]);
    }
    setCurrData(segmentLocalData);
  };

  return (
    <div className={`segment-threshhold-container ${className && className}`}>
      <div className="segment-threshhold-input">
        <p className="bold">{name}</p>
        <div
          className={`multiset-popup-row segment-threshold-propensity ${
            customClassName ? customClassName : ""
          } ${modelConfigId}`}
        >
          {data.map((item) => (
            <div
              className={`input-item ${
                customClassName ? customClassName : ""
              } ${item.id}`}
              key={item.id}
            >
              <p title={item.name}>{item.name}:</p>
              <input
                type="number"
                onWheel={() => document.activeElement.blur()}
                disabled={!enable || disabledForUploadAudience}
                value={currData[item.id]}
                min={item.minValue}
                max={item.maxValue}
                step={0.001}
                onChange={(e) => {
                  onChangeCurr(item.id, parseFloat(e.target.value));
                }}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default SegmentThreshhold;

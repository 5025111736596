import config_local from "../../configuration/local_config.json";

/*-------------------------Actual APIs-------------------------*/
const reckitt_domain = config_local.LOCAL ? config_local.reckitt_domain : "";
const backendApi = "/api";
const signInUrl = `/auth/sign_in?post_sign_in_url=https%3A%2F%2F`;
const signOutUrl = `/auth/sign_out`;
const authUrl = `${reckitt_domain}${backendApi}/isauthenticated`;
const userInfoUrl = `${reckitt_domain}${backendApi}/userinfo?post_sign_in_url=https%3A%2F%2F`;
const authoriseUrl = `${reckitt_domain}${backendApi}/isauthorised?post_sign_in_url=https%3A%2F%2F`;
const appUserRoleUrl = `${reckitt_domain}${backendApi}/appuserrole`;
const appsUrl = `${backendApi}/apps`;
const configsUrl = `${backendApi}/configs`;
const countryUrl = `${backendApi}/countries`;
const brandUrl = `${backendApi}/brands`;
const segmentUrl = `${backendApi}/segments`;
const globalParamsUrl = `${backendApi}/globalParams`;
const modelsUrl = `${backendApi}/models`;
const datasetsUrl = `${backendApi}/datasets`;
const configUrl = `${backendApi}/config`;
// const configRunUrl = `${backendApi}/config/run`;
const configRunUrl = `${backendApi}/v2/config/run`;
const runUrl = `${backendApi}/runs`;
const notificationUrl = `${backendApi}/notifications`;
const downloadRunUrl = `${backendApi}/run/download`;
const audienceNameTargetRunUrl = `${backendApi}/audienceName`;
const veritasAppUrl = "https://veritas.rbone.net/";
const histogramUrl = `${backendApi}/run/histogram`;
const appAccessDetailsUrl = `${backendApi}/appAccessDetails`;
const sharedConfigsUrl = `${backendApi}/sharedConfigs`;
const listUsersUrl = `${backendApi}/users`;
const shareConfigUrl = `${backendApi}/shareConfig`;
const appResourcesUrl = `${backendApi}/appResources`;
const datasetDetailsUrl = `${backendApi}/datasetDetails`;
const submitFormUrl = `${backendApi}/triggerEmail`;
const getEventDetailsForPropensityUrl = `${backendApi}/propensityEventDetails`;
const userUrl = `${backendApi}/admin/user`;
const groupUrl = `${backendApi}/admin/group`;
const allUsersUrl = `${backendApi}/admin/globalUsers`;
const listAdminUsersUrl = `${backendApi}/admin/users`;
const listFeatureList = `${backendApi}/admin/appFeatures`;
const scheduleConfigurl = `${backendApi}/scheduleJobs`;
const bookmarkUrl = `${backendApi}/bookmark`;
const getTagsUrl = `${backendApi}/getTags`;
const uploadValidateFilesUrl = `${backendApi}/uploadValidate`;
const downloadUrl = `${backendApi}/download`;
const controlRegionUrl = `${backendApi}/controlRegion`;
const sharedRunsUrl = `${backendApi}/sharedRuns`;
const shareRunUrl = `${backendApi}/shareRun`;
const saveConfigForSharedRun = `${backendApi}/sharedRunConfig`;
const editTags = `${backendApi}/tags`;
const configAndSchedule = `${backendApi}/configAndSchedule`;
const dataQualityReports = `${backendApi}/dataQualityReports`;
const geoTargeting = `${backendApi}/geoTargeting`;
const geoTargetingDownload = `${backendApi}/geoTargeting/download`;
const countryWiseBrands = `${backendApi}/countryWiseBrands`;
const nlpReport = `${backendApi}/nlpReport`;
const dataStudioReport = `${backendApi}/dataStudioReport`;
const validateSignOut = `${backendApi}/validateSignOut`;
const v2RunsUrl = `${backendApi}/v2/runs`;
const checkCmu = `${backendApi}/check-cmu`;
const cmuFile = `${backendApi}/cmu-file`;
const optimizedSchedules = `${backendApi}/fetch_optimized_schedules`;
const userLoginInfo = `${backendApi}/user_login_info`;
const userMetric = `${backendApi}/user-metrics`;
const userMetricReport = `${backendApi}/user-metrics-report`;
const downloadResourcesUrl = `${backendApi}/downloadResources`;
// "https://run.mocky.io/v3/4aad4c8b-0250-4188-a434-dfa619c9fbf2";

const messages = {
  UnauthorisedMessage: "User does not have access to this Application",
  authenticatingUserMessage: "Authenticating user",
  noVerticals: "No verticals available",
  noApps: "No applications available",
  noAppsAccess: "You no longer have access to this app",
  noDatasources: "No datasets available",
  configSuccessSave: "Configuration saved successfully.",
  configSuccessUpdate: "Configuration updated successfully.",
  configSuccessDelete: "Configuration deleted successfully",
  configDeleteRequest: "Requesting to delete configuration",
  configSuccessRun: "Configuration saved and run successfully",
  runActive: "Configuration running...",
  noRuns: "No runs available under this category.",
  runDeleteRequest: "Requesting run delete...",
  runDeleteSuccess: "Run deleted successfully",
  noConfigs: "No configurations available for your selections.",
  noRecentConfigs: "No Recent configurations available",
  noAdminUsersFound: "No Users found for your selections.",
  noAdminGroupsFound: "No Groups found for your selections.",
  nodatasetParameters: "No Parameters available for your selections.",
  noOptions: "No options available",
  noDocuments: "No Document found",
  noRuns: "No runs available under this category.",
  noNames: "No names available",
  noLevels: "No levels available",
  noDrivers: "No drivers available",
  confirmDelete: "Are you sure you want to delete?",
  runFailed: "Sorry, your job was not successful.",
  runQueued: "Your job is in the queue.",
  runRunning: "Your job is running.",
  runScheduledRunning: "Your scheduled job is running.",
  runSuccess: "Your job was successful!",
  runSuccessInfo:
    "You can download your output files by clicking on the Download button at top right of this pane.",
  noDatasets: "No datasets available for your selections.",
  noData: "No data available",
  invalidBudgetOnlyRun:
    "Cannot add Regression Budget, budget only run without ",
  oneModelSelectedError: "At least one model should be enabled",
  multipleModelSelectedError: "Please enable only one model for Schedule runs.",
  multipleModelSelectedOnDemandError:
    "Please enable only one model for On Demand runs.",
  invalidConfigName:
    "Configuration names can only have letters, numbers, hyphens and underscores.",
  validConfigAdd: "Valid configuration. Requesting inclusion...",
  validConfigUpdate: "Valid configuration. Requesting update...",
  nodataForCategory: "No datasets available",
  regressionBudgetCombinations:
    "Cannot add Regression Budget without combination set selected.",
  volumeFieldAvailableMessage:
    "Please select volume field in Diff in Diff model.",
  salesUpliftTestGroupParameters:
    "Cannot add Sales Uplift without test group parameters set selected.",
  salesUpliftTestRegionsTestGroupParametersMismatch:
    "Test Region sets and Test Group Parameter sets don’t match in Sales Uplift Model.",
  propensityModelingActionNamesParameters:
    "Cannot add Propensity Modeling without Action Names parameters set selected.",
  autoMlCampaign: "Cannot add AutoML without all campaign selected.",
  noCategories: "No categories",
  noPeriodicity: "No periodicity",
  historicWeekValidation:
    "Historic Week cannot be empty, 0, negative, decimal or greater than 300",
  dateSelectedValidation:
    "Start Date or End Date in date range cannot be empty",
  invalidDateSelection:
    "Global End date should be greater than Global Start date.",
  weekValidation: "Please enter weeks",
  emptyConfigName: "Please enter the Configuration Name",
  testControlNonEmpty: "Test & Control regions need to be non-empty",
  combinationError: "Please enter valid inputs.",
  productionBannerMessage:
    "We will soon be decommissioning all user data from the UAT environment. Please access the Production environment by visiting",
  segmentHotWarmMessage:
    "Hot segment can't be equal or greater than Warm segment",
  segmentWarmColdMessage:
    "Warm segment can't be equal or greater than Cold segment",
  noRegionsAvailable: "No regions selected",
  requestSubmitMessage:
    "Thank you for your request! Our team will reach out to you shortly for further nformation. Have a great day ahead.",
  tryAgainMsg: "Some error occured. Please try again!",
  disclaimerMessage:
    "Disclaimer: Start, End and Last Updated Date are based on country only. Brand granularity is not considered.",
  salesRangeMessage:
    "0 -100 percentile based on sales KPI where 0 represents region(s) with lowest sales and 100 represents region(s) with highest sales.",
  somethingWentWrong: "Something went wrong, please contact support.",
  pilotDateValidation:
    "Pilot Start Date or Pilot End Date in date in AutoML cannot be empty",
  pilotDatesInRangevalidation:
    "Pilot Dates for AutoML should lie between the selected global date range.",
  pilotStartDateEndDateValidation:
    "Pilot End date should be greater than Pilot Start date in AutoML.",
  pilotDateValidationAutoMlBudget:
    "Pilot Start Date or Pilot End Date in date in AutoML Budget cannot be empty",
  pilotDatesInRangevalidationAutoMlBudget:
    "Pilot Dates for AutoML Budget should lie after the selected global end date.",
  pilotStartDateEndDateValidationAutoMlBudget:
    "Pilot End date should be greater than Pilot Start date in AutoML Budget.",
  configSharingSuccess:
    "Configuration shared with the selected users successfully.",
  configSharingFailure: "Configuration sharing Failed. Please try again.",
  runSharingSuccess: "Run shared with the selected users successfully.",
  runSharingFailure: "Run sharing Failed. Please try again.",
  noOperationForShareConfig: "No operation selected. Please select.",
  RequestingToCloneConfig: "Requesting to add a new configuration...",
  RequestingToShareConfig: "Requesting to share configuration...",
  RequestingToShareRun: "Requesting to share run",
  RequestingToSaveConfigForRun:
    "Requesting to create configuration for this run",
  configCreatedSuccessfullyForRun: "Configuration created successfully",
  configCreationFailedForNestedRunForAE:
    "Configuration creation is not supported for target or upload audience run",
  loginPrimaryText: "AI Deployment Environment",
  loginSecondaryText_1: "One place to run your AI models across functions.",
  loginSecondaryText_2: "Log in to find out more!",
  uploadFileSizeMessage: "Allowed maximum size is 10MB.",
  uploadFileSizeMessageForGeoTargeting: "Allowed maximum size is 20MB.",
  allowedTagsLimitMessage: "Only 5 tags are allowed.",
  duplicateTagFoundMessage: "Tag is already added.",
  fileNameSpacesMessage:
    "The filename can not contain whitespace. Please rename the file.",
  statusMessage400:
    "Note: Multiple Categories With Same Category Name Are Found",
  statusMessage400a: "Note: Duplicate Categories Are Found",
  duplicateName:
    "Configuration Name Already Exists, Please Use a Different Name",
  numTopicInfo:
    "Optimal will select the optimal number of topics by running the model for a range of different number of topics and selecting the best one out of them. Note that this will extend the model running time.",
  upload_audience_info:
    "Select this option to upload model outputs to platform",
  invalid_cc_pm:
    "Data is not available for CMU for the selected date range. Please contact support by raising a ticket",
  invalid_nlp:
    "NLP model is not enabled for selected CMU. Please contact support by raising a ticket ",
  semrush: "Please note that using Semrush data has an additional cost",
  semrushNA: `Data is not available for CMU to run the model with Semrush data for the selected date range.
    Please run it without Semrush data or contact support by raising a ticket.`,
};

const hardCoded = {
  nativeAppsList: ["AUDIENCE ENGINE"],
  nativeAppIdList: ["AE"],
  aeAppId: "AE",
  aeAppName: "AUDIENCE ENGINE",
  runRunningId: "running",
  runPrevRunsId: "previous_runs",
  runQueuedId: "queued",
  runSuccess: "Success",
  runFailed: "Failed",
  runQueued: "Queued",
  runRunning: "Running",
  runScheduled: "Scheduled",
  downloadInputType: "input",
  downloadOutputType: "output",
  communityClusteringId: "COMMUNITY_CLUSTERING",
  propensityId: "PROPENSITY_MODELING",
  nlpId: "NLP_KEYWORD_ANALYSIS",
  initialRunId: "initial",
  targetRunId: "target",
  uploadAudienceRunId: "upload_audience",
  communityRunKey: "community_clustering_run_type",
  propensityRynKey: "propensity_modeling_run_type",
  specificId: "specific",
  configurableUiElementTypes: [
    "radio_button",
    "inputbox_number",
    "inputbox_text",
    "dropdown",
  ],
  historicWeek: "historic_week_data",
  dateRange: "date_range",
  datasetSalesType: "SALES",
  datasetNeilsenType: "CATEGORY",
  datasetMediaType: "MEDIA",
  radioDatasetList: ["SALES", "CATEGORY"],
  selectClearAllDatasetList: ["MEDIA", "MACRO"],
  combinationsId: "combinations",
  campaignsArrayId: "campaigns",
  combinationsSalesId: "test_group_parameters",
  actionNamesId: "action_names",
  communityIdListType: "community_id_type",
  interfaceNameId: "interface_name",
  salesUpliftSets: 15,
  regressionBudgetSets: 10,
  allSelection: "ALL",
  homeLocation: "https://admin-aide-dev.rbone.net",
  mappingStructure: {
    country: "name",
    brand: "id",
    archetype: "id",
  },
  localSource: "LOCAL",
  automlCustomDataset: ["SALES", "CATEGORY"],
  mandatoryTargetVariable: ["sales_value", "sales_units", "sales_volume"],
  adstockMappingForDisplay: ["sl_limit", "su_limit"],
  defaultVertical: "hygiene",
  mulipleRunType: [
    "propensity_modeling_run_type",
    "community_clustering_run_type",
  ],
  adminRole: "admin",
  viewRunsCategory: ["Running", "Queued", "Success", "Failed"],
  tabsForScheduleToggle: ["myconfigurations", "starred", "recent"],
  tabsForGa360: ["myconfigurations", "starred", "recent", "sharedwithme"],
  fileExtensions: ["csv", "xlsx"],
  ga360: "GA360",
  oldPMActionName: ["eventAction", "eventCategory", "eventLabel", "pagePath"],
  newPMActionName: ["eventValue", "eventName", "eventVariable", "pagePath"],
};

const api = {
  userInfoUrl,
  authoriseUrl,
  appUserRoleUrl,
  appsUrl,
  configsUrl,
  globalParamsUrl,
  modelsUrl,
  datasetsUrl,
  countryUrl,
  brandUrl,
  segmentUrl,
  configUrl,
  configRunUrl,
  authUrl,
  signInUrl,
  signOutUrl,
  runUrl,
  notificationUrl,
  audienceNameTargetRunUrl,
  downloadRunUrl,
  veritasAppUrl,
  histogramUrl,
  appAccessDetailsUrl,
  sharedConfigsUrl,
  listUsersUrl,
  shareConfigUrl,
  appResourcesUrl,
  datasetDetailsUrl,
  submitFormUrl,
  getEventDetailsForPropensityUrl,
  userUrl,
  groupUrl,
  allUsersUrl,
  listAdminUsersUrl,
  listFeatureList,
  scheduleConfigurl,
  bookmarkUrl,
  getTagsUrl,
  uploadValidateFilesUrl,
  downloadUrl,
  controlRegionUrl,
  sharedRunsUrl,
  shareRunUrl,
  saveConfigForSharedRun,
  editTags,
  configAndSchedule,
  dataQualityReports,
  geoTargeting,
  geoTargetingDownload,
  countryWiseBrands,
  nlpReport,
  dataStudioReport,
  validateSignOut,
  v2RunsUrl,
  checkCmu,
  cmuFile,
  optimizedSchedules,
  userLoginInfo,
  userMetric,
  userMetricReport,
  downloadResourcesUrl,
};

const authorizationMessages = {
  noAccessMessage: "You don't have access to this app. Please contact support.",
};

const config = {
  footerText: "Powered by RBOne",
  localStorageReduxStoreName: "rbAideReduxStore",
  campaignDupErrorNote: "Cannot add same campaign with multiple date range",
  campaignMinErrorNote: "Please select a min date",
  campaignMaxErrorNote: "Please select a max date",
  campaignSelectErrorNote: "Select a campaign",
  inputNumberValidationError: "Input number out of range",
  inputTextValidationError: "Please enter value",
  campaignStartEndDateError: "End date should be greater than Start date",
  salesRangeError: "Percentile Range must be less than 50",
  api,
  messages,
  hardCoded,
  autoHideDuration: 10000,
  fetchNotificationDuration: 300000,
  runDataInterval: 300000,
  runsLimit: 1000,
  pilotWeek: 2,
  configSuccessSaveMessage: "Configuration saved successfully.",
  configSuccessUpdateMessage: "Configuration updated successfully.",
  configSuccessDeleteMessage: "Configuration deleted successfully",
  configDeleteRequestMessage: "Requesting to delete configuration...",
  configSuccessRunMessage: "Configuration saved and run successfully",
  scheduleConfigRequestMessage:
    "Requesting the configuration to be scheduled...",
  scheduleConfigUpdateRequestMessage:
    "Requesting schedule configuration update...",
  scheduleConfigSuccessMessage: "Configuration scheduled successfully",
  scheduleConfigUpdateSuccess:
    "Schedule of the configuration updated successfully",
  scheduleDeleteRequestMessage:
    "Requesting to delete scheduled configuration...",
  scheduleSuccessDeleteMessage:
    "Schedule of the configuration deleted successfully",
  authorizationMessages,
};

export { config };

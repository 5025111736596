import React from "react";
import PropTypes from "prop-types";

//Import Custom Component
import UserDetails from "./user/UserDetails";
import LayoutTopSideBottom from "../../layouts/LayoutTopSideBottom/LayoutTopSideBottom";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";

function EntityDetails({ match }) {
  const { params: { entityName } = {} } = match;

  return (
    <LayoutTopSideBottom match={match}>
      <Breadcrumbs match={match} />
      <UserDetails preferredUser={entityName} />
    </LayoutTopSideBottom>
  );
}

EntityDetails.propTypes = {
  match: PropTypes.object,
};

export default EntityDetails;

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

//Import Custom Component
import UsersTableRow from "./UsersTableRow";
import Loader from "../../../components/Loader/Loader";
import noDataImage from "../../../../assets/images/undraw_empty.svg";
import AdminUsersListFilter from "./AdminUsersListFilter";

//Import utils & data
import { config } from "../../../config/config";
import {
  updateAdminUserList,
  updateUserInfo,
  updateUserListFilterString,
  updateSelections,
  updateAlertInfo,
  updateData,
} from "../../../redux/actions";
import { makeDefaultResponseJson } from "../../../utils/utils";

function UserList({
  match,
  userListObj,
  user,
  userListFilterString,
  updateAdminUserList,
  changeFlag,
  updateUserInfo,
  updateUserListFilterString,
  updateAlertInfo,
  updateSelections,
  updateData,
}) {
  const [allUsers, setAllUsers] = useState({});

  useEffect(() => {
    let url = `${config.api.listAdminUsersUrl}`;
    setAllUsers({ status: "loading", message: "", data: [] });
    updateUserListFilterString("");
    let status;
    fetch(url)
      .then((response) => {
        console.groupCollapsed("requesting", url);
        console.log("REPSONSE -> ", response);
        status = response.status;
        return response.clone().json();
      })
      .then((json) => {
        console.log("JSON -> ", json);
        console.groupEnd();
        if (status === 200) {
          updateAdminUserList(json.data);
          updateSelections("tableOrderBy", "modified_on");
          setAllUsers({
            status: "success",
            message: "",
            data: json.data,
          });
        } else if (status === 403) {
          setAllUsers({
            status: "error",
            message: json.error,
            data: [],
          });
          window.location.href = "/home/myconfigurations";
          updateAlertInfo({
            open: true,
            message: json.statusMessage,
            severity: "error",
          });
        } else {
          updateAlertInfo({
            open: true,
            message: json.statusMessage,
            severity: "error",
          });
        }
      });
  }, [changeFlag.reloadAdminUserList]);

  useEffect(() => {
    if (userListObj?.data?.length) {
      let filteredArr = userListObj.data;
      setAllUsers({
        status: "",
        message: "",
        data: [],
      });
      filteredArr = filteredArr.filter((obj) => {
        return obj["name"]
          .toLowerCase()
          .includes(userListFilterString.toLowerCase());
      });
      setTimeout(() => {
        setAllUsers({ status: "success", data: filteredArr, message: "" });
      }, 100);
    }
  }, [userListFilterString]);

  useEffect(() => {
    updateData("appAccessDetails", null);
    let url = config.api.appAccessDetailsUrl;
    let status;
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ app_id: config.hardCoded.aeAppId }),
    })
      .then((response) => {
        console.groupCollapsed("requesting", url);
        console.log("REPSONSE -> ", response);
        status = response.status;
        return response.clone().json();
      })
      .then((json) => {
        console.log("JSON -> ", json);
        console.groupEnd();
        if (status === 200) {
          updateData("appAccessDetails", json.data);
        } else {
          updateAlertInfo({
            open: true,
            message: json.statusMessage,
            severity: "error",
          });
        }
      });

    return () => {};
  }, []);

  return (
    <>
      {user?.role !== config.hardCoded.adminRole ? (
        <>
          <div className="no-configs">
            <img src={noDataImage} />
            <p className="no-data">
              <b>You don't have access to view this</b>
            </p>
          </div>
        </>
      ) : userListObj?.status === "loading" ||
        allUsers?.status === "loading" ? (
        <Loader />
      ) : allUsers?.status === "error" ? (
        <div className="no-configs">
          <img src={noDataImage} />
          <p className="no-data">
            <b>{allUsers?.message || "You don't have access to view this"}</b>
          </p>
        </div>
      ) : (
        <>
          <AdminUsersListFilter match={match} />
          {allUsers?.status === "success" && (
            <>
              {allUsers?.data?.length ? (
                <UsersTableRow title={""} match={match} data={allUsers?.data} />
              ) : (
                <div className="no-configs">
                  <img src={noDataImage} />
                  <p className="no-data">
                    <b>{config.messages.noAdminUsersFound}</b>
                  </p>
                </div>
              )}
            </>
          )}
        </>
      )}
    </>
  );
}

UserList.propTypes = {
  user: PropTypes.object,
  match: PropTypes.object,
  userListObj: PropTypes.object,
  userListFilterString: PropTypes.string,
  updateAdminUserList: PropTypes.func,
  updateUserListFilterString: PropTypes.func,
};

const mapStateToProps = (state) => ({
  user: state.user,
  userListObj: state.adminSetting.userList,
  userListFilterString: state.adminSetting.userListFilterString,
  changeFlag: state.changeFlag,
});

const mapDispatchToProps = {
  updateAdminUserList,
  updateUserInfo,
  updateUserListFilterString,
  updateSelections,
  updateAlertInfo,
  updateData,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserList);

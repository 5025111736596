// Import required libraries
import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import PopOver from "../../components/PopOver/PopOver";
import { useHistory } from "react-router-dom";
import { updateAlertInfo } from "../../redux/actions";

// Import styles

// Import config
import { config as appConfig } from "../../../js/config/config";

function AppCard(props) {
  let {
    name,
    match,
    popOverFlag,
    config,
    allAppsFlag,
    updateAlertInfo,
    isAdminPanel,
  } = props;
  const history = useHistory();
  let location = `/home/${match.params.tab}/${name}/`;

  const navigateToDetails = () => {
    if (allAppsFlag) {
      window.open(config.to, "mywindow");
      return;
    }

    const { openRecordError } = config;
    if (openRecordError && openRecordError.trim()) {
      updateAlertInfo({
        open: true,
        message: openRecordError,
        severity: "info",
      });
      return;
    }
    history.push(location);
  };

  return (
    <>
      <div className="card-container">
        {isAdminPanel ? (
          <>
            <Link
              to={`/home/admin-panel/${name}`}
              className="disabled-style app"
            >
              <i className="material-icons-outlined app-icon">widgets</i>
              <p className="app-title">{config.name}</p>
            </Link>
          </>
        ) : allAppsFlag ? (
          <div
            className="disabled-style app"
            onClick={() => window.open(config.to, "mywindow")}
          >
            <i
              className={`${
                config?.material_icon_class
                  ? config.material_icon_class
                  : "material-icons-outlined"
              } app-icon`}
            >
              {config.material_icon_name
                ? config.material_icon_name
                : "widgets"}
            </i>
            <p className="app-title">{config.name}</p>
          </div>
        ) : appConfig.hardCoded.nativeAppsList.includes(name) ? (
          <Link to={`/home/myconfigurations`} className="disabled-style app">
            <i
              className={`${
                config?.material_icon_class
                  ? config.material_icon_class
                  : "material-icons-outlined"
              } app-icon`}
            >
              {config.material_icon_name
                ? config.material_icon_name
                : "widgets"}
            </i>
            <p className="app-title">{config.name}</p>
          </Link>
        ) : (
          <>
            {popOverFlag && (
              <div className="popover-container">
                <PopOver match={match} name={name} config={config} />
              </div>
            )}
            <div className="disabled-style app" onClick={navigateToDetails}>
              <i className="material-icons-outlined app-icon">widgets</i>
              <p className="app-title" title={config.name}>
                {config.name}
              </p>
            </div>
          </>
        )}
      </div>
    </>
  );
}

AppCard.propTypes = {
  name: PropTypes.string.isRequired,
  match: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({ user: state.user });

const mapDispatchToProps = {
  updateAlertInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(AppCard);

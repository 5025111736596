// Import required libraries
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

// Import action creators
import { updateUserListFilterString } from "../../../redux/actions";

function AdminUsersListFilter(props) {
  let { userListFilterString, updateUserListFilterString } = props;
  const history = useHistory();

  return (
    <>
      <div className="aide-filter-new">
        <div className="input-search">
          <i className={`material-icons-outlined search-icon `}>search</i>
          <input
            type="text"
            id="search"
            name="search"
            value={userListFilterString}
            className="input-label"
            autoComplete="off"
            onChange={(e) => {
              updateUserListFilterString(e.target.value);
            }}
            placeholder="Search"
          />
          <i
            className={`material-icons-outlined cancel-icon ${
              userListFilterString ? "visible" : ""
            }`}
            onClick={() => {
              updateUserListFilterString("");
            }}
          >
            clear
          </i>
        </div>
        <div className="right-filter-div">
          <div className="filter-btn" style={{ display: "inline-block" }}>
            <button
              style={{ display: "inline-block" }}
              onClick={() => {
                history.push(`/home/admin-panel/users/createnewuser`);
              }}
            >
              Create New
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

AdminUsersListFilter.propTypes = {
  match: PropTypes.object,
  user: PropTypes.object,
  userListFilterString: PropTypes.string,
  updateUserListFilterString: PropTypes.func,
};

const mapStateToProps = (state) => ({
  user: state.user,
  userListFilterString: state.adminSetting.userListFilterString,
});

const mapDispatchToProps = {
  updateUserListFilterString,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminUsersListFilter);

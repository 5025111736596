import { reloadConfigs } from "../actions";
import C from "../constants";

export default function changeFlag(state = {}, payload) {
  let { type, key, data } = payload;
  let datasetAddedId;
  switch (type) {
    case C.ADD_DATASET:
      datasetAddedId = data.dataset.id;
      return {
        ...state,
        selectedDatasets: !state.selectedDatasets,
        selectedKpi: !state.selectedKpi,
        datasetAddedId,
      };
    case C.REMOVE_DATASET:
      return {
        ...state,
        selectedDatasets: !state.selectedDatasets,
        selectedKpi: !state.selectedKpi,
      };
    case C.ADD_KPI:
      let flag = !payload.data.selectedDataset;
      return flag
        ? {
            ...state,
            selectedDatasets: !state.selectedDatasets,
            selectedKpi: !state.selectedKpi,
          }
        : state;
    case C.TOGGLE_KPI:
      return {
        ...state,
        selectedDatasets: !state.selectedDatasets,
        selectedKpi: !state.selectedKpi,
      };
    case C.ADD_TOGGLE_KPI:
      return {
        ...state,
        selectedDatasets: !state.selectedDatasets,
        selectedKpi: !state.selectedKpi,
      };
    case C.TOGGLE_DATASET:
      datasetAddedId = data.dataset.id;
      return {
        ...state,
        selectedDatasets: !state.selectedDatasets,
        selectedKpi: !state.selectedKpi,
        datasetAddedId,
      };
    case C.UPDATE_WHOLE_CONFIG_USER_INPUTS:
      return {
        ...state,
        datasetAddedId: "",
      };
    case C.RELOAD_RUN_DATA:
      return { ...state, runData: !state.runData };
    case C.REFRESH_DATA:
      return { ...state, refreshData: !state.refreshData };
    case C.RELOAD_CONFIGS:
      return { ...state, reloadConfigs: !state.reloadConfigs };
    case C.RELOAD_ADMIN_USER_LIST:
      return { ...state, reloadAdminUserList: !state.reloadAdminUserList };
    case C.REFRESH_COMMENTS:
      return { ...state, refreshComments: !state.refreshComments };
    default:
      return state;
  }
}

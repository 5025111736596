//Import required libraies
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";

//Import Custom Component
import Loader from "../../../../components/Loader/Loader";
import InputNumberValidation from "./InputNumberValidation";
import MultisetPopup from "./MultisetPopup";
import RadioButtons from "./RadioButtons";
import CalendarPickerValidation from "./CalendarPickerValidation";
import MultiTreeSelect from "./MultiTreeSelect";
import InputTextValidation from "./InputTextValidation";
import TargetCommunity from "./TargetCommunity";
import TargetCommunityUploadAudience from "./TargetCommunityUploadAudience";
import Algorithm from "./Algorithm";
import CommunityType from "./CommunityType";
import InterfaceName from "./InterfaceName";
import TargetPropensity from "./TargetPropensity";
import TargetPropensityUploadAudience from "./TargetPropensityUploadAudience";
import SegmentThreshhold from "./SegmentThreshhold";
import UploadAudience from "./UploadAudience";
import ActionNames from "./ActionNames";
import GlobalWeekDateSelection from "./GlobalWeekDateSelection";
import CountryBrandSegmentHierarchy from "./CountryBrandSegmentHierarchy";
import CustomCheckbox from "./CustomCheckbox";
import DatasetGranularity from "./DatasetGranularity";
import PropActionEventDetails from "./PropActionEventDetails";
import TargetCommunityUploadAudienceForScheduleRuns from "./TargetCommunityUploadAudienceForScheduleRuns";
import TargetPropensityUploadAudienceForScheduleRuns from "./TargetPropensityUploadAudienceForScheduleRuns";

// Import custom hooks

// Import action creators
import {
  updateConfigUserInputs,
  updateUserInfo,
  updateAlertInfo,
} from "../../../../redux/actions";

//Import utils
import { config } from "../../../../config/config";
import { addAllNodeAndSort, checkForAccess } from "../../../../utils/utils";

// Import styles

//Import data
import MultiTreeSelectSettings from "../../../../../assets/data/MultiTreeSelectSettings.json";
import UserAccessDetails from "../../../../../assets/data/UserAccessDetails.json";
import NumberOfTopics from "./NumberOfTopics";
import { components } from "react-select/dist/index-fe3694ff.cjs.dev";
import UploadAudienceCheckbox from "./UploadAudienceCheckbox";

function LocalParameterItem(props) {
  let {
    id,
    name,
    data,
    ui_element_type,
    enable,
    minValue,
    maxValue,
    value,
    onChange,
    userInputs,
    allCampaigns,
    userSelectedCampaigns,
    activeModel,
    show,
    customClassName,
    match,
    setUserInputs,
    updateUserInfo,
    changeFlag,
    disableFlag,
    loading,
    activeApp,
    updateDatasetLocalParameters,
    selectedDataset,
    activeModelId,
    selectedModelDatasets,
    userData,
    updateConfigUserInputs,
    updateCheckDE,
    configUserInputs,
    selectedModel,
    updateAlertInfo,
    style,
    className,
  } = props;
  let Component;

  const useStyles = makeStyles({
    multiSelect: {
      fontSize: "12px",
      fontFamily: "Hind Siliguri",
      width: "100%",
      height: "30px",
      borderRadius: "5px",
      color: "#46596a",
      backgroundColor: "white",
      boxSizing: "border-box",
      //paddingLeft: "8px",
      display: "flex",
      alignItems: "center",
      //textAlign: "center",
      border: "1px solid #dedede",
      "& .Mui-disabled": {
        backgroundColor: "#f3f3f3",
        borderRadius: "4px",
      },
      "&:before": {
        borderBottom: "0px",
      },
      "&:after": {
        borderBottom: "0px",
      },
      "&:hover:not(.Mui-disabled):before": {
        borderBottom: "0px",
      },
      "& .MuiOutlinedInput-input": {
        padding: "6px 6px",
      },
      "& .MuiSelect-select:focus": {
        backgroundColor: "transparent",
      },
      "& .MuiSelect-select.MuiSelect-select": {
        paddingLeft: "8px",
        // height: "25px",
      },
    },
    singleMenuItem: {
      fontSize: "12px",
      fontFamily: "Hind Siliguri",
      padding: "4px 12px",
      color: "#46596a",
      width: "auto",
      display: "flex",
      alignItems: "center",
      textAlign: "center",
      "&:hover": {
        backgroundColor: "#4EAFB3",
        color: "white",
      },
    },
  });
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 150,
        width: "auto",
      },
    },
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "left",
    },
    variant: "menu",
    getContentAnchorEl: null,
  };

  const classes = useStyles();

  let checkPropenisty;

  switch (ui_element_type) {
    case "inputbox_text":
      //Update configUserInputs if it's a new parameter addition
      // if (value === "NEW_PARAMETER") {
      //   onChange(id, "");
      // }
      Component = <InputTextValidation {...props} />;
      break;
    case "inputbox_text_event_action":
      Component = <PropActionEventDetails {...props} />;
      break;
    case "inputbox_text_event_category":
      Component = <PropActionEventDetails {...props} />;
      break;
    case "inputbox_number":
      //Update configUserInputs if it's a new parameter addition; breaking because of backend API not returning max value
      // if (value === "NEW_PARAMETER") {
      //   onChange(id, parseFloat(minValue));
      // }
      Component = <InputNumberValidation {...props} />;
      break;
    case "radio_button":
      //Update configUserInputs if it's a new parameter addition
      // if (value === "NEW_PARAMETER") {
      //   onChange(id, data[0].id);
      // }
      Component = (
        <RadioButtons
          data={data}
          name={name}
          id={id}
          enable={enable}
          show={show}
          value={value}
          onChange={onChange}
          customClassName={customClassName}
        />
      );
      break;
    case "calender_picker":
      Component = <CalendarPickerValidation {...props} />;
      break;
    case "dropdown":
      //Update configUserInputs if it's a new parameter addition
      // if (value === "NEW_PARAMETER") {
      //   onChange(id, data[0].id);
      // }
      Component = (
        <div
          className={`input-item row  ${
            activeModel?.id === config.hardCoded.propensityId
              ? "input-item-propensity"
              : ""
          } ${customClassName ? customClassName : ""} ${
            show ? "visible" : "hidden"
          } ${id} ${className && className}`}
        >
          {name && (
            <label
              className={
                (id === "dayCat" ||
                  id === "target_run_id" ||
                  id === "run_id") &&
                customClassName !== "side-view-runs-filter"
                  ? "width-22"
                  : ""
              }
            >
              {name.replace(":", "")}:
            </label>
          )}
          <div
            className="single-select-dropdown"
            id={
              (id === "dayCat" || id === "target_run_id" || id === "run_id") &&
              customClassName !== "side-view-runs-filter"
                ? "width-380"
                : ""
            }
          >
            <Select
              disabled={!enable}
              value={value ? value : ""}
              onChange={(e) => {
                onChange(id, e.target.value);
              }}
              className={classes.multiSelect}
              MenuProps={MenuProps}
            >
              {data &&
                data.map((obj) => (
                  <MenuItem
                    key={obj.id + (obj.regions ? obj.regions : "")}
                    value={obj.id}
                    className={classes.singleMenuItem}
                  >
                    {obj.name}
                  </MenuItem>
                ))}
            </Select>
          </div>
        </div>
      );
      break;
    case "multiple_select_dropdown":
      Component = (
        <p>
          {ui_element_type}, {name}
        </p>
      );
      break;
    case "multiset_popup":
      Component = updateCheckDE ? (
        <MultisetPopup
          name={name}
          data={data}
          value={value}
          onChange={onChange}
          modelConfigId={id}
          userInputs={userInputs}
          enable={enable}
          show={show}
          customClassName={customClassName}
          disableFlag={disableFlag}
        />
      ) : null;
      break;
    case "tree_single_select":
      Component = (
        <MultiTreeSelect
          data={addAllNodeAndSort({
            data: data,
            settings: MultiTreeSelectSettings[config.hardCoded.aeAppId],
            orderType: "asc",
          })}
          userInputs={userInputs}
          setUserInputs={setUserInputs}
          disableFlag={disableFlag}
        />
      );
      break;
    case "checkbox":
      Component = <CustomCheckbox {...props} />;
      break;
    case "custom":
      switch (id) {
        case "country_brand_segment_hierarchy":
          Component = <CountryBrandSegmentHierarchy {...props} />;
          break;
        case "global_week_date_selection":
          Component = <GlobalWeekDateSelection {...props} />;
          break;
        case "custom_target_community_run":
          const checkTarget = userInputs.models.find(
            (model) => model.id === config.hardCoded.communityClusteringId
          )?.config.community_clustering_run_type;
          if (checkTarget) {
            Component =
              userInputs.models
                .find(
                  (model) => model.id === config.hardCoded.communityClusteringId
                )
                .config.community_clustering_run_type?.toLowerCase() ===
                config.hardCoded.targetRunId &&
              activeModel.id === config.hardCoded.communityClusteringId &&
              !userInputs.is_schedule_config ? (
                <TargetCommunity {...props} />
              ) : userInputs.models
                  .find(
                    (model) =>
                      model.id === config.hardCoded.communityClusteringId
                  )
                  .config.community_clustering_run_type?.toLowerCase() ===
                  config.hardCoded.uploadAudienceRunId &&
                activeModel.id === config.hardCoded.communityClusteringId &&
                !userInputs.is_schedule_config ? (
                <TargetCommunityUploadAudience {...props} />
              ) : userInputs.models
                  .find(
                    (model) =>
                      model.id === config.hardCoded.communityClusteringId
                  )
                  .config.community_clustering_run_type?.toLowerCase() ===
                  config.hardCoded.uploadAudienceRunId &&
                activeModel.id === config.hardCoded.communityClusteringId &&
                userInputs.is_schedule_config ? (
                <TargetCommunityUploadAudienceForScheduleRuns {...props} />
              ) : null;
          } else {
            Component = null;
          }
          break;
        case "custom_target_proppensity_run":
          Component =
            userInputs.models
              .find((model) => model.id === config.hardCoded.propensityId)
              .config.propensity_modeling_run_type?.toLowerCase() ===
              config.hardCoded.targetRunId &&
            activeModel.id === config.hardCoded.propensityId &&
            !userInputs.is_schedule_config &&
            !userInputs.is_fixed_schedule_config ? (
              <TargetPropensity {...props} />
            ) : userInputs.models
                .find((model) => model.id === config.hardCoded.propensityId)
                .config.propensity_modeling_run_type?.toLowerCase() ===
                config.hardCoded.uploadAudienceRunId &&
              activeModel.id === config.hardCoded.propensityId &&
              !userInputs.is_schedule_config &&
              !userInputs.is_fixed_schedule_config ? (
              <TargetPropensityUploadAudience {...props} />
            ) : userInputs.models
                .find((model) => model.id === config.hardCoded.propensityId)
                .config.propensity_modeling_run_type?.toLowerCase() ===
                config.hardCoded.uploadAudienceRunId &&
              activeModel.id === config.hardCoded.propensityId &&
              (userInputs.is_schedule_config ||
                userInputs.is_fixed_schedule_config) ? (
              <TargetPropensityUploadAudienceForScheduleRuns {...props} />
            ) : null;
          break;
        case "interface_name":
          const checkInterFaceName = userInputs.models.find(
            (model) => model.id === config.hardCoded.communityClusteringId
          ).config?.community_clustering_run_type;

          checkPropenisty = userInputs.models.find(
            (model) => model.id === config.hardCoded.propensityId
          )?.config.propensity_modeling_run_type;

          Component =
            ((checkInterFaceName &&
              checkInterFaceName?.toLowerCase() ===
                config.hardCoded.targetRunId) ||
              !checkInterFaceName) &&
            activeModel.id === config.hardCoded.communityClusteringId ? (
              <InterfaceName {...props} disabledForUploadAudience={false} />
            ) : !checkPropenisty &&
              activeModel.id === config.hardCoded.propensityId ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "50px",
                }}
              >
                <InterfaceName {...props} disabledForUploadAudience={false} />
                <UploadAudienceCheckbox
                  configUserInputs={userInputs}
                  selectedModel={selectedModel}
                  // propensity={true}
                  uaAccess={
                    userData.appAccessDetails.feature_list
                      .propensity_modelling_audience_data.write
                  }
                />
              </div>
            ) : (userInputs.is_schedule_config ||
                userInputs.is_fixed_schedule_config) &&
              userInputs.models
                .find((model) => model.id === config.hardCoded.propensityId)
                .config.propensity_modeling_run_type?.toLowerCase() ===
                config.hardCoded.targetRunId &&
              activeModel.id === config.hardCoded.propensityId ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "start",
                }}
              >
                <InterfaceName
                  {...props}
                  selectedModel={selectedModel}
                  disabledForUploadAudience={false}
                />
                <UploadAudienceCheckbox
                  configUserInputs={userInputs}
                  selectedModel={selectedModel}
                  propensity={true}
                  uaAccess={
                    userData.appAccessDetails.feature_list
                      .propensity_modelling_audience_data.write
                  }
                />
              </div>
            ) : // ) :  ) : userInputs.models
            //     .find(
            //       (model) => model.id === config.hardCoded.communityClusteringId
            //     )
            //     .config.community_clustering_run_type?.toLowerCase() ===
            //     config.hardCoded.uploadAudienceRunId &&
            //   activeModel.id === config.hardCoded.communityClusteringId ? (
            //   <InterfaceName {...props} disabledForUploadAudience={true} />
            // userInputs.models
            //     .find((model) => model.id === config.hardCoded.propensityId)
            //     .config.propensity_modeling_run_type?.toLowerCase() ===
            //     config.hardCoded.uploadAudienceRunId &&
            //   activeModel.id === config.hardCoded.propensityId ? (
            //   <InterfaceName {...props} disabledForUploadAudience={true} />
            null;
          break;
        case "community_id_type":
          checkPropenisty = userInputs.models.find(
            (model) => model.id === config.hardCoded.propensityId
          )?.config.propensity_modeling_run_type;
          Component =
            userInputs.models
              .find(
                (model) => model.id === config.hardCoded.communityClusteringId
              )
              .config.community_clustering_run_type?.toLowerCase() ===
              config.hardCoded.targetRunId &&
            activeModel.id === config.hardCoded.communityClusteringId ? (
              <CommunityType {...props} disabledForUploadAudience={false} />
            ) : null;
          break;
        case "action_names":
          checkPropenisty = userInputs.models.find(
            (model) => model.id === config.hardCoded.propensityId
          )?.config.propensity_modeling_run_type;
          const oldPmModelSelected = userInputs.models
            ?.find(
              (model) => model.id === config.hardCoded.propensityId && model.run
            )
            ?.data_sources?.find(
              (dataset) => dataset?.id === config.hardCoded.ga360
            );
          const actionNamesData = oldPmModelSelected
            ? data.filter((item) =>
                config.hardCoded.oldPMActionName.includes(item.id)
              )
            : data.filter((item) =>
                config.hardCoded.newPMActionName.includes(item.id)
              );
          Component =
            ((checkPropenisty &&
              checkPropenisty?.toLowerCase() ===
                config.hardCoded.initialRunId) ||
              !checkPropenisty) &&
            activeModel.id === config.hardCoded.propensityId ? (
              <ActionNames
                name={name}
                data={actionNamesData}
                value={value}
                onChange={onChange}
                modelConfigId={id}
                userInputs={userInputs}
                enable={enable}
                show={show}
                customClassName={customClassName}
                disableFlag={disableFlag}
                activeApp={activeApp}
              />
            ) : null;

          break;
        case "segment_thresholds":
          checkPropenisty = userInputs.models.find(
            (model) => model.id === config.hardCoded.propensityId
          )?.config.propensity_modeling_run_type;
          Component =
            ((checkPropenisty &&
              checkPropenisty?.toLowerCase() ===
                config.hardCoded.targetRunId) ||
              !checkPropenisty) &&
            activeModel.id === config.hardCoded.propensityId ? (
              <SegmentThreshhold
                name={name}
                data={data}
                value={value}
                onChange={onChange}
                modelConfigId={id}
                userInputs={userInputs}
                enable={enable}
                show={show}
                customClassName={customClassName}
                updateUserInfo={updateUserInfo}
                updateAlertInfo={updateAlertInfo}
                disabledForUploadAudience={false}
              />
            ) : null;

          break;
        case "audience_name":
          checkPropenisty = userInputs.models.find(
            (model) => model.id === config.hardCoded.propensityId
          )?.config.propensity_modeling_run_type;
          const checkAudience = userInputs.models.find(
            (model) => model.id === config.hardCoded.communityClusteringId
          ).config?.community_clustering_run_type;

          Component =
            ((checkAudience &&
              checkAudience?.toLowerCase() === config.hardCoded.targetRunId) ||
              !checkAudience) &&
            activeModel.id === config.hardCoded.communityClusteringId ? (
              <InputTextValidation
                {...props}
                disabledForUploadAudience={false}
                checkAudience={checkAudience}
              />
            ) : ((checkPropenisty &&
                checkPropenisty?.toLowerCase() ===
                  config.hardCoded.initialRunId) ||
                !checkPropenisty) &&
              activeModel.id === config.hardCoded.propensityId ? (
              <InputTextValidation
                {...props}
                disabledForUploadAudience={false}
              />
            ) : null;
          break;
        case "algorithm":
          const checkAlgorithm = userInputs.models.find(
            (model) => model.id === config.hardCoded.communityClusteringId
          ).config?.community_clustering_run_type;
          if (
            !checkAlgorithm &&
            activeModel.id === config.hardCoded.communityClusteringId
          ) {
            Component = (
              <Algorithm
                {...props}
                style={{ marginLeft: `${!checkAlgorithm ? "0px" : "60px"} ` }}
                checkAlgorithm={checkAlgorithm}
              />
            );
          } else if (
            checkAlgorithm &&
            activeModel.id === config.hardCoded.communityClusteringId
          ) {
            if (
              checkAlgorithm?.toLowerCase() === config.hardCoded.initialRunId &&
              activeModel.id === config.hardCoded.communityClusteringId
            ) {
              Component = (
                <Algorithm
                  {...props}
                  style={{ marginLeft: `${!checkAlgorithm ? "0px" : "60px"}` }}
                  checkAlgorithm={checkAlgorithm}
                />
              );
            } else {
              Component = null;
            }
          } else {
            Component = null;
          }

          // Component = !checkAlgorithm ? (
          //   <Algorithm
          //     {...props}
          //     style={{ marginLeft: `${!checkAlgorithm ? "0px" : "60px"} ` }}
          //     checkAlgorithm={checkAlgorithm}
          //   />
          // ) : checkAlgorithm &&
          //   checkAlgorithm?.toLowerCase() === config.hardCoded.initialRunId &&
          //   activeModel.id === config.hardCoded.communityClusteringId ? (
          //   <Algorithm
          //     {...props}
          //     style={{ marginLeft: `${!checkAlgorithm ? "0px" : "60px"} ` }}
          //     checkAlgorithm={checkAlgorithm}
          //   />
          // ) : null;
          break;
        case "dataset":
          Component =
            userInputs.models
              .find(
                (model) => model.id === config.hardCoded.communityClusteringId
              )
              .config.community_clustering_run_type?.toLowerCase() ===
              config.hardCoded.initialRunId &&
            activeModel.id === config.hardCoded.communityClusteringId ? (
              <Algorithm {...props} />
            ) : null;
          break;
        case "upload_audience":
          Component =
            userInputs.models
              .find(
                (model) => model.id === config.hardCoded.communityClusteringId
              )
              .config.community_clustering_run_type?.toLowerCase() ===
              config.hardCoded.targetRunId &&
            activeModel.id === config.hardCoded.communityClusteringId ? (
              <UploadAudience {...props} />
            ) : userInputs.models
                .find((model) => model.id === config.hardCoded.propensityId)
                .config.propensity_modeling_run_type?.toLowerCase() ===
                config.hardCoded.targetRunId &&
              activeModel.id === config.hardCoded.propensityId ? (
              <UploadAudience {...props} />
            ) : null;
          break;
        case "channel_granularity":
          Component = (
            <DatasetGranularity {...props} allCampaigns={allCampaigns} />
          );
          break;
        case "community_clustering_run_type":
          // data Tempering
          let runTypeData = data;
          if (userInputs?.configType === "add") {
            runTypeData?.map((obj) => {
              if (
                (obj.id === "target" || obj.id === "upload_audience") &&
                !userInputs?.is_schedule_config
              ) {
                obj.disabled = true;
              } else {
                obj.disabled = false;
              }
            });
          } else {
            runTypeData?.map((obj) => {
              obj.disabled = false;
            });
          }
          const getModelConfigId =
            UserAccessDetails[activeModel.id].settings["upload_audience"].id;
          //Checking if user has write access then show in UI
          const displayModelConfigFlag = checkForAccess(
            userData.appAccessDetails.feature_list,
            getModelConfigId,
            "write"
          );
          if (
            !displayModelConfigFlag ||
            typeof displayModelConfigFlag === "undefined"
          ) {
            runTypeData = data?.filter(
              (singleObj) => singleObj.id !== "upload_audience"
            );
          }
          Component = (
            <RadioButtons
              data={runTypeData}
              name={name}
              id={id}
              enable={enable}
              show={show}
              value={value}
              onChange={onChange}
              customClassName={customClassName}
            />
          );
          break;
        case "propensity_modeling_run_type":
          // data Tempering
          let propRunType = data;
          if (userInputs?.configType === "add") {
            propRunType?.map((obj) => {
              if (
                (obj.id === "target" || obj.id === "upload_audience") &&
                !userInputs?.is_schedule_config
              ) {
                obj.disabled = true;
              } else {
                obj.disabled = false;
              }
            });
          } else {
            propRunType?.map((obj) => {
              obj.disabled = false;
            });
          }
          const getModelConfigIdForProp =
            UserAccessDetails[activeModel.id].settings["upload_audience"].id;
          //Checking if user has write access then show in UI
          const displayModelConfigFlagForProp = checkForAccess(
            userData.appAccessDetails.feature_list,
            getModelConfigIdForProp,
            "write"
          );
          if (
            !displayModelConfigFlagForProp ||
            typeof displayModelConfigFlagForProp === "undefined"
          ) {
            propRunType = data?.filter(
              (singleObj) => singleObj.id !== "upload_audience"
            );
          }
          Component = (
            <RadioButtons
              data={propRunType}
              name={name}
              id={id}
              enable={enable}
              show={show}
              value={value}
              onChange={onChange}
              customClassName={customClassName}
            />
          );
          break;
        case "top_n_competitor":
          return null;
        case "corr_upper_limit":
          Component = updateCheckDE && (
            <InputNumberValidation
              {...props}
              step={0.01}
              onKeyPress={(event) => {
                if (!/^([0-9]|\.)/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
          );
          break;
        case "num_topics":
          Component = <NumberOfTopics {...props} />;
          break;
        default:
          Component = (
            <p>
              {ui_element_type}, {name}
            </p>
          );
      }
      break;
    default:
      Component = (
        <p>
          {ui_element_type}, {name}
        </p>
      );
  }

  return id === "custom_target_community_run" ||
    id === "custom_target_proppensity_run" ||
    (activeModel?.id === config.hardCoded.propensityId &&
      ["target_run_id"].includes(id))
    ? Component && <>{Component}</>
    : Component && (
        <div
          className={`${
            id === "country_brand_segment_hierarchy"
              ? "country-brand-container"
              : "datasets-selections-local-parameters-row"
          }  ${disableFlag ? "disabled-pointer" : ""}`}
          key={id}
        >
          {Component}
        </div>
      );
}

LocalParameterItem.propTypes = {
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
  configUserInputs: state.configUserInputs,
});

const mapDispatchToProps = {
  updateConfigUserInputs,
  updateUserInfo,
  updateAlertInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(LocalParameterItem);

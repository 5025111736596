// Import required libraies
import React, { useState } from "react";
import PropTypes from "prop-types";

// Import Custom Component
import LayoutNone from "../../layouts/LayoutNone/LayoutNone";
// import LocalParameterItem from "../AIDEApp/AddNewConfig/components/LocalParameterItem";

// Import Context

// Import utils/data

function Error(props) {
  let { location } = props;
  // const [data, setData] = useState({ combinations: [] });
  // console.log("PARENT DATA -> ", data);

  // let onChange = (key, value) => {
  //   let data = {
  //     key,
  //     value,
  //   };
  //   // updateModelLocalParameters(data);
  //   setData({ [key]: value });
  // };

  // let localParamItemObj = {
  //   enable: true,
  //   id: "combinations",
  //   name: "Select Multiple Sets",
  //   show: true,
  //   ui_element_type: "multiset_popup",
  //   data: [
  //     {
  //       id: "weeks_pilot",
  //       maxValue: "null",
  //       minValue: "0",
  //       name: "Weeks Pilot",
  //       ui_element_type: "inputbox_number",
  //     },
  //     {
  //       id: "min_ci",
  //       maxValue: "100",
  //       minValue: "0",
  //       name: "Minimum Confidence Interval",
  //       ui_element_type: "inputbox_number",
  //     },
  //     {
  //       id: "expected_roas",
  //       maxValue: "null",
  //       minValue: "1",
  //       name: "Expected Return on Actual Sales",
  //       ui_element_type: "inputbox_number",
  //     },
  //     {
  //       id: "sales_coverage",
  //       maxValue: "100",
  //       minValue: "0",
  //       name: "Sales Coverage",
  //       ui_element_type: "inputbox_number",
  //     },
  //   ],
  // };

  return (
    <LayoutNone>
      <h1>Error</h1>
      <p>{location.pathname}</p>
      {/* <LocalParameterItem
        {...localParamItemObj}
        value={data["combinations"]}
        onChange={onChange}
      /> */}
    </LayoutNone>
  );
}

Error.propTypes = {
  location: PropTypes.object.isRequired,
};

export default Error;

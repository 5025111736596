// Import required libraries
import React from "react";
import useState from "react-usestateref";
import { connect } from "react-redux";
import {
  makeStyles,
  TableBody,
  TableRow,
  TableCell,
  withStyles,
} from "@material-ui/core";

// Import data & utils
import useTable from "../../utils/useTable";

import { config } from "../../config/config";

// Import action creators
import { updateAlertInfo, updateMultiSelections } from "../../redux/actions";
import moment from "moment";
import { Paper } from "@mui/material";
import { formatDate } from "../../utils/utils";

const StyledTableCell = withStyles((theme) => ({
  body: {
    color: "#46596a",
    fontFamily: "Hind Siliguri",
    borderBottom: "0px",
  },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
  },
  listIcon: {
    display: "flex",
    alignItems: "center",
  },
  name: {
    maxWidth: "500px",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "13px !important",
    lineHeight: "23px",
  },
  tableRow: {
    backgroundColor: "red !important",
    padding: "9px !important",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "13px !important",
    lineHeight: "23px",
    "&:last-child th, &:last-child td": {
      borderBottom: 0,
    },
    "&:nth-of-type(odd)": {
      backgroundColor: "white !important",
      "&:hover": {
        backgroundColor: "#4eafb342 !important",
      },
    },
    "&:nth-of-type(even)": {
      backgroundColor: "rgba(70, 89, 106, 0.07) !important",
      "&:hover": {
        backgroundColor: "#4eafb342 !important",
      },
    },
  },
  starLoading: {
    marginRight: "5px",
    cursor: "pointer",
  },
}));

function NLPGoogleDashboardTable(props) {
  let { records, user, updateMultiSelections, nlpReport, updateAlertInfo } =
    props;
  const report_type = nlpReport ? "NLP" : "DATAPORTRAIT";
  const columnsForDashboard = nlpReport
    ? [
        {
          id: "nlp_last_successful_run",
          label: "Last Executed",
          width: "15%",
        },
      ]
    : [
        {
          id: "cc_last_successful_run",
          label: "CC Last Executed",
          width: "15%",
        },
        {
          id: "pm_last_successful_run",
          label: "PM Last Executed",
          width: "15%",
        },
      ];
  let headCells = [
    {
      id: "country",
      label: "Country",
      width: "10%",
    },
    {
      id: "unit",
      label: "Unit",
      width: "10%",
    },
    {
      id: "brand",
      label: "Brand",
      width: "10%",
    },
    { id: "report_name", label: "Report Name", width: "30%" },
    ...columnsForDashboard,
    {
      id: "url",
      label: nlpReport ? "Links" : "Link",
      width: "10%",
      disableSorting: "disable",
      align: "center",
    },
  ];
  const classes = useStyles();

  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });

  //handler for downloading and open in new tab the nlp report
  const handleDownloadReport = (
    country,
    brand,
    download_html,
    userTrackingProps
  ) => {
    let status;
    const url = config.api.nlpReport;
    fetch(url, {
      // Adding method type
      method: "POST",
      // Adding body or contents to send
      body: JSON.stringify({
        country,
        brand,
        download_html: download_html,
      }),
      // Adding headers to the request
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => {
        status = response.status;
        return response.clone().json();
      })
      .then((json) => {
        if (status === 200) {
          handleUserTracking(userTrackingProps);
          const anchor = document.createElement("a");
          anchor.href = json.data[0].url;
          anchor.target = "_blank";
          // Append to the DOM
          document.body.appendChild(anchor);
          // Trigger `click` event
          anchor.click();
          // Remove element from DOM
          if (download_html) {
            document.body.removeChild(anchor);
          }
        } else {
          updateAlertInfo({
            open: true,
            message: json.statusMessage,
            severity: "error",
          });
        }
      });
  };

  const handleUserTracking = (userTrackingProps) => {
    // userTrackingProps contains
    // country, brand, viewed_by, unit, report_name, report_type
    const url = config.api.userMetric;
    fetch(url, {
      // Adding method type
      method: "POST",
      // Adding body or contents to send
      body: JSON.stringify(userTrackingProps),
      // Adding headers to the request
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => {
        return response.clone().json();
      })
      .then((json) => {
        // the response will not be required in UI so no need to access the response
      });
  };

  const googleDashboardCheck = true;
  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    useTable(
      records,
      headCells,
      filterFn,
      user,
      updateMultiSelections,
      googleDashboardCheck
    );

  return (
    <>
      <Paper
        sx={{
          height: "66vh",
          overflow: "auto",
          border: "none",
          boxShadow: "none",
        }}
      >
        <TblContainer>
          <TblHead />
          <TableBody>
            {recordsAfterPagingAndSorting().map((item, index) => (
              <TableRow
                key={item.report_name + index}
                className={classes.tableRow}
                hover
                style={{ cursor: "default" }}
              >
                <StyledTableCell>{item.country}</StyledTableCell>
                <StyledTableCell>{item.unit}</StyledTableCell>
                <StyledTableCell>{item.brand}</StyledTableCell>
                <StyledTableCell>{item.report_name}</StyledTableCell>
                {nlpReport ? (
                  <StyledTableCell>
                    {formatDate(item.nlp_last_successful_run)}
                  </StyledTableCell>
                ) : (
                  <>
                    <StyledTableCell>
                      {formatDate(item.cc_last_successful_run)}
                    </StyledTableCell>
                    <StyledTableCell>
                      {formatDate(item.pm_last_successful_run)}
                    </StyledTableCell>
                  </>
                )}
                <StyledTableCell align="center">
                  {nlpReport ? (
                    <>
                      <span
                        target="_blank"
                        style={{
                          textDecoration: "none",
                        }}
                        title="Open in new tab"
                      >
                        <i
                          className="material-icons-outlined"
                          style={{
                            fontSize: "16px",
                            color: "#46596a",
                          }}
                          onClick={() => {
                            handleDownloadReport(
                              item.country,
                              item.brand,
                              false,
                              {
                                country: item.country,
                                brand: item.brand,
                                viewed_by: user.email,
                                unit: item.unit,
                                report_name: item.report_name,
                                report_type: report_type,
                              }
                            );
                          }}
                        >
                          open_in_new
                        </i>
                      </span>
                      <span
                        target="_blank"
                        style={{
                          textDecoration: "none",
                          marginLeft: "10px",
                        }}
                        title="Download NLP Report"
                        onClick={() => {
                          handleDownloadReport(item.country, item.brand, true, {
                            country: item.country,
                            brand: item.brand,
                            viewed_by: user.email,
                            unit: item.unit,
                            report_name: item.report_name,
                            report_type: report_type,
                          });
                        }}
                      >
                        <i
                          className="material-icons-outlined"
                          style={{
                            fontSize: "16px",
                            color: "#46596a",
                          }}
                        >
                          file_download
                        </i>
                      </span>
                    </>
                  ) : (
                    <a
                      href={item.url}
                      target="_blank"
                      rel="noreferrer"
                      style={{
                        textDecoration: "none",
                      }}
                      title="Open in new tab, and it is valid for only 15 minute"
                      onClick={() =>
                        handleUserTracking({
                          country: item.country,
                          brand: item.brand,
                          viewed_by: user.email,
                          unit: item.unit,
                          report_name: item.report_name,
                          report_type: report_type,
                        })
                      }
                    >
                      <i
                        className="material-icons-outlined"
                        style={{
                          fontSize: "16px",
                          color: "#46596a",
                        }}
                      >
                        open_in_new
                      </i>
                    </a>
                  )}
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </TblContainer>
      </Paper>
      <div style={{ height: "40px" }}>
        {records.length > 20 && <TblPagination />}
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  allData: state.data,
});

const mapDispatchToProps = {
  updateMultiSelections,
  updateAlertInfo,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NLPGoogleDashboardTable);

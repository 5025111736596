// Import required libraries
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
// import Button from "@material-ui/core/Button";
import { Button } from "../../../../uiTheme";
import { makeStyles } from "@material-ui/core/styles";

// Import custom component
import ScheduleConfigContainer from "../../../../components/ScheduleConfig/ScheduleConfigContainer";
import Modal from "../../../../components/Modal/Modal";

// Import utils & data
import { config } from "../../../../config/config";
import {
  makeConfigTemplate,
  validateConfig,
  validateDatasetWeeksSync,
  makeDefaultResponseJson,
  getRunType,
} from "../../../../utils/utils";

// Import actions
import {
  updateAlertInfo,
  updateUserInfo,
  updateDialogInfo,
  refreshData,
  reloadConfigs,
} from "../../../../redux/actions";
import { el } from "date-fns/locale";

function Buttons(props) {
  let {
    match,
    user,
    app,
    configUserInputs,
    allData,
    updateAlertInfo,
    updateUserInfo,
    updateDialogInfo,
    refreshData,
    setStepCompleted,
    setActiveStep,
    activeStep,
    stepCompleted,
    nestedRunsArray,
    onStepperChange,
    activeModelId,
    reloadConfigs,
    scheduleRunData,
    validateStepper,
    changeInGlobalParamsFlag,
  } = props;
  const history = useHistory();
  const [disableButtons, setDisableButtons] = useState(false);

  const oldPmModelSelected =
    configUserInputs.old_pm &&
    configUserInputs.models.filter((model) => model.run).length === 1 &&
    configUserInputs.models.find(
      (model) => model.id === config.hardCoded.propensityId
    ).run;
  const useStyles = makeStyles((theme) => ({
    toastTheme: {
      backgroundColor: "#f0f2f4",
      color: "#46596a",
      fontSize: "12px",
      fontWeight: "bold",
    },
    contained: {
      color: "white",
      backgroundColor: "#46596A",
      fontFamily: "Hind Siliguri",
      fontSize: "12px",
      fontWeight: "bold",
      textTransform: "capitalize",
    },
    outlined: {
      borderColor: "#46596A",
      color: "#46596A",
      fontFamily: "Hind Siliguri",
      fontSize: "12px",
      fontWeight: "bold",
      textTransform: "capitalize",
    },
    root: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      pointerEvents: disableButtons ? "none" : "auto",
      // position: "fixed",
      // bottom: "45px",
      // right: "35px",
      "& .MuiButton-root:hover": {
        backgroundColor: "transparent",
      },
      "& .MuiButton-contained:hover": {
        backgroundColor: "#46596A",
        boxShadow: "none",
      },
      "& .MuiButton-contained": {
        boxShadow: "none",
      },
      "& > * + *": {
        marginLeft: theme.spacing(2),
      },
    },
  }));

  let appName = config.hardCoded.aeAppName.toLowerCase();
  let activeApp =
    allData.apps.data.length > 0
      ? allData.apps.data.find(
          (obj) => obj.name.toLowerCase() === appName.toLowerCase()
        )
      : null;

  const classes = useStyles();
  const [modalComponent, setModalComponent] = useState({
    component: null,
    openModal: false,
    handleCancel: () => {
      console.log("handleCancel");
    },
    handleDone: () => {
      console.log("handleDone");
    },
  });

  const handleDialogClose = () => {
    setModalComponent((prevState) => {
      return { ...prevState, openModal: false };
    });
  };

  const handleDialogOpen = (component) => {
    setModalComponent((prevState) => {
      return {
        ...prevState,
        openModal: true,
        component: component,
      };
    });
  };

  const handleDelete = () => {
    setDisableButtons(true);
    updateDialogInfo({ ...user.dialogInfo, open: false });
    console.groupCollapsed("DELETE REQUEST SENT...");
    updateAlertInfo({
      open: true,
      message: config.messages.configDeleteRequest,
      severity: "info",
    });
    let url = `${config.api.configUrl}?config_id=${configUserInputs.configId}&app_id=${config.hardCoded.aeAppId}`;
    let status;
    fetch(url, {
      method: "DELETE",
    })
      .then((response) => {
        console.groupCollapsed("requesting", url);
        console.log("REPSONSE -> ", response);
        status = response.status;
        return response.clone().json();
      })
      .then((json) => {
        console.log("JSON -> ", json);
        console.groupEnd();
        if (status === 200) {
          updateAlertInfo({
            open: true,
            message: config.configSuccessDeleteMessage,
            severity: "success",
          });
          history.push(
            match.params.tab === "addnewconfig"
              ? `/home/myconfigurations/`
              : `/home/${match.params.tab}/`
          );
        } else {
          updateAlertInfo({
            open: true,
            message: json.statusMessage,
            severity: "error",
          });
        }
      });
  };

  const handleNo = () => {
    setDisableButtons(false);
    updateDialogInfo({ ...user.dialogInfo, open: false });
  };

  const saveConfig = () => {
    setDisableButtons(true);
    updateDialogInfo({ ...user.dialogInfo, open: false });
    let { message } = validateConfig(configUserInputs, allData);
    let finalConfig = makeConfigTemplate(configUserInputs, allData);
    let url = config.api.configUrl;
    let status;
    updateAlertInfo({ open: true, message, severity: "info" });
    fetch(url, {
      method: match.params.configName ? "PUT" : "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(finalConfig),
    })
      .then((response) => {
        console.groupCollapsed("requesting", url);
        console.log("REPSONSE -> ", response);
        status = response.status;
        return response.clone().json();
      })
      .then((json) => {
        console.log("JSON -> ", json);
        console.groupEnd();
        if (status === 200) {
          updateAlertInfo({
            open: true,
            message: match.params.configName
              ? config.messages.configSuccessUpdate
              : config.messages.configSuccessSave,
            severity: "success",
          });
          history.push(
            match.params.tab === "addnewconfig"
              ? `/home/myconfigurations/`
              : `/home/${match.params.tab}/`
          );
        } else {
          setDisableButtons(false);
          // if (status === 500) {
          //   updateAlertInfo({
          //     open: true,
          //     message: config.messages.duplicateName,
          //     severity: "success",
          //   });
          // } else {
          updateAlertInfo({
            open: true,
            message: json.statusMessage,
            severity: "error",
          });
          // }
        }
      });
  };

  const saveAndRunConfig = () => {
    setDisableButtons(true);
    updateDialogInfo({ ...user.dialogInfo, open: false });
    let { message } = validateConfig(configUserInputs, allData);
    let finalConfig = makeConfigTemplate(configUserInputs, allData);
    let url = config.api.configUrl;
    let status;
    updateAlertInfo({ open: true, message, severity: "info" });
    fetch(url, {
      method: match.params.configName ? "PUT" : "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(finalConfig),
    })
      .then((response) => {
        console.groupCollapsed("requesting", url);
        console.log("REPSONSE -> ", response);
        status = response.status;
        return response.clone().json();
      })
      .then((json) => {
        console.log("JSON -> ", json);
        console.groupEnd();
        if (status === 200) {
          updateAlertInfo({
            open: true,
            message: match.params.configName
              ? config.messages.configSuccessUpdate + " Requesting run..."
              : config.messages.configSuccessSave + " Requesting run...",
            severity: "success",
          });
          let id = match.params.configName
            ? configUserInputs.configId
            : json.data.id;
          let url = config.api.configRunUrl;
          let status;
          fetch(url, {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              id,
              app_id: config.hardCoded.aeAppId,
              run_type: getRunType(configUserInputs, "inside"),
              run_name: configUserInputs.configName,
            }),
          })
            .then((response) => {
              console.groupCollapsed("requesting", url);
              console.log("REPSONSE -> ", response);
              status = response.status;
              return response.clone().json();
            })
            .then((json) => {
              if (status === 200) {
                setDisableButtons(true);
                refreshData();
                updateAlertInfo({
                  open: true,
                  message: json.statusMessage,
                  severity: "success",
                });
                history.push(
                  match.params.tab === "addnewconfig"
                    ? `/home/myconfigurations/`
                    : `/home/${match.params.tab}/`
                );
              } else {
                updateAlertInfo({
                  open: true,
                  message: json.statusMessage,
                  severity: "error",
                });
                history.push(
                  match.params.tab === "addnewconfig"
                    ? `/home/myconfigurations/`
                    : `/home/${match.params.tab}/`
                );
              }
            });
        } else {
          setDisableButtons(false);
          updateAlertInfo({
            open: true,
            message: json.statusMessage,
            severity: "error",
          });
        }
      });
  };

  const handleScheduleSaveConfig = () => {
    updateDialogInfo({ ...user.dialogInfo, open: false });
    let { status, message } = validateConfig(configUserInputs, allData);
    let finalConfig = makeConfigTemplate(configUserInputs, allData);
    let scheduleConfigDetails =
      allData.saved_configurations.find(
        (elem) => elem.id === configUserInputs.configId
      )?.schedule_details || {};

    // console.log("STATUS -> ", status);
    // console.log("MESSAGE -> ", message);
    status === "pass" &&
      handleDialogOpen(
        <ScheduleConfigContainer
          activeApp={activeApp}
          match={match}
          handleDialogClose={handleDialogClose}
          config={{
            ...(Object.keys(scheduleConfigDetails).length !== 0 && {
              schedule_details: scheduleConfigDetails,
            }),
            //todo to be changed
            id: match.params.configName ? configUserInputs.configId : "",

            name: finalConfig.name,
          }}
          finalConfig={finalConfig}
        />
      );
  };

  const handleSaveUpdate = (configUserInputs, allData) => {
    console.groupCollapsed("SAVE/UPDATE REQUESTED...");
    console.log("CONFIG NAME -> ", match.params.configName);
    let { status, message, partialDataset } = validateConfig(
      configUserInputs,
      allData
    );
    // console.log("STATUS -> ", status);
    // console.log("MESSAGE -> ", message);
    // console.log("PARTIAL DATASET -> ", partialDataset);
    let finalConfig = makeConfigTemplate(configUserInputs, allData);
    console.log("FINAL CONFIG -> ", finalConfig);

    if (status === "fail") {
      updateAlertInfo({ open: true, message, severity: "error" });
    }

    if (status === "pass") {
      if (!partialDataset?.length) {
        saveConfig();
      } else {
        let displayMessage = "";
        partialDataset.forEach(
          (elem, index) =>
            (displayMessage += index + 1 + ": " + elem.message + "\n")
        );
        updateDialogInfo({
          message: displayMessage + "\n Do you want to continue?",
          open: true,
          handleYes: saveConfig,
          handleNo: handleNo,
        });
      }
    }
    console.groupEnd();
  };

  const handleSaveUpdateRun = (configUserInputs, allData) => {
    console.groupCollapsed("REQUEST SENT");
    console.log("CONFIG NAME -> ", match.params.configName);
    let { status, message, partialDataset } = validateConfig(
      configUserInputs,
      allData
    );
    // console.log("STATUS -> ", status);
    // console.log("MESSAGE -> ", message);
    // console.log("PARTIAL DATASET -> ", partialDataset);
    let finalConfig = makeConfigTemplate(configUserInputs, allData);
    console.log("CONFIG -> ", finalConfig);

    if (status === "fail") {
      updateAlertInfo({ open: true, message, severity: "error" });
    }

    if (status === "pass") {
      let { statusWeek, messageWeek } = validateDatasetWeeksSync(
        configUserInputs.models,
        allData.datasets,
        parseFloat(configUserInputs.historic_week_data)
      );
      console.log("STATUS WEEK -> ", statusWeek);
      console.log("MESSAGE WEEK -> ", messageWeek);
      if (!partialDataset?.length) {
        configUserInputs?.is_schedule_config ||
        configUserInputs?.is_fixed_schedule_config
          ? handleScheduleSaveConfig()
          : saveAndRunConfig();
      } else {
        let displayMessage = "";
        partialDataset.forEach(
          (elem, index) =>
            (displayMessage += index + 1 + ": " + elem.message + "\n")
        );
        updateDialogInfo({
          message: displayMessage + "\n Do you want to continue?",
          open: true,
          handleYes:
            configUserInputs?.is_schedule_config ||
            configUserInputs?.is_fixed_schedule_config
              ? handleScheduleSaveConfig
              : saveAndRunConfig,
          handleNo: handleNo,
        });
      }
    }
    console.groupEnd();
  };

  const scheduleUpdateConfig = (configUserInputs, allData) => {
    console.groupCollapsed("SAVE/UPDATE REQUESTED...");
    console.log("CONFIG NAME -> ", match.params.configName);
    let validCheck = validateConfig(configUserInputs, allData);
    let finalConfig = makeConfigTemplate(configUserInputs, allData);
    console.log("FINAL CONFIG -> ", finalConfig);
    if (validCheck.status === "fail") {
      updateAlertInfo({
        open: true,
        message: validCheck.message,
        severity: "error",
      });
    }
    if (validCheck.status === "pass") {
      if (
        scheduleRunData.number_of_runs ||
        !configUserInputs.is_fixed_schedule_config
      ) {
        let newScheduleBody = {
          time_zone: scheduleRunData.timeZone,
          trigger_time: scheduleRunData.triggerTime,
          frequency_type: scheduleRunData.frequencyType,
          frequency_value: scheduleRunData.frequencyValue,
        };
        let scheduledBody =
          finalConfig &&
          scheduleRunData.scheduleType === "add" &&
          !finalConfig?.id
            ? newScheduleBody
            : { ...newScheduleBody, config_id: finalConfig.id };
        if (configUserInputs.is_fixed_schedule_config) {
          scheduledBody = {
            ...scheduledBody,
            number_of_runs: scheduleRunData.number_of_runs,
          };
        }
        let activeModelInfo = configUserInputs.models.find(
          (model) => model.id === activeModelId
        );
        let model_parameters =
          activeModelId === config.hardCoded.communityClusteringId
            ? { interface_details: activeModelInfo.config.interface_details }
            : activeModelId === config.hardCoded.propensityId
            ? {
                interface_details: activeModelInfo.config.interface_details,
                action_names: activeModelInfo.config.action_names.map((el) => {
                  const { id, ...remaining } = el;
                  return remaining;
                }),
              }
            : {
                num_topics: activeModelInfo.config.num_topics,
                semrush: activeModelInfo.config.semrush,
              };
        let restriction_check_info = {
          frequency_type: scheduleRunData.frequencyType,
          frequency_value: scheduleRunData.frequencyValue.replaceAll("/", "_"),
          country: configUserInputs.country,
          brand: configUserInputs.brand,
          model: activeModelId,
          weeks: configUserInputs.historic_week_data,
          model_parameters: model_parameters,
        };
        let body = finalConfig
          ? {
              config_info: finalConfig,
              schedule_info: scheduledBody,
            }
          : scheduledBody;
        body = configUserInputs.is_schedule_config
          ? { ...body, restriction_check_info: restriction_check_info }
          : body;
        let url = finalConfig
          ? config.api.configAndSchedule
          : config.api.scheduleConfigurl;
        let status;
        updateAlertInfo({
          open: true,
          message:
            scheduleRunData.scheduleType === "add"
              ? config.scheduleConfigRequestMessage
              : config.scheduleConfigUpdateRequestMessage,
          severity: "info",
        });

        fetch(url, {
          method: finalConfig
            ? scheduleRunData.scheduleType === "add" && !finalConfig?.id
              ? "POST"
              : "PUT"
            : scheduleRunData.scheduleType === "add"
            ? "POST"
            : "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        })
          .then((response) => {
            console.groupCollapsed("requesting", url);
            console.log("REPSONSE -> ", response);
            status = response.status;
            return response.clone().json();
          })
          .then((json) => {
            console.log("JSON -> ", json);
            console.groupEnd();
            if (status === 200) {
              if (finalConfig && scheduleRunData.scheduleType === "add") {
                updateAlertInfo({
                  open: true,
                  message: "Configuration saved and scheduled successfully",
                });
              } else if (finalConfig) {
                updateAlertInfo({
                  open: true,
                  message: "Configuration updated and scheduled successfully",
                });
              } else {
                updateAlertInfo({
                  open: true,
                  message:
                    scheduleRunData.scheduleType === "add"
                      ? config.scheduleConfigSuccessMessage
                      : config.scheduleConfigUpdateSuccess,
                  severity: "success",
                });
              }

              history.push(
                match.params.tab === "addnewconfig"
                  ? `/home/myconfigurations/`
                  : `/home/${match.params.tab}/`
              );
              reloadConfigs();
            } else {
              if (status === 500) {
                updateAlertInfo({
                  open: true,
                  message: config.messages.duplicateName,
                  severity: "success",
                });
              } else {
                updateAlertInfo({
                  open: true,
                  message: json.statusMessage,
                  severity: "success",
                });
              }
            }
          });
      } else {
        updateAlertInfo({
          open: true,
          message: "Number of runs cannot be 0",
          severity: "info",
        });
      }
    }
    console.groupEnd();
  };
  let nestedRunsArrayData = nestedRunsArray[0]?.data?.filter((elem) => {
    if (activeModelId === config.hardCoded.communityClusteringId) {
      if (elem.id === config.hardCoded.uploadAudienceRunId) {
        if (
          allData.appAccessDetails.feature_list.community_clustering_audience_data.hasOwnProperty(
            "write"
          )
        ) {
          return elem;
        }
      } else {
        return elem;
      }
    } else {
      if (elem.id === config.hardCoded.uploadAudienceRunId) {
        if (
          allData.appAccessDetails.feature_list.propensity_modelling_audience_data.hasOwnProperty(
            "write"
          )
        ) {
          return elem;
        }
      } else {
        return elem;
      }
    }
  });
  const buttonStyle = {
    marginLeft: "16px",
    fontSize: "12px",
    fontWeight: "bold",
    minWidth: "80px",
  };
  const is_fixed_schedule_config_flag =
    configUserInputs?.is_fixed_schedule_config &&
    configUserInputs.configType === "edit"
      ? configUserInputs?.fixed_runs !== scheduleRunData.number_of_runs &&
        scheduleRunData.configurationId
      : true;

  return (
    <div className={classes.root}>
      <Link
        className="disabled-style"
        to={
          match.params.tab === "addnewconfig"
            ? `/home/myconfigurations/`
            : `/home/${match.params.tab}/`
        }
      >
        <Button variant="outlined" style={buttonStyle}>
          Cancel
        </Button>
      </Link>

      {match.params.configName && (
        <Button
          disableRipple
          onClick={() => {
            updateDialogInfo({
              message: config.messages.confirmDelete,
              open: true,
              handleYes: handleDelete,
              handleNo: handleNo,
            });
          }}
          disabled={false}
          variant="outlined"
          style={buttonStyle}
        >
          Delete
        </Button>
      )}

      {(configUserInputs?.is_schedule_config ||
        configUserInputs?.is_fixed_schedule_config) &&
        activeStep > 0 &&
        ![
          config.hardCoded.nlpId,
          config.hardCoded.communityClusteringId,
        ].includes(activeModelId) && (
          <Button
            disableRipple
            disabled={
              oldPmModelSelected
                ? !oldPmModelSelected
                : !configUserInputs?.cmu_status?.status ||
                  configUserInputs?.cmu_status?.status === "loading" ||
                  !configUserInputs?.cmu_status?.data?.cc_pm
            }
            variant="outlined"
            onClick={() => {
              setActiveStep(activeStep - 1);
              onStepperChange(nestedRunsArrayData[activeStep - 1].id);
            }}
            style={{
              marginLeft: "16px",
              fontSize: "12px",
              fontWeight: "bold",
              minWidth: "80px",
              background:
                !changeInGlobalParamsFlag &&
                configUserInputs?.cmu_status?.status !== "loading" &&
                (configUserInputs?.cmu_status?.data?.cc_pm ||
                  oldPmModelSelected)
                  ? "#46596a"
                  : "rgba(0,0,0,0.4)",
              color: "#fff",
            }}
          >
            Previous
          </Button>
        )}

      {/* {(configUserInputs?.is_schedule_config ||
        configUserInputs?.is_fixed_schedule_config) &&
        ![
          config.hardCoded.nlpId,
          config.hardCoded.communityClusteringId,
        ].includes(activeModelId) &&
        activeStep <
          (nestedRunsArray
            ? nestedRunsArrayData?.length -
              (allData.appAccessDetails.feature_list
                .propensity_modelling_audience_data.write
                ? 2
                : 1)
            : 2) && (
          <Button
            disableRipple
            disabled={
              oldPmModelSelected
                ? !oldPmModelSelected
                : !configUserInputs?.cmu_status?.status ||
                  configUserInputs?.cmu_status?.status === "loading" ||
                  !configUserInputs?.cmu_status?.data?.cc_pm
            }
            variant="contained"
            onClick={() => {
              const validate = validateStepper(configUserInputs, activeModelId);
              if (validate) {
                setStepCompleted({ ...stepCompleted, [activeStep]: true });
                setActiveStep(activeStep + 1);
                onStepperChange(nestedRunsArrayData[activeStep + 1].id);
              } else {
                setStepCompleted({ ...stepCompleted, [activeStep]: false });
              }
            }}
            style={{
              marginLeft: "16px",
              fontSize: "12px",
              fontWeight: "bold",
              minWidth: "80px",
              background:
                !changeInGlobalParamsFlag &&
                configUserInputs?.cmu_status?.status !== "loading" &&
                (configUserInputs?.cmu_status?.data?.cc_pm ||
                  oldPmModelSelected)
                  ? "#46596a"
                  : "rgba(0,0,0,0.4)",
              color: "#fff",
            }}
          >
            Next
          </Button>
        )} */}
      {
        // this is because in NLP there is not any stepper so a save/update button should
        // be visible in NLP model
        (configUserInputs?.is_schedule_config ||
          configUserInputs?.is_fixed_schedule_config) &&
          (activeStep ===
            (nestedRunsArray
              ? nestedRunsArrayData?.length -
                (allData.appAccessDetails.feature_list
                  .propensity_modelling_audience_data.write
                  ? 2
                  : 1)
              : 2) ||
            [
              config.hardCoded.nlpId,
              config.hardCoded.communityClusteringId,
              config.hardCoded.propensityId,
              
            ].includes(activeModelId)) && (
            <Button
              disableRipple
              variant="contained"
              disabled={
                !changeInGlobalParamsFlag &&
                configUserInputs?.cmu_status?.status !== "loading" &&
                is_fixed_schedule_config_flag &&
                !oldPmModelSelected
                  ? false
                  : true
              }
              onClick={() => {
                setStepCompleted({ ...stepCompleted, [activeStep]: true });
                configUserInputs?.is_schedule_config ||
                configUserInputs?.is_fixed_schedule_config
                  ? scheduleUpdateConfig(configUserInputs, allData)
                  : handleSaveUpdate(configUserInputs, allData);
              }}
              style={{
                marginLeft: "16px",
                fontSize: "12px",
                fontWeight: "bold",
                background:
                  !changeInGlobalParamsFlag &&
                  configUserInputs?.cmu_status?.status !== "loading" &&
                  is_fixed_schedule_config_flag &&
                  !oldPmModelSelected
                    ? "#46596a"
                    : "rgba(0,0,0,0.4)",
                // color: !changeInGlobalParamsFlag ? "#fff" : "#46596a",
                color: "#fff",
              }}
            >
              {match.params.configName ? "Update" : "Save"}
            </Button>
          )
      }

      {!configUserInputs?.is_schedule_config &&
        !configUserInputs?.is_fixed_schedule_config && (
          <Button
            disableRipple
            disabled={
              !changeInGlobalParamsFlag &&
              configUserInputs?.cmu_status?.status !== "loading" &&
              !oldPmModelSelected
                ? false
                : true
            }
            variant="outlined"
            onClick={() => {
              handleSaveUpdateRun(configUserInputs, allData);
            }}
            style={{
              marginLeft: "16px",
              fontSize: "12px",
              fontWeight: "bold",
              background:
                !changeInGlobalParamsFlag &&
                configUserInputs?.cmu_status?.status !== "loading" &&
                !oldPmModelSelected
                  ? "#fff"
                  : "rgba(0,0,0,0.4)",
              color:
                !changeInGlobalParamsFlag &&
                configUserInputs?.cmu_status?.status !== "loading" &&
                !oldPmModelSelected
                  ? "#46596a"
                  : "#fff",
            }}
          >
            {match.params.configName ? "Update & Run" : "Save & Run"}
          </Button>
        )}

      {!configUserInputs?.is_schedule_config &&
        !configUserInputs?.is_fixed_schedule_config && (
          <Button
            disableRipple
            variant="contained"
            disabled={
              !changeInGlobalParamsFlag &&
              configUserInputs?.cmu_status?.status !== "loading" &&
              !oldPmModelSelected
                ? false
                : true
            }
            onClick={() => {
              handleSaveUpdate(configUserInputs, allData);
            }}
            style={{
              marginLeft: "16px",
              fontSize: "12px",
              fontWeight: "bold",
              background:
                !changeInGlobalParamsFlag &&
                configUserInputs?.cmu_status?.status !== "loading" &&
                !oldPmModelSelected
                  ? "#46596a"
                  : "rgba(0,0,0,0.4)",
              // color: !changeInGlobalParamsFlag ? "#fff" : "#46596a",
              color: "#fff",
            }}
          >
            {match.params.configName ? "Update" : "Save"}
          </Button>
        )}

      <Modal
        modalComponent={modalComponent}
        setModalComponent={setModalComponent}
        handleDialogClose={handleDialogClose}
      />
    </div>
  );
}

Buttons.propTypes = {};

const mapStateToProps = (state) => ({
  user: state.user,
  configUserInputs: state.configUserInputs,
  allData: state.data,
});

const mapDispatchToProps = {
  updateAlertInfo,
  updateUserInfo,
  updateDialogInfo,
  refreshData,
  reloadConfigs,
};

export default connect(mapStateToProps, mapDispatchToProps)(Buttons);

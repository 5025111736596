import React from "react";
import PropTypes from "prop-types";
import "../index.css";

function getDateStyle(theme, styleVariant) {
  return {
    borderRadius: "4px",
    height: "26px",
    color: `var(--datepicker-${styleVariant}-textcolor-${theme})`,
    border: "1px solid #dedede",
    paddingLeft: "4px",
    boxSizing: "border-box",
    textTransform: "uppercase",
    fontFamily: "var(--fontFamily)",
    fontSize: "var(--font-size-XS)",
  };
}

/**
 * [DatePicker description]
 * @param {[string]} theme [The theme of the component - default: light]
 * @param {[string]} styleVariant [Used to represent colored interface elements for a user - default: primary]
 * @param {[object]} style [The style object which is passed to the component]
 * @param {[any]} ...props [Other valid props for input date]
 * @returns {[object]} [Returns the DatePicker component]
 */
const DatePicker = (props) => {
  const {
    theme = "light",
    styleVariant = "primary",
    style = {},
    ...remaining
  } = props;
  const defaultStyle = getDateStyle(theme, styleVariant);
  const finalStyle = { ...defaultStyle, ...style };
  return (
    <input
      type="date"
      style={finalStyle}
      onKeyDown={(e) => e.preventDefault()}
      {...remaining}
    />
  );
};

DatePicker.propTypes = {
  theme: PropTypes.oneOf(["light", "dark"]),
  styleVariant: PropTypes.oneOf(["primary", "secondary"]),
};

export default DatePicker;

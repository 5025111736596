//Import required libraies
import React from "react";

//Import Custom Component
import LayoutTopSideBottom from "../../layouts/LayoutTopSideBottom/LayoutTopSideBottom";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import AddNewConfigContainer from "../AIDEApp/AddNewConfig/AddNewConfigContainer";
import AIDEAppContainer from "../AIDEAppContainer/AIDEAppContainer";

function AIDEFunction(props) {
  let { match } = props;

  return (
    <AIDEAppContainer>
      {/* <LayoutTopSideBottom match={match}>
        <Breadcrumbs match={match} /> */}
        <AddNewConfigContainer match={match} />
      {/* </LayoutTopSideBottom> */}
    </AIDEAppContainer>
  );
}

export default AIDEFunction;

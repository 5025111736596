//Import required libraies
import React, { useState, useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { connect } from "react-redux";

// Import styles

// Import utils & data
import { config } from "../../../../config/config";
import { makeDefaultResponseJson } from "../../../../utils/utils";
import { updateAlertInfo } from "../../../../redux/actions";
import { useFirstRender } from "../../../../hooks/useFirstRender";

const useStyles = makeStyles(() => ({
  typographyStyle: {
    border: "none !important",
    color: "#46596a",
    fontSize: 12,
    "& .MuiChip-root": {
      fontFamily: "Hind Siliguri",
      fontSize: 12,
      background: "white",
    },
    "& .MuiInput-underline:after": {
      borderBottom: 0,
    },
    "& .MuiFormControl-fullWidth": {
      minWidth: "225px",
      width: "225px",
    },
    "& .MuiInput-underline:before": {
      borderBottom: 0,
      transition: "none",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      // borderBottom: "1px solid #dedede !important",
      borderBottom: 0,
    },
    "& .Mui-focused .MuiInput-underline": {
      borderBottom: 0,
    },
    "& .MuiAutocomplete-paper": {
      fontFamily: "Hind Siliguri !important",
      fontSize: 6,
    },
    "& .MuiInputBase-input": {
      fontFamily: "Hind Siliguri",
      fontSize: 12,
      border: "none !important",
    },
    "& .MuiAutocomplete-option": {
      backgrounColor: "#46596a3b",
      fontFamily: "Hind Siliguri !important",
    },
    "& .Mui-disabled": {
      backgroundColor: "#f1f1f1",
      border: "1px solid #dedede",
      borderRadius: "3px",
      color: "#46596a",
      fontSize: 12,
    },
  },
  root: {
    fontFamily: "Hind Siliguri !important",
    display: "flex",
    alignItems: "center",
    backgroundColor: "white",
    height: "26px",
    borderRadius: "3px",
    width: "225px !important",
  },
}));

const StyledAutoComplete = withStyles(() => ({
  endAdornment: {
    display: "none",
  },
  tag: {
    color: "#46596a !important",
    fontFamily: "Hind Siliguri !important",
  },
  paper: {
    fontFamily: "Hind Siliguri",
    fontSize: 13,
    color: "#46596a",
  },
}))(Autocomplete);

function PropActionEventDetails(props) {
  let {
    id,
    name,
    enable,
    value,
    onChange,
    customClassName,
    disabledForUploadAudience,
    activeApp,
    updateAlertInfo,
    user,
  } = props;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const isFirstRender = useFirstRender();
  const classes = useStyles();
  const filter = createFilterOptions();

  const handleChange = (e, val) => {
    onChange(id, val);
  };

  useEffect(() => {
    // fetch Event actions and event categories
    const controller = new AbortController();
    const { signal } = controller;
    if (!isFirstRender.current) {
      let url = `${config.api.getEventDetailsForPropensityUrl}?app_id=${activeApp?.id}`;
      let status;
      fetch(url, { signal })
        .then((response) => {
          console.groupCollapsed("requesting", url);
          console.log("REPSONSE -> ", response);
          status = response.status;
          return response.clone().json();
        })
        .then((json) => {
          console.log("JSON -> ", json);
          console.groupEnd();
          if (status === 200) {
            setLoading(false);
            if (id === "eventAction") {
              setData(json?.data?.event_actions);
            } else if (id === "eventCategory") {
              setData(json?.data?.event_categories);
            } else if (id === "eventName") {
              setData(json?.data?.event_name);
            } else if (id === "eventVariable") {
              setData(json?.data?.event_variable);
            }
          } else {
            updateAlertInfo({
              open: true,
              message: json.statusMessage,
              severity: "error",
            });
          }
        });
    }
    return () => {
      controller.abort();
    };
  }, []);

  return (
    <div
      className={`input-item ${customClassName ? customClassName : ""} ${id}`}
    >
      {" "}
      <label title={name}>{name}:</label>
      <div className={classes.root}>
        <StyledAutoComplete
          id={`tags-standard-${name}`}
          freeSolo={true}
          size="small"
          style={{ fontFamily: "Hind Siliguri !important" }}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);
            // Suggest the creation of a new value
            if (params.inputValue !== "") {
              filtered.push({
                inputValue: params.inputValue,
                title: `Add "${params.inputValue}"`,
              });
            }
            return filtered;
          }}
          limitTags={5}
          renderTags={() => null}
          disableClearable
          className={classes.typographyStyle}
          options={data.sort()}
          // getOptionLabel={(option) => option || ""}
          getOptionLabel={(option) => {
            if (option.title) {
              return option.title;
            }
            if (option.inputValue) {
              return option.inputValue;
            }
            // Regular option
            return option;
          }}
          clearOnBlur={true}
          value={value ? value : ""}
          onChange={(e, value) => {
            if (value && value.inputValue) {
              // Create a new value from the user input
              handleChange(e, value.inputValue);
            } else {
              handleChange(e, value);
            }
          }}
          disabled={!enable || disabledForUploadAudience}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              size="small"
              placeholder="Type here"
              className={classes.typographyStyle}
              disabled={!enable || disabledForUploadAudience}
            />
          )}
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  updateAlertInfo,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PropActionEventDetails);

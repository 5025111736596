// Import required libraries
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// Import custom components
import UsersTable from "./UsersTable";

// Import utils
import { config } from "../../../config/config";

function UsersTableRow(props) {
  let { data, title, user, match } = props;

  return (
    <div className={`${user.theme} apps-row`}>
      {title && <h1 className="apps-row-title">{title}</h1>}
      {data?.length ? (
        <div>
          <UsersTable data={data} match={match} paginationFlag={true} />
        </div>
      ) : (
        <p className="no-data">
          <b>{config.messages.noAdminUsersFound}</b>
        </p>
      )}
    </div>
  );
}

UsersTableRow.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({ user: state.user });

export default connect(mapStateToProps)(UsersTableRow);
